import React, { useState, useRef } from 'react';
import { Container, Row, Form, FormGroup, Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { url } from '../Constant';
import { Btn, H4, P } from '../AbstractElements';

const SadminLogin = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    username: '',
    password: ''
  });

  const [errors, setErrors] = useState({
    username: '',
    password: ''
  });

  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  
  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormValues({
      ...formValues,
      [id]: value
    });

    const newErrors = {
      ...errors,
      [id]: value.trim() === '' ? 'This field is required' : ''
    };

    if (id === 'username') {
      newErrors.username = !validateEmail(value) ? 'Invalid username' : '';
    }

    setErrors(newErrors);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileRegex = /^\d{10}$/;
    return emailRegex.test(email) || mobileRegex.test(email);
  };

  const validate = () => {
    const newErrors = {
      username: formValues.username === '' || !validateEmail(formValues.username) ? 'Invalid username' : '',
      password: formValues.password === '' ? 'Password is required' : ''
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setLoginError('');
      setErrors({ username: '', password: '' }); // Reset errors before submission

      try {
        const response = await fetch(`${url}/auth/sup/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formValues),
        });

        const res = await response.json();
        if (response.ok) {
          localStorage.setItem('token', res.token);
          localStorage.setItem('role', res.role);
          localStorage.setItem('login', JSON.stringify(true));
          navigate(`${process.env.PUBLIC_URL}/sup/dashboard`);
        } else {
          const newErrors = { username: '', password: '' }; // Reset error messages

          if (res.error === "Invalid username") {
            newErrors.username = "Invalid username";
          } else if (res.error === "Invalid password") {
            newErrors.password = "Invalid password";
          } else {
            setLoginError("Login failed. Please try again.");
          }
          setErrors(newErrors);
        }
      } catch (error) {
        setLoginError(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container fluid={true} className='p-0 login-page '>
    <Toast ref={toast} />
    <div className='login-card'>
    <div className='login-main login-tab'>
    <Row>
      <Form className='form-bookmark needs-validation'>
              <H4>Sign In</H4>
              <P>Enter your user-name & password to login</P>

              {/* User Name Field */}
              <FormGroup>
                <Label className='col-form-label'>Username</Label>
                <Input
                  className={errors.username ? 'form-control is-invalid' : 'form-control'}
                  type='text'
                  id='username'
                  value={formValues.username}
                  onChange={handleChange}
                />
                <span style={{ fontSize: "15px" }} className="text-danger">{errors.username}</span>
              </FormGroup>

          <Label>{"Password"}</Label>
          <InputGroup>
              <Input className={errors.password === "" ? 'form-control' : 'form-control is-invalid'} id='password' value={formValues.password} onChange={handleChange} type={togglePassword?"text":"password"} placeholder=""  aria-label="Recipient's username" />
              <InputGroupText className='p-0 m-0'>
              <Button
                className='my-auto'
                variant="contained"
                disableElevation
                onClick={() => setTogglePassword(!togglePassword)}
                startIcon={togglePassword ? <VisibilityOff /> : <Visibility />}
                style={{
                  backgroundColor: 'transparent',
                  color: 'inherit', // Inherits the color from the parent or default text color
                  paddingRight: 0,
                  minWidth: 'auto',
                  height:"auto",
                  boxShadow: 'none',
                  border: "none",
                  '&:hover': {
                    backgroundColor: 'transparent',
                  }
                }}
              />
            </InputGroupText>
          </InputGroup>
          <span style={{ fontSize: "15px" }} className="text-danger">{errors.password}</span>
              {/* Buttons */}
              <div className='form-group mb-3'>
                <Label style={{ margin: "4% 0%" }}>
                  <a className='link' href='#javascript'>Forgot Password?</a>
                </Label>
                <Btn
                  attrBtn={{
                    color: 'primary',
                    className: 'd-block w-100 mt-2',
                    type: 'submit',
                    onClick: handleSubmit,
                    disabled: loading
                  }}
                >
                  Sign In
                </Btn>
              </div>
            </Form>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default SadminLogin;

import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FlatStyle, url } from '../../../Constant';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import FullPageLoader from '../../../Components/Loaders/FullPageLoader';
import { Skeleton } from 'primereact/skeleton';
import { padStart } from '@fullcalendar/core/internal';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { SplitButton } from 'primereact/splitbutton';
import * as XLSX from 'xlsx';
import Pagination from '../../../_helper/Pagination/Pagination';
import TableSkeleton from '../../../Components/Loaders/TableSkeleton';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { uniqueId } from 'lodash';
import AddMachineModal from './AddMachineModal';


// import { Box, Typography } from '@mui/material';
// import SadminSidebar from '../../components/Sidebar';


export default function MachineTable() {
    let emptyCompany = {
        catId: null,
        prodImage: '',
        prodName: '',
        prodType: '',
        prodDescr: '',
    };

    const [machineDialog, setMachineDialog] = useState(false);
    const [deleteCompanyDialog, setDeleteCompanyDialog] = useState(false);
    const [deleteCompaniesDialog, setDeleteCompaniesDialog] = useState(false);
    const [machine, setMachine] = useState(emptyCompany);
    const [selectedMachines, setSelectedMachines] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loader, setLoader] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [drawerWidth, setDrawerWidth] = React.useState(260);
    const [metadata, setMetadata] = useState({});
    const [machines, setMachines] = useState(null);
    const [currentPage, setCurrentPage] = useState(() => { return 1 });
    const [pageSize, setPageSize] = useState(() => { return 10 });
    const [statusLoader, setStatusloader] = useState(false);
    const [editMachineId,setEditMachineId]=useState();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const catId = queryParams.get('catId');
    //console.log(catId)
    useEffect(() => {
        //console.log(selectedMachines)
        //console.log(catId)
    }, [selectedMachines])

    const fetchMachines = () => {
        console.log(catId,"--------------------------------");
        setLoader(true);
        const filtersParams = ['prodId', 'prod_name', 'prod_type']; // Example array
        const params = new URLSearchParams({
            companyCode: localStorage.getItem('companyCode'),
            page: currentPage,
            limit: pageSize,
            filter: globalFilter || '',
            mainfilter_param: 'catId',
            mainfilter: catId,
        });

        // Append each filter as a separate query parameter
        filtersParams.forEach(filter => params.append('filterParams[]', filter));

        fetch(url + `/api/admin/get_machines_by_category?${params.toString()}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 403) {
                    setLoader(false);
                    toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
                    throw new Error('Unauthorized');
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then(result => {
                if (pageSize === 1000) {
                    setMachines(prevCategories => [...prevCategories, ...result.data]);
                } else {
                    setMachines(result.data);
                }
                setMetadata(result.metadata);
                setLoader(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setLoader(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while fetching data', life: 3000 });
            });
    }

    useEffect(() => {
        // Reset machines data when filter, pageSize, or currentPage changes
        setLoader(true);
        setMachines([]);
        fetchMachines();
    }, [currentPage, pageSize, globalFilter]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= metadata.totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handlePageSizeChange = (event) => {
        // //console.log(event.target.value);
        setPageSize(Number(event.target.value));
        //console.log("page size set to ", event.target.value)
        setCurrentPage(1); // Reset to first page on page size change
    };



    const openNew = () => {
        setMachine(emptyCompany);
        setSubmitted(false);
        setMachineDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setMachineDialog(false);
    };

    const hideDeleteCompanyDialog = () => {
        setDeleteCompanyDialog(false);
    };

    const hideDeleteCompaniesDialog = () => {
        setDeleteCompaniesDialog(false);
    };



    const saveMachine = () => {
        setSubmitted(true);

        if (machine.machineName.trim()) {
            let _machines = [...machines];
            let _machine = { ...machine };

            if (machine.machine_code) {
                const index = findIndexById(machine.machine_code);

                _machines[index] = _machine;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Updated', life: 3000 });
            } else {
                _machine.machine_code = createId();
                _machines.push(_machine);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Created', life: 3000 });
            }

            setMachines(_machines);
            setMachineDialog(false);
            setMachine(emptyCompany);
        }
    };

    const editCompany = (machine) => {
        setMachine({ ...machine });
        setMachineDialog(true);
    };

    const confirmDeleteCompany = (machine) => {

        setMachine(machine);
        setDeleteCompanyDialog(true);
    };

    const confirmDelete = (machine) => {
        confirmDialog({
            message: 'Are you sure you want to delete?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDelete(machine),
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptClassName: "custom-accept-button",
            rejectClassName: "custom-reject-button"
        });
    }

    const handleDelete = (machine) => {
        //console.log(machine)
        const collection = "machines"
        const field = "prodId"
        const id = machine.prodId;
        fetch(`${url}/api/admin/field/delete?collection=${collection}&field=${field}&id=${id}`, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (!response.ok) {
                setLoader(false);
                throw new Error('Network response was not ok');
            }
            setLoader(false);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Machine deleted', life: 3000 });
            fetchMachines();
        }).catch((err) => {
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Failed to delete', life: 3000 });
            //console.log(err)
        })
    }

    const deleteCompany = async () => {
        setLoader(true);
        //console.log(machine.machine_code)
        // //console.log(rowData.status, "++++++++++")
        try {
            // Perform the POST request to update the status
            const response = await fetch(`${url}/api/sup/delete_admin`, {
                method: 'delete',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    machine_code: machine.machine_code,
                })
            });
            if (!response.ok) {
                setLoader(false);
                throw new Error('Network response was not ok');

            }
            const result = await response.json();
            if (response.status == 200) {
                setLoader(false);
                //console.log("OK")
                // Update local state if the status change was successful
                fetchMachines();
                // const index = findIndexById(rowData.machine_code);
                // let _machines = [...machines];
                // _machines[index] = { ...rowData, status: newStatus };
                // setMachines(_machines);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Deleted', life: 3000 });
            } else {
                throw new Error(result.message || 'Failed to Delete status');
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setLoader(false);
            setDeleteCompanyDialog(false);
            // //console.log("", newStatus);
        }
        // Delete API Call

        // let _machines = machines.filter((val) => val.machine_code !== machine.machine_code);
        // setMachines(_machines);
        // setDeleteCompanyDialog(false);
        // setMachine(emptyCompany);
        // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Deleted', life: 3000 });
    };

    const machineDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveMachine} />
        </React.Fragment>
    );

    const onCategoryChange = (e) => {
        let _machine = { ...machine };

        _machine['category'] = e.value;
        setMachine(_machine);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _machine = { ...machine };
        _machine[`${name}`] = val;
        setMachine(_machine);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _machine = { ...machine };
        _machine[`${name}`] = val;
        setMachine(_machine);
    };



    const findIndexById = (machine_code) => {
        let index = -1;

        for (let i = 0; i < machines.length; i++) {
            if (machines[i].machine_code === machine_code) {
                index = i;
                break;
            }
        }
        return index;
    };

    const createId = () => {
        let machine_code = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            machine_code += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return machine_code;
    };



    const exportOptions = [
        {
            label: 'Export as PDF',
            icon: 'pi pi-file-pdf',
            command: () => {
                exportToPDF();
                toast.current.show({ severity: 'success', summary: 'Exported', detail: 'Data Exported' });
            }
        },
        {
            label: 'Export CSV',
            icon: 'pi-file',
            command: () => {
                exportCSV();
                toast.current.show({ severity: 'success', summary: 'Exported', detail: 'Data Exported' });
            }
        },
    ];


    const exportCSV = () => {
        // Use the same column headers as in your PDF export
        // const columns = [
        //     { title: "Company Code", dataKey: "machineCode" },
        //     { title: "Company Name", dataKey: "machineName" },
        //     { title: "Prefix", dataKey: "prefix" },
        //     { title: "Contact Person", dataKey: "contactPerson" },
        //     { title: "Contact Number", dataKey: "contactNumber" },
        //     { title: "Registration Date/Time", dataKey: "registrationDate" },
        //     { title: "Status", dataKey: "status" }
        // ];

        // Extract the data
        const machineData = selectedMachines || machines;
        const rows = machineData.map(machine => ({
            machineCode: `FR${machine.CompanyDetails?.machinePrefix || "---"}${machine.machine_code ? padStart(machine.machine_code, 4, '0') : "----"}`,
            machineName: machine.CompanyDetails?.machineName || "N/A",
            prefix: machine.CompanyDetails?.machinePrefix || "N/A",
            contactPerson: `${machine.PersonalDetails?.firstName || "N/A"} ${machine.PersonalDetails?.middleName || ""} ${machine.PersonalDetails?.lastName || ""}`,
            contactNumber: machine.mobile || "N/A",
            registrationDate: machine.register_date || "N/A",
            status: machine.status ? "Active" : "Inactive"
        }));

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(rows, { header: columns.map(col => col.title) });

        // Create a new workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Selected Companies');
        // Write the file
        XLSX.writeFile(wb, 'Companies.xlsx');
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.text("Companies List", 14, 16);

        const machineData = selectedMachines || machines;


        const columns = [
            { title: "Company Code", dataKey: "machineCode" },
            { title: "Company Name", dataKey: "machineName" },
            { title: "Prefix", dataKey: "prefix" },
            { title: "Contact Person", dataKey: "contactPerson" },
            { title: "Contact Number", dataKey: "contactNumber" },
            { title: "Registration Date/Time", dataKey: "registrationDate" },
            { title: "Status", dataKey: "status" }
        ];
        const rows = machineData.map(machine => ({
            machineCode: `FR${machine.CompanyDetails?.machinePrefix || "---"}${machine.machine_code ? padStart(machine.machine_code, 4, '0') : "----"}`,
            machineName: machine.CompanyDetails?.machineName || "N/A",
            prefix: machine.CompanyDetails?.machinePrefix || "N/A",
            contactPerson: (machine.PersonalDetails?.firstName || "N/A") + " " + (machine.PersonalDetails?.middleName || "") + " " + (machine.PersonalDetails?.lastName || ""),
            contactNumber: machine.mobile || "N/A",
            registrationDate: machine.register_date || "N/A",
            status: machine.status ? "Active" : "Inactive"
        }));
        doc.autoTable(columns, rows, { startY: 22 });
        doc.save('machines.pdf');
    };

    const confirmDeleteSelected = () => {
        setDeleteCompaniesDialog(true);
    };

    const deleteSelectedCompanies = async () => {
        setLoader(true);
        const machineCodes = selectedMachines.map(machine => machine.machine_code);
        //console.log(machineCodes)

        // //console.log(filteredCompanies)
        // //console.log(rowData.status, "++++++++++")
        try {
            // Perform the POST request to update the status
            const response = await fetch(`${url}/api/sup/delete_admins`, {
                method: 'delete',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    machineCodes: machineCodes,
                })
            });
            if (!response.ok) {
                setLoader(false);
                throw new Error('Network response was not ok');

            }
            const result = await response.json();
            if (response.status === 200) {
                setLoader(false);
                //console.log("OK")
                // Update local state if the status change was successful
                fetchMachines();
                // const index = findIndexById(rowData.machine_code);
                // let _machines = [...machines];
                // _machines[index] = { ...rowData, status: newStatus };
                // setMachines(_machines);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Deleted', life: 3000 });
                // deleteCompanyDialog(false);
            } else {
                throw new Error(result.message || 'Failed to Delete status');
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setLoader(false);
            // //console.log("", newStatus);
        }

        setDeleteCompaniesDialog(false);
        setSelectedMachines(null);
        //console.log(selectedMachines)
    };


    const leftToolbarTemplate = () => {
        return (<>
            <SplitButton label="EXPORT" className='mx-1' onClick={exportCSV} icon="pi pi-file-xls" severity='danger' model={exportOptions} />
            {/* <Button label="Add Machine" icon="pi pi-plus" className="p-button-help mx-1" onClick={openNew} /> */}
            <AddMachineModal editMachineId={editMachineId} setEditMachineId={setEditMachineId} machineDialog={machineDialog} setMachineDialog={setMachineDialog}/>
            <FormControl variant="outlined" className='mx-1' size="small" style={{ minWidth: 75 }}>
                <InputLabel>Items</InputLabel>
                <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    label="Items"
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </Select>
            </FormControl>
        </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </IconField>
        )
        // <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />;
    };

    const onstatusChange = async (rowData) => {
        setStatusloader(true)
        const newStatus = rowData.status === true ? false : true;

        try {
            const response = await fetch(`${url}/api/admin/change_status`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    collectionName: 'machines',
                    status: newStatus,
                    uniqueRow: 'prodId',
                    id: rowData.prodId
                })
            });
            if (!response.ok) {
                // setLoader(false);
                setStatusloader(false)
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            if (response.status == 200) {
                setStatusloader(false)
                //console.log("OK")
                fetchMachines();
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Status Updated', life: 3000 });
            } else {
                setStatusloader(false)
                throw new Error(result.message || 'Failed to update status');
            }
        } catch (error) {
            setStatusloader(false)
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setStatusloader(false)
            //console.log("Status changed: ", newStatus);
        }
    };


    const statusBodyTemplate = (rowData) => {
        return (
            <Button style={{ fontSize: "12px", padding: "2px", cursor: "pointer", backgroundColor: rowData.status ? "green" : "red" }} onClick={() => onstatusChange(rowData)} value={rowData.status} severity={getSeverity(rowData)}>{rowData.status ? "Active" : "Inactive"}</Button>
        );
    };


    function padZero(value) {
        const strValue = String(value);

        return strValue.padStart(4, '0');
    }

    const machineCodeBody = (rowData) => {
        return (
            <div>{`FR${rowData.CompanyDetails ? rowData.CompanyDetails.machinePrefix : "---"}${rowData.machine_code ? padZero(rowData.machine_code) : "----"}`}</div>
        )
    }
    const nameBodyTemplate = (rowData) => {
        return <div>{(rowData.PersonalDetails && rowData.PersonalDetails.firstName) ? rowData.PersonalDetails.firstName + " " + ((rowData.PersonalDetails.middleName !== undefined && rowData.PersonalDetails.middleName !== "") ? rowData.PersonalDetails.middleName : "") + " " + ((rowData.PersonalDetails.lastName !== undefined && rowData.PersonalDetails.lastName !== "") ? rowData.PersonalDetails.lastName : "") : "N/A"}</div>

    }
    function formatTimestampDDMMYYYY(timestamp) {
        // Create a new Date object from the timestamp
        const date = new Date(timestamp);

        // Extract day, month, and year
        const day = String(date.getUTCDate()).padStart(2, '0'); // Pad single-digit days with leading zero
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
        const year = date.getUTCFullYear();

        // Format the date as DD-MM-YYYY
        return `${day}-${month}-${year}`;
    }



    const formattedDate = (rowData) => {
        return <div>{formatTimestampDDMMYYYY(rowData.register_date)}</div>
    }
    const lastActiveBodyTemplate = (rowData) => {
        return <div>{(rowData.status) ? <div className='text-success'>Currently Active</div> : <div className='text-danger'>{rowData.deactivated_at}</div>}</div>

    }

    const linkBodyTemplate = (rowData) => {
        return (<Link to={`${process.env.PUBLIC_URL}/sup/machine/profile/${rowData.machine_code}`}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <img src={rowData && rowData.prod_image} alt="category Logo" className="shadow-2" style={{ width: '35px', height: '35px', borderRadius: "50%" }} />
                <div className="my-auto" style={{ marginLeft: "7px" }}>{rowData && rowData.prodName}</div>
                {rowData.subscription && <i className='pi pi-crown my-auto mx-2' style={{ color: "#fc9403", fontSize: "1rem" }} />}
            </div>
        </Link>);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    rounded
                    outlined
                    severity="success"
                    onClick={() => {setEditMachineId(rowData.prodId);setMachineDialog(true)}}
                    style={{
                        outline: 'none',
                        border: 'none', // Remove border if needed
                        boxShadow: 'none' // Remove box-shadow if needed
                    }}
                />
                <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                    onClick={() => confirmDelete(rowData)}
                    style={{
                        outline: 'none',
                        border: 'none', // Remove border if needed
                        boxShadow: 'none' // Remove box-shadow if needed
                    }}
                />
            </React.Fragment>
        );
    };

    const getSeverity = (machine) => {
        switch (machine.status) {
            case true:
                return 'success';
            case false:
                return 'danger';
            default:
                return 'danger';
        }
    };

    const deleteCompanyDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteCompanyDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteCompany} />
        </React.Fragment>
    );

    const deleteCompaniesDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteCompaniesDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedCompanies} />
        </React.Fragment>
    );

    const columns = [
        { selectionMode: 'multiple', exportable: false, style: { width: '3rem', fontSize: "13px" } },
        // { field: 'machineLogo', header: 'Company Logo', body: logoBodyTemplate, style: { minWidth: '10rem', fontSize: "13px" } },
        {
            field: 'prodId',
            header: 'Product ID',
            sortable: true,
            style: { minWidth: '10rem', fontSize: "13px" }
        },
        {
            field: 'prodName',
            header: 'Product Name',
            body: linkBodyTemplate,
            sortable: true,
            style: { minWidth: '16rem', fontSize: "13px" }
        },
        {
            field: 'prodDescr',
            header: 'Product Description',
            style: { minWidth: '5rem', fontSize: "13px" }
        },
        {
            field: 'status',
            header: 'Status',
            body: statusBodyTemplate,
            style: { minWidth: '5rem', fontSize: "13px" }
        },
        {
            header: 'Action',
            body: actionBodyTemplate,
            exportable: false,
            style: { minWidth: '8rem' }
        }
    ];

    const [distanceFromBottom, setDistanceFromBottom] = useState(0);
    const [noOfscrolls, setnoOfscrolls] = useState(0)
    const divRef = useRef(null); // Ref to access the div element

    // Function to be called when reaching the bottom
    const onReachBottom = () => {
        //console.log(pageSize)
        if (!loader && pageSize === 1000) {
            setnoOfscrolls(noOfscrolls + 1);
            setCurrentPage(noOfscrolls)
            //console.log('ififif');

        }
        //console.log('Reached the bottom of the page');
        // Add your logic here;
        // Add your logic here
    };

    useEffect(() => {
        const calculateDistanceFromBottom = () => {
            if (divRef.current) {
                const rect = divRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const distance = viewportHeight - rect.bottom;

                // Call function when the div is at or near the bottom
                if (distance >= 0) {
                    onReachBottom();
                }
            }
        };

        // Initial calculation
        calculateDistanceFromBottom();

        // Recalculate on window resize and scroll
        window.addEventListener('resize', calculateDistanceFromBottom);
        window.addEventListener('scroll', calculateDistanceFromBottom);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('resize', calculateDistanceFromBottom);
            window.removeEventListener('scroll', calculateDistanceFromBottom);
        };
    }, []); // Empty dependency array to run only once on mount


    return (
        <>
            <div className="datatable-crud-demo">
                <Breadcrumbs mainTitle="Machines" parent="General" title="Machines" />
                <FullPageLoader open={statusLoader} />
                <Toast ref={toast} />
                <div className="card">
                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
                    <DataTable
                        ref={dt}
                        value={machines}
                        selection={selectedMachines}
                        onSelectionChange={(e) => setSelectedMachines(e.value)}
                        dataKey="prodId"
                        paginator={false}
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        // globalFilter={globalFilter}
                        // header={header}
                        emptyMessage={" "}
                        className="datatables-demo"
                    >
                        {columns.map((col, i) => (
                            <Column key={i} field={col.field} header={col.header} body={col.body} sortable={col.sortable} style={col.style} exportable={col.exportable} selectionMode={col.selectionMode} />
                        ))}
                    </DataTable>
                    {loader && (
                        <TableSkeleton rowCount={pageSize} columns={columns} />
                    )}

                    <Pagination
                        currentPage={currentPage}
                        totalPages={metadata?.totalPages}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        pageSize={pageSize}
                        setLoader={setLoader}
                    />
                    {/* <p>Distance from bottom of viewport: {distanceFromBottom}px</p> */}
                </div>


                {/* <Dialog visible={machineDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Category Details" modal className="p-fluid" footer={machineDialogFooter} onHide={hideDialog}>
                    {machine.image && <img src={`https://primefaces.org/cdn/primereact/images/category/${machine.image}`} alt={machine.image} className="category-image block m-auto pb-3" />}
                    <div className="field">
                        <label htmlFor="name" className="font-bold">
                            Name
                        </label>
                        <InputText id="name" value={machine.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !machine.name })} />
                        {submitted && !machine.name && <small className="p-error">Name is required.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="description" className="font-bold">
                            Description
                        </label>
                        <InputTextarea id="description" value={machine.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
                    </div>

                    <div className="field">
                        <label className="mb-3 font-bold">Category</label>
                        <div className="formgrid grid">
                            <div className="field-radiobutton col-6">
                                <RadioButton inputId="category1" name="category" value="Accessories" onChange={onCategoryChange} checked={machine.category === 'Accessories'} />
                                <label htmlFor="category1">Accessories</label>
                            </div>
                            <div className="field-radiobutton col-6">
                                <RadioButton inputId="category2" name="category" value="Clothing" onChange={onCategoryChange} checked={machine.category === 'Clothing'} />
                                <label htmlFor="category2">Clothing</label>
                            </div>
                            <div className="field-radiobutton col-6">
                                <RadioButton inputId="category3" name="category" value="Electronics" onChange={onCategoryChange} checked={machine.category === 'Electronics'} />
                                <label htmlFor="category3">Electronics</label>
                            </div>
                            <div className="field-radiobutton col-6">
                                <RadioButton inputId="category4" name="category" value="Fitness" onChange={onCategoryChange} checked={machine.category === 'Fitness'} />
                                <label htmlFor="category4">Fitness</label>
                            </div>
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="price" className="font-bold">
                                Price
                            </label>
                            <InputNumber id="price" value={machine.price} onValueChange={(e) => onInputNumberChange(e, 'price')} mode="currency" currency="USD" locale="en-US" />
                        </div>
                        <div className="field col">
                            <label htmlFor="quantity" className="font-bold">
                                Quantity
                            </label>
                            <InputNumber id="quantity" value={machine.quantity} onValueChange={(e) => onInputNumberChange(e, 'quantity')} />
                        </div>
                    </div>
                </Dialog> */}



                <Dialog visible={deleteCompanyDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCompanyDialogFooter} onHide={hideDeleteCompanyDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {machine && (
                            <span>
                                Are you sure you want to delete <b>{machine.machineName}</b>?
                            </span>
                        )}
                    </div>
                </Dialog>

                <Dialog visible={deleteCompaniesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCompaniesDialogFooter} onHide={hideDeleteCompaniesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {selectedMachines && <span>Are you sure you want to delete the selected machines?</span>}
                    </div>
                </Dialog>
                <ConfirmDialog />
            </div>
        </>
    );
}
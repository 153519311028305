// import React from 'react';
// import React, { Fragment } from "react";
// import { Btn, H4 } from "../AbstractElements";
// import { useForm } from "react-hook-form";
// import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap'
// import { Company, UsersCountryMenu, Address, EmailAddress, BirthDate, Mobile, ConfirmPassword, Designation, Gender, MiddleName, NextStep, PrevStep, FirstName, LastName, Password, } from '../Constant';

// const PersonalDetails = () => {
//   const { register, handleSubmit, formState: { errors } } = useForm();
//   const onEditSubmit = (data) => {
//     alert(data)
//   }
// return (
//   <Fragment>
//     <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
//       <CardHeader>
//         <H4 attrH4={{ className: "card-title mb-0" }}>{`Personal Detail`}</H4>
//         <div className="card-options">
//           <a className="card-options-collapse" href="#javascript">
//             <i className="fe fe-chevron-up"></i>
//           </a>
//           <a className="card-options-remove" href="#javascript">
//             <i className="fe fe-x"></i>
//           </a>
//         </div>
//       </CardHeader>
//       <CardBody>
//         <Row>
//           <Col md="6">
//             <FormGroup className="mb-3"> <Label className="form-label">{FirstName}</Label>
//               <Input className="form-control" type="text" placeholder="First Name" {...register("company", { required: true })} /><span style={{ color: "red" }}>{errors.CompanyName && 'Company Name is required'} </span>
//             </FormGroup>
//           </Col>
//           <Col md="6">
//             <FormGroup> <Label className="form-label">{MiddleName}</Label>
//               <Input className="form-control" type="text" placeholder="Middle Name" {...register("Username", { required: true })} /><span style={{ color: "red" }}>{errors.ComplexPrefix && 'Complex Prefix is required'} </span>
//             </FormGroup>
//           </Col>
//           <Col md="6">
//             <FormGroup> <Label className="form-label">{LastName}</Label>
//               <Input className="form-control" type="text" placeholder="Last Name" {...register("Username", { required: true })} /><span style={{ color: "red" }}>{errors.ComplexPrefix && 'Complex Prefix is required'} </span>
//             </FormGroup>
//           </Col>
//           <Col md="6">
//             <FormGroup> <Label className="form-label">{BirthDate}</Label>
//               <Input className="form-control" type="date" placeholder="DOB" {...register("EmailAddress", { required: true })} /><span style={{ color: "red" }}>{errors.EmailAddress && 'EmailAddress is required'} </span>
//             </FormGroup>
//           </Col>
//           <Col md="6">
//             <FormGroup><Label className="form-label">{Gender}</Label>
//               <Input type="select" name="select" className="form-control btn-square">
//                 {UsersCountryMenu.map((items, i) =>
//                   <option key={i}>{items}</option>
//                 )}
//               </Input>
//             </FormGroup>
//           </Col>
//           <Col sm="6" md="6">
//             <FormGroup><Label className="form-label">{Designation}</Label>
//               <Input className="form-control" type="text" placeholder="Designation" {...register("FirstName", { required: true })} /><span style={{ color: "red" }}>{errors.GSTIN && 'GSTIN is required'} </span>
//             </FormGroup>
//           </Col>
//           <Col sm="6" md="6">
//             <FormGroup><Label className="form-label">{Password}</Label>
//               <Input className="form-control" type="text" placeholder="Password" {...register("PAN Number", { required: true })} /><span style={{ color: "red" }}>{errors.PANNumber && 'PAN Number is required'} </span>
//             </FormGroup>
//           </Col>
//           <Col md="12">
//             <FormGroup><Label className="form-label">{ConfirmPassword}</Label>
//               <Input className="form-control" type="text" placeholder="Confirm Password" {...register("Address", { required: true })} /><span style={{ color: "red" }}>{errors.CompanyAddress && ' Company Address is required'} </span>
//             </FormGroup>
//           </Col>
//           <Col md="12">
//             <FormGroup><Label className="form-label">{Mobile}</Label>
//               <Input className="form-control" type="phone" placeholder="Mobile" {...register("Address", { required: true })} /><span style={{ color: "red" }}>{errors.CompanyAddress && ' Company Address is required'} </span>
//             </FormGroup>
//           </Col>




//         </Row>
//       </CardBody>
//       {/* <CardFooter className="text-"> */}
//       <div style={{ display: 'flex', justifyContent: "space-between", margin: "2rem" }}>

//         <Btn className="text-start" attrBtn={{ color: "primary", type: "submit" }} >{PrevStep}</Btn>
//         {/* </CardFooter> */}
//         {/* <CardFooter className="text-end"> */}
//         <Btn attrBtn={{ color: "primary", type: "submit" }} >{NextStep}</Btn>
//         {/* </CardFooter> */}
//       </div>

//     </Form>
//   </Fragment>
// )

// }
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, TextField, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import { Button } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useFormData } from '../Components/FormDataProvider';
import { url } from '../Constant';
import CircularIntegration from '../Components/CircularIntegration';
import { useLocation } from 'react-router-dom';

import { green } from '@mui/material/colors';

export default function PersonalDetails({ handleBack, handleNext, setActiveTabs, setValue, isUpdate, onValidate }) {
    const location = useLocation();
    const showPasswordField = (location.pathname === '/fix-rental/registration'); //hide or show Password field 

    const { updateFormData, combinedFormData } = useFormData();
    const Gender = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'other', label: 'Other' },
    ];

    const [formData, setFormData] = useState((combinedFormData && combinedFormData.PersonalDetails) ? combinedFormData.PersonalDetails : {
        firstName: '',
        middleName: '',
        lastName: '',
        dateOfBirth: null,
        gender: '',
        designation: '',
        password: '',
        confirmPassword: '',
        mobile: '',
        otp: ''
    });

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        dateOfBirth: '',
        gender: '',
        designation: '',
        password: '',
        confirmPassword: '',
        mobile: '',
        otp: ''
    });


    const [otpSent, setOtpSent] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState((combinedFormData && combinedFormData.PersonalDetails) ? true : false);
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const [otpTimer, setOtpTimer] = useState(null);
    const [resendOtp, setResendOtp] = useState(false);
    const [remainingTime, setRemainingTime] = useState(45);
    const [loading, setLoading] = useState(false);

    // Handle form field changes
    const handleChange = (e) => {
        let { id, value } = e.target;
        if (id === 'otp' && value.toString().length > 4) {
            value = value.toString().slice(0, 4); // Truncate to 11 characters
        }
        if (id === 'mobile' && value.toString().length > 10) {
            value = value.toString().slice(0, 10); // Truncate to 11 characters
        }
        let errors = { ...formErrors };

        switch (id) {
            case 'firstName':
                errors.firstName = value.trim() ? '' : 'First Name is required';
                break;
            case 'lastName':
                errors.lastName = value.trim() ? '' : 'Last Name is required';
                break;
            case 'dateOfBirth':
                errors.dateOfBirth = value ? '' : 'Date of Birth is required';
                break;
            case 'gender':
                errors.gender = value ? '' : 'Gender is required';
                break;
            case 'designation':
                errors.designation = value.trim() ? '' : 'Designation is required';
                break;
            case 'password':
                errors.password = value.trim() ? '' : 'Password is required';
                if (value === formData.confirmPassword) {
                    errors.confirmPassword = '';
                } else if (value !== formData.confirmPassword) {
                    //console.log("object")
                    errors.confirmPassword = 'Passwords do not match';
                }
                break;
            case 'confirmPassword':
                if (value.trim() === '') {
                    errors.confirmPassword = 'Confirm Password is required';
                } else if (value !== formData.password) {
                    errors.confirmPassword = 'Passwords do not match';
                } else {
                    errors.confirmPassword = '';
                }
                break;
            case 'mobile':
                if (/^\d+$/.test(value)) {
                    errors.mobile = value.trim() ? '' : 'Enter Valid Mobile Number';
                    errors.mobile = value.length > 9 ? '' : 'Enter Valid Mobile Number';
                } else {
                    errors.mobile = 'Mobile must contain only digits';
                }
                break;
            case 'otp':
                errors.otp = value.trim() ? false : true;
                break;
            default:
                break;
        }

        setFormData({ ...formData, [id]: value });
        setFormErrors(errors);
        let isFormValid = true;
        Object.values(errors).forEach(error => {
            if (error) {
                isFormValid = false;
            }
        });
        //console.log(errors)
        onValidate(isFormValid && isOtpVerified ? true : false);
        if (isFormValid && isOtpVerified) {
            updateFormData('PersonalDetails', formData);
            // handleNext();
            // setValue(1);
        }
    };

    const handleGenderChange = (id, value) => {
        let errors = { ...formErrors };
        errors.gender = value ? '' : 'Gender is required';
        setFormData({ ...formData, [id]: value.value });
        setFormErrors(errors);
    };

    // Handle date picker change
    const handleDateChange = (date) => {
        setFormData({ ...formData, dateOfBirth: date });
        setFormErrors({ ...formErrors, dateOfBirth: date ? '' : 'Date of Birth is required' });
    };

    // Validate form fields
    const validateForm = () => {
        let errors = { ...formErrors };
        let formIsValid = true;

        Object.entries(formData).forEach(([key, value]) => {
            switch (key) {
                case 'firstName':
                    errors.firstName = value.trim() ? '' : 'First Name is required';
                    break;
                case 'lastName':
                    errors.lastName = value.trim() ? '' : 'Last Name is required';
                    break;
                case 'dateOfBirth':
                    errors.dateOfBirth = value ? '' : 'Date of Birth is required';
                    break;
                case 'gender':
                    errors.gender = value ? '' : 'Gender is required';
                    break;
                case 'designation':
                    errors.designation = value.trim() ? '' : 'Designation is required';
                    break;
                case 'password':
                    if (showPasswordField) {
                        errors.password = value.trim() ? '' : 'Password is required';
                        if (value === formData.confirmPassword) {
                            errors.confirmPassword = '';
                        } else if (value !== formData.confirmPassword) {
                            //console.log("object")
                            errors.confirmPassword = 'Passwords do not match';
                        }
                        break;
                    }
                case 'confirmPassword':
                    if (showPasswordField) {
                        if (value.trim() === '') {
                            errors.confirmPassword = 'Confirm Password is required';
                        } else if (value !== formData.password) {
                            errors.confirmPassword = 'Passwords do not match';
                        } else {
                            errors.confirmPassword = '';
                        }
                        break;
                    }

                case 'mobile':
                    if (/^\d+$/.test(value)) {
                        errors.mobile = value.trim() ? '' : 'Enter Valid Mobile Number';
                    } else {
                        errors.mobile = 'Mobile must contain only digits';
                    }
                    break;
                case 'otp':
                    errors.otp = value.trim() ? '' : 'OTP is required';
                    break;
                default:
                    break;
            }
        });

        setFormErrors(errors);

        // Check if there are any errors
        Object.values(errors).forEach((error) => {
            if (error.length > 0) {
                formIsValid = false;
            }
        });
        return formIsValid;
    };

    // Send OTP API call
    const sendOTP = () => {
        setLoading(true);
        if (!formData.mobile.trim() || !/^\d+$/.test(formData.mobile)) {
            // Mobile number validation failed
            setFormErrors({ ...formErrors, mobile: 'Please enter a valid mobile number' });
            return;
        }

        // Implement API call to send OTP
        // Example:
        //console.log("object")
        fetch(url + '/auth/register/send_mobile_otp', {
            method: 'POST',
            body: JSON.stringify({ mobile: formData.mobile }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async (response) => {
                setLoading(false);
                if (response.ok) {
                    setOtpSent(true)
                    startOtpTimer();
                    setResendOtp(true);
                    setFormErrors({ ...formErrors, mobile: '' });
                    response.json().then((result)=>{
                        console.log(result);
                    })
                } else {
                    const data = await response.json()
                    setFormErrors({ ...formErrors, mobile: data });
                }
            })
            .catch(error => {
            });
    };

    const startOtpTimer = () => {
        setOtpSent(true);
        setRemainingTime(45);
        const timer = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    setOtpSent(false);
                    return 45;
                }
                return prevTime - 1;
            });
        }, 1000);
        setOtpTimer(timer);
    };

    useEffect(()=>{

    },[isOtpVerified, setIsOtpVerified])


    // Verify OTP API call
    const verifyOTP = () => {
        // Implement API call to verify OTP
        // Example:
        //console.log("object")
        setLoading(true);

        fetch(url + '/auth/register/verify_mobile_otp', {
            method: 'POST',
            body: JSON.stringify({ mobile: formData.mobile, otp: formData.otp }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                setLoading(false);
                if (response.ok) {
                    setFormErrors({ ...formErrors, otp: '' });
                    setIsOtpVerified(true)
                    let isFormValid = true;
                    Object.values(formErrors).forEach(error => {
                        if (error) {
                            isFormValid = false;
                        }
                    });
                    console.log(isFormValid)
                    onValidate(isFormValid ? true : false);
                    if (isFormValid) {
                        updateFormData('PersonalDetails', formData);
                    }
                }
                else {
                    response.json().then((error) => {
                        setFormErrors({ ...formErrors, otp: error.message });
                    })
                }
            })
            .catch(error => {
                // Handle error
            });
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // handleNext();
        if (validateForm()) {
            if (isOtpVerified) {
                // Create a copy of formData and remove confirmPassword
                // const { confirmPassword, ...filteredFormData } = formData;

                // Update context with filtered formData
                updateFormData('PersonalDetails', formData);
                handleNext();
                setValue(1);
                // setActiveTabs(2);
            } else {
                setFormErrors({ ...formErrors, otp: "Please Verify Otp" });
            }
        }
    };





    return (
        <div style={{ opacity: 1 }}>
            <Card sx={{ boxShadow: "0px 0px 0px 0px lightGray", borderRadius: "25px", borderTopRightRadius: "0px", borderTopLeftRadius: "0px", marginTop: "-2vh", background: "transparent" }}>
                <CardContent>
                    {/* <div style={{ display: "flex" }}>
                        <div style={{ width: "1.8em", height: "1.8em", marginTop:4, justifyContent: "center", display: "flex", alignItems: "center", borderRadius: "50%", border: "2px solid #a0a0a0", marginRight: "0.9vw" }}>
                            <span style={{ fontSize: "1em", color:"#a0a0a0" }}>1</span>
                        </div>
                        <Typography gutterBottom variant="h5" component="div" fontStyle={"Roboto,sans-serif"} color="#a0a0a0" display={"flex"}>
                            Personal Details
                        </Typography>
                    </div> */}
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                        style={{ marginTop: "1vh", paddingTop: "2vh", paddingBottom: "0vh" }}
                        onSubmit={handleSubmit}
                    >
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <TextField
                                error={formErrors.firstName !== ''}
                                fullWidth
                                id="firstName"
                                label="First Name"
                                value={formData.firstName}
                                onChange={handleChange}
                                // helperText={formErrors.firstName}
                                required
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.7vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity: 1,
                                        backgroundColor: "#fff"
                                    }

                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.7vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                            <TextField
                                fullWidth
                                id="middleName"
                                label="Middle Name"
                                value={formData.middleName}
                                onChange={handleChange}
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.7vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        backgroundColor: "#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.7vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                            <TextField
                                error={formErrors.lastName !== ''}
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                value={formData.lastName}
                                onChange={handleChange}
                                // helperText={formErrors.lastName}
                                required
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.7vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        backgroundColor: "#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.7vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", marginTop: "1vh" }}>
                            <TextField
                                error={formErrors.dateOfBirth !== ''}
                                fullWidth
                                id="dateOfBirth"
                                label="Date of Birth"
                                type="date"
                                value={formData.dateOfBirth}
                                onChange={handleChange}
                                // helperText={formErrors.dateOfBirth}
                                required
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.7vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        backgroundColor: "#fff"
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    sx: {
                                        fontSize: '0.7vw', // Adjust the label font size as needed
                                    },
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                            <Autocomplete
                                style={{ marginRight: "5%" }}
                                disablePortal
                                fullWidth
                                clearIcon={false}
                                disableClearable
                                id="gender"
                                options={Gender}
                                value={formData.gender}
                                onChange={(event, newValue) => handleGenderChange('gender', newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Gender *"
                                        error={formErrors.gender !== ''}
                                        // helperText={formErrors.gender}
                                        InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '0.7vw', // Adjust the font size as needed
                                                borderRadius: 30,
                                                backgroundColor: "#fff"
                                            },
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '0.7vw', // Adjust the label font size as needed
                                            },
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '0.6vw', // Adjust the helper text font size as needed
                                            }
                                        }}
                                    />
                                )}
                                required
                            />

                        </div>
                        <div style={{ display: "flex", marginTop: "1vh" }}>
                            <TextField
                                error={formErrors.designation !== ''}
                                fullWidth
                                id="designation"
                                label="Designation"
                                value={formData.designation}
                                onChange={handleChange}
                                // helperText={formErrors.designation}
                                required
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.7vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        backgroundColor: "#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.7vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                        </div>
                        {!showPasswordField && (
                            <div style={{ display: "flex", marginTop: "1vh" }}>
                                <TextField
                                    error={formErrors.password !== ''}
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formData.password}
                                    onChange={handleChange}
                                    helperText={formErrors.password}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={togglePasswordVisibility}
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                >
                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.7vw', // Adjust the font size as needed
                                            borderRadius: 30,
                                            backgroundColor: "#fff"
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.7vw', // Adjust the label font size as needed
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '0.6vw', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                <TextField
                                    error={formErrors.confirmPassword !== ''}
                                    fullWidth
                                    id="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    helperText={formErrors.confirmPassword}
                                    required
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.7vw', // Adjust the font size as needed
                                            borderRadius: 30,
                                            backgroundColor: "#fff"
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.7vw', // Adjust the label font size as needed
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '0.6vw', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                            </div>)}
                        <div style={{ display: "flex", marginTop: "1vh" }}>
                            <div style={{ width: "98%", display: "flex", flexDirection: "column" }}>
                                <TextField
                                    error={formErrors.mobile !== ''}
                                    fullWidth
                                    disabled={isOtpVerified}
                                    id="mobile"
                                    label="Mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    helperText={formErrors.mobile}
                                    required
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.7vw', // Adjust the label font size as needed
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '0.6vw', // Adjust the helper text font size as needed
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {!loading && 
                                                <Button
                                                    style={{ cursor: "pointer", fontSize: "0.5vw", borderRadius: 15, padding: 3 }}
                                                    onClick={sendOTP}
                                                disabled={otpSent || !/^\d+$/.test(formData.mobile) || formData.mobile.length < 10 || isOtpVerified}
                                                >
                                                    Send OTP
                                                </Button>
                                                }
                                                {loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            color: green[500],
                                                        }}
                                                    />
                                                )}
                                            </InputAdornment>
                                        ),

                                        sx: {
                                            height: '45px',
                                            fontSize: '0.7vw',
                                            borderRadius: 30,
                                            width: "98%",
                                            backgroundColor: "#fff"
                                        }
                                    }}
                                />
                                {otpSent && !isOtpVerified && <h6 style={{ alignSelf: "flex-end", color: "gray", fontSize: 13 }}>
                                    {otpSent ? `Resend OTP in: ${remainingTime}s` : 'Resend OTP'}
                                </h6>}
                            </div>
                            {(otpSent || resendOtp || isOtpVerified) && (
                                <TextField
                                    error={formErrors.otp !== '' || formErrors.otp == true}
                                    fullWidth
                                    id="otp"
                                    label="OTP"
                                    type="number"
                                    value={formData.otp}
                                    onChange={handleChange}
                                    helperText={formErrors.otp}
                                    required
                                    disabled={isOtpVerified}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.7vw', // Adjust the label font size as needed
                                            marginLeft: "2%",
                                            
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '0.6vw', // Adjust the helper text font size as needed
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {!loading && <Button
                                                    disabled={formData?.otp?.length < 4 || isOtpVerified}
                                                    style={{ cursor: "pointer", backgroundColor: "green", borderColor: "white", fontSize: "0.6vw", borderRadius: 15, padding:3 }}
                                                    onClick={verifyOTP}
                                                >
                                                    Verify
                                                </Button>}

                                                {loading && otpSent && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            color: green[500],
                                                        }}
                                                    />
                                                )}
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            height: '45px',
                                            fontSize: '0.7vw',
                                            borderRadius: 30,
                                            backgroundColor:"#fff",
                                            width: "98%",
                                            marginLeft: "2%"
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </Box>
                </CardContent>
                {/* {!isUpdate && <div style={{ display: "flex", justifyContent: "center", marginBottom: "2vh" }}> */}
                {/* <Button disabled onClick={handleBack} type="submit" style={{ marginTop: "2vh", backgroundColor: "blue", borderColor: "green", color: "white", alignSelf: "end" }}>
                        Previous Step
                    </Button> */}
                {/* <Button
                        onClick={handleSubmit}
                        type="submit"
                        disabled={!isOtpVerified}
                        style={{ marginTop: "2vh", backgroundColor: "green", borderColor: "green", color: "white", marginLeft: 50 }}
                    >
                        Next Step
                    </Button> */}
                {/* </div>} */}
            </Card>
        </div>
    );
}

// export default PersonalDetails
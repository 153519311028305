import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import styled from 'styled-components';
import { url } from '../../../Constant';
import { Toast } from 'primereact/toast';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';


const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const FieldGroup = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

const Field = styled.div`
    flex: 1;
    margin-right: 1rem;
    position: relative;

    &:last-child {
        margin-right: 0;
    }
`;

const FileInputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ErrorMessage = styled.span`
    color: red;
    font-size: 0.875rem;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const initialFormData = {
    prodId: '',
    prodOwner: '',
    catId: Number,
    prodId: '',
    machineModel: '',
    machineModelYear: '',
    machineSerialNo: '',
    rtoNumber: '',
    insuranceValidThru: '',
    chassisNo: '',
    engineNumber: '',
    alternatorNo: '',
    roadTaxValidThru: '',
    pollutionValidThru: '',
    currentServiceHmr: '',
    nextServiceHmr: '',
    typesOfNextService: '',
    status: '',
    rtoImage: null,
    insuranceImage: null,
    roadTaxImage: null,
    pollutionImage: null,
    prodDescr: '', // New field
};


export default function AddMachineModal({ hideDialog, setEditMachineId, editMachineId, machineDialog, setMachineDialog }) {
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const toast = useRef(null);
    const [loader, setLoader] = useState(false);
    const [catIdOptions, setCatIdOptions] = useState()

    const statusOptions = [
        { label: 'Ready For Hire', value: 'readyForHire' },
        { label: 'BreakDown', value: 'BreakDown' },
        { label: 'On-Hire', value: 'On-Hire' },
        { label: 'Sold', value: 'Sold' },
    ];

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${url}/api/admin/get_all_categories_for_machines`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) {
                if (response.status === 403) {
                    toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
                    throw new Error('Unauthorized');
                }
                throw new Error('Network response was not ok.');
            }
            const result = await response.json();
            //console.log(result)
            setCatIdOptions(result);
        } catch (error) {
            console.error('Fetch error:', error);
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while fetching data', life: 3000 });
        } finally {
            setLoader(false);
        }
    };
    const fetchMachineDetails = async () => {
        try {
            const response = await fetch(`${url}/api/admin/get_machine_by_id?prodId=${editMachineId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) {
                if (response.status === 403) {
                    toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
                    throw new Error('Unauthorized');
                }
                throw new Error('Network response was not ok.');
            }
            const result = await response.json();
            console.log(result)
            setFormData(result);
        } catch (error) {
            console.error('Fetch error:', error);
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while fetching data', life: 3000 });
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchCategories()
    }, [])

    useEffect(() => {
        if (editMachineId) {
            fetchMachineDetails();
        }
    }, [editMachineId])


    const handleInputChange = (e, field) => {
        console.log(e.target.value);
        setFormData({ ...formData, [field]: e.target.value });
    };

    const handleNumberChange = (e, field) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setFormData({ ...formData, [field]: value });
        }
    };

    const handleImageChange = (e, field) => {
        const file = e.target.files[0];
        setFormData({ ...formData, [field]: file });
    };

    const handleStatusChange = (e) => {
        setFormData({ ...formData, status: e.value });
    };

    const handleCatIdChange = (e) => {
        console.log(e.value)
        setFormData({ ...formData, catId: e.value });
    };
    const validateForm = () => {
        const newErrors = {};
        if (!formData.prodOwner) newErrors.prodOwner = "This field is required";
        if (!formData.insuranceValidThru) newErrors.insuranceValidThru = "Insurance Valid Thru is required";
        if (!formData.roadTaxValidThru) newErrors.roadTaxValidThru = "Road Tax Valid Thru is required";
        if (!formData.pollutionValidThru) newErrors.pollutionValidThru = "Pollution Valid Thru is required";

        if (!formData.status) newErrors.status = "Status is required";
        if (!formData.catId) newErrors.catId = "Product Type is required";
        if (!formData.typesOfNextService) newErrors.typesOfNextService = "Types of Next Service is required";
        if (!formData.chassisNo) newErrors.chassisNo = "Machine Chassis No. is required";
        if (!formData.prodDescr) newErrors.prodDescr = "Product Description is required"; // New validation

        return newErrors;
    };
    function clearForm() {
        setMachineDialog(!machineDialog); setFormData(initialFormData); setErrors({}); setEditMachineId(null);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) return;

        const formDataToSend = new FormData();
        Object.entries({ ...formData, prodId: editMachineId ? editMachineId : undefined }).forEach(([key, value]) => {
            formDataToSend.append(key, value);
        });
        console.log(formDataToSend)

        try {
            const response = await fetch(`${url}/api/admin/add_machine`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: formDataToSend,
            });

            // Handle different response statuses
            if (response.ok) {
                const data = await response.json();
                //console.log('Data submitted successfully:', data);

                toast.current.show({ severity: 'success', summary: 'Success', detail: editMachineId ? 'Machine updated successfully' : 'Machine added successfully', life: 3000 });
                clearForm();
            } else {
                const errorData = await response.json();
                switch (response.status) {
                    case 400:
                        toast.current.show({ severity: 'error', summary: 'Validation Error', detail: errorData.message || 'Invalid input data', life: 3000 });
                        break;
                    case 403:
                        toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You do not have permission to perform this action', life: 3000 });
                        break;
                    case 500:
                        toast.current.show({ severity: 'error', summary: 'Server Error', detail: 'An error occurred on the server', life: 3000 });
                        break;
                    default:
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'An unexpected error occurred', life: 3000 });
                        break;
                }
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };



    function parseISODate(isoDateString) {
        return isoDateString ? new Date(isoDateString) : null; // Return null for empty input
    }
    return (
        <>
            <Button label="Add Machine" icon="pi pi-plus" className="mx-1" size='small' onClick={() => setMachineDialog(!machineDialog)} style={{ backgroundColor: '#1778B0', color: 'white' }} />
            <Dialog header={editMachineId ? "Update Machine Details" : "Add Machine"} visible={machineDialog} onHide={() => { clearForm() }} style={{ width: '800px' }}>
                <Toast ref={toast} />
                <FormContainer onSubmit={handleSubmit} className="p-fluid">
                    <Field>
                        <label htmlFor="prodOwner">Machine Owner</label>
                        <InputText id="prodOwner" value={formData.prodOwner} onChange={(e) => handleInputChange(e, 'prodOwner')} />
                        {errors.prodOwner && <ErrorMessage>{errors.prodOwner}</ErrorMessage>}
                    </Field>
                    <FieldGroup>
                        <Field>
                            <label htmlFor="catId">Product Type</label>
                            <Dropdown
                                id="catId"
                                options={catIdOptions?.map((option) => ({
                                    value: option.catId,
                                    label: option.catName
                                }))}
                                // value={formData.catId}
                                value={formData.catId}
                                onChange={handleCatIdChange}
                                placeholder="Select Product Type"
                            />
                            {errors.catId && <ErrorMessage>{errors.catId}</ErrorMessage>}
                        </Field>
                        <Field>
                            <label htmlFor="prodId">Asset Id</label>
                            <InputText id="prodId" value={formData.prodId} onChange={(e) => handleInputChange(e, 'prodId')} />
                        </Field>
                    </FieldGroup>
                    <FieldGroup>
                        <Field>
                            <label htmlFor="prodDescr">Product Description</label>
                            <InputTextarea id="prodDescr" value={formData.prodDescr} onChange={(e) => handleInputChange(e, 'prodDescr')} rows={5} cols={30} />
                            {errors.prodDescr && <ErrorMessage>{errors.prodDescr}</ErrorMessage>}
                        </Field>
                    </FieldGroup>


                    <FieldGroup>
                        <Field>
                            <label htmlFor="machineModel">Machine Model</label>
                            <InputText id="machineModel" value={formData.machineModel} onChange={(e) => handleInputChange(e, 'machineModel')} />
                        </Field>
                        <Field>
                            <label htmlFor="machineModelYear">Machine Model Year</label>
                            <InputText id="machineModelYear" value={formData.machineModelYear} onChange={(e) => handleInputChange(e, 'machineModelYear')} />
                        </Field>
                    </FieldGroup>

                    <FieldGroup>
                        <Field>
                            <label htmlFor="machineSerialNo">Machine Serial No.</label>
                            <InputText id="machineSerialNo" value={formData.machineSerialNo} onChange={(e) => handleInputChange(e, 'machineSerialNo')} />
                        </Field>
                        <Field>
                            <label htmlFor="rtoNumber">Machine R.T.O Number</label>
                            <InputText id="rNumber" value={formData.rtoNumber} onChange={(e) => handleInputChange(e, 'rtoNumber')} />
                        </Field>
                    </FieldGroup>

                    <FieldGroup>
                        <FileInputContainer>
                            <label htmlFor="rtoImage">{editMachineId && "Update "}RTO Image</label>
                            <input type="file" onChange={(e) => handleImageChange(e, 'rtoImage')} accept="image/*" />
                        </FileInputContainer>
                        <FileInputContainer>
                            <label htmlFor="insuranceImage">{editMachineId && "Update "}Insurance Image</label>
                            <input type="file" onChange={(e) => handleImageChange(e, 'insuranceImage')} accept="image/*" />
                        </FileInputContainer>
                    </FieldGroup>

                    <FieldGroup>
                        <Field>
                            <label htmlFor="insuranceValidThru">Insurance Valid Thru (MM-DD-YYYY)</label>
                            {/* <InputText
                                id="insuranceValidThru"
                                value={formData.insuranceValidThru}
                                onChange={(e) => handleInputChange(e, 'insuranceValidThru')}
                            /> */}
                            <Calendar id="insuranceValidThru" placeholder='MM-DD-YYYY' value={parseISODate(formData.insuranceValidThru)} onChange={(e) => handleInputChange(e, 'insuranceValidThru')} />
                            {/* <Calendar id="insuranceValidThru" placeholder='MM-DD-YYYY' value={parseDate(formData.roadTaxValidThru)} onChange={(e) => handleInputChange(e, 'insuranceValidThru')} /> */}
                            {errors.insuranceValidThru && <ErrorMessage>{errors.insuranceValidThru}</ErrorMessage>}
                        </Field>
                        <Field>
                            <label htmlFor="chassisNo">Machine Chassis No.</label>
                            <InputText id="chassisNo" value={formData.chassisNo} onChange={(e) => handleInputChange(e, 'chassisNo')} />
                            {errors.chassisNo && <ErrorMessage>{errors.chassisNo}</ErrorMessage>}
                        </Field>
                    </FieldGroup>

                    <FieldGroup>
                        <Field>
                            <label htmlFor="engineNumber">Machine Engine Number</label>
                            <InputText id="engineNumber" value={formData.engineNumber} onChange={(e) => handleInputChange(e, 'engineNumber')} />
                        </Field>
                        <Field>
                            <label htmlFor="alternatorNo">Alternator No.</label>
                            <InputText id="alternatorNo" value={formData.alternatorNo} onChange={(e) => handleInputChange(e, 'alternatorNo')} />
                        </Field>
                    </FieldGroup>

                    <FieldGroup>
                        <Field>
                            <label htmlFor="roadTaxValidThru">Road Tax Valid Thru (MM-DD-YYYY)</label>
                            {/* <InputText
                                id="roadTaxValidThru"
                                value={formatDate(formData.roadTaxValidThru)}
                                onChange={(e) => handleInputChange(e, 'roadTaxValidThru')}
                            /> */}
                            <Calendar id="roadTaxValidThru" placeholder='MM-DD-YYYY' value={parseISODate(formData.roadTaxValidThru)} onChange={(e) => handleInputChange(e, 'roadTaxValidThru')} />
                            {errors.roadTaxValidThru && <ErrorMessage>{errors.roadTaxValidThru}</ErrorMessage>}
                        </Field>
                        <Field>
                            <label htmlFor="pollutionValidThru">Pollution Valid Thru (MM-DD-YYYY)</label>
                            {/* <InputText
                                id="pollutionValidThru"
                                value={formData.pollutionValidThru}
                                onChange={(e) => handleInputChange(e, 'pollutionValidThru')}
                            /> */}
                            <Calendar id="pollutionValidThru" placeholder='MM-DD-YYYY' value={parseISODate(formData.pollutionValidThru)} onChange={(e) => handleInputChange(e, 'pollutionValidThru')} />
                            {errors.pollutionValidThru && <ErrorMessage>{errors.pollutionValidThru}</ErrorMessage>}
                        </Field>
                    </FieldGroup>

                    <FieldGroup>
                        <Field>
                            <label htmlFor="currentServiceHmr">Current Service HMR</label>
                            <InputText
                                id="currentServiceHmr"
                                value={formData.currentServiceHmr}
                                onChange={(e) => handleNumberChange(e, 'currentServiceHmr')}
                            />
                        </Field>
                        <Field>
                            <label htmlFor="nextServiceHmr">Next Service HMR</label>
                            <InputText
                                id="nextServiceHmr"
                                value={formData.nextServiceHmr}
                                onChange={(e) => handleNumberChange(e, 'nextServiceHmr')}
                            />
                        </Field>
                    </FieldGroup>

                    <FieldGroup>
                        <Field>
                            <label htmlFor="typesOfNextService">Types Of Next Service</label>
                            <InputText id="typesOfNextService" value={formData.typesOfNextService} onChange={(e) => handleInputChange(e, 'typesOfNextService')} />
                            {errors.typesOfNextService && <ErrorMessage>{errors.typesOfNextService}</ErrorMessage>}
                        </Field>
                        <Field>
                            <label htmlFor="status">Status</label>
                            <Dropdown
                                id="status"
                                options={statusOptions}
                                value={formData.status}
                                onChange={handleStatusChange}
                                placeholder="Select Status"
                            />
                            {errors.status && <ErrorMessage>{errors.status}</ErrorMessage>}
                        </Field>
                    </FieldGroup>

                    <Button label={editMachineId ? "Update" : "Submit"} icon="pi pi-check" type="submit" />
                </FormContainer>
            </Dialog>
        </>
    );
}

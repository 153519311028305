import { TextField, Button, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { FormContext } from '../../Provider/FormProvider'

export default function AdvancePaymentBank({type}) {
    const {formData, updateFormData} = useContext(FormContext);
    const [formValues, setFormValues] = useState({
        amount: "",
        date: new Date().toISOString().split('T')[0], // Setting current date in 'YYYY-MM-DD' format
        bankName: "",
        UTRNumber: "",
    })

    const [errors, setErrors] = useState({})

    // Validation function to validate individual fields during input change
    const validateField = (name, value) => {
        let tempErrors = { ...errors }

        switch (name) {
            case "bankName":
                tempErrors.bankName = value ? "" : "Bank name is required"
                break
            case "UTRNumber":
                tempErrors.UTRNumber = value ? "" : "UTR Number is required"
                break
            case "amount":
                if (!value) {
                    tempErrors.amount = "Amount is required"
                } else if (isNaN(value)) {
                    tempErrors.amount = "Amount must be a number"
                } else {
                    tempErrors.amount = ""
                }
                break
            case "date":
                tempErrors.date = value ? "" : "Date is required"
                break
            default:
                break
        }
        setErrors(tempErrors)
    }

    // Function to handle input changes and trigger validation
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormValues(prev=>{ 
            const values = {...prev, [name]: value} 
            let newFormValues = { AdvancePayment:{bank:{...values},advancePaymentType:"Bank"}, paymentType: type };
            updateFormData("paymentInfo", newFormValues,)
            return values;
        })

        validateField(name, value) // Validate the field as the user types
    }

    // Handle form submission and final validation
    const handleSubmit = (e) => {
        e.preventDefault()
        const isValid = Object.values(errors).every((err) => err === "")
        if (isValid) {
            console.log("Form is valid:", formValues)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <TextField
                    fullWidth
                    id="bankName"
                    name="bankName"
                    label="Bank Name"
                    sx={{ width: "20%", backgroundColor: "#F7F8F9" }}
                    value={formValues.bankName}
                    onChange={handleInputChange}
                    error={!!errors.bankName}
                    helperText={errors.bankName}
                    InputProps={{
                        sx: {
                            height: '45px',
                            fontSize: '13px',
                            borderRadius: 3,
                        }
                    }}
                    InputLabelProps={{
                        sx: {
                            fontSize: '13px',
                            zIndex: 1
                        }
                    }}
                    FormHelperTextProps={{
                        sx: {
                            fontSize: '12px',
                        }
                    }}
                />

                <TextField
                    fullWidth
                    id="UTRNumber"
                    name="UTRNumber"
                    label="UTR Number"
                    sx={{ width: "17%", ml: 2, backgroundColor: "#F7F8F9" }}
                    value={formValues.UTRNumber}
                    onChange={handleInputChange}
                    error={!!errors.UTRNumber}
                    helperText={errors.UTRNumber}
                    InputProps={{
                        sx: {
                            height: '45px',
                            fontSize: '13px',
                            borderRadius: 3,
                        }
                    }}
                    InputLabelProps={{
                        sx: {
                            fontSize: '13px',
                            zIndex: 1
                        }
                    }}
                    FormHelperTextProps={{
                        sx: {
                            fontSize: '12px',
                        }
                    }}
                />

                <TextField
                    fullWidth
                    id="amount"
                    name="amount"
                    label="Amount"
                    sx={{ width: "15%", ml: 2, backgroundColor: "#F7F8F9" }}
                    value={formValues.amount}
                    onChange={handleInputChange}
                    error={!!errors.amount}
                    helperText={errors.amount}
                    InputProps={{
                        sx: {
                            height: '45px',
                            fontSize: '13px',
                            borderRadius: 3,
                        }
                    }}
                    InputLabelProps={{
                        sx: {
                            fontSize: '13px',
                            zIndex: 1
                        }
                    }}
                    FormHelperTextProps={{
                        sx: {
                            fontSize: '12px',
                        }
                    }}
                />

                <TextField
                    fullWidth
                    id="date"
                    name="date"
                    label="Date"
                    type="date"
                    sx={{ width: "14%", ml: 2, backgroundColor: "#F7F8F9" }}
                    value={formValues.date}
                    onChange={handleInputChange}
                    error={!!errors.date}
                    helperText={errors.date}
                    InputProps={{
                        sx: {
                            height: '45px',
                            fontSize: '13px',
                            borderRadius: 3,
                        }
                    }}
                    InputLabelProps={{
                        sx: {
                            fontSize: '13px',
                            zIndex: 1
                        }
                    }}
                    FormHelperTextProps={{
                        sx: {
                            fontSize: '12px',
                        }
                    }}
                />
            </div>
        </form>
    )
}

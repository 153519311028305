import React, { Fragment, useContext, useState, useEffect, useRef } from 'react';
import { Card, CardBody, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { H5, H6, P, Image } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import CustomizerContext from '../../../_helper/Customizer';
import { Button, Col, Form as BootstrapForm } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import { url } from '../../../Constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@mui/material';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const CompanyProfile = ({ profile, refreshData }) => {
  const { layoutURL } = useContext(CustomizerContext);
  const toast = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isDisabledCompanyDetails, setIsDisabledCompanyDetails] = useState(true);
  const [isDisabledEmailInput, setIsDisabledEmailInput] = useState(true);
  const [email, setEmail] = useState(profile?.email);
  const [emailError, setEmailError] = useState('');
  const [loader, setLoader] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState();
  const [otpError, setOtpError] = useState();
  const [companyDetails, setCompany] = useState({})

  const mobileRegex = /^\+?[1-9]\d{1,14}$/;
  const [mobile, setMobile] = useState(profile?.mobile);
  const [mobileError, setMobileError] = useState("");
  const [mobileotp, setMobileOtp] = useState("");
  const [mobileOtpError, setMobileOtpError] = useState("");
  const [isDisabledMobileInput, setIsDisabledMobileInput] = useState(true);
  const [mobileLoader, setMobileLoader] = useState(false);
  const [mobileOtpSent, setMobileOtpSent] = useState(false);
  const [mobileShowOtp, setMobileShowOtp] = useState(false);
  const [isMobileResend, setIsMobileResend] = useState(false);
  const [mobileTimeLeft, setMobileTimeLeft] = useState(30);
  const [errors, setErrors] = useState()

  useEffect(() => {
    //console.log("object")
    setEmail(profile?.email)
    setMobile(profile?.mobile)
    setCompany(profile)
  }, [profile])

  const confirmStatusChange = () => {
    confirmDialog({
      message: `Are you sure you want to make it ${!profile.status ? "Active" : "Inactive"}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => onstatusChange(),
    });
  }

  const onstatusChange = async () => {
    setLoader(true);
    const newStatus = !profile?.status;

    try {
      const response = await fetch(`${url}/api/sup/change_status`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_code: companyDetails?.company_code,
          status: newStatus
        })
      });
      if (!response.ok) {
        setLoader(false);
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      if (response.status == 200) {
        setLoader(false);
        //console.log("OK")
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Status Updated', life: 3000 });
        refreshData()
      } else {
        throw new Error(result.message || 'Failed to update status');
      }
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
    } finally {
      setLoader(false);
      //console.log("Status changed: ", newStatus);
    }
  };

  const sendEmailOtp = () => {
    let emailError = "";
    if (!email.trim()) {
      setEmailError('Email is required');
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError('Email is not valid');
      return;
    } else {
      setEmailError('');
    }
    setLoader(true);
    //console.log(companyDetails)
    fetch(url + "/auth/update-email-otp", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ updatedEmail: email, company_code: companyDetails?.company_code })
    }).then((response) => {
      //console.log(response)
      if (response.ok) {
        startCountdown()
        setShowOtp(true)
        setIsDisabledEmailInput(true)
        setEmailSent(true)
        // companyDetails = { ...companyDetails, email }
        setCompany((prev) => ({
          ...prev,
          email,
        }));
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Email Sent Successfully!!', life: 3000 });
      } else if (response.status == 400) {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please enter valid email', life: 3000 });
      }
      else if (response.status == 409) {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Email already exists', life: 3000 });
      }
      setLoader(false)
      setIsResend(false);
    }).catch((error) => {
      setLoader(false)
      //console.log(error)
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Failed to send OTP', life: 3000 });
    })
  }

  const verifyEmailOtp = () => {
    let otpError = "";

    // Validate the OTP
    if (!otp.trim()) {
      setOtpError('OTP is required');
      return;
    } else if (otp.length !== 4) {  // Assuming OTP length is 6 digits
      setOtpError('OTP should be 4 digits');
      return;
    } else {
      setOtpError('');
    }

    setLoader(true);

    // Make an API request to verify OTP
    fetch(url + "/auth/verify-email-otp", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ otp: otp, email: email, company_code: companyDetails?.company_code, prefix: companyDetails?.companyPrefix })
    }).then((response) => {
      if (response.ok) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'OTP Verified Successfully!!', life: 3000 });
        setIsResend(false);
        setEmailSent(false)
        setShowOtp(false)
        setIsDisabledEmailInput(true);
        // Optionally redirect the user or perform further actions on successful verification
      } else if (response.status == 400) {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Invalid OTP', life: 3000 });
      } else if (response.status == 401) {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'OTP Expired', life: 3000 });
      }

      setLoader(false);

    }).catch((error) => {
      setLoader(false);
      //console.log(error);
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Failed to verify OTP', life: 3000 });
    });
  }


  const startCountdown = () => {
    //console.log("1")
    let countdown = 30; // Reset countdown to 30 seconds
    setTimeLeft(countdown);
    //console.log("2")

    const timer = setInterval(() => {
      countdown -= 1;
      setTimeLeft(countdown);
      if (countdown === 0) {
        clearInterval(timer);
        setIsResend(true); // Re-enable the resend button after countdown
      }
    }, 1000);
  };

  const enableEdit = () => {
    setIsDisabledEmailInput(!isDisabledEmailInput)
    setEmailSent(false)
    setEmailError("")
    setShowOtp(false);
  }

  const sendMobileOtp = () => {
    let mobileError = "";

    // Validate mobile number
    if (!mobile.trim()) {
      setMobileError('Mobile number is required');
      return;
    } else if (!mobileRegex.test(mobile)) { // Ensure the mobile number is valid
      setMobileError('Mobile number is not valid');
      return;
    } else {
      setMobileError('');
    }

    setMobileLoader(true); // Show loading spinner

    // API request to send the mobile OTP
    fetch(url + "/auth/send-mobile-otp", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ mobile, company_code: companyDetails?.company_code })
    }).then((response) => {
      if (response.ok) {
        startMobileCountdown(); // Start the countdown timer for OTP resend
        setMobileShowOtp(true); // Display the OTP input field
        setIsDisabledMobileInput(true); // Disable mobile number input after OTP sent
        setMobileOtpSent(true); // Track that OTP was sent
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Mobile OTP Sent Successfully!!', life: 3000 });
      } else if (response.status === 400) {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please enter a valid mobile number', life: 3000 });
      } else if (response.status === 409) {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Mobile number already exists', life: 3000 });
      }
      setMobileLoader(false); // Hide loading spinner
      setIsMobileResend(false); // Disable resend button until countdown completes
    }).catch((error) => {
      setMobileLoader(false); // Hide loading spinner on error
      //console.log(error);
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Failed to send Mobile OTP', life: 3000 });
    });
  };

  // Function to verify the mobile OTP
  const verifyMobileOtp = () => {
    let mobileOtpError = "";

    // Validate OTP input
    if (!mobileotp.trim()) {
      setMobileOtpError('OTP is required');
      return;
    } else if (mobileotp.length !== 4) {  // Assuming OTP length is 4 digits
      setMobileOtpError('OTP should be 4 digits');
      return;
    } else {
      setMobileOtpError('');
    }

    setMobileLoader(true); // Show loading spinner

    // API request to verify mobile OTP
    fetch(url + "/auth/verify-mobile-otp", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ otp: mobileotp, mobile, company_code: companyDetails?.company_code, prefix: companyDetails?.companyPrefix })
    }).then((response) => {
      if (response.ok) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Mobile OTP Verified Successfully!!', life: 3000 });
        setIsMobileResend(false); // Disable resend after verification
        setMobileOtpSent(false);  // Reset OTP sent flag
        setMobileShowOtp(false);  // Hide OTP input after verification
        setIsDisabledMobileInput(true); // Keep mobile input disabled
      } else if (response.status === 400) {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Invalid Mobile OTP', life: 3000 });
      } else if (response.status === 401) {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Mobile OTP Expired', life: 3000 });
      }

      setMobileLoader(false); // Hide loading spinner
    }).catch((error) => {
      setMobileLoader(false); // Hide loading spinner on error
      //console.log(error);
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Failed to verify Mobile OTP', life: 3000 });
    });
  };

  // Function to start countdown for OTP resend
  const startMobileCountdown = () => {
    let countdown = 30; // Set countdown to 30 seconds
    setMobileTimeLeft(countdown);

    const timer = setInterval(() => {
      countdown -= 1;
      setMobileTimeLeft(countdown);

      if (countdown === 0) {
        clearInterval(timer);
        setIsMobileResend(true); // Enable resend button after countdown
      }
    }, 1000);
  };

  // Function to enable editing of mobile number input
  const enableMobileEdit = () => {
    setIsDisabledMobileInput(!isDisabledMobileInput); // Toggle mobile input enable/disable
    setMobileOtpSent(false);  // Reset OTP sent flag
    setMobileError("");       // Clear any mobile number error
    setMobileShowOtp(false);  // Hide OTP input field
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    let panNumber;

    if (name === "companyTelephone" || name === "panNumber") {
      // Limit the value to 10 digits
      newValue = value.slice(0, 10);
    }

    if (name === "gstNumber") {
      newValue = value.slice(0, 15);
      if (newValue?.length >= 12) {
        const pan = newValue.slice(2,12);
        panNumber = pan;
      }
    }

    // Update the state with the modified value
    setCompany({
      ...companyDetails,
      [name]: newValue,
      panNumber:panNumber?panNumber:companyDetails.panNumber
    });

    //console.log(companyDetails);
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate telephone (10 digits)
    const telephoneRegex = /^\d{10}$/;
    if (!telephoneRegex.test(companyDetails.companyTelephone)) {
      newErrors.companyTelephone = 'Telephone number must be 10 digits';
    }

    // Validate PAN number
    const panNumberRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (!panNumberRegex.test(companyDetails.panNumber)) {
      newErrors.panNumber = 'Invalid PAN number';
    }

    const gstNumberRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (companyDetails?.gstNumber?.length > 0) {
      if (!gstNumberRegex.test(companyDetails.gstNumber)) {
        newErrors.gstNumber = 'Invalid GST number';
      }
    }

    // Validate required fields
    if (!companyDetails.incorporationDate) {
      newErrors.incorporationDate = 'Incorporation Date is required';
    }
    if (!companyDetails.companyAddress) {
      newErrors.companyAddress = 'Address is required';
    }
    if (!companyDetails.state) {
      newErrors.state = 'State is required';
    }
    if (!companyDetails.district) {
      newErrors.district = 'District is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Proceed with submission
      const company_code = companyDetails?.company_code;
      const data = {
        ...companyDetails,
        companyPrefix: companyDetails?.companyPrefix,
        email,
        companyName: companyDetails?.companyName,
        incorporationDate: formatDate(companyDetails.incorporationDate),
      };

      fetch(url + `/api/admin/profile/update?company_code=${company_code}`, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify({ CompanyDetails: data }),
      }).then((response) => {
        if (response.ok) {
          setCompany(data);
          //console.log(data)
          setIsDisabledCompanyDetails(true);
          refreshData()
          toast.current.show({ severity: 'success', summary: 'Company Profile updated Successfully!!', life: 3000 });
        } else {
          toast.current.show({ severity: 'error', summary: 'Failed to update Company Profile', life: 3000 });
        }
      }).catch((error) => {
        toast.current.show({ severity: 'error', summary: 'Failed to update Company Profile', life: 3000 });
      })
    }
  };

  useEffect(() => {

  }, [isDisabledCompanyDetails, setIsDisabledCompanyDetails])

  const cancelEmailEdit = () => {
    setIsDisabledEmailInput(!isDisabledEmailInput);
    setEmail(profile.email);
  }

  const cancelMobileEdit = () => {
    setIsDisabledMobileInput(!isDisabledMobileInput);
    setMobile(profile.mobile);
    //console.log(profile.mobile)
  }

  const cancelCompanyProfileEdit = () => {
    setIsDisabledCompanyDetails(!isDisabledCompanyDetails);
    setCompany(profile);
    setErrors({});
  }


  return (
    <Fragment>
      <Toast ref={toast} />
      <Card>
        <CardBody>
          <Row className='mb-2'>
            <div className='profile-title'>
              <div className='media'>
                <Image attrImage={{ className: 'img-70 m-0 rounded-circle', alt: '', src: require('../../../assets/images/user/2.png') }} />
                <div className='media-body'>
                  <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile/${layoutURL}`}>
                    <H5 attrH5={{ className: 'mb-1' }}>{companyDetails?.companyName}</H5>
                  </Link>
                  <P>
                    {"FR" + companyDetails?.companyPrefix + String(companyDetails?.company_code).padStart(4, '0')}
                  </P>
                  <div className="d-flex align-items-center">
                    <span style={{ color: companyDetails?.status ? 'green' : 'red', fontWeight: 'bold' }}>{companyDetails?.status ? "Active" : "Inactive"}</span>
                    <FontAwesomeIcon style={{ marginLeft: "5px", color: "blue", cursor: "pointer" }} icon={faEdit} onClick={() => confirmStatusChange()} />
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <FormGroup className='mb-3' style={{ marginTop: "-20px" }}>
            <Label className='form-label'>Email</Label>
            <div className="d-flex">
              {isDisabledEmailInput ?
                <p style={{ fontWeight: "500", fontSize: "14px" }}>{email}</p> :
                <div className='d-flex flex-column' >
                  <input value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: "14rem", borderColor: emailError && "red" }} />
                  <p style={{ color: "red" }}>{emailError}</p>
                </div>
              }
              {isDisabledEmailInput ?
                <FontAwesomeIcon style={{ marginLeft: "5px", color: "blue", marginTop: "3px", cursor: "pointer" }} icon={faEdit} onClick={() => enableEdit()} /> :
                <>
                  {!emailSent &&
                    <>
                      {loader ?
                        <CircularProgress style={{ marginLeft: "10px", marginTop: "3px", width: "20px", height: "20px" }} /> :
                        <Button variant="primary" size="sm" style={{ marginLeft: "10px", paddingLeft: "5px", paddingRight: "5px", height: "30px" }} onClick={sendEmailOtp}>Send-OTP</Button>
                      }
                      <Button variant="danger" size="sm" style={{ marginLeft: "10px", paddingLeft: "5px", paddingRight: "5px", height: "30px" }} onClick={() => cancelEmailEdit()}>Cancel</Button>
                    </>
                  }

                </>
              }
            </div>
            {showOtp &&
              <div style={{ marginTop: "1px", display: "flex" }}>
                <div className='d-flex flex-column' >
                  <input value={otp} type='number' onChange={(e) => setOtp(e.target.value)} style={{ width: "14rem", borderColor: otpError && "red" }} />
                  <p style={{ color: "red" }}>{otpError}</p>
                </div>
                <Button variant="success" size="sm" style={{ marginLeft: "10px", paddingLeft: "5px", paddingRight: "5px", height: "30px" }} onClick={verifyEmailOtp}>Verify-OTP</Button>
                {isResend ?
                  loader ?
                    <CircularProgress style={{ marginLeft: "10px", marginTop: "3px", width: "20px", height: "20px" }} />
                    : <Button variant='link' size="sm" style={{ marginLeft: "10px", paddingLeft: "5px", paddingRight: "5px", height: "30px" }} onClick={sendEmailOtp}>Resend OTP?</Button>
                  : <span style={{ color: "#a0a0a0", marginLeft: "5px" }}>Resend Otp: {timeLeft}s </span>}
              </div>
            }
            <Label className='form-label'>Mobile</Label>
            {isDisabledMobileInput ? (
              <div className='d-flex'>
                <p style={{ fontWeight: "500", fontSize: "14px" }}>{mobile}</p>
                <FontAwesomeIcon
                  style={{
                    marginLeft: "5px",
                    color: "blue",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                  icon={faEdit}
                  onClick={enableMobileEdit}
                />
              </div>
            ) : (
              <div className="d-flex flex-row">
                <>
                  <input
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    style={{ width: "14rem", borderColor: mobileError ? "red" : "" }}
                  />
                  {mobileError && <p style={{ color: "red" }}>{mobileError}</p>}
                </>
                <>
                  {!mobileOtpSent && (
                    <>
                      {mobileLoader ? (
                        <CircularProgress
                          style={{
                            marginLeft: "10px",
                            marginTop: "3px",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      ) : (
                        <Button
                          variant="primary"
                          size="sm"
                          style={{
                            marginLeft: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            height: "30px",
                          }}
                          onClick={sendMobileOtp}
                        >
                          Send OTP
                        </Button>
                      )}
                      <Button
                        variant="danger"
                        size="sm"
                        style={{
                          marginLeft: "10px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          height: "30px",
                        }}
                        onClick={() => cancelMobileEdit()}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </>
              </div>
            )}
            {/* {!isDisabledMobileInput && (
                
                )} */}

            {mobileShowOtp && (
              <div style={{ marginTop: "1px", display: "flex" }}>
                <div className="d-flex flex-column">
                  <input
                    value={mobileotp}
                    type="number"
                    onChange={(e) => setMobileOtp(e.target.value)}
                    style={{ width: "14rem", borderColor: mobileOtpError ? "red" : "" }}
                  />
                  {mobileOtpError && <p style={{ color: "red" }}>{mobileOtpError}</p>}
                </div>
                <Button
                  variant="success"
                  size="sm"
                  style={{
                    marginLeft: "10px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    height: "30px",
                  }}
                  onClick={verifyMobileOtp}
                >
                  Verify OTP
                </Button>
                {isMobileResend ? (
                  mobileLoader ? (
                    <CircularProgress
                      style={{
                        marginLeft: "10px",
                        marginTop: "3px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ) : (
                    <Button
                      variant="link"
                      size="sm"
                      style={{
                        marginLeft: "10px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        height: "30px",
                      }}
                      onClick={sendMobileOtp}
                    >
                      Resend OTP?
                    </Button>
                  )
                ) : (
                  <span style={{ color: "#a0a0a0", marginLeft: "5px" }}>
                    Resend OTP: {mobileTimeLeft}s
                  </span>
                )}
              </div>
            )}


          </FormGroup>
          <Form onSubmit={onSubmit}>
            <FormGroup className='mb-3'>
              <Label className='form-label'>Telephone</Label>
              <Input
                name="companyTelephone"
                value={companyDetails?.companyTelephone}
                onChange={handleInputChange}
                disabled={isDisabledCompanyDetails}
                className='form-control'
                style={{ backgroundColor: isDisabledCompanyDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
              />
              {errors?.companyTelephone && <span className="text-danger">{errors?.companyTelephone}</span>}
            </FormGroup>

            <FormGroup className='mb-3'>
              <Row>
                <Col md="6">
                  <Label className='form-label'>GST</Label>
                  <Input
                    name="gstNumber"
                    value={companyDetails?.gstNumber}
                    onChange={handleInputChange}
                    disabled={isDisabledCompanyDetails}
                    className='form-control'
                    style={{ backgroundColor: isDisabledCompanyDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                  />
                  {errors?.gstNumber && <span className="text-danger">{errors?.gstNumber}</span>}
                </Col>
                <Col md="6">
                  <Label className='form-label'>PAN</Label>
                  <Input
                    name="panNumber"
                    value={companyDetails?.panNumber}
                    onChange={handleInputChange}
                    disabled={isDisabledCompanyDetails || companyDetails.gstNumber}
                    className='form-control'
                    style={{ backgroundColor: isDisabledCompanyDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                  />
                  {errors?.panNumber && <span className="text-danger">{errors?.panNumber}</span>}
                </Col>
              </Row>
            </FormGroup>

            <FormGroup className='mb-3'>
              <Label className='form-label'>Company-Incorporation Date</Label>
              <Input
                name="incorporationDate"
                value={companyDetails?.incorporationDate}
                onChange={handleInputChange}
                disabled={isDisabledCompanyDetails}
                type='date'
                style={{ backgroundColor: isDisabledCompanyDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                className='form-control'
              />
              {errors?.incorporationDate && <span className="text-danger">{errors?.incorporationDate}</span>}
            </FormGroup>

            <FormGroup className='mb-3'>
              <H6 attrH6={{ className: 'form-label' }}>Address</H6>
              <Input
                name="companyAddress"
                value={companyDetails?.companyAddress}
                onChange={handleInputChange}
                disabled={isDisabledCompanyDetails}
                type='textarea'
                style={{ backgroundColor: isDisabledCompanyDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                className='form-control'
                rows='2'
              />
              {errors?.companyAddress && <span className="text-danger">{errors?.companyAddress}</span>}
            </FormGroup>

            {/* {!isDisabledCompanyDetails && ( */}
            <Row>
              <Col md="6">
                <FormGroup className='mb-3'>
                  <Label className='form-label'>District</Label>
                  <Input
                    name="district"
                    value={companyDetails?.district}
                    onChange={handleInputChange}
                    type="select"
                    style={{ backgroundColor: isDisabledCompanyDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                    disabled={isDisabledCompanyDetails}
                    className='form-control'
                  >
                    <option value="">Select District</option>
                    <option value="District1">District 1</option>
                    <option value="District2">District 2</option>
                  </Input>
                  {errors?.district && <span className="text-danger">{errors?.district}</span>}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className='mb-3'>
                  <Label className='form-label'>State</Label>
                  <Input
                    name="state"
                    value={companyDetails?.state}
                    onChange={handleInputChange}
                    type="select"
                    style={{ backgroundColor: isDisabledCompanyDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                    disabled={isDisabledCompanyDetails}
                    className='form-control'
                  >
                    <option value="">Select State</option>
                    <option value="State1">State 1</option>
                    <option value="State2">State 2</option>
                  </Input>
                  {errors?.state && <span className="text-danger">{errors.state}</span>}
                </FormGroup>
              </Col>
            </Row>
            {/* )} */}

            <div className='d-flex justify-content-end mb-3' style={{ marginRight: "1px", marginTop: "10px" }}>
              {isDisabledCompanyDetails ? (
                <Button type="button" onClick={() => setIsDisabledCompanyDetails(!isDisabledCompanyDetails)}>Update</Button>
              ) : (
                <>
                  <Button variant="danger" type="button" onClick={() => cancelCompanyProfileEdit()}>Cancel</Button>
                  {loader ? (
                    <CircularProgress style={{ marginLeft: "10px", marginTop: "3px", width: "20px", height: "20px" }} />
                  ) : (
                    <Button style={{ backgroundColor: "#0B0FF1CY", marginLeft: "15px" }} type="submit">Submit</Button>
                  )}
                </>
              )}
            </div>

          </Form>
        </CardBody>
      </Card>
      <ConfirmDialog />
    </Fragment >
  );
};

export default CompanyProfile;
import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { url } from '../../../../../../Constant';
import { Toast } from 'primereact/toast';
import { DialogActions, TextField } from '@mui/material';

export default function DataTable() {
    const toast = useRef(null);
    const [visible, setVisible] = useState(true);
    const [date, setDate] = useState();
    const [dateError, setDateError] = useState();
    const [dateErrorMessage, setDateErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!date) {
            setDateError(true);
            setDateErrorMessage('Breakdown date is required');
            return;
        }
        try {
            const response = await fetch(`${url}/api/admin/add_category`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                // body: JSON.stringify(body),
            });

            if (response.ok) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: "Breakdown date updated successfully",
                    life: 3000,
                });
            } else {
                const errorData = await response.json();
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: errorData.message || 'An unexpected error occurred',
                    life: 3000,
                });
            }
        } catch (error) {
            console.error('Error during submission:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Network Error',
                detail: `An error occurred: ${error.message}`,
                life: 3000,
            });
        }
    };

    return (
        <>
            <Dialog header={"Breakdown Date"} visible={visible} onHide={() => setVisible(!visible)} style={{ width: '300px' }}>
                <Toast ref={toast} />
                <div className='d-flex justify-content-center align-items-center'>
                    <TextField
                        fullWidth
                        id="breakdownDate"
                        name="breakdownDate"
                        label="Breakdown Date"
                        type="date"
                        sx={{ width: "100%", mt: 3, backgroundColor: "#F7F8F9" }}
                        value={date}
                        onChange={(e)=>setDate(e.target.value)}
                        error={dateError}
                        helperText={dateErrorMessage}
                        InputProps={{
                            sx: {
                                height: '45px',
                                fontSize: '13px',
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1,
                            },
                            shrink: true
                        }}
                    />
                </div>
                <DialogActions className="mt-3">
                        <Button severity='danger' style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} color='error' onClick={()=>setVisible(!visible)}>Cancel</Button>
                        <Button severity='primary' style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} type="submit">Update</Button>
                    </DialogActions>
            </Dialog>
        </>
    );
}

import React from 'react';
import { Skeleton } from 'primereact/skeleton'; // Adjust according to your skeleton component library

const RowSkeleton = ({ columns }) => (
    <tr>
        {columns.map((col, index) => (
            <td key={index} style={{ padding: '1.5rem 0.38rem' }}> {/* Optional padding for cells */}
                <Skeleton 
                    width={col.style.minWidth} 
                    height="1rem" 
                    style={{ margin: '0.5rem 0' }}  // Adjust margin as needed
                />
            </td>
        ))}
    </tr>
);

const TableSkeleton = ({ rowCount, columns }) => (
    <table className="p-datatable p-component">
        <thead>
            <tr>
                {columns.map((col, index) => (
                    <th key={index}  style={{ padding: '1.5rem 0.38rem' }}>
                        <Skeleton 
                            width={col.style.minWidth} 
                            height="1rem" 
                            style={{ margin: '0rem 0rem' }}  // Adjust margin as needed
                        />
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            {Array.from({ length: rowCount }).map((_, rowIndex) => (
                <RowSkeleton key={rowIndex} columns={columns} />
            ))}
        </tbody>
    </table>
);

export default TableSkeleton;

// src/FullPageLoader.js
import React from 'react';

const FullPageLoader = ({ open }) => {
  if (!open) return null;

  // Inline styles for the backdrop
  const backdropStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    paddingTop: '10px', // Space at the top for the loader
  };

  // Inline styles for the spinner
  const spinnerStyles = {
    border: '8px solid #f3f3f3', // Light grey
    borderTop: '8px solid #3498db', // Blue
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    animation: 'spin 1s linear infinite',
  };

  // Inline styles for the text
  const textStyles = {
    marginTop: '8px',
    color: 'white',
    fontSize: '14px',
  };

  // Add keyframes for spinning animation
  const styleSheet = document.styleSheets[0];
  const keyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

  return (
    <div style={backdropStyles}>
      <div style={spinnerStyles}></div>
      <div style={textStyles}>Loading...</div>
    </div>
  );
};

export default FullPageLoader;

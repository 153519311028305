import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { AddCategory, FlatStyle, url } from '../../../Constant';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import FullPageLoader from '../../../Components/Loaders/FullPageLoader';
import { Skeleton } from 'primereact/skeleton';
import { padStart } from '@fullcalendar/core/internal';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { SplitButton } from 'primereact/splitbutton';
import * as XLSX from 'xlsx';
import Pagination from '../../../_helper/Pagination/Pagination';
import TableSkeleton from '../../../Components/Loaders/TableSkeleton';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddMachineModal from './AddMachineModal';
import AddCategoryModal from './AddCategoryModal';


// import { Box, Typography } from '@mui/material';
// import SadminSidebar from '../../components/Sidebar';


export default function MachineCategories() {
    let emptyCompany = {
        catId: null,
        cat_image:'' ,
        catName: '',
        catType: '',
        catDescr: '',
    };

    const [categoryDialog, setCategoryDialog] = useState(false);
    const [deleteCompanyDialog, setDeleteCompanyDialog] = useState(false);
    const [deleteCompaniesDialog, setDeleteCompaniesDialog] = useState(false);
    const [category, setCategory] = useState(emptyCompany);
    const [selectedCategories, setSelectedCategories] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loader, setLoader] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [drawerWidth, setDrawerWidth] = React.useState(260);
    const [metadata, setMetadata] = useState({});
    const [categories, setCategories] = useState(null);
    const [currentPage, setCurrentPage] = useState(()=>{ return 1});
    const [pageSize, setPageSize] = useState(()=>{ return 10});
    const [statusLoader, setStatusloader]=useState(false);
    const [editCategoryId,setEditCategoryId]=useState(null);

    useEffect(()=>{
        //console.log(selectedCategories)
    },[selectedCategories])
    
    const fetchCategories = async () => {
        setLoader(true);
    
        const filtersParams = ['catName', 'catId', 'catDescr']; // Example array
        const params = new URLSearchParams({
            companyCode: localStorage.getItem('companyCode'),
            page: currentPage,
            limit: pageSize,
            filter: globalFilter || ''
        });
    
        // Append each filter as a separate query parameter
        filtersParams.forEach(filter => params.append('filterParams[]', filter));
    
        try {
            const response = await fetch(`${url}/api/admin/get_all_categories?${params.toString()}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) {
                if (response.status === 403) {
                    toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
                    throw new Error('Unauthorized');
                }
                throw new Error('Network response was not ok.');
            }
    
            const result = await response.json();
            //console.log(result)
    
            if (pageSize === 1000) {
                setCategories(prevCategories => [...prevCategories, ...result.data]);
            } else {
                setCategories(result.data);
            }
            
            setMetadata(result.metadata);
        } catch (error) {
            console.error('Fetch error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while fetching data', life: 3000 });
        } finally {
            setLoader(false);
        }
    };
    
    
    useEffect(() => {
        // Reset categories data when filter, pageSize, or currentPage changes
        setLoader(true);
        setCategories([]);
        fetchCategories();
    }, [currentPage, pageSize, globalFilter]);
    
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= metadata.totalPages) {
          setCurrentPage(newPage);
        }
      };
    
      const handlePageSizeChange = (event) => {
        // //console.log(event.target.value);
        setPageSize(Number(event.target.value));
        //console.log("page size set to ",event.target.value)
        setCurrentPage(1); // Reset to first page on page size change
      };



    const openNew = () => {
        setSubmitted(false);
        setCategoryDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setCategoryDialog(false);
    };

    const hideDeleteCompanyDialog = () => {
        setDeleteCompanyDialog(false);
    };

    const hideDeleteCompaniesDialog = () => {
        setDeleteCompaniesDialog(false);
    };

    const saveCategory = () => {
        setSubmitted(true);
        if (category.companyName.trim()) {
            let _categories = [...categories];
            let _category = { ...category };
            if (category.company_code) {
                const index = findIndexById(category.company_code);
                _categories[index] = _category;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Updated', life: 3000 });
            } else {
                _category.company_code = createId();
                _categories.push(_category);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Created', life: 3000 });
            }

            setCategories(_categories);
            setCategoryDialog(false);
            setCategory(emptyCompany);
        }
    };

    const editCompany = (category) => {
        setCategory({ ...category });
        setCategoryDialog(true);
    };

    const confirmDeleteCompany = (category) => {

        setCategory(category);
        setDeleteCompanyDialog(true);
    };

    const deleteCompany = async () => {
        setLoader(true);
        //console.log(category.company_code)
        // //console.log(rowData.status, "++++++++++")
        try {
            // Perform the POST request to update the status
            const response = await fetch(`${url}/api/sup/delete_admin`, {
                method: 'delete',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    company_code: category.company_code,
                })
            });
            if (!response.ok) {
                setLoader(false);
                throw new Error('Network response was not ok');

            }
            const result = await response.json();
            if (response.status == 200) {
                setLoader(false);
                //console.log("OK")
                // Update local state if the status change was successful
                fetchCategories();
                // const index = findIndexById(rowData.company_code);
                // let _categories = [...categories];
                // _categories[index] = { ...rowData, status: newStatus };
                // setCategories(_categories);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Deleted', life: 3000 });
            } else {
                throw new Error(result.message || 'Failed to Delete status');
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setLoader(false);
            setDeleteCompanyDialog(false);
            // //console.log("", newStatus);
        }
        // Delete API Call

        // let _categories = categories.filter((val) => val.company_code !== category.company_code);
        // setCategories(_categories);
        // setDeleteCompanyDialog(false);
        // setCategory(emptyCompany);
        // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Deleted', life: 3000 });
    };
    




    const findIndexById = (company_code) => {
        let index = -1;

        for (let i = 0; i < categories.length; i++) {
            if (categories[i].company_code === company_code) {
                index = i;
                break;
            }
        }
        return index;
    };

    const createId = () => {
        let company_code = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            company_code += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return company_code;
    };



    const exportOptions = [
        {
            label: 'Export as PDF',
            icon: 'pi pi-file-pdf',
            command: () => {
                exportToPDF();
                toast.current.show({ severity: 'success', summary: 'Exported', detail: 'Data Exported' });
            }
        },
        {
            label: 'Export CSV',
            icon: 'pi-file',
            command: () => {
                exportCSV();
                toast.current.show({ severity: 'success', summary: 'Exported', detail: 'Data Exported' });
            }
        },
    ];


    const exportCSV = () => {
        // Use the same column headers as in your PDF export
        // const columns = [
        //     { title: "Company Code", dataKey: "companyCode" },
        //     { title: "Company Name", dataKey: "companyName" },
        //     { title: "Prefix", dataKey: "prefix" },
        //     { title: "Contact Person", dataKey: "contactPerson" },
        //     { title: "Contact Number", dataKey: "contactNumber" },
        //     { title: "Registration Date/Time", dataKey: "registrationDate" },
        //     { title: "Status", dataKey: "status" }
        // ];
    
        // Extract the data
        const categoryData = selectedCategories || categories;
        const rows = categoryData.map(category => ({
            companyCode: `FR${category.CompanyDetails?.companyPrefix || "---"}${category.company_code ? padStart(category.company_code, 4, '0') : "----"}`,
            companyName: category.CompanyDetails?.companyName || "N/A",
            prefix: category.CompanyDetails?.companyPrefix || "N/A",
            contactPerson: `${category.PersonalDetails?.firstName || "N/A"} ${category.PersonalDetails?.middleName || ""} ${category.PersonalDetails?.lastName || ""}`,
            contactNumber: category.mobile || "N/A",
            registrationDate: category.register_date || "N/A",
            status: category.status ? "Active" : "Inactive"
        }));
    
        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(rows, { header: columns.map(col => col.title) });
    
        // Create a new workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Selected Companies');
    
        // Write the file
        XLSX.writeFile(wb, 'Companies.xlsx');
    };
    
    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.text("Companies List", 14, 16);
        
        const categoryData=selectedCategories || categories;        


        const columns = [
            { title: "Company Code", dataKey: "companyCode" },
            { title: "Company Name", dataKey: "companyName" },
            { title: "Prefix", dataKey: "prefix" },
            { title: "Contact Person", dataKey: "contactPerson" },
            { title: "Contact Number", dataKey: "contactNumber" },
            { title: "Registration Date/Time", dataKey: "registrationDate" },
            { title: "Status", dataKey: "status" }
        ];
        const rows = categoryData.map(category => ({
            companyCode: `FR${category.CompanyDetails?.companyPrefix || "---"}${category.company_code ? padStart(category.company_code, 4, '0') : "----"}`,
            companyName: category.CompanyDetails?.companyName || "N/A",
            prefix: category.CompanyDetails?.companyPrefix || "N/A",
            contactPerson: (category.PersonalDetails?.firstName || "N/A") + " " + (category.PersonalDetails?.middleName || "") + " " + (category.PersonalDetails?.lastName || ""),
            contactNumber: category.mobile || "N/A",
            registrationDate: category.register_date || "N/A",
            status: category.status ? "Active" : "Inactive"
        }));
        doc.autoTable(columns, rows, { startY: 22 });
        doc.save('categories.pdf');
    };

    const confirmDeleteSelected = () => {
        setDeleteCompaniesDialog(true);
    };

    const deleteSelectedCompanies = async () => {
            setLoader(true);
            const companyCodes = selectedCategories.map(category => category.company_code);
            //console.log(companyCodes)

            // //console.log(filteredCompanies)
            // //console.log(rowData.status, "++++++++++")
            try {
                // Perform the POST request to update the status
                const response = await fetch(`${url}/api/sup/delete_admins`, {
                    method: 'delete',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        companyCodes: companyCodes,
                    })
                });
                if (!response.ok) {
                    setLoader(false);
                    throw new Error('Network response was not ok');
    
                }
                const result = await response.json();
                if (response.status === 200) {
                    setLoader(false);
                    //console.log("OK")
                    // Update local state if the status change was successful
                    fetchCategories();
                    // const index = findIndexById(rowData.company_code);
                    // let _categories = [...categories];
                    // _categories[index] = { ...rowData, status: newStatus };
                    // setCategories(_categories);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Deleted', life: 3000 });
                    // deleteCompanyDialog(false);
                } else {
                    throw new Error(result.message || 'Failed to Delete status');
                }
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            } finally {
                setLoader(false);
                // //console.log("", newStatus);
            }

            setDeleteCompaniesDialog(false);
            setSelectedCategories(null);
        //console.log(selectedCategories)
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _category = { ...category };
        _category[`${name}`] = val;
        setCategory(_category);
    };
    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = { ...category };

        _product[`${name}`] = val;

        setCategory(_product);
    };

    const onCategoryChange = (e) => {
        let _product = { ...category };

        _product['category'] = e.value;
        setCategory(_product);
    };



    const leftToolbarTemplate = () => {
        return (<>
                    <SplitButton label="EXPORT" className='mx-1' onClick={exportCSV} icon="pi pi-file-xls" severity='danger' model={exportOptions} />
                    {/* <Button label="Add Category" icon="pi pi-plus" className="p-button-help mx-1" onClick={openNew} /> */}
                    {/* <AddCategoryModal visible={categoryDialog} hideDialog={hideDialog} editCategoryId={editCategoryId} fetchCategories={fetchCategories}/> */}
                    <AddCategoryModal editCategoryId={editCategoryId} setEditCategoryId={setEditCategoryId} categoryDialog={categoryDialog} setCategoryDialog={setCategoryDialog} fetchCategories={fetchCategories}/>
                    {/* <AddMachineModal editMachineId={editMachineId} setEditMachineId={setEditMachineId} machineDialog={machineDialog} setMachineDialog={setMachineDialog}/> */}
                    <FormControl variant="outlined" className='mx-1' size="small" style={{ minWidth: 75 }}>
                    <InputLabel>Items</InputLabel>
                    <Select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        label="Items"
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                    </FormControl>
                </>
        );
    };

    const rightToolbarTemplate = () => {
        return(
        <IconField iconPosition="left">
            <InputIcon className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </IconField>
        )
        // <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />;
    };
    const onstatusChange = async (rowData) => {
        // setLoader(true);
        setStatusloader(true)
        const newStatus = rowData.status === true ? false : true;

        try {
            const response = await fetch(`${url}/api/admin/change_status`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    company_code: localStorage.getItem('company_code'),
                    status: newStatus
                })
            });
            if (!response.ok) {
                // setLoader(false);
                setStatusloader(false)

                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            if (response.status == 200) {

                setStatusloader(false)
                //console.log("OK")
                fetchCategories();
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Status Updated', life: 3000 });
            } else {
                setStatusloader(false)
                throw new Error(result.message || 'Failed to update status');
            }
        } catch (error) {
            setStatusloader(false)
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setStatusloader(false)
            //console.log("Status changed: ", newStatus);
        }
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <Button style={{ fontSize: "12px", padding: "2px", cursor: "pointer", backgroundColor: rowData.status ? "green" : "red" }} onClick={() => onstatusChange(rowData)} value={rowData.status} severity={getSeverity(rowData)}>{rowData.status ? "Active" : "Inactive"}</Button>
        );
    };


    function padZero(value) {
        const strValue = String(value);
      
        return strValue.padStart(4, '0');
    }

    const companyCodeBody = (rowData) => {
        return (
            <div>{`FR${rowData.CompanyDetails?rowData.CompanyDetails.companyPrefix:"---"}${rowData.company_code?padZero(rowData.company_code):"----"}`}</div>
        )
    }
    const nameBodyTemplate =(rowData)=>{
        return <div>{(rowData.PersonalDetails&&rowData.PersonalDetails.firstName)?rowData.PersonalDetails.firstName+" "+((rowData.PersonalDetails.middleName!==undefined&&rowData.PersonalDetails.middleName!=="")?rowData.PersonalDetails.middleName:"")+" "+((rowData.PersonalDetails.lastName!==undefined&&rowData.PersonalDetails.lastName!=="")?rowData.PersonalDetails.lastName:""):"N/A"}</div>

    }
    function formatTimestampDDMMYYYY(timestamp) {
        // Create a new Date object from the timestamp
        const date = new Date(timestamp);
        
        // Extract day, month, and year
        const day = String(date.getUTCDate()).padStart(2, '0'); // Pad single-digit days with leading zero
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
        const year = date.getUTCFullYear();
        
        // Format the date as DD-MM-YYYY
        return `${day}-${month}-${year}`;
    }
    
    const formattedDate=(rowData)=>{
        return <div>{formatTimestampDDMMYYYY(rowData.register_date)}</div>
    }
    const lastActiveBodyTemplate =(rowData)=>{
        return <div>{(rowData.status)?<div className='text-success'>Currently Active</div>:<div className='text-danger'>{rowData.deactivated_at}</div>}</div>
``
    }

    const linkBodyTemplate = (rowData) => {
        return (<Link to={`${process.env.PUBLIC_URL}/panel/machine_table?catId=${rowData.catId}`}>
                    <div style={{display:"flex",flexDirection:"row"}}>
                    {/* <img src={rowData&&rowData.cat_image} alt="category Logo" className="shadow-2" style={{ width: '35px',height:'35px',borderRadius:"50%" }} /> */}
                        <div className="my-auto" style={{marginLeft:"7px"}}>{rowData && rowData.catName}</div>
                        {rowData.subscription&&<i className='pi pi-crown my-auto mx-2' style={{color:"#fc9403",fontSize:"1rem"}}/>}
                    </div>
               </Link>);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                  icon="pi pi-pencil"
                  rounded
                  outlined
                  severity="success"
                  onClick={() => {openNew();setEditCategoryId(rowData.catId)}}
                  style={{
                    outline: 'none',
                    border: 'none', // Remove border if needed
                    boxShadow: 'none' // Remove box-shadow if needed
                  }}
                />
                <Button
                  icon="pi pi-trash"
                  rounded
                  outlined
                  severity="danger"
                  onClick={() => {confirmDeleteCompany(rowData)}}
                  style={{
                    outline: 'none',
                    border: 'none', // Remove border if needed
                    boxShadow: 'none' // Remove box-shadow if needed
                  }}
                />
            </React.Fragment>
        );
    };

    const getSeverity = (category) => {
        switch (category.status) {
            case true:
                return 'success';
            case false:
                return 'danger';
            default:
                return 'danger';
        }
    };

    const deleteCompanyDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteCompanyDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteCompany} />
        </React.Fragment>
    );
    const categoryDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveCategory} />
        </React.Fragment>
    );

    const deleteCompaniesDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteCompaniesDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedCompanies} />
        </React.Fragment>
    );

    const columns = [
        { selectionMode: 'multiple', exportable: false, style: { width: '3rem', fontSize: "13px" } },
        // { field: 'companyLogo', header: 'Company Logo', body: logoBodyTemplate, style: { minWidth: '10rem', fontSize: "13px" } },
        {
            field: 'catId',
            header: 'Category ID',
            sortable: true,
            style: { minWidth: '10rem', fontSize: "13px" }
        },
        {
            field: 'catName',
            header: 'category Name',
            body: linkBodyTemplate,
            sortable: true,
            style: { minWidth: '16rem', fontSize: "13px" }
        },
        {
            field: 'catDescr',
            header: 'Category Description',
            style: { minWidth: '5rem', fontSize: "13px" }
        },
        {
            header: 'Action',
            body: actionBodyTemplate,
            exportable: false,
            style: { minWidth: '8rem' }
        }
    ];

    const [distanceFromBottom, setDistanceFromBottom] = useState(0);
    const [noOfscrolls,setnoOfscrolls]=useState(0)
    const divRef = useRef(null); // Ref to access the div element

    // Function to be called when reaching the bottom
    const onReachBottom = () => {
        //console.log(pageSize)
        if(!loader&&pageSize===1000 ){
            setnoOfscrolls(noOfscrolls+1);
            setCurrentPage(noOfscrolls)
            //console.log('ififif');
            
        }
        //console.log('Reached the bottom of the page');
      // Add your logic here;
      // Add your logic here
    };
  
    useEffect(() => {
      const calculateDistanceFromBottom = () => {
        if (divRef.current) {
          const rect = divRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight;
          const distance = viewportHeight - rect.bottom;
  
          // Call function when the div is at or near the bottom
          if (distance >= 0) {
            onReachBottom();
          }
        }
      };
  
      // Initial calculation
      calculateDistanceFromBottom();
  
      // Recalculate on window resize and scroll
      window.addEventListener('resize', calculateDistanceFromBottom);
      window.addEventListener('scroll', calculateDistanceFromBottom);
  
      // Cleanup event listeners on component unmount
      return () => {
        window.removeEventListener('resize', calculateDistanceFromBottom);
        window.removeEventListener('scroll', calculateDistanceFromBottom);
      };
    }, []); // Empty dependency array to run only once on mount
  
        
    return (
        <>
        <div className="datatable-crud-demo">
            <Breadcrumbs mainTitle="Machine categories" parent="General" title="Categories" />
            <FullPageLoader open={statusLoader} />
            <Toast ref={toast} />
            <div className="card">
                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
                <DataTable
                    ref={dt}
                    value={categories}
                    selection={selectedCategories}
                    onSelectionChange={(e) => setSelectedCategories(e.value)}
                    dataKey="catId"
                    paginator={false}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    // globalFilter={globalFilter}
                    // header={header}
                    emptyMessage={" "}
                    className="datatables-demo"
                >
                    {columns.map((col, i) => (
                        <Column key={i} field={col.field} header={col.header} body={col.body} sortable={col.sortable} style={col.style} exportable={col.exportable} selectionMode={col.selectionMode} />
                    ))}
                </DataTable>
                    {loader&&(
                        <TableSkeleton rowCount={pageSize} columns={columns} />
                    )}

                <Pagination
                    currentPage={currentPage}
                    totalPages={metadata?.totalPages}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    pageSize={pageSize}
                    setLoader={setLoader}
                />
                {/* <p>Distance from bottom of viewport: {distanceFromBottom}px</p> */}
            </div>

            {/* <AddMachineModal visible={categoryDialog} hideDialog={hideDialog} editCategoryId={editCategoryId}/> */}
            {/* <AddCategoryModal visible={categoryDialog} hideDialog={hideDialog} editCategoryId={editCategoryId} fetchCategories={fetchCategories}/> */}



            <Dialog visible={deleteCompanyDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCompanyDialogFooter} onHide={hideDeleteCompanyDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {category && (
                        <span>
                            Are you sure you want to delete <b>{category.companyName}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteCompaniesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCompaniesDialogFooter} onHide={hideDeleteCompaniesDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {selectedCategories && <span>Are you sure you want to delete the selected categories?</span>}
                </div>
            </Dialog>
        </div>
    </>
    );
}
import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { url } from '../../../Constant';
import { Toast } from 'primereact/toast';
import { DialogActions, TextField, Box, InputLabel, FormControl, FormHelperText, Select, MenuItem, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import { Breadcrumbs } from '../../../AbstractElements';
import ChangeUserPassword from './ChangeUserPassword';
import { useNavigate } from 'react-router-dom';


const initialFormData = {
    id: '',
    username: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    mobile: '',
    prefix: '',
    roleId: '',
    role: '',
    permission: '',
    details: '',
    aadharImage: null,
    licenseImage: null,
    dateOfBirth: ''
};

export default function EditUserProfile() {
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const toast = useRef(null);
    const [RIdOptions, setRIdOptions] = useState();
    const [customers, setCustomers] = useState();
    const urlParams = new URLSearchParams(window.location.search);
    const [disabled, setDisabled] = useState(false);
    const userId = urlParams.get('id');
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserData()
        fetchAllRoles()
    }, [])

    const fetchUserData = async () => {
        try {
            const response = await fetch(`${url}/api/admin/users/getUserById?id=${userId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch user data');

            const userData = await response.json();
            console.log(userData)
            setFormData(userData);
        } catch (error) {
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch user data', life: 3000 });
        }
    };

    const fetchAllRoles = () => {
        fetch(url + '/api/sup/role/getAll').then((response) => {
            if (response.ok) {
                response.json().then((result) => {
                    setCustomers(result)
                    setRIdOptions(result);
                    console.log(result)
                })
            }
        })
    }

    const handleInputChange = (e, field) => {
        setFormData({ ...formData, [field]: e.target.value });
    };

    const handleImageChange = (e, field) => {
        const file = e.target.files[0];
        setFormData({ ...formData, [field]: file });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.username) newErrors.username = "Username is required";
        if (!formData.email) newErrors.email = "Email is required";
        // Additional validations as needed...
        return newErrors;
    };

    const handleRIdChange = (e) => {
        console.log(e.value)
        setFormData({ ...formData, roleId: e.value });
    };

    const handleSubmit = async () => {
        const formErrors = validateForm();
        setErrors(formErrors);
        if (Object.keys(formErrors).length > 0) return;
        const formDataToSend = new FormData();
        Object.entries({ ...formData }).forEach(([key, value]) => {
            formDataToSend.append(key, value);
        });
        try {
            const response = await fetch(`${url}/api/admin/users/addUser`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formDataToSend,
            });

            if (response.ok) {
                const data = await response.json();
                toast.current.show({ severity: 'success', summary: 'Success', detail: data.message, life: 3000 });
                setDisabled(!disabled)
            }
            else {
                const errorData = await response.json();
                toast.current.show({ severity: 'error', summary: 'Error', detail: errorData.message || 'An error occurred', life: 3000 });
            }
        } catch (error) {
            console.error('Error during submission:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'An unexpected error occurred', life: 3000 });
        }
    };
    return (
        <>
            <Breadcrumbs mainTitle="Edit User Profile" parent="Setup" title="Edit" subParent="User" subParentTwo="Profile" />

            <Toast ref={toast} />
            <div className="row">
                <div className='col-md-12' style={{ marginTop: 5 }}>
                    <form>
                        <div style={{ width: "100%", height: "auto", paddingLeft: "2%", paddingRight: "2%", paddingTop: "2%", paddingBottom: "2%", backgroundColor: "#fff", borderRadius: 30 }}>
                            <h5>Basic Details</h5>
                            <div className="row" style={{ marginTop: 25, paddingLeft: "1%", paddingRight: "1%" }}>

                                <FormControl
                                    sx={{ width: "24%", marginLeft: "1%" }}
                                    disabled={disabled}
                                // error={!!errors.gender}
                                >
                                    <InputLabel sx={{ fontSize: '0.6vw', zIndex: 1 }} id="prefix-label">
                                        Prefix
                                    </InputLabel>
                                    <Select
                                        disabled={disabled}
                                        labelId="prefix-label"
                                        id="prefix"
                                        value={formData.prefix}
                                        name="prefix"
                                        label="Prefix"
                                        sx={{
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                            // zIndex:1001
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    fontSize: '0.6vw',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="Mr">
                                            <em>Mr</em>
                                        </MenuItem>
                                        <MenuItem value="Miss">
                                            <em>Miss</em>
                                        </MenuItem>
                                        <MenuItem value="Miss">
                                            <em>Miss</em>
                                        </MenuItem>
                                        <MenuItem value="Other">
                                            <em>Other</em>
                                        </MenuItem>

                                    </Select>
                                    <FormHelperText sx={{ fontSize: '12px' }}>
                                        {errors.prefix}
                                    </FormHelperText>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    disabled={disabled}
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    value={formData.firstName}
                                    sx={{ width: "24%", marginLeft: "1%" }}
                                    onChange={(e) => handleInputChange(e, 'firstName')}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.6vw',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="middleName"
                                    disabled={disabled}
                                    name="middleName"
                                    label="Middle Name"
                                    sx={{ marginLeft: "1%", width: "24%" }}
                                    value={formData.middleName}
                                    onChange={(e) => handleInputChange(e, 'middleName')}
                                    error={!!errors.middleName}
                                    helperText={errors.middleName}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.6vw',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    disabled={disabled}
                                    label="Last Name"
                                    sx={{ marginLeft: "1%", width: "24%" }}
                                    value={formData.lastName}
                                    onChange={(e) => handleInputChange(e, 'lastName')}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.6vw',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="username"
                                    disabled={disabled}
                                    name="username"
                                    label="Username"
                                    value={formData.username}
                                    sx={{ marginLeft: "1%", width: "24%", mt: 3 }}
                                    onChange={(e) => handleInputChange(e, 'username')}
                                    error={!!errors.username}
                                    helperText={errors.username}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.6vw',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                <TextField
                                    error={!!errors.dateOfBirth}
                                    fullWidth
                                    type="date"
                                    disabled={disabled}
                                    sx={{ mt: 3, width: "24%", marginLeft: "1%" }}
                                    id="dateOfBirth"
                                    label="Date of Birth"
                                    value={formData.dateOfBirth}
                                    onChange={(e) => handleInputChange(e, 'dateOfBirth')}
                                    helperText={errors.dateOfBirth}
                                    required
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '13px', // Adjust the font size as needed
                                            borderRadius: 3,
                                            width: "98.9%"
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        sx: {
                                            fontSize: '13px', // Adjust the label font size as needed
                                        },
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        },
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    disabled={disabled}
                                    label="Email"
                                    value={formData.email}
                                    sx={{ width: "24%", mt: 3, marginLeft: "1%" }}
                                    onChange={(e) => handleInputChange(e, 'email')}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.6vw',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                <FormControl
                                    sx={{ width: "24%", marginLeft: "1%", mt: 3 }}
                                    error={!!errors.roleTypeOption}
                                    disabled={disabled}
                                >
                                    <InputLabel sx={{ fontSize: '0.6vw', zIndex: 1 }} id="roleTypeOption-label">
                                        Role
                                    </InputLabel>
                                    <Select
                                        labelId="roleTypeOption-label"
                                        id="roleTypeOption"
                                        name="roleTypeOption"
                                        disabled={disabled}
                                        value={formData.roleId}
                                        onChange={(e) => handleRIdChange(e.target)}
                                        label="Role"
                                        sx={{
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                            // zIndex:1001
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    fontSize: '0.6vw',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {
                                            RIdOptions?.map((option) => (
                                                <MenuItem value={option.id}>
                                                    <em>{option.roleName}</em>
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormHelperText sx={{ fontSize: '12px' }}>
                                        {errors.roleTypeOption}
                                    </FormHelperText>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    id="mobile"
                                    name="mobile"
                                    label="Mobile"
                                    disabled={disabled}
                                    value={formData.mobile}
                                    sx={{ width: "24%", mt: 3, marginLeft: "1%" }}
                                    onChange={(e) => handleInputChange(e, 'mobile')}
                                    error={!!errors.mobile}
                                    helperText={errors.mobile}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.6vw',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="panNumber"
                                    name="panNumber"
                                    disabled={disabled}
                                    label="PAN Number"
                                    sx={{ width: "24%", marginLeft: "1%", mt: 3 }}
                                    value={formData.panNumber}
                                    onChange={(e) => handleInputChange(e, 'panNumber')}
                                    error={!!errors.panNumber}
                                    helperText={errors.panNumber}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        sx: {
                                            fontSize: '0.6vw',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />

                                <FormControl
                                    sx={{ width: "24%", marginLeft: "1%", mt: 3 }}
                                    error={!!errors.status}
                                    disabled={disabled}
                                >
                                    <InputLabel sx={{ fontSize: '0.6vw', zIndex: 1 }} id="status-label">
                                        Status
                                    </InputLabel>
                                    <Select
                                        labelId="status-label"
                                        id="status"
                                        name="status"
                                        value={formData.status}
                                        onChange={(e) => handleInputChange(e, 'status')}
                                        label="Status"
                                        disabled={disabled}
                                        sx={{
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 3,
                                            // zIndex:1001
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    fontSize: '0.6vw',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={true}>
                                            <em>Active</em>
                                        </MenuItem>
                                        <MenuItem value={false}>
                                            <em>Inactive</em>
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText sx={{ fontSize: '12px' }}>
                                        {errors.status}
                                    </FormHelperText>
                                </FormControl>

                            </div>
                        </div>
                    </form>
                </div>
                {/* <div className='col-md-4' style={{ marginTop: 30 }}>
                    <ChangeUserPassword />
                </div> */}
            </div>
            {formData.roleId == 1 &&
                <div className="row">
                    <div className='col-md-12' style={{ marginTop: 30 }}>
                        <form>
                            <div style={{ width: "100%", height: "auto", paddingLeft: "2%", paddingRight: "2%", paddingTop: "2%", paddingBottom: "2%", backgroundColor: "#fff", borderRadius: 30 }}>
                                <h5>Operator Details</h5>
                                <div className="row" style={{ marginTop: 25, paddingLeft: "1%", paddingRight: "1%" }}>

                                    <Box display="flex" alignItems="center" sx={{ width: "100%", mt: 3 }}>
                                        {/* Aadhaar Card Number Input */}
                                        <Box display="flex" flexDirection="row" sx={{ width: "100%", justifyContent: "space-around" }}>
                                            <TextField
                                                disabled={disabled}
                                                fullWidth
                                                id="aadharNumber"
                                                name="aadharNumber"
                                                label="Aadhaar Card Number"
                                                type="number"
                                                value={formData.aadharNumber}
                                                onChange={(e) => handleInputChange(e, 'aadharNumber')}
                                                error={!!errors.aadharNumber}
                                                sx={{ width: "24%" }}
                                                helperText={errors.aadharNumber}
                                                InputProps={{
                                                    sx: {
                                                        height: '45px',
                                                        fontSize: '0.6vw',
                                                        borderRadius: 3,
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    sx: {
                                                        fontSize: '0.6vw',
                                                        zIndex: 1
                                                    }
                                                }}
                                                FormHelperTextProps={{
                                                    sx: {
                                                        fontSize: '12px',
                                                    }
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                disabled={disabled}
                                                id="aadharImage"
                                                name="aadharImage"
                                                label="Aadhaar Card Image"
                                                type="file"
                                                // value={formData.aadharImage}
                                                onChange={(e) => handleImageChange(e, 'aadharImage')}
                                                error={!!errors.aadharImage}
                                                sx={{ width: "24%" }}
                                                helperText={errors.aadharImage}
                                                InputProps={{
                                                    sx: {
                                                        height: '45px',
                                                        fontSize: '0.6vw',
                                                        borderRadius: 3,
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    sx: {
                                                        fontSize: '0.6vw',
                                                        zIndex: 1
                                                    }
                                                }}
                                                FormHelperTextProps={{
                                                    sx: {
                                                        fontSize: '12px',
                                                    }
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                id="licenseNumber"
                                                disabled={disabled}
                                                name="licenseNumber"
                                                label="Driving License Number"
                                                value={formData.licenseNumber}
                                                onChange={(e) => handleInputChange(e, 'licenseNumber')}
                                                error={!!errors.licenseNumber}
                                                sx={{ width: "24%" }}
                                                helperText={errors.licenseNumber}
                                                InputProps={{
                                                    sx: {
                                                        height: '45px',
                                                        fontSize: '0.6vw',
                                                        borderRadius: 3,
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    sx: {
                                                        fontSize: '0.6vw',
                                                        zIndex: 1
                                                    }
                                                }}
                                                FormHelperTextProps={{
                                                    sx: {
                                                        fontSize: '12px',
                                                    }
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                id="licenseImage"
                                                name="licenseImage"
                                                disabled={disabled}
                                                label="License Image"
                                                type="file"
                                                // value={formData.licenseImage}
                                                onChange={(e) => handleImageChange(e, 'licenseImage')}
                                                error={!!errors.licenseImage}
                                                sx={{ width: "24%" }}
                                                helperText={errors.licenseImage}
                                                InputProps={{
                                                    sx: {
                                                        height: '45px',
                                                        fontSize: '0.6vw',
                                                        borderRadius: 3,
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    sx: {
                                                        fontSize: '0.6vw',
                                                        zIndex: 1
                                                    }
                                                }}
                                                FormHelperTextProps={{
                                                    sx: {
                                                        fontSize: '12px',
                                                    }
                                                }}
                                            />
                                        </Box>

                                    </Box>

                                </div>
                            </div>
                        </form>
                        <div className='d-flex justify-content-end mt-3 mr-5'>
                            <Button
                                severity="danger"
                                style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }}
                                color="error"
                                onClick={() =>
                                    navigate(-1)}
                            >
                                Cancel
                            </Button>
                            <Button severity='primary' onClick={() => handleSubmit()} style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold", marginLeft: "1%" }} type="submit">Submit</Button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from './Provider/FormProvider'
import { url } from '../../../../Constant'

export default function BookingSection() {
    const { formData, updateFormData } = useContext(FormContext)
    const [formValues, setFormValues] = useState({
        bookingId: "",
        customer: "",
        siteLocation: "",
        operator: "",
        currentHMR: "",
        startHMR: "",
        hrsCommittedPerMonth: "",
        bookingDuration: "",
        bookingType: "Months",
        bookingPrice: "",
    })

    const [errors, setErrors] = useState({
        bookingId: "",
        customer: "",
        siteLocation: "",
        operator: "",
        currentHMR: "",
        startHMR: "",
        hrsCommittedPerMonth: "",
        bookingDuration: "",
        bookingPrice: ""
    })

    const fetchBookingId = () => {
        fetch(`${url}/api/admin/assets/bookings/get_booking_id?prodId=1`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }
            response.json().then((result) => {
                setFormValues({ ...formValues, bookingId: result.bookingId });
                updateFormData("bookingInfo", formValues)
            })
        }).catch((error) => {
            console.error('Error:', error)
        })
    }

    useEffect(() => {
        fetchBookingId()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues(prev => {
            const values = { ...prev, [name]: value }
            updateFormData("bookingInfo", values)
            return values;
        })

        // Validation logic
        let errorMsg = ""
        if (!value) {
            errorMsg = `Input required`
        } else if (name === 'currentHMR' || name === 'startHMR' || name === 'hrsCommittedPerMonth' || name === 'bookingPrice') {
            if (isNaN(value)) {
                errorMsg = `Field must be a number`
            }
        }

        setErrors({ ...errors, [name]: errorMsg })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        // Final validation before submitting
        const validationErrors = {}
        let hasErrors = false

        Object.keys(formValues).forEach((key) => {
            if (!formValues[key] && key !== 'bookingPrice') {
                validationErrors[key] = `Input Required`
                hasErrors = true
            } else if (['currentHMR', 'startHMR', 'hrsCommittedPerMonth', 'bookingPrice'].includes(key) && isNaN(formValues[key])) {
                validationErrors[key] = `Field must be a number`
                hasErrors = true
            }
        })

        setErrors(validationErrors)

        if (!hasErrors) {
            console.log("Form Submitted: ", formValues)
            // Perform submit action
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div style={{ width: "100%", height: "auto", paddingLeft: "2%", paddingRight: "2%", paddingTop: "2%", paddingBottom: "2%", backgroundColor: "#fff", borderRadius: 30 }}>
                    <h5>On-Hire Condition</h5>
                    <div className="row" style={{ marginTop: 25, paddingLeft: "1%", paddingRight: "1%" }}>

                        {/* Booking ID */}
                        <TextField
                            fullWidth
                            id="bookingId"
                            name="bookingId"
                            label="Booking Id"
                            sx={{ width: "15%", ml: "2%" }}
                            value={formValues.bookingId}
                            onChange={handleChange}
                            error={!!errors.bookingId}
                            // helperText={errors.bookingId}
                            disabled
                            InputProps={{
                                sx: {
                                    height: '45px', fontSize: '13px', borderRadius: 3, backgroundColor: "#F7F8F9"
                                }
                            }}
                            InputLabelProps={{
                                sx: { fontSize: '13px', zIndex: 1 }
                            }}
                            FormHelperTextProps={{
                                sx: { fontSize: '12px' }
                            }}
                        />

                        {/* Customer */}
                        <FormControl
                            variant="outlined"
                            sx={{ width: "38%", ml: "2%" }}
                        >
                            <InputLabel id="customer-label" sx={{ fontSize: '13px' }}>
                                Customer
                            </InputLabel>
                            <Select
                                labelId="customer-label"
                                id="customer"
                                name="customer"
                                value={formValues.customer}
                                onChange={handleChange}
                                error={!!errors.customer}
                                label="Customer"
                                sx={{ height: '45px', fontSize: '13px', borderRadius: 3, backgroundColor: "#F7F8F9" }}
                                MenuProps={{ PaperProps: { sx: { fontSize: '13px' } } }}
                            >
                                <MenuItem value="Aman">Aman</MenuItem>
                                <MenuItem value="Ankit">Ankit</MenuItem>
                                <MenuItem value="Naveen">Naveen</MenuItem>
                            </Select>
                            {/* {errors.customer && <p style={{ color: 'red', fontSize: '12px' }}>{errors.customer}</p>} */}
                        </FormControl>

                        {/* Site Location */}
                        <FormControl
                            variant="outlined"
                            sx={{ width: "39%", ml: "2%" }}
                        >
                            <InputLabel id="siteLocation-label" sx={{ fontSize: '13px' }}>
                                Site Location
                            </InputLabel>
                            <Select
                                labelId="siteLocation-label"
                                id="siteLocation"
                                name="siteLocation"
                                value={formValues.siteLocation}
                                onChange={handleChange}
                                error={!!errors.siteLocation}
                                label="Site Location"
                                sx={{ height: '45px', fontSize: '13px', borderRadius: 3, backgroundColor: "#F7F8F9" }}
                                MenuProps={{ PaperProps: { sx: { fontSize: '13px' } } }}
                            >
                                <MenuItem value="Delhi">Delhi</MenuItem>
                                <MenuItem value="Bombay">Bombay</MenuItem>
                                <MenuItem value="Chennai">Chennai</MenuItem>
                            </Select>
                            {/* {errors.siteLocation && <p style={{ color: 'red', fontSize: '12px' }}>{errors.siteLocation}</p>} */}
                        </FormControl>

                        {/* Operator */}
                        <FormControl
                            variant="outlined"
                            sx={{ width: "28%", ml: "2%", mt: 3 }}
                        >
                            <InputLabel id="operator-label" sx={{ fontSize: '13px' }}>
                                Operator
                            </InputLabel>
                            <Select
                                labelId="operator-label"
                                id="operator"
                                name="operator"
                                value={formValues.operator}
                                onChange={handleChange}
                                error={!!errors.operator}
                                label="Operator"
                                sx={{ height: '45px', fontSize: '13px', borderRadius: 3, backgroundColor: "#F7F8F9" }}
                                MenuProps={{ PaperProps: { sx: { fontSize: '13px' } } }}
                            >
                                <MenuItem value="Operator 1">Operator 1</MenuItem>
                                <MenuItem value="Operator 2">Operator 2</MenuItem>
                                <MenuItem value="Operator 3">Operator 3</MenuItem>
                            </Select>
                            {/* {errors.operator && <p style={{ color: 'red', fontSize: '12px' }}>{errors.operator}</p>} */}
                        </FormControl>

                        {/* Current HMR */}
                        <TextField
                            fullWidth
                            id="currentHMR"
                            name="currentHMR"
                            label="Current HMR"
                            sx={{ width: "21%", ml: "2%", mt: 3 }}
                            value={formValues.currentHMR}
                            onChange={handleChange}
                            error={!!errors.currentHMR}
                            // helperText={errors.currentHMR}
                            InputProps={{ sx: { height: '45px', fontSize: '13px', borderRadius: 3, backgroundColor: "#F7F8F9" } }}
                            InputLabelProps={{ sx: { fontSize: '13px', zIndex: 1 } }}
                        />

                        {/* Start HMR */}
                        <TextField
                            fullWidth
                            id="startHMR"
                            name="startHMR"
                            label="Start HMR"
                            sx={{ width: "21%", ml: "2%", mt: 3 }}
                            value={formValues.startHMR}
                            onChange={handleChange}
                            error={!!errors.startHMR}
                            // helperText={errors.startHMR}
                            InputProps={{ sx: { height: '45px', fontSize: '13px', borderRadius: 3, backgroundColor: "#F7F8F9" } }}
                            InputLabelProps={{ sx: { fontSize: '13px', zIndex: 1 } }}
                        />

                        {/* Hours Committed per Month */}
                        <TextField
                            fullWidth
                            id="hrsCommittedPerMonth"
                            name="hrsCommittedPerMonth"
                            label="Hours Committed Per Month"
                            sx={{ width: "20%", ml: "2%", mt: 3 }}
                            value={formValues.hrsCommittedPerMonth}
                            onChange={handleChange}
                            error={!!errors.hrsCommittedPerMonth}
                            // helperText={errors.hrsCommittedPerMonth}
                            InputProps={{ sx: { height: '45px', fontSize: '11px', borderRadius: 3, backgroundColor: "#F7F8F9" } }}
                            InputLabelProps={{ sx: { fontSize: '11px', zIndex: 1 } }}
                        />

                        {/* Booking Duration */}
                        <TextField
                            fullWidth
                            id="bookingDuration"
                            name="bookingDuration"
                            label="Booking Duration"
                            sx={{ width: "20%", ml: "2%", mt: 3 }}
                            value={formValues.bookingDuration}
                            onChange={handleChange}
                            error={!!errors.bookingDuration}
                            // helperText={errors.bookingDuration}
                            InputProps={{ sx: { height: '45px', fontSize: '13px', borderRadius: 3, backgroundColor: "#F7F8F9" } }}
                            InputLabelProps={{ sx: { fontSize: '13px', zIndex: 1 } }}
                        />

                        {/* Booking Type */}
                        <FormControl
                            variant="outlined"
                            sx={{ width: "20%", ml: "2%", mt: 3 }}
                        >
                            <InputLabel id="bookingType-label" sx={{ fontSize: '13px' }}>
                                Booking Type
                            </InputLabel>
                            <Select
                                labelId="bookingType-label"
                                id="bookingType"
                                name="bookingType"
                                value={formValues.bookingType}
                                onChange={handleChange}
                                error={!!errors.bookingType}
                                label="Booking Type"
                                sx={{ height: '45px', fontSize: '13px', borderRadius: 3, backgroundColor: "#F7F8F9" }}
                                MenuProps={{ PaperProps: { sx: { fontSize: '13px' } } }}
                            >
                                <MenuItem value="Months">Months</MenuItem>
                                <MenuItem value="Weeks">Weeks</MenuItem>
                                <MenuItem value="Days">Days</MenuItem>
                                <MenuItem value="Hours">Hours</MenuItem>
                            </Select>
                            {/* {errors.bookingType && <p style={{ color: 'red', fontSize: '12px' }}>{errors.bookingType}</p>} */}
                        </FormControl>

                        {/* Booking Price */}
                        <TextField
                            fullWidth
                            id="bookingPrice"
                            name="bookingPrice"
                            label="Booking Price"
                            sx={{ width: "50%", ml: "4%", mt: 3 }}
                            value={formValues.bookingPrice}
                            onChange={handleChange}
                            error={!!errors.bookingPrice}
                            // helperText={errors.bookingPrice}
                            InputProps={{ sx: { height: '45px', fontSize: '13px', borderRadius: 3, backgroundColor: "#F7F8F9" } }}
                            InputLabelProps={{ sx: { fontSize: '13px', zIndex: 1 } }}
                        />

                    </div>
                    {/* <div style={{ marginTop: 25, textAlign: "center", display:"flex", justifyContent:"flex-end" }}>
                        <Button
                            type="submit"
                            sx={{
                                width: "auto", height: 'auto', px: 5, py: 1,
                                borderRadius: 2, backgroundColor: "#1778B0",
                                color: "#fff", fontSize: 13, fontWeight: 500, textTransform: 'none'
                            }}
                        >
                            Submit
                        </Button>
                    </div> */}
                </div>
            </form>
        </div>
    )
}

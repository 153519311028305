import React, { useEffect, useState } from 'react';
import { DialogActions, TextField, Box, Switch, Alert, Typography } from '@mui/material';
import Swal from 'sweetalert2'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { url } from '../../../_helper/Route/Url';

export default function AddRoleDialog({ refreshData, isUpdate }) {
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        roleName: '',
        permissionMobile: false,
        permissionWeb: false,
        status: true,
    });
    const [formErrors, setFormErrors] = useState({
        requestError: "",
        roleName: '',
        permissionMobile: '',
        permissionWeb: '',
        status: '',
    });

    useEffect(() => {
        if (open && isUpdate) {
            fetchRole();
        }
    }, [open, isUpdate]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormValues({
            roleName: '',
            permissionMobile: false,
            permissionWeb: false,
            status: true,
        });
        setFormErrors({
            requestError: "",
            roleName: '',
            permissionMobile: '',
            permissionWeb: '',
            status: '',
        });
    };

    const handleChange = (field, value) => {
        if(value.length > 25)return;
        setFormValues({
            ...formValues,
            [field]: value,
        });
        setFormErrors({
            ...formErrors,
            [field]: '',
        });
    };

    const validate = () => {
        const errors = {};
        if (!formValues.roleName) errors.roleName = 'Role Name is required';
        if (formValues.roleName.length > 25) errors.roleName = 'Max Characters 25';
        if (formValues.roleName.startsWith(' ')) errors.roleName = 'Enter Valid Role';
        if (!formValues.permissionMobile && !formValues.permissionWeb) errors.requestError = "You must grant permission for at least one device"
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validate()) return;
        //console.log(isUpdate)
        try {
            const response = await fetch(isUpdate ? url + "/api/sup/role/update?id=" + isUpdate : url + "/api/sup/role/add", {
                method: isUpdate ? "PUT" : "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify(formValues),
            });
            if (!response.ok) {
                const result = await response.json();
                setFormErrors({ requestError: result.message });
            } else {
                handleClose();
                setFormValues({
                    roleName: '',
                    permissionMobile: false,
                    permissionWeb: false,
                    status: true,
                });
                Swal.fire({
                    position: "center",
                    icon: "success",
                    html: `
        <div class="custom-content">
            Status has been changed
        </div>
        <style>
            .swal2-popup {
                padding: 0px !important; /* Reduce padding inside the Swal box */
            }
            .swal2-icon.swal2-success {
                font-size: 11px !important; /* Adjust the size as needed */
            }
            .swal2-title {
                font-size: 18px !important; /* Adjust the size as needed */
            }
            .custom-content {
                font-size: 14px !important; /* Adjust the size as needed */
            }
        </style>
    `,
                    customClass: {
                        icon: 'swal2-icon',
                        title: 'swal2-title',
                        content: 'custom-content'
                    },
                    title: isUpdate ? "Role Updated Successfully" : "Role Created Successfully",
                    showConfirmButton: false,
                    timer: 2000
                });
                refreshData();
            }
        } catch (error) {
            isUpdate ?
                setFormErrors({ requestError: "Failed to Update Role" })
                :
                setFormErrors({ requestError: "Failed to Add Role" })
        }
    };

    const fetchRole = async () => {
        const id = isUpdate;
        try {
            const response = await fetch(url + '/api/sup/role/get?id=' + id);
            if (!response.ok) {
                const result = await response.json();
                setFormErrors({ requestError: result.message });
            } else {
                const data = await response.json();
                //console.log(data)
                setFormValues({
                    roleName: data.roleName,
                    permissionMobile: data.permissionMobile,
                    permissionWeb: data.permissionWeb,
                    status: data.status,
                });
                setFormErrors({ requestError: '' });
            }
        } catch (error) {
            setFormErrors({ requestError: 'Failed to fetch Role' });
        }
    };

    return (
        <>
            <React.Fragment>
                {isUpdate ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-info" onClick={handleClickOpen} style={{ marginRight: "15px", width: "20px", height: "20px", color:"#0b0ff1c7"}} text />
                    :
                    <Button icon="pi pi-plus" label="Add Role" onClick={handleClickOpen} style={{ marginRight: "10", height:"35px", padding:"3px", backgroundColor:"#0b0ff1c7", borderColor:"#0b0ff1c7" }} raised />
                }

                <Dialog
                    visible={open}
                    onHide={handleClose}
                    style={{ minWidth: "23vw" }}
                    header={isUpdate ? "Update Role" : "Add Role"}
                >
                    <Box sx={{ padding: 2, paddingTop: 1 }}>
                        {formErrors.requestError && <Alert sx={{ marginBottom: 3 }} severity="error">{formErrors.requestError}</Alert>}
                        <TextField
                            fullWidth
                            id="roleName"
                            name="roleName"
                            label="Role"
                            value={formValues.roleName}
                            onChange={(e) => handleChange('roleName', e.target.value)}
                            error={!!formErrors.roleName}
                            helperText={formErrors.roleName}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '13px', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '13px',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        />
                        <Box sx={{}}>
                            <Box sx={{ marginTop: 3, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography sx={{ fontSize: '13px', marginBottom: 1 }}>Mobile Access*</Typography>
                                <Switch
                                    checked={formValues.permissionMobile}
                                    onChange={(e) => handleChange('permissionMobile', e.target.checked)}
                                    color="primary"
                                />
                                {formErrors.permissionMobile && (
                                    <Typography color="error" sx={{ fontSize: '12px' }}>{formErrors.permissionMobile}</Typography>
                                )}
                            </Box>

                            <Box sx={{ marginTop: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography sx={{ fontSize: '13px', marginBottom: 1 }}>Web Access*</Typography>
                                <Switch
                                    checked={formValues.permissionWeb}
                                    onChange={(e) => handleChange('permissionWeb', e.target.checked)}
                                    color="primary"
                                />
                                {formErrors.permissionWeb && (
                                    <Typography color="error" sx={{ fontSize: '12px' }}>{formErrors.permissionWeb}</Typography>
                                )}
                            </Box>
                            {/* <Box sx={{ marginTop: 1, display:"flex", alignItems:"center", justifyContent:"space-between" }}>
                                <Typography sx={{ fontSize: '13px', marginBottom: 1 }}>Status*</Typography>
                                <Switch
                                    checked={formValues.status}
                                    onChange={(e) => handleChange('status', e.target.checked)}
                                    color="primary"
                                />
                                {formErrors.status && (
                                    <Typography color="error" sx={{ fontSize: '12px' }}>{formErrors.status}</Typography>
                                )}
                            </Box> */}
                        </Box>

                    </Box>
                    <DialogActions>
                        <Button severity='danger' style={{ borderRadius: "10px", fontSize:"15px", padding:"8px", fontWeight:"bold" }} color='error' onClick={handleClose}>Cancel</Button>
                        <Button severity='primary' onClick={handleSubmit} style={{ borderRadius: "10px", fontSize:"15px", padding:"8px", fontWeight:"bold" }} type="submit">{isUpdate ? "Update" : "Create"}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </>
    );
}
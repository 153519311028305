import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { AlertTitle, Box, CircularProgress, TextField } from '@mui/material';
import { useFormData } from '../Components/FormDataProvider';
import { Button } from 'primereact/button';
import { url } from '../Constant';
import Alert from '@mui/material/Alert';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { green } from '@mui/material/colors';

export default function PicturesUpload({ handleBack, handleNext, setValue, isUpdate, onValidate, handleIndex }) {
    const { updateFormData, combinedFormData, clearFormData } = useFormData()
    const [formValues, setFormValues] = useState((combinedFormData && combinedFormData.PicturesUpload) ? combinedFormData.PicturesUpload : {
        sealImage: null,
        signImage: null,
        piLogo: null,
        companyLogo: null
    });
    const [errors, setErrors] = useState({
        sealImage: false,
        signImage: false,
        piLogo: false,
        companyLogo: false,
        submissionError: null
    });
    const [openDialog, setOpenDialog] = useState(false);
    const navigation = useNavigate("");
    const [loading, setLoading] = useState(false);


    const handleFileChange = (e) => {
        const { id, files } = e.target;
        let formData = formValues;
        formData[id] = files[0];
        setFormValues({
            ...formData
        });
        updateFormData("PicturesUpload", formData)
        //console.log(formData)
        // Reset the error state for the current field
        setErrors({
            ...errors,
            [id]: false
        });
        let isFormValid = true;
        Object.values(errors).forEach(error => {
            if (error) {
                isFormValid = false;
            }
        });
        //console.log(errors)
        onValidate(isFormValid ? true : false);
        if (isFormValid) {
            updateFormData("PicturesUpload", formData)
            // handleNext();
            // setValue(1);
        }
    };

    const validate = () => {
        const newErrors = {
            sealImage: formValues.sealImage === null,
            signImage: formValues.signImage === null,
            piLogo: formValues.piLogo === null,
            companyLogo: formValues.companyLogo === null
        };

        setErrors(newErrors);

        // Check if there are any errors
        return !Object.values(newErrors).some((error) => error);
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (validate()) {
            let mainData = {
                PersonalDetails: combinedFormData.PersonalDetails,
                CompanyDetails: combinedFormData.CompanyDetails,
                BankDetails: combinedFormData.BankDetails,
            }
            delete mainData.confirmPassword;

            let formData = new FormData();

            // Append JSON data as a string
            formData.append('mainData', JSON.stringify(mainData));

            // Append file data
            Object.keys(formValues).forEach((key) => {
                if (formValues[key]) {
                    formData.append(key, formValues[key]);
                    //console.log(formValues[key])
                }
            });
            //console.log(formValues, "formValues")
            try {
                // Send POST request with FormData
                const response = await fetch(url + '/auth/register/create_admin', {
                    method: 'POST',
                    body: formData,
                });

                // Check if response is okay
                if (response.ok) {
                    setLoading(false);
                    const result = await response.json();
                    //console.log('Success:', result);
                    clearFormData();
                    Swal.fire({
                        title: 'Congratulations',
                        text: 'Your Company Code is: ' + result.companyCode,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(() => {
                        navigation("/")
                    })
                } else {
                    setLoading(false);
                    const error = await response.json();
                    setErrors({
                        ...errors,
                        submissionError: error.message
                    });
                }
            } catch (error) {
                console.error('Error:', error);
                setErrors({
                    ...errors,
                    submissionError: error.message
                });
            }
        }
    };

    return (
        <div style={{ opacity: 1 }}>
            <Card sx={{ boxShadow: "0px 0px 0px 0px lightGray", borderRadius: "25px", borderTopRightRadius: "0px", borderTopLeftRadius: "0px", marginTop: "1vh", background: "transparent" }}>
                <CardContent>
                    {/* <div style={{ display: "flex" }}>
                        <div style={{ width: "1.8em", marginTop: 4, height: "1.8em", justifyContent: "center", display: "flex", alignItems: "center", borderRadius: "50%", border: "2px solid #a0a0a0", marginRight: "0.9vw" }}>
                            <span style={{ fontSize: "1em", color: "#a0a0a0" }}>4</span>
                        </div>
                        <Typography gutterBottom variant="h5" component="div" fontStyle={"Roboto,sans-serif"} color="#a0a0a0" display={"flex"}>
                            Pictures Upload
                        </Typography>
                    </div> */}
                    {errors.submissionError &&
                        <Alert severity="error">
                            <AlertTitle>Error : {errors.submissionError}</AlertTitle>
                        </Alert>
                    }
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                        style={{ marginTop: "1vh", padding: "1vh" }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <TextField
                                    error={errors.sealImage}
                                    fullWidth
                                    id="sealImage"
                                    label="Seal Image"
                                    required
                                    type="file"
                                    accept="image/*"
                                    // style={{ marginLeft: "1vw" }}
                                    // value={formValues.sealImage}
                                    onChange={handleFileChange}
                                    helperText={errors.sealImage ? "Seal Image is required" : ""}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '13px', // Adjust the font size as needed
                                            borderRadius: 30,
                                            opacity: 1,
                                            backgroundColor: "#fff"
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '15px', // Adjust the label font size as needed
                                        },
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                {formValues.sealImage &&
                                    <Typography variant="body2" style={{ marginTop: '-0.1rem', fontSize: '13px', color: 'grey', alignSelf: "flex-start", marginLeft: "1.5rem" }}>
                                        Selected file: {formValues.sealImage.name}
                                    </Typography>}
                            </div>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <TextField
                                    error={errors.signImage}
                                    fullWidth
                                    id="signImage"
                                    label="Sign Image"
                                    required
                                    accept="image/*"
                                    type="file"
                                    style={{ marginTop: "3vh" }}
                                    onChange={handleFileChange}
                                    helperText={errors.signImage ? "Sign Image is required" : ""}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '13px', // Adjust the font size as needed
                                            borderRadius: 30,
                                            opacity: 1,
                                            backgroundColor: "#fff"
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '15px', // Adjust the label font size as needed
                                        },
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                {formValues.signImage &&
                                    <Typography variant="body2" style={{ marginTop: '-0.1rem', fontSize: '13px', color: 'grey', alignSelf: "flex-start", marginLeft: "1.5rem" }}>
                                        Selected file: {formValues.signImage.name}
                                    </Typography>}
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", marginTop: "2vh" }}>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <TextField
                                    error={errors.piLogo}
                                    fullWidth
                                    id="piLogo"
                                    label="PI Logo"
                                    required
                                    type="file"
                                    accept="image/*"
                                    // style={{ marginLeft: "1vw" }}
                                    onChange={handleFileChange}
                                    helperText={errors.piLogo ? "PI Logo is required" : ""}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '13px', // Adjust the font size as needed
                                            borderRadius: 30,
                                            opacity: 1,
                                            backgroundColor: "#fff"
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '15px', // Adjust the label font size as needed
                                        },
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                {formValues.piLogo &&
                                    <Typography variant="body2" style={{ marginTop: '-0.1rem', fontSize: '13px', color: 'grey', alignSelf: "flex-start", marginLeft: "1.5rem" }}>
                                        Selected file: {formValues.piLogo.name}
                                    </Typography>}
                            </div>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>

                                <TextField
                                    error={errors.companyLogo}
                                    fullWidth
                                    id="companyLogo"
                                    label="Company Logo"
                                    required
                                    type="file"
                                    style={{ marginTop: "3vh" }}
                                    onChange={handleFileChange}
                                    accept="image/*"
                                    helperText={errors.companyLogo ? "Company Logo is required" : ""}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '13px', // Adjust the font size as needed
                                            borderRadius: 30,
                                            opacity: 1,
                                            backgroundColor: "#fff"
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '15px', // Adjust the label font size as needed
                                        },
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />
                                {formValues.companyLogo &&
                                    <Typography variant="body2" style={{ marginTop: '-0.1rem', fontSize: '13px', color: 'grey', alignSelf: "flex-start", marginLeft: "1.5rem" }}>
                                        Selected file: {formValues.companyLogo.name}
                                    </Typography>}
                            </div>
                        </div>
                    </Box>
                </CardContent>
                <>
                    <div className="flex py-4" style={{ marginLeft: "25px" }}>
                        <Button label="Back" style={{padding:"0.4vw"}} severity="secondary" icon="pi pi-arrow-left" onClick={()=>handleIndex()} />
                        {!loading &&
                            <Button
                                onClick={handleSubmit}
                                type="submit"
                                label="Submit"
                                // icon="pi pi-arrow-right"
                                iconPos="right"
                                style={{ marginLeft: "1em",padding:"0.4vw" }}
                            />}
                        {loading && (
                            <CircularProgress
                                size={30}
                                style={{ marginTop: 15, marginLeft: 50 }}
                                sx={{
                                    color: green[600],
                                }}
                            />
                        )}
                    </div>
                </>
                {/* {!isUpdate && <div style={{ display: "flex", justifyContent: "center", marginBottom: "2vh" }}>
                <Button label="Back" severity="secondary" icon="pi pi-arrow-left"  />
                    {!loading && <Button
                        onClick={handleSubmit}
                        type="submit"
                        // disabled={!isOtpVerified}
                        style={{ marginTop: "2vh", backgroundColor: "green", borderColor: "green", color: "white", marginLeft: 50 }}
                    >
                        Finish
                    </Button>}
                    {loading && (
                        <CircularProgress
                            size={30}
                            style={{ marginTop: 15, marginLeft: 50 }}
                            sx={{
                                color: green[600],
                            }}
                        />
                    )}
                </div>} */}
            </Card>
        </div>
    );
}

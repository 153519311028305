import React ,{useState} from 'react'
import { Breadcrumbs } from '../../../AbstractElements';
import AssetsCatNavbar from './AssetsCatNavbar' 
import MachineTable from './AssetsTable';

const Asset = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <div>
      <Breadcrumbs mainTitle="Assets" parent="Equipment" title="Assets" />
      <AssetsCatNavbar selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>
      <MachineTable selectedCategory={selectedCategory}/>
    </div>
  )
}

export default Asset

import React, { useEffect, useState } from 'react';
import { DialogActions, TextField, Box, Alert, FormControl, InputLabel, Select, MenuItem, FormHelperText, } from '@mui/material';
import { url } from '../../../_helper/Route/Url';
import Swal from 'sweetalert2'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { XCircle } from 'react-feather';

export default function AddMenu({ refreshData, isUpdate, isSubMenu }) {
    const [open, setOpen] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const [sections, setSections] = useState();
    const [formValues, setFormValues] = useState({
        section: "",
        menuName: '',
        menuRoutename: '',
        sequence: 1,
        menuIcon: '',
        status: true,
        menuIconType: '',
        menuType: '',
    });
    const [formErrors, setFormErrors] = useState({
        section: "",
        menuName: '',
        menuRoutename: '',
        status: '',
        menuIcon: '',
        sequence: '',
        menuIconType: '',
        menuType: '',
    });
    const [subMenuForm, setsubMenuForm] = useState({
        subMenuName: '',
        subMenuRouteName: '',
        subMenuStatus: '',
        sequence: 1,
        subMenuIcon: '',
    });
    const [subMenuFormErrors, setsubMenuFormErrors] = useState({
        subMenuName: '',
        subMenuRouteName: '',
        subMenuStatus: '',
        sequence: '',
        subMenuIcon: '',
    });
    const [subMenus, setSubMenus] = useState([]);

    const handleAddSubMenu = () => {
        if (subMenus && subMenus.length > 0) setSubMenus([...subMenus, { subMenuName: '', subMenuRouteName: '', subMenuStatus: true, sequence: 1, subMenuIcon: '', }]);
        else setSubMenus([{ subMenuName: '', subMenuRouteName: '', subMenuStatus: true, sequence: 1, subMenuIcon: '' }]);
    };

    const handleInputChange = (index, event) => {
        const values = [...subMenus];
        values[index][event.target.name] = event.target.value;
        setSubMenus(values);
    };

    useEffect(() => {
        if (open && isUpdate) {
            fetchMenu();
        }
        fetchSections();
    }, [open, isUpdate]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormValues({
            menuName: '',
            menuRoutename: '',
            status: true,
            menuIconType: '',
            menuIcon: '',
            sequence: 1,
            menuType: '',
            section: "",
        });
        setFormErrors({
            menuName: '',
            menuRoutename: '',
            menuIconType: '',
            status: "",
            menuIcon: '',
            sequence: '',
            menuType: '',
            section: "",
        });
        setSubMenus([])
    };

    const handleChange = (field, value) => {
        setFormValues({
            ...formValues,
            [field]: value,
        });
        setFormErrors({
            ...formErrors,
            [field]: '',
        });
    };

    const validate = () => {
        const errors = {};
        if (!formValues.section) errors.section = 'Section is required';
        if (!formValues.menuName) errors.menuName = 'Menu Name is required';
        if (!formValues.menuRoutename) errors.menuRoutename = 'Menu Route is required';
        if (!formValues.sequence) errors.sequence = 'Menu Sequence is required';
        if (!formValues.menuIcon) errors.menuIcon = 'Menu Icon is required';
        if (!formValues.menuIconType) errors.menuIconType = 'Menu Icon Type is required';
        if (!formValues.menuType) errors.menuType = 'Menu Type is required';
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validate()) return;
        let formData = {}
        formData = { ...formValues };
        if (formValues.subMenus && formValues.subMenus.length > 0) {
            if (subMenus && subMenus.length > 0) {
                formData.subMenus = [...formValues.subMenus, ...subMenus];
            }
        } else if (subMenus && subMenus.length > 0) formData.subMenus = [...subMenus];
        //console.log(formData, "formaData")
        try {
            const response = await fetch(isUpdate ? url + "/api/sup/menu/update?id=" + isUpdate : url + "/api/sup/menu/add", {
                method: isUpdate ? "PUT" : "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify(formData),
            });
            if (!response.ok) {
                const result = await response.json();
                setFormErrors({ requestError: result.message });
            } else {
                setFormValues({
                    menuName: '',
                    menuRoutename: '',
                    status: true,
                    sequence: 1,
                    menuIcon: '',
                    menuIconType: '',
                    section: '',
                    menutype: ''
                });
                setSubMenus([])
                handleClose();
                Swal.fire({
                    position: "center",
                    icon: "success",
                    html: `
        <div class="custom-content">
            Status has been changed
        </div>
        <style>
            .swal2-popup {
                padding: 0px !important; /* Reduce padding inside the Swal box */
            }
            .swal2-icon.swal2-success {
                font-size: 11px !important; /* Adjust the size as needed */
            }
            .swal2-title {
                font-size: 18px !important; /* Adjust the size as needed */
            }
            .custom-content {
                font-size: 14px !important; /* Adjust the size as needed */
            }
        </style>
    `,
                    customClass: {
                        icon: 'swal2-icon',
                        title: 'swal2-title',
                        content: 'custom-content'
                    },
                    title: isUpdate ? "Menu Updated Successfully" : "Menu Created Successfully",
                    showConfirmButton: false,
                    timer: 2000
                });
                refreshData();
            }
        } catch (error) {
            //console.log(error)
            isUpdate ?
                setFormErrors({ requestError: "Failed to Update Menu" })
                :
                setFormErrors({ requestError: "Failed to Add Menu" })
        }
    };

    const fetchMenu = async () => {
        const id = isUpdate;
        try {
            const response = await fetch(url + '/api/sup/menu/get?id=' + id);
            if (!response.ok) {
                const result = await response.json();
                setFormErrors({ requestError: result.message });
            } else {
                const data = await response.json();
                //console.log(data)
                setFormValues({
                    ...data
                });
                setFormErrors({ requestError: '' });
            }
        } catch (error) {
            setFormErrors({ requestError: 'Failed to fetch Menu' });
        }
    };

    const handleSubMenuModalClose = () => {
        setOpenSubMenu(false);
    }

    const handleSubMenuModalOpen = () => {
        setOpenSubMenu(true);
        fetchSubMenu();
    }

    const fetchSubMenu = async () => {
        try {
            const response = await fetch(`${url}/api/sup/menu/submenu/get?subMenuId=${isSubMenu}`);
            if (!response.ok) {
                const result = await response.json();
                setsubMenuFormErrors({ requestError: result.message });
            } else {
                const data = await response.json();
                setsubMenuForm(data);
                //console.log(data, "----------------------------")
            }
        } catch (error) {
            setsubMenuFormErrors({ requestError: 'Failed to fetch Menu' });
        }
    }

    const handleSubMenuChange = (field, value) => {
        setsubMenuForm({
            ...subMenuForm,
            [field]: value,
        });
        setsubMenuFormErrors({
            ...subMenuFormErrors,
            [field]: '',
        });
    }

    const handleUpdateSubMenu = async (e) => {
        e.preventDefault();
        try {
            //console.log(subMenuForm)
            fetch(`${url}/api/sup/menu/submenu/update?subMenuId=${isSubMenu}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(subMenuForm),
                }
            )
                .then(async (response) => {
                    if (!response.ok) {
                        const result = await response.json();
                        setsubMenuFormErrors({ requestError: result.message });
                    } else {
                        setOpenSubMenu(false);
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            html: `
                        <div class="custom-content">
                        Sub-Menu has been updated
                        </div>
                        <style>
                         .swal2-popup {
                            padding: 0px!important; /* Reduce padding inside the Swal box */
                        }
                            .swal2-icon.swal2-success {
                            font-size: 11px!important; /* Adjust the size as needed */
                        }
                            .swal2-title {
                            font-size: 18px!important; /* Adjust the size as needed */
                        }
                            .custom-content {
                            font-size: 14px!important; /* Adjust the size as needed */
                        }
                            </style>
                        `,
                            customClass: {
                                icon: 'swal2-icon',
                                title: 'swal2-title',
                                content: 'custom-content'
                            },
                            title: "Sub-Menu Updated Successfully",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        refreshData();

                    }
                })
        } catch (error) {
            //console.log(error, "****")
            setsubMenuFormErrors({ requestError: error.message });
        }

    }

    const removeSubMenu = (index) => {
        setSubMenus((prevSubMenus) =>
            prevSubMenus.filter((item, i) => i !== index)
        );
    };

    useEffect(() => {

    }, [])

    const fetchSections = () => {
        fetch(url + "/api/sup/menu/sections/getall").then((response) => {
            if (response.ok) {
                response.json().then((result) => {
                    //console.log(result, "sections")
                    setSections(result)
                })
            }
        })
    }


    return (
        <>
            <React.Fragment>
                {!isSubMenu ?
                    <>
                        {isUpdate ?
                            <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-info" onClick={handleClickOpen} style={{ marginRight: "10px", fontSize: "10px", width: "20px", height: "20px", color: "#0b0ff1c7" }} text />
                            :
                            <Button severity="info" icon="pi pi-plus" label="Add Menu" onClick={handleClickOpen} style={{ marginRight: "10", height: "35px", padding: "3px", backgroundColor: "#0b0ff1c7", borderColor: "#0b0ff1c7" }} raised />
                        }

                        <Dialog
                            visible={open}
                            onHide={handleClose}
                            style={{ minWidth: "58vw", zIndex: 1000 }}
                            header={isUpdate ? "Update Menu" : "Add Menu"}
                        >
                            <Box sx={{ padding: 2, paddingTop: 1, paddingBottom: 5 }}>
                                {formErrors.requestError && <Alert sx={{ marginBottom: 3 }} severity="error">{formErrors.requestError}</Alert>}
                                <Box sx={{ display: "flex", width: "100%" }}>
                                    <FormControl
                                        sx={{ width: "48.5%" }}
                                        error={!!formErrors.section}
                                    >
                                        <InputLabel sx={{ fontSize: '13px', zIndex: 1 }} id="section-label">
                                            Section
                                        </InputLabel>
                                        <Select
                                            labelId="section-label"
                                            id="section"
                                            name="section"
                                            value={formValues.section}
                                            onChange={(e) => handleChange('section', e.target.value)}
                                            label="Section"
                                            sx={{
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '13px', // Adjust the font size as needed
                                                borderRadius: 3,
                                                // zIndex:1001
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        fontSize: '13px',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {sections?.map((section, index) => (
                                                <MenuItem key={index} value={section.name}>
                                                    {section.name}
                                                </MenuItem>
                                            ))}
                                            {/* Add more options as needed */}
                                        </Select>
                                        <FormHelperText sx={{ fontSize: '12px' }}>
                                            {formErrors.section}
                                        </FormHelperText>
                                    </FormControl>


                                    <FormControl
                                        sx={{ width: "48.5%",ml: 2 }}
                                        error={!!formErrors.menuType}
                                    >
                                        <InputLabel sx={{ fontSize: '13px', zIndex: 1 }} id="menuType-label">
                                            Menu Type
                                        </InputLabel>
                                        <Select
                                            labelId="menuType-label"
                                            id="menuType"
                                            name="menuType"
                                            value={formValues.menuType}
                                            onChange={(e) => handleChange('menuType', e.target.value)}
                                            label="Menu-Type"
                                            sx={{
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '13px', // Adjust the font size as needed
                                                borderRadius: 3,
                                                // zIndex:1001
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        fontSize: '13px',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="SUPERADMIN">
                                            SUPERADMIN
                                            </MenuItem>
                                            <MenuItem value="ADMIN">
                                                ADMIN
                                            </MenuItem>
                                            <MenuItem value="BOTH">
                                                BOTH
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText sx={{ fontSize: '12px' }}>
                                            {formErrors.menuType}
                                        </FormHelperText>
                                    </FormControl>


                                    <TextField
                                        fullWidth
                                        id="menuName"
                                        name="menuName"
                                        label="Menu Name"
                                        sx={{ width: "48.5%", ml: 2 }}
                                        value={formValues.menuName}
                                        onChange={(e) => handleChange('menuName', e.target.value)}
                                        error={!!formErrors.menuName}
                                        helperText={formErrors.menuName}
                                        InputProps={{
                                            sx: {
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '13px', // Adjust the font size as needed
                                                borderRadius: 3,
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '13px',
                                                zIndex: 1
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '12px', // Adjust the helper text font size as needed
                                            }
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        sx={{ ml: 2, width: "48.5%", }}
                                        id="menuRoutename"
                                        name="menuRoutename"
                                        label="Route Name"
                                        value={formValues.menuRoutename}
                                        onChange={(e) => handleChange('menuRoutename', e.target.value)}
                                        error={!!formErrors.menuRoutename}
                                        helperText={formErrors.menuRoutename}
                                        InputProps={{
                                            sx: {
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '13px', // Adjust the font size as needed
                                                borderRadius: 3,
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '13px',
                                                zIndex: 1
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '12px', // Adjust the helper text font size as needed
                                            }
                                        }}
                                    />

                                    <FormControl
                                        sx={{ ml: 2, width: "48.5%" }}
                                        error={!!formErrors.menuIconType}
                                    >
                                        <InputLabel sx={{ fontSize: '13px', zIndex: 1 }} id="menuIconType-label">
                                            Menu Icon Type
                                        </InputLabel>
                                        <Select
                                            labelId="menuIconType-label"
                                            id="menuIconType"
                                            name="menuIconType"
                                            value={formValues.menuIconType}
                                            onChange={(e) => handleChange('menuIconType', e.target.value)}
                                            label="Menu Icon Type"
                                            sx={{
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '13px', // Adjust the font size as needed
                                                borderRadius: 3,
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        fontSize: '13px',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="feather">Feather</MenuItem>
                                            <MenuItem value="fontAwesome">FontAwesome</MenuItem>
                                            <MenuItem value="ionIcon">IonIcon</MenuItem>
                                            {/* Add more options as needed */}
                                        </Select>
                                        <FormHelperText sx={{ fontSize: '12px' }}>
                                            {formErrors.menuIconType}
                                        </FormHelperText>
                                    </FormControl>

                                    <TextField
                                        sx={{ ml: 2, width: "48.5%" }}
                                        id="menuIcon"
                                        name="menuIcon"
                                        label="Menu Icon"
                                        value={formValues.menuIcon}
                                        onChange={(e) => handleChange('menuIcon', e.target.value)}
                                        error={!!formErrors.menuIcon}
                                        helperText={formErrors.menuIcon}
                                        InputProps={{
                                            sx: {
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '13px', // Adjust the font size as needed
                                                borderRadius: 3,
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '13px',
                                                zIndex: 1
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '12px', // Adjust the helper text font size as needed
                                            }
                                        }}
                                    />
                                    <TextField
                                        sx={{ ml: 2, width: "25%" }}
                                        id="sequence"
                                        name="sequence"
                                        label="Sequence"
                                        value={formValues.sequence}
                                        onChange={(e) => handleChange('sequence', e.target.value)}
                                        error={!!formErrors.sequence}
                                        helperText={formErrors.sequence}
                                        type='number'
                                        InputProps={{
                                            sx: {
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '13px', // Adjust the font size as needed
                                                borderRadius: 3,
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '13px',
                                                zIndex: 1
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '12px', // Adjust the helper text font size as needed
                                            }
                                        }}
                                    />
                                </Box>

                            </Box>
                            {/* <DialogTitle sx={{ mb: -3 }}>{isUpdate ? "Update Sub-Menu" : "Add Sub-Menu"}</DialogTitle> */}
                            <Box sx={{ marginTop: -3, padding: 4, paddingTop: 0, paddingBottom: 0, maxHeight: '75vh', overflowY: 'auto' }}>
                                <form onSubmit={handleSubmit}>
                                    {subMenus && subMenus.length > 0 && subMenus.map((subMenu, index) => (
                                        <div style={{ marginTop: -15 }} key={index}>
                                            <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                                <Box sx={{ minWidth: 30, height: 30, borderRadius: '50%', border: "1px solid GrayText", display: "flex", justifyContent: "center", alignItems: "center", mt: 1.5 }}>
                                                    {index + 1}
                                                </Box>
                                                <TextField
                                                    fullWidth
                                                    sx={{ mt: 2, ml: 1 }}
                                                    id={`subMenuName-${index}`}
                                                    name="subMenuName"
                                                    label="Sub Menu Name"
                                                    value={subMenu.subMenuName}
                                                    onChange={(event) => handleInputChange(index, event)}
                                                    // error={!!formErrors.subMenuName}
                                                    // helperText={formErrors.subMenuName}
                                                    InputProps={{
                                                        sx: {
                                                            height: '45px', // Adjust the height as needed
                                                            fontSize: '13px', // Adjust the font size as needed
                                                            borderRadius: 3,
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        sx: {
                                                            fontSize: '13px',
                                                            zIndex: 1
                                                        }
                                                    }}
                                                    FormHelperTextProps={{
                                                        sx: {
                                                            fontSize: '12px', // Adjust the helper text font size as needed
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    sx={{ mt: 2, ml: 2 }}
                                                    id={`subMenuRouteName-${index}`}
                                                    name="subMenuRouteName"
                                                    label="Sub Menu Route"
                                                    value={subMenu.subMenuRouteName}
                                                    onChange={(event) => handleInputChange(index, event)}
                                                    // error={!!formErrors.}
                                                    // helperText={formErrors.subMenuRouteName}
                                                    InputProps={{
                                                        sx: {
                                                            height: '45px', // Adjust the height as needed
                                                            fontSize: '13px', // Adjust the font size as needed
                                                            borderRadius: 3,
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        sx: {
                                                            fontSize: '13px',
                                                            zIndex: 1
                                                        }
                                                    }}
                                                    FormHelperTextProps={{
                                                        sx: {
                                                            fontSize: '12px', // Adjust the helper text font size as needed
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    sx={{ mt: 2, ml: 2 }}
                                                    id={`subMenuIcon-${index}`}
                                                    name="subMenuIcon"
                                                    label="Sub Menu Icon"
                                                    value={subMenu.subMenuIcon}
                                                    onChange={(event) => handleInputChange(index, event)}
                                                    // error={!!formErrors.}
                                                    // helperText={formErrors.subMenuRouteName}
                                                    InputProps={{
                                                        sx: {
                                                            height: '45px', // Adjust the height as needed
                                                            fontSize: '13px', // Adjust the font size as needed
                                                            borderRadius: 3,
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        sx: {
                                                            fontSize: '13px',
                                                            zIndex: 1
                                                        }
                                                    }}
                                                    FormHelperTextProps={{
                                                        sx: {
                                                            fontSize: '12px', // Adjust the helper text font size as needed
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    // fullWidth
                                                    sx={{ mt: 2, ml: 2, width: "60%" }}
                                                    id={`sequence-${index}`}
                                                    name="sequence"
                                                    label="Sequence"
                                                    type='number'
                                                    value={subMenu.sequence}
                                                    onChange={(event) => handleInputChange(index, event)}
                                                    // error={!!formErrors.}
                                                    // helperText={formErrors.subMenuRouteName}
                                                    InputProps={{
                                                        sx: {
                                                            height: '45px', // Adjust the height as needed
                                                            fontSize: '13px', // Adjust the font size as needed
                                                            borderRadius: 3,
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        sx: {
                                                            fontSize: '13px',
                                                            zIndex: 1
                                                        }
                                                    }}
                                                    FormHelperTextProps={{
                                                        sx: {
                                                            fontSize: '12px', // Adjust the helper text font size as needed
                                                        }
                                                    }}
                                                />
                                                <XCircle color='#EF4444' onClick={() => removeSubMenu(index)} style={{ cursor: "pointer", padding: "0px", margin: "0px", marginLeft: "5px", marginTop: "16px" }} size={"100px"} />
                                            </Box>
                                            <hr />
                                        </div>
                                    ))}
                                    <div style={{ color: "#338af9", display: "flex", alignItems: "center" }}>
                                        <div style={{ border: "2px solid #338af9", borderRadius: "15px", width: "20px", height: "20px", display: "flex", justifyContent: "center", alignItems: "center", marginRight: "5px" }}>
                                            <span  onClick={handleAddSubMenu} style={{ fontWeight: "bold", cursor: "pointer", }}>+</span>
                                        </div>
                                        <span style={{cursor: "pointer"}}  onClick={handleAddSubMenu}>Add Sub Menu</span>
                                    </div>
                                </form>
                            </Box>
                            <DialogActions>
                                <Button severity='danger' style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} color='error' onClick={handleClose}>Cancel</Button>
                                <Button severity='primary' onClick={handleSubmit} style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} type="submit">{isUpdate ? "Update Menu" : "Add Menu"}</Button>
                            </DialogActions>
                        </Dialog>
                    </> :

                    <>
                        {/* <Edit color='primary' sx={{ ml: 3, cursor: "pointer" }} onClick={handleSubMenuModalOpen} /> */}
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-info" onClick={handleSubMenuModalOpen} style={{ marginRight: "10" }} text />
                        <Dialog
                            visible={openSubMenu}
                            onHide={handleSubMenuModalClose}
                            style={{ minWidth: "41vw" }}
                            header={"Update Sub-Menu"}
                        >
                            <Box sx={{ padding: 2, paddingTop: 1, display: "flex" }}>
                                {subMenuFormErrors.requestError && <Alert sx={{ marginBottom: 3 }} severity="error">{subMenuFormErrors.requestError}</Alert>}
                                <TextField
                                    fullWidth
                                    id="subMenuName"
                                    name="subMenuName"
                                    value={subMenuForm.subMenuName}
                                    onChange={(e) => handleSubMenuChange('subMenuName', e.target.value)}
                                    error={!!subMenuFormErrors.subMenuName}
                                    helperText={subMenuFormErrors.subMenuName}
                                    label="Sub-Menu Name"
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '13px', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '13px',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    id="subMenuRouteName"
                                    name="subMenuRouteName"
                                    sx={{ ml: 2 }}
                                    value={subMenuForm.subMenuRouteName}
                                    onChange={(e) => handleSubMenuChange('subMenuRouteName', e.target.value)}
                                    error={!!subMenuFormErrors.subMenuRouteName}
                                    helperText={subMenuFormErrors.subMenuRouteName}
                                    label="Sub-Menu Route"
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '13px', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '13px',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    id="sequence"
                                    name="sequence"
                                    value={subMenuForm.sequence}
                                    onChange={(e) => handleSubMenuChange('sequence', e.target.value)}
                                    error={!!subMenuFormErrors.sequence}
                                    sx={{ ml: 2 }}
                                    label="Sub-Menu Sequence"
                                    type='number'
                                    helperText={subMenuFormErrors.sequence}
                                    InputProps={{
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '13px', // Adjust the font size as needed
                                            borderRadius: 3,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '13px',
                                            zIndex: 1
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '12px', // Adjust the helper text font size as needed
                                        }
                                    }}
                                />

                            </Box>
                            <DialogActions>
                                <Button severity='danger' style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} color='error' onClick={handleSubMenuModalClose}>Cancel</Button>
                                <Button severity='primary' onClick={handleUpdateSubMenu} style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} type="submit">{"Update Sub-Menu"}</Button>
                            </DialogActions>
                        </Dialog>
                    </>
                }
            </React.Fragment>
        </>
    );
}

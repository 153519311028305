import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from 'react-bootstrap';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import PersonalDetails from './PersonalDetails'
import CompanyDetails from './CompanyDetails';
import BankDetails from './BankDetails';
import PicturesUpload from './PicturesUpload';
import { Card } from '@mui/material';

const steps = ['Personal Details', 'Company Details', 'Bank Details', 'Pictures Upload'];


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (

    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <p>{children}</p>
        </div>

      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function RegistrationStepperComp() {
  const [activeStep, setActiveStep] = React.useState(1);
  const [completed, setCompleted] = React.useState({});



  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if (!isLastStep()) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const [value, setValue] = React.useState(0);
  const [activeTabs, setActiveTabs] = useState(0);
  useEffect(() => {
    //console.log(value, "  ", activeTabs);
    setActiveTabs(value >= activeTabs ? value : activeTabs);

  }, [value]);

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  return (
    <>
      <div style={{ width: "100%", height: "100%" }} >
        <Card sx={{ width: "80%", boxShadow: "0px 0px 0px 0px lightGray", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
          <Button
            variant={activeStep == 0 ? "#fff" : '#C2E7FD'}
            style={{
              paddingLeft: "1.99%",
              paddingRight: "1.99%",
              backgroundColor: activeStep == 0 ? "#fff" : "#C2E7FD",
              color: "#000", border: "1px solid #a0a0a0",
              borderRadius: "0px",
              borderTop: "0px",
              borderRight: "1px solid #fff"
            }}>
            <Typography style={{ fontSize: "0.757vw" }}>Personal Details</Typography>
          </Button>
          <Button
            variant={activeStep == 1 ? "#fff" : '#C2E7FD'}
            style={{
              paddingLeft: "1.85%",
              paddingRight: "1.85%",
              backgroundColor: activeStep == 1 ? "#fff" : "#C2E7FD",
              color: "#000", border: "1px solid #a0a0a0",
              borderBottom: "0px",
              borderTop: "0px",
              borderRadius: "0px",
              borderRight: "1px solid #fff"
            }}>
            <Typography style={{ fontSize: "0.757vw" }}>Company Details</Typography>
          </Button>
          <Button 
          variant={activeStep == 2 ? "#fff" : '#C2E7FD'} 
          style={{ 
            paddingLeft: "1.85%", paddingRight: "1.85%", backgroundColor: activeStep == 2 ? "#fff" : "#C2E7FD", color: "#000", border: "1px solid #a0a0a0", borderRadius: "0px", borderTop: "0px", borderRight: "1px solid #fff" }}>
            <Typography style={{ fontSize: "0.757vw" }}>Bank Details</Typography>
          </Button>
          <Button variant={activeStep == 3 ? "#fff" : '#C2E7FD'} style={{ paddingLeft: "1.85%", paddingRight: "1.85%", backgroundColor: activeStep == 3 ? "#fff" : "#C2E7FD", color: "#000", border: "1px solid #a0a0a0", borderRadius: "0px", borderTop: "0px", borderRight: "1px solid #fff" }}>
            <Typography style={{ fontSize: "0.757vw" }}>Pictures Upload</Typography>
          </Button>
        </Card>
        <div style={{ borderRadius: 25, width: "100%", }}>
          <TabPanel value={value} index={0} dir={'ltr'}>
            <PersonalDetails setValue={setValue} handleBack={handleBack} handleNext={handleNext} setActiveTabs={setActiveTabs} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={'ltr'}>
            <CompanyDetails setValue={setValue} handleBack={handleBack} handleNext={handleNext} setActiveTabs={setActiveTabs} />
          </TabPanel>
          <TabPanel value={value} index={2} dir={'ltr'}>
            <BankDetails setValue={setValue} handleBack={handleBack} handleNext={handleNext} setActiveTabs={setActiveTabs} />
          </TabPanel>
          <TabPanel value={value} index={3} dir={'ltr'}>
            <PicturesUpload setValue={setValue} handleBack={handleBack} handleNext={handleNext} setActiveTabs={setActiveTabs} />
          </TabPanel>
        </div>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you're finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : null)}
              </Box>
            </React.Fragment>
          )}
        </div>


      </div>
    </>
  );
}

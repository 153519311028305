import React, { useEffect, useState } from 'react';
import { DialogActions, TextField, Box, Switch, Alert, Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText, InputAdornment } from '@mui/material';
import Swal from 'sweetalert2'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { url } from '../../../_helper/Route/Url';

export default function AddMachine({ refreshData, isUpdate }) {
    const sections = [
        { name: "Machine Status" },
        { name: "Booking Status" },
        { name: "Invoice Status" },
        { name: "Billing Status" },
    ]

    const status = [
        { name: "Active" },
        { name: "Inactive" },
    ]

    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        statusName: '',
        section: '',
        backgroundColor: '',
        textColor: '',
        status: false,
    });
    const [formErrors, setFormErrors] = useState({
        requestError: "",
        statusName: '',
        section: '',
        backgroundColor: '',
        textColor: '',
        status: '',
    });

    useEffect(() => {
        if (open && isUpdate) {
            fetchMachineStatus();
        }
    }, [open, isUpdate]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormValues({
            statusName: '',
            status: false,
        });
        setFormErrors({
            requestError: "",
            statusName: '',
            section: '',
            backgroundColor: '',
            textColor: '',
            status: '',
        });
    };

    const handleChange = (field, value) => {
        console.log(value)
        setFormValues({
            ...formValues,
            [field]: value,
        });
        setFormErrors({
            ...formErrors,
            [field]: '',
        });
    };

    const validate = () => {
        const errors = {};
        if (!formValues.statusName) errors.statusName = 'Field is required';
        if (!formValues.section) errors.section = 'Field required';
        if (!formValues.backgroundColor) errors.backgroundColor = 'Field is required';
        if (!formValues.textColor) errors.textColor = 'Field is required';
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validate()) return;
        //console.log(isUpdate)
        try {
            const response = await fetch(isUpdate ? url + "/api/sup/machineStatus/update?id=" + isUpdate : url + "/api/sup/machineStatus/add", {
                method: isUpdate ? "PUT" : "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify(formValues),
            });
            if (!response.ok) {
                const result = await response.json();
                setFormErrors({ requestError: result.message });
            } else {
                handleClose();
                setFormValues({
                    statusName: '',
                    section: '',
                    backgroundColor: '',
                    textColor: '',
                    status: false,
                });
                Swal.fire({
                    position: "center",
                    icon: "success",
                    html: `
        <div class="custom-content">
            Status has been changed
        </div>
        <style>
            .swal2-popup {
                padding: 0px !important; /* Reduce padding inside the Swal box */
            }
            .swal2-icon.swal2-success {
                font-size: 11px !important; /* Adjust the size as needed */
            }
            .swal2-title {
                font-size: 18px !important; /* Adjust the size as needed */
            }
            .custom-content {
                font-size: 14px !important; /* Adjust the size as needed */
            }
        </style>
    `,
                    customClass: {
                        icon: 'swal2-icon',
                        title: 'swal2-title',
                        content: 'custom-content'
                    },
                    title: isUpdate ? "Machine Status Updated Successfully" : "Machine Status Created Successfully",
                    showConfirmButton: false,
                    timer: 2000
                });
                refreshData();
            }
        } catch (error) {
            isUpdate ?
                setFormErrors({ requestError: "Failed to Update Machine Status" })
                :
                setFormErrors({ requestError: "Failed to Add Machine Status" })
        }
    };

    const fetchMachineStatus = async () => {
        const id = isUpdate;
        try {
            const response = await fetch(url + '/api/sup/machineStatus/get?id=' + id);
            if (!response.ok) {
                const result = await response.json();
                setFormErrors({ requestError: result.message });
            } else {
                const data = await response.json();
                //console.log(data)
                setFormValues({
                    statusName: data.statusName,
                    section: data.section,
                    backgroundColor: data.backgroundColor,
                    textColor: data.textColor,
                    status: data.status,
                });
                setFormErrors({ requestError: '' });
            }
        } catch (error) {
            setFormErrors({ requestError: 'Failed to fetch Machine Status' });
        }
    };

    return (
        <>
            <React.Fragment>
                {isUpdate ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-info" onClick={handleClickOpen} style={{ marginRight: "15px", width: "20px", height: "20px", color: "#0b0ff1c7" }} text />
                    :
                    <Button icon="pi pi-plus" label="Add Status" onClick={handleClickOpen} style={{ marginRight: "20", height: "35px", padding: "3px", backgroundColor: "#0b0ff1c7", borderColor: "#0b0ff1c7", paddingTop: 10, paddingBottom: 10 }} />
                }

                <Dialog
                    visible={open}
                    onHide={handleClose}
                    style={{ minWidth: "43vw" }}
                    header={isUpdate ? "Update Machine Status" : "Add Machine Status"}
                >
                    {formErrors.requestError && <Alert sx={{ marginBottom: 3 }} severity="error">{formErrors.requestError}</Alert>}
                    <Box sx={{ padding: 2, paddingTop: 1, display: "flex", justifyContent: "space-around", }}>
                        <TextField
                            fullWidth
                            id="statusName"
                            name="statusName"
                            label="Status Name"
                            value={formValues.statusName}
                            onChange={(e) => handleChange('statusName', e.target.value)}
                            error={!!formErrors.statusName}
                            helperText={formErrors.statusName}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '13px', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '13px',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        />
                        <FormControl
                            fullWidth
                            sx={{ ml: "2%" }}
                            error={!!formErrors.section}
                        >
                            <InputLabel sx={{ fontSize: '13px', zIndex: 1 }} id="section-label">
                                Section
                            </InputLabel>
                            <Select
                                labelId="section-label"
                                id="section"
                                name="section"
                                value={formValues.section}
                                fullWidth
                                onChange={(e) => handleChange('section', e.target.value)}
                                label="Section"
                                sx={{
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '13px', // Adjust the font size as needed
                                    borderRadius: 3,
                                    // zIndex:1001
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            fontSize: '13px',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {sections?.map((section, index) => (
                                    <MenuItem key={index} value={section.name}>
                                        {section.name}
                                    </MenuItem>
                                ))}
                                {/* Add more options as needed */}
                            </Select>
                            <FormHelperText sx={{ fontSize: '12px' }}>
                                {formErrors.section}
                            </FormHelperText>
                        </FormControl>

                        <TextField
                            fullWidth
                            id="backgroundColor"
                            sx={{ ml: "2%" }}
                            type='text'
                            name="backgroundColor"
                            label="Background Color"
                            value={formValues.backgroundColor}
                            onChange={(e) => handleChange('backgroundColor', e.target.value)}
                            error={!!formErrors.backgroundColor}
                            helperText={formErrors.backgroundColor}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {formValues.backgroundColor} {/* Display the color hex value */}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <input
                                            type="color"
                                            value={formValues.backgroundColor}
                                            onChange={(e) => handleChange('backgroundColor', e.target.value)}
                                            style={{
                                                cursor: 'pointer',
                                                height: '25px',
                                                width: '25px',
                                                borderRadius: '50%',
                                                border: 'none',
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '13px', // Adjust the font size as needed
                                    borderRadius: 3,
                                    width: '6vw',
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '13px',
                                    zIndex: 1,
                                },
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            id="textColor"
                            name="textColor"
                            sx={{ ml: "2%" }}
                            label="Text Color"
                            type="text" // Change type to text to display hex code
                            value={formValues.textColor}
                            onChange={(e) => handleChange('textColor', e.target.value)}
                            error={!!formErrors.textColor}
                            helperText={formErrors.textColor}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {formValues.textColor} {/* Display the color hex value */}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <input
                                            type="color"
                                            value={formValues.textColor}
                                            onChange={(e) => handleChange('textColor', e.target.value)}
                                            style={{
                                                cursor: 'pointer',
                                                height: '25px',
                                                width: '25px',
                                                borderRadius: '50%',
                                                border: 'none',
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '13px', // Adjust the font size as needed
                                    borderRadius: 3,
                                    width: '6vw',
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '13px',
                                    zIndex: 1,
                                },
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                },
                            }}
                        />


                        <FormControl
                            fullWidth
                            error={!!formErrors.status}
                        >
                            <InputLabel sx={{ fontSize: '13px', zIndex: 1 }} id="status-label">
                                Status
                            </InputLabel>
                            <Select
                                labelId="status-label"
                                id="status"
                                name="status"
                                value={formValues.status}
                                fullWidth
                                onChange={(e) => handleChange('status', e.target.value)}
                                label="Section"
                                sx={{
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '13px', // Adjust the font size as needed
                                    borderRadius: 3,
                                    // zIndex:1001
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            fontSize: '13px',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {status?.map((item, index) => (
                                    <MenuItem key={index} value={item.name == "Active" ? true : false}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                                {/* Add more options as needed */}
                            </Select>
                            <FormHelperText sx={{ fontSize: '12px' }}>
                                {formErrors.status}
                            </FormHelperText>
                        </FormControl>
                        {/* <Box sx={{}}>
                            <Box sx={{ marginTop: 3, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography sx={{ fontSize: '13px', marginBottom: 1 }}>Status</Typography>
                                <Switch
                                    checked={formValues.status}
                                    onChange={(e) => handleChange('status', e.target.checked)}
                                    color="primary"
                                />
                                {formErrors.status && (
                                    <Typography color="error" sx={{ fontSize: '12px' }}>{formErrors.status}</Typography>
                                )}
                            </Box>
                        </Box> */}

                    </Box>
                    <DialogActions>
                        <Button severity='danger' style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} color='error' onClick={handleClose}>Cancel</Button>
                        <Button severity='primary' onClick={handleSubmit} style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} type="submit">{isUpdate ? "Update" : "Create"}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </>
    );
}
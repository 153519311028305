
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { url } from '../../../_helper/Route/Url';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export default function SubMenu({ subMenus, id }) {
    const [products, setProducts] = useState();
    const [statuses] = useState(['Active', 'Inactive']);
    const [render, setRender] = useState(false);
    const toast = useRef(null);

    const refreshData = () => {
        setRender(!render)
    }

    const getSeverity = (value) => {
        switch (value) {
            case true:
                return 'success';

            case false:
                return 'warning';

            default:
                return null;
        }
    };

    const onRowEditComplete = (e) => {
        let { newData } = e;
        handleUpdateSubMenu(newData)
    };

    const textEditor = (options) => {
        return <InputText style={{width:"11rem"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const statusEditor = (options) => {
        return (
            <Dropdown
                value={options.value ? "Active" : "Inactive"}
                options={statuses}
                required
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
                itemTemplate={(option) => {
                    return <Tag value={option} severity={getSeverity(option)}></Tag>;
                }}
            />
        );
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag onClick={() => confirmSubMenuStatusChange(rowData.id, !rowData.subMenuStatus)} value={rowData.subMenuStatus ? "Active" : "Inactive"} style={{ cursor: "pointer", backgroundColor: rowData.subMenuStatus ? "green" : "red", fontSize:"10px" }} severity={getSeverity(rowData.subMenuStatus)}></Tag>;
    };

    const confirmSubMenuStatusChange = (id, status) => {
        confirmDialog({
            message: `Are you sure you want to make it ${status ? "Active" : "Inactive"}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => changeSubmenuStatus(id, status),
        });
    }

    const changeSubmenuStatus = async (subMenuId, status) => {
        //console.log(status)
        try {
            const response = await fetch(`${url}/api/sup/menu/submenu/status/update?subMenuId=${subMenuId}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: status }),
            });
            if (!response.ok) {
                toast.current.show({ severity: 'error', summary: 'Failed to change status', life: 3000 })
            } else {
                refreshData();
                toast.current.show({ severity: 'success', summary: 'Sub-Menu Status Changed Successfully!!', life: 3000 })
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Failed to change status', life: 3000 })
        }
    };

    const allowEdit = (rowData) => {
        return rowData.name !== 'Blue Band';
    };

    const deleteBodyTemplate = (rowData) => {
        return (
            <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-text p-button-danger"
                onClick={() => confirmSubMenuDelete(rowData.id)}
                aria-label="Delete"
            />
        )
    };

    const confirmSubMenuDelete = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleSubmenuDelete(id),
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptClassName: "custom-accept-button",
            rejectClassName: "custom-reject-button"
        });
    }

    const handleSubmenuDelete = async (subMenuId) => {
        try {
            const response = await fetch(`${url}/api/sup/menu/submenu/delete?subMenuId=${subMenuId}`, {
                method: 'DELETE',
            });

            response.ok ?
                toast.current.show({ severity: 'success', summary: 'Sub-Menu Deleted Successfully!!', life: 3000 })
                :
                toast.current.show({ severity: 'error', summary: 'Failed to delete Sub-Menu', life: 3000 });

            setRender(!render);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Failed to delete Sub-Menu', life: 3000 });
        }
    };

    const fetchMenu = async () => {
        try {
            const response = await fetch(url + '/api/sup/menu/submenu/get-by-menu?menuId=' + id);
            if (!response.ok) {
                const result = await response.json();
            } else {
                const data = await response.json();
                //console.log(data,"datadatadatad")
                setProducts(data)
            }
        } catch (error) {
        }
    };

    const handleUpdateSubMenu = async (subMenuForm) => {
        const subMenuId = subMenuForm.id;
        try {
            fetch(`${url}/api/sup/menu/submenu/update?subMenuId=${subMenuId}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(subMenuForm),
                }
            )
                .then(async (response) => {
                    if (!response.ok) {
                        const result = await response.json();
                toast.current.show({ severity: 'error', summary: 'Failed to update Sub-Menu', life: 3000 });
                    } else {
                        refreshData();
                        toast.current.show({ severity: 'success', summary: 'Sub-Menu Updated Successfully!!', life: 3000 })
                    }
                })
        } catch (error) {
            //console.log(error, "****")
            toast.current.show({ severity: 'error', summary: 'Failed to update Sub-Menu', life: 3000 });
        }

    }

    useEffect(()=>{
        setProducts(subMenus)
        //console.log("object1")
    },[])

    useEffect(() => {
        fetchMenu();
        //console.log("object")
    }, [render, setRender]);

    return (
        <div className="card p-fluid">
            <Toast ref={toast} />
            <DataTable value={products} showGridlines stripedRows  editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} tableStyle={{ minWidth: '50rem' }}>
                <Column field="id" header="Id" sortable style={{fontSize: "14px", width: '10%' }}></Column>
                <Column field="subMenuName" header="Name" editor={(options) => textEditor(options)} style={{ fontSize: "14px",width: '25%' }}></Column>
                <Column field="subMenuRouteName" header="Route" editor={(options) => textEditor(options)} style={{ fontSize: "14px",width: '40%' }}></Column>
                <Column field="subMenuStatus" header="Status" body={statusBodyTemplate} editor={(options) => statusEditor(options)} style={{ fontSize: "14px",width: '20%' }}></Column>
                <Column field="sequence" header="Sequence" sortable editor={(options) => textEditor(options)} style={{ fontSize: "14px",width: '20%' }}></Column>
                <Column rowEditor={allowEdit} headerStyle={{ width: '10%', minWidth: '6rem' }}></Column>
                <Column body={deleteBodyTemplate} headerStyle={{ fontSize: "14px",width: '10%', minWidth: '1rem' }} bodyStyle={{}}></Column>
            </DataTable>
            {/* <ConfirmDialog /> */}
        </div>
    );
}

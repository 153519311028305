import React, { useEffect, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FlatStyle, url } from '../../../Constant';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import FullPageLoader from '../../../Components/Loaders/FullPageLoader';
import { Skeleton } from 'primereact/skeleton';
import { padStart } from '@fullcalendar/core/internal';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { SplitButton } from 'primereact/splitbutton';
import * as XLSX from 'xlsx';
import Pagination from '../../../_helper/Pagination/Pagination';
import TableSkeleton from '../../../Components/Loaders/TableSkeleton';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import AddUser from './AddUser';
import Permissions from '../Roles/Permissions';





const UserTable = () => {
    const [users, setUsers] = useState([]);
    const toast = useRef(null)
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loader, setLoader] = useState(false);
    const [metadata, setMetadata] = useState({});
    const [currentPage, setCurrentPage] = useState(() => { return 1 });
    const [pageSize, setPageSize] = useState(() => { return 10 });
    const [statusLoader, setStatusLoader] = useState(false);
    const dt = useRef(null);
    const [editUserId, setEditUserId] = useState();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [render, setRender] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, [render, setRender])

    const actionBodyTemplate = (rowData) => {
        // alert("Action Body")
        // console.log(rowData);
        // return (<p>This is Action</p>);
        return (
            <React.Fragment>
                {/* <p></p> */}
                {/* <AddUser editUserId={rowData.id} setEditUserId={setEditUserId} refreshData={() => setRender(!render)} /> */}
                <Link to={`/panel/user/profile?id=${rowData.id}`}>
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-text p-button-info" style={{ marginRight: "15px", color: "#0b0ff1c7" }} text />
                </Link>
                {/* <Button
                    icon="pi pi-pencil"
                    rounded
                    outlined
                    severity="success"
                    // onClick={() => {setEditMachineId(rowData.id);setMachineDialog(true)}}
                    style={{
                        outline: 'none',
                        border: 'none', // Remove border if needed
                        boxShadow: 'none' // Remove box-shadow if needed
                    }}
                /> */}
                <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                    onClick={() => confirmDelete(rowData)}
                    style={{
                        outline: 'none',
                        border: 'none', // Remove border if needed
                        boxShadow: 'none' // Remove box-shadow if needed
                    }}
                />
            </React.Fragment>
        );
    };


    const statusBodyTemplate = (rowData) => {
        return (
            <Button style={{ fontSize: "0.6vw", padding: "2px", cursor: "pointer", backgroundColor: rowData.status ? "green" : "red" }} onClick={() => confirmStatusChange(rowData)} value={rowData.status} severity={getSeverity(rowData)}>{rowData.status ? "Active" : "Inactive"}</Button>
        );
    };
    const columns = [
        { selectionMode: 'multiple', exportable: false, style: { width: '3rem', fontSize: "10px" } },

        { field: 'id', header: 'User ID', sortable: true, style: { minWidth: "4rem", fontSize: "0.7vw" } },
        { field: 'username', header: 'User Name', sortable: true, style: { minWidth: "10rem", fontSize: "0.7vw" } },
        { field: 'fullName', header: 'Full Name', sortable: true, style: { minWidth: "10rem", fontSize: "0.7vw" } },
        // { field: 'middleName`', header: 'Middle Name', sortable: true, style:{minWidth:"14rem"} },
        { field: 'mobile', header: 'Mobile', sortable: true, style: { minWidth: "10rem", fontSize: "0.7vw" } },
        { field: 'email', header: 'Email', sortable: true, style: { minWidth: "10rem", fontSize: "0.7vw" } },
        // { field: 'gender', header: 'Gender', sortable: true, style: { minWidth: "8rem", fontSize: "0.7vw" } },
        { field: 'roleName', header: 'Role', sortable: true, style: { minWidth: "10rem", fontSize: "0.7vw" } },
        // { field: 'role', header: 'Department', sortable: true, style:{minWidth:"10rem", fontSize:"0.7vw"} },
        // { field: 'permission', header: 'Permission', sortable: true, style:{minWidth:"14rem"} },
        // { field: 'designation', header: 'Designation', sortable: true, style:{minWidth:"10rem", fontSize:"0.7vw"} },
        // { field: 'details', header: 'Details', sortable: true, style:{minWidth:"14rem"} },
        {
            field: 'status',
            header: 'Status',
            body: statusBodyTemplate,
            style: { minWidth: '5rem', fontSize: "0.7vw" }
        },
        {
            field: 'id',
            header: 'Action',
            body: actionBodyTemplate,
            style: { minWidth: "10rem" }
        },

    ];

    const fetchUsers = async () => {
        setLoader(true);
        setLoading(true);

        const filtersParams = ['firstName', 'lastName', 'email', 'id', 'roleName', 'mobile']; // Example filter parameters
        const params = new URLSearchParams({
            companyCode: localStorage.getItem('companyCode'),
            page: currentPage,
            limit: pageSize,
            filter: globalFilter || ''
        });

        // Append each filter as a separate query parameter
        filtersParams.forEach(filter => params.append('filterParams[]', filter));

        try {
            const response = await fetch(`${url}/api/admin/users/allUsers?${params.toString()}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                if (response.status === 403) {
                    toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
                    throw new Error('Unauthorized');
                } else {
                    throw new Error('Network response was not ok.');
                }
            }

            const result = await response.json();
            console.log(result);
            let data = result?.map((user) => {
                user.fullName = user.prefix + ". " + user?.firstName + " " + user?.lastName;
                return user;
            })
            console.log(data, "Fetched Users Data:");
            setUsers(pageSize === 1000 ? prevUsers => [...prevUsers, ...data] : data);
            setMetadata(result.metadata);
        } catch (error) {
            console.error('Fetch error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while fetching data', life: 3000 });
        } finally {
            setLoader(false); // Ensure loader is hidden after the fetch completes
            setLoading(false); // Ensure loader is hidden after the fetch completes
        }
    };

    useEffect(() => {
        // Reset users data when filter, pageSize, or currentPage changes
        setLoader(true);
        setUsers([]);
        fetchUsers();
        setLoader(false);
    }, [currentPage, pageSize, globalFilter]);

    useEffect(() => {
        fetchUsers()
    }, [])

    // const fetchAllRoles = () => {
    //     fetch(url + '/api/sup/role/getAll').then((response) => {
    //         if (response.ok) {
    //             response.json().then((result) => {
    //                 setCustomers(result)
    //                 //console.log(result)
    //             })
    //         }
    //     })
    // }


    const confirmDelete = (customer) => {
        confirmDialog({
            message: 'Are you sure you want to delete?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDelete(customer),
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptClassName: "custom-accept-button",
            rejectClassName: "custom-reject-button"
        });
    };

    const handleDelete = (users) => {
        //console.log(machine)
        const collection = "users"
        const field = "id"
        const id = users.id;
        fetch(`${url}/api/admin/field/delete?collection=${collection}&field=${field}&id=${id}`, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (!response.ok) {
                setLoader(false);
                throw new Error('Network response was not ok');
            }
            setLoader(false);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User deleted', life: 3000 });
            fetchUsers();
        }).catch((err) => {
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Failed to delete', life: 3000 });
            //console.log(err)
        })
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= metadata.totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handlePageSizeChange = (event) => {
        // //console.log(event.target.value);
        setPageSize(Number(event.target.value));
        //console.log("page size set to ", event.target.value)
        setCurrentPage(1); // Reset to first page on page size change
    };

    const confirmStatusChange = (rowData) => {
        confirmDialog({
            message: `Are you sure you want to make it ${!rowData.status ? "Active" : "Inactive"}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => onstatusChange(rowData),
        });

    }



    const onstatusChange = async (rowData) => {
        setStatusLoader(true)
        const newStatus = rowData.status === true ? false : true;

        try {
            const response = await fetch(`${url}/api/admin/change_status`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    collectionName: 'users',
                    status: newStatus,
                    uniqueRow: 'id',
                    id: rowData.id
                })
            });
            if (!response.ok) {
                // setLoader(false);
                setStatusLoader(false)
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            if (response.status == 200) {
                setStatusLoader(false)
                //console.log("OK")
                fetchUsers();
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Status Updated', life: 3000 });
            } else {
                setStatusLoader(false)
                throw new Error(result.message || 'Failed to update status');
            }
        } catch (error) {
            setStatusLoader(false)
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setStatusLoader(false)
            //console.log("Status changed: ", newStatus);
        }
    };

    const getSeverity = (users) => {
        switch (users.status) {
            case true:
                return 'success';
            case false:
                return 'danger';
            default:
                return 'danger';
        }
    };


    // const exportOptions = [
    //     {
    //         label: 'Export as PDF',
    //         icon: 'pi pi-file-pdf',
    //         command: () => {
    //             exportToPDF();
    //             toast.current.show({ severity: 'success', summary: 'Exported', detail: 'Data Exported' });
    //         }
    //     },
    //     {
    //         label: 'Export CSV',
    //         icon: 'pi-file',
    //         command: () => {
    //             exportCSV();
    //             toast.current.show({ severity: 'success', summary: 'Exported', detail: 'Data Exported' });
    //         }
    //     },
    // ];


    // const exportCSV = () => {
    //     // Use the same column headers as in your PDF export
    //     // const columns = [
    //     //     { title: "Company Code", dataKey: "machineCode" },
    //     //     { title: "Company Name", dataKey: "machineName" },
    //     //     { title: "Prefix", dataKey: "prefix" },
    //     //     { title: "Contact Person", dataKey: "contactPerson" },
    //     //     { title: "Contact Number", dataKey: "contactNumber" },
    //     //     { title: "Registration Date/Time", dataKey: "registrationDate" },
    //     //     { title: "Status", dataKey: "status" }
    //     // ];

    //     // Extract the data
    //     const machineData = selectedMachines || machines;
    //     const rows = machineData.map(machine => ({
    //         machineCode: `FR${machine.CompanyDetails?.machinePrefix || "---"}${machine.machine_code ? padStart(machine.machine_code, 4, '0') : "----"}`,
    //         machineName: machine.CompanyDetails?.machineName || "N/A",
    //         prefix: machine.CompanyDetails?.machinePrefix || "N/A",
    //         contactPerson: `${machine.PersonalDetails?.firstName || "N/A"} ${machine.PersonalDetails?.middleName || ""} ${machine.PersonalDetails?.lastName || ""}`,
    //         contactNumber: machine.mobile || "N/A",
    //         registrationDate: machine.register_date || "N/A",
    //         status: machine.status ? "Active" : "Inactive"
    //     }));

    //     // Convert data to worksheet
    //     const ws = XLSX.utils.json_to_sheet(rows, { header: columns.map(col => col.title) });

    //     // Create a new workbook and add the worksheet
    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws, 'Selected Companies');
    //     // Write the file
    //     XLSX.writeFile(wb, 'Companies.xlsx');
    // };

    // const exportToPDF = () => {
    //     const doc = new jsPDF();
    //     doc.text("Companies List", 14, 16);

    //     const machineData = selectedMachines || machines;


    //     const columns = [
    //         { title: "Company Code", dataKey: "machineCode" },
    //         { title: "Company Name", dataKey: "machineName" },
    //         { title: "Prefix", dataKey: "prefix" },
    //         { title: "Contact Person", dataKey: "contactPerson" },
    //         { title: "Contact Number", dataKey: "contactNumber" },
    //         { title: "Registration Date/Time", dataKey: "registrationDate" },
    //         { title: "Status", dataKey: "status" }
    //     ];
    //     const rows = machineData.map(machine => ({
    //         machineCode: `FR${machine.CompanyDetails?.machinePrefix || "---"}${machine.machine_code ? padStart(machine.machine_code, 4, '0') : "----"}`,
    //         machineName: machine.CompanyDetails?.machineName || "N/A",
    //         prefix: machine.CompanyDetails?.machinePrefix || "N/A",
    //         contactPerson: (machine.PersonalDetails?.firstName || "N/A") + " " + (machine.PersonalDetails?.middleName || "") + " " + (machine.PersonalDetails?.lastName || ""),
    //         contactNumber: machine.mobile || "N/A",
    //         registrationDate: machine.register_date || "N/A",
    //         status: machine.status ? "Active" : "Inactive"
    //     }));
    //     doc.autoTable(columns, rows, { startY: 22 });
    //     doc.save('machines.pdf');
    // };

    const permissionBodyTemplate = (rowData) => {
        return <Permissions roleId={rowData.id} />
    };

    const leftToolbarTemplate = () => {
        return (<>
            {/* <Button label="Add User" icon="pi pi-plus" className="p-button-help mx-1" onClick={openNew} /> */}
            {/* <AddMachineModal editMachineId={editMachineId} setEditMachineId={setEditMachineId} machineDialog={machineDialog} setMachineDialog={setMachineDialog}/> */}
            <SplitButton label="EXPORT" className='mx-1' icon="pi pi-file-xls" severity='danger' />
            <FormControl variant="outlined" className='mx-1' size="small" style={{ minWidth: 75 }}>
                <InputLabel>Items</InputLabel>
                <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    label="Items"
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </Select>
            </FormControl>
        </>
        );
    };

    const rightToolbarTemplate = () => {


        return (
            <div className='d-flex align-items-center'>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </IconField>
                <div style={{ marginLeft: "0.4vw" }}>
                    < AddUser editUserId={editUserId} setEditUserId={setEditUserId} refreshData={() => setRender(!render)} />
                </div>
            </div>
        )
        // <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />;
    };




    return (
        <>
            <div className="datatable-crud-demo">
                <Breadcrumbs mainTitle="User" parent="General" title="Users" />
                <FullPageLoader open={statusLoader} />
                <Toast ref={toast} />
                <div className="card">
                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
                    <DataTable
                        ref={dt}
                        value={users}
                        selection={selectedUsers}
                        onSelectionChange={(e) => setSelectedUsers(e.value)}
                        dataKey="id"
                        // paginator={false} // Enable pagination
                        // rows={pageSize}
                        // rowsPerPageOptions={[5, 10, 25]}
                        emptyMessage="No users found."
                        className="datatables-demo"
                    >
                        {columns.map((col, i) => (
                            <Column key={i} field={col.field} header={col.header} body={col.body} sortable={col.sortable} style={col.style} exportable={col.exportable} selectionMode={col.selectionMode} />

                        ))}
                        {/* <Column field="permission" header="Permissions" style={{ fontSize: "14px", minWidth: '3rem' }} align={"center"} body={permissionBodyTemplate} />
                        <Column
                            body={(rowData) => (
                                <button onClick={() => handleDelete(rowData.id)}>Edit</button>
                            )}
                            header="Actions"
                        />
                        <Column
                            body={(rowData) => (

                                <button icon="pi pi-trash"
                                rounded
                                outlined
                                severity="danger" onClick={() => handleDelete(rowData.id)}>Delete</button>
                            )}
                            header="Actions"
                        /> */}
                    </DataTable>
                    {loader && <TableSkeleton rowCount={pageSize} columns={columns} />}
                    <Pagination
                        currentPage={currentPage}
                        totalPages={metadata?.totalPages} // Make sure to define this variable
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        pageSize={pageSize}
                        setLoader={setLoader}
                    />
                </div>
                <ConfirmDialog />
            </div>
        </>
    );
};

export default UserTable;

import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";

import OverallBalance from "./OverallBalance";
import GreetingCard from "./GreetingCard";
import WidgetsWrapper from "./WidgetsWrapper.js";
// import RecentOrders from "./RecentOrders";
// import ActivityCard from "./ActivityCard";
import RecentSales from "./RecentSales.js";
// import TimelineCard from "./TimelineCard";
// import PreAccountCard from "./PreAccountCard";
// import TotalUserAndFollower from "./TotalUserAndFollower";
// import PaperNote from "./PaperNote";

const AdminDashboard = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Default" parent="Dashboard" title="Default" />
      <Container fluid={true}>
        <Row className="widget-grid">
          <GreetingCard />
          <WidgetsWrapper />
          <OverallBalance />
          {/* <RecentOrders /> */}
          {/* <ActivityCard /> */}
          <RecentSales />
          {/* <TimelineCard /> */}
          {/* <PreAccountCard /> */}
          {/* <TotalUserAndFollower /> */}
          {/* <PaperNote /> */}
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminDashboard;

import React, { useContext, useState } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements'
import MachineInfo from './MachineInfo'
import BookingSection from './BookingSection'
import PaymentSection from './PaymentSection'
import TransportationInfo from './TransportationInfo'
import DateModal from './Components/Breakdown/DateModal'
import { FormContext } from './Provider/FormProvider'
import { Button } from 'primereact/button'
import { url } from '../../../../Constant'
import { useNavigate } from 'react-router'

export default function Bookings() {
    const [statusId, setStatusId] = useState();
    const { formData, updateFormData } = useContext(FormContext);
    const navigation = useNavigate();
    const handleSubmit = () => {
        const formDataToSend = new FormData();

        // Handle SecurityCheque payment
        if (formData.paymentInfo.paymentType === "SecurityCheque") {
            const paymentData = { ...formData.paymentInfo, SecurityCheque: { ...formData.paymentInfo.SecurityCheque } };
            const chequeImage = paymentData.SecurityCheque.chequeImage;
            if (chequeImage) {
                formDataToSend.append("SecurityCheque.chequeImage", chequeImage); // Append chequeImage as file
                console.log(chequeImage)
            }

            formDataToSend.append("paymentInfo", JSON.stringify(paymentData)); // Append payment info as JSON
        }
        // Handle AdvancePayment
        else if (formData.paymentInfo.paymentType === "AdvancePayment") {
            const paymentData = { ...formData.paymentInfo, AdvancePayment: { ...formData.paymentInfo.AdvancePayment } };

            if (paymentData.AdvancePayment.advancePaymentType === "Cheque") {
                const chequeImage = paymentData.AdvancePayment.cheque.chequeImage;
                console.log(chequeImage)

                if (chequeImage) {
                    formDataToSend.append("AdvancePayment.chequeImage", chequeImage); // Append chequeImage as file
                }
            }

            formDataToSend.append("paymentInfo", JSON.stringify(paymentData)); // Append payment info as JSON
        }

        else if (formData.paymentInfo.paymentType === "SecurityChequeAndAdvancePayment") {
            const paymentData = { ...formData.paymentInfo, SecurityCheque: { ...formData.paymentInfo.SecurityCheque }, AdvancePayment: { ...formData.paymentInfo.AdvancePayment } };
            const chequeImage = paymentData.SecurityCheque.chequeImage;
            if (chequeImage) {
                formDataToSend.append("SecurityCheque.chequeImage", chequeImage); // Append chequeImage as file
                console.log(chequeImage)
            }
            if (paymentData.AdvancePayment.advancePaymentType === "Cheque") {
                const chequeImage = paymentData.AdvancePayment.cheque.chequeImage;
                console.log(chequeImage)

                if (chequeImage) {
                    formDataToSend.append("AdvancePayment.chequeImage", chequeImage); // Append chequeImage as file
                }
            }

            formDataToSend.append("paymentInfo", JSON.stringify(paymentData)); // Append payment info as JSON
        }
        // No cheque image case
        else {
            formDataToSend.append("paymentInfo", JSON.stringify(formData.paymentInfo));
        }

        formDataToSend.append("bookingInfo", JSON.stringify(formData.bookingInfo));
        formDataToSend.append("transportationInfo", JSON.stringify(formData.transportationInfo));
        formDataToSend.append("machineInfo", JSON.stringify(formData.machineInfo));

        for (let pair of formDataToSend.entries()) {
            console.log(`${pair[0]}:`, pair[1]);  // This will show files and form data in the console
        }

        // Send the request
        fetch(`${url}/api/admin/assets/bookings/update?bookingId=${formData.bookingInfo.bookingId}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                // No need to set Content-Type header, it will be set automatically
            },
            body: formDataToSend
        })
            .then((response) => {
                if (response.ok) {
                    // alert("Booking updated successfully!");
                    navigation("/panel/assets");
                } else {
                    alert("Failed to update booking!");
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleStatus = (status) => {
        // Handle status change
        setStatusId(status)
    }
    return (
        <div>
            <Breadcrumbs mainTitle="Machine Booking" parent="Equipment" title="Booking" subParent="Machines" />
            <MachineInfo handleStatus={handleStatus} />
            {statusId == 1 &&
                <>
                    <div className="row">
                        <div className='col-md-6' style={{ marginTop: 30 }}>
                            <BookingSection />
                        </div>
                        <div className='col-md-6' style={{ marginTop: 30 }}>
                            <TransportationInfo />
                        </div>
                    </div>
                    <PaymentSection />
                    <div className='d-flex justify-content-start mb-3 ml-3'>
                        <Button severity='primary' onClick={handleSubmit} style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} type="submit">{"Update Asset Status"}</Button>
                    </div>
                </>
            }
            {
                statusId == 3 &&
                <DateModal />
            }
        </div>
    )
}

import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { url } from '../../../Constant';
import { Toast } from 'primereact/toast';
import { DialogActions, TextField, Box, FormControl, FormHelperText, Select, MenuItem, Radio, RadioGroup, FormControlLabel, FormLabel, InputLabel } from '@mui/material';

const initialFormData = {
    cid: '',
    prefix: '',
    cname: '',
    email: '',
    city: '',
    phone: '',
    contactPerson: '',
    address: '',
    status: '',
};

const statusOptions = ["Active", "Inactive"]

export default function AddCustomer({ hideDialog, editCustomerId, setEditCustomerId, setCustomerDialog }) {
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [loader, setLoader] = useState(false);
    let contactPersonObject =  {
        phone: '',
        contactPerson: '',
        email: '',
    }

    const [contactPersons, setContactPersons] = useState([contactPersonObject])

    const fetchCustomerData = async () => {
        try {
            const response = await fetch(`${url}/api/admin/getCustomerById?cid=${editCustomerId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) {
                if (response.status === 403) {
                    toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
                    throw new Error('Unauthorized');
                }
                throw new Error('Network response was not ok.');
            }

            const result = await response.json();
            console.log(result, "-------------------");
            setFormData(result); // Update your state with the fetched customer data
        } catch (error) {
            console.error('Fetch error:', error);
            // Optionally show an error toast
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while fetching data', life: 3000 });
        } finally {
            setLoader(false);
        }
    };
    const handleModalOpen = () => {
        setVisible(true)
        if (editCustomerId) {
            // Fetch existing customer data for editing
            fetchCustomerData();
        }
    }

    const handleInputChange = (e, field) => {
        setFormData({ ...formData, [field]: e.target.value });
    };
    const handleContactPersoninputChange = (e, field, index) =>{
        setContactPersons(prev=>{
            let persons = prev;
            console.log(persons)
            persons.map((person, i)=>{
                if(i===index){
                    return {...person, [field]: e.target.value};
                }
                return person;
            })
        })
    }

    const validateForm = () => {
        const newErrors = {};
        if (!formData.prefix) newErrors.prefix = "Field cannot be empty";
        if (!formData.cid) newErrors.cid = "Field cannot be empty";
        if (!formData.cname) newErrors.cname = "Field cannot be empty";
        if (!formData.email) newErrors.email = "Field cannot be empty";
        if (!formData.phone) newErrors.phone = "Field cannot be empty";

        if (!formData.status) newErrors.status = "Field cannot be empty";
        if (!formData.contactPerson) newErrors.contactPerson = "Field cannot be empty";
        if (!formData.address) newErrors.address = "Field cannot be empty";
        if (!formData.city) newErrors.city = "Field cannot be empty";
        return newErrors;
    };
    function clearForm() {
        setCustomerDialog(false); setFormData(initialFormData); setErrors({}); setEditCustomerId(null);
    }

    const handleClose = (e) => {
        // setCustomerDialog(false);
        setVisible(false);
        clearForm();
    }

    const addMoreContactPerson = ()=>{
        // setContactPersons(...contactPersons, contactPersonObject)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        setErrors(formErrors);
        console.log(contactPersons)
        if(formErrors)return;
        try {
            const response = await fetch(`${url}/api/admin/addCustomer`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(formData),

            });

            // Handle different response statuses
            if (response.ok) {
                const data = await response.json();
                console.log('Data submitted successfully:', data);
                setFormData(initialFormData);
                setErrors({});
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Customer added successfully', life: 3000 });
                handleClose()
            } else {
                const errorData = await response.json();
                switch (response.status) {
                    case 400:
                        toast.current.show({ severity: 'error', summary: 'Validation Error', detail: errorData.message || 'Invalid input data', life: 3000 });
                        break;
                    case 403:
                        toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You do not have permission to perform this action', life: 3000 });
                        break;
                    case 500:
                        toast.current.show({ severity: 'error', summary: 'Server Error', detail: 'An error occurred on the server', life: 3000 });
                        break;
                    default:
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'An unexpected error occurred', life: 3000 });
                        break;
                }
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };

    return (
        <>
            {!editCustomerId ?
                <Button icon="pi pi-plus" label="Add User" onClick={() => handleModalOpen()} style={{ marginRight: "10", height: "42px", padding: "3px", backgroundColor: "#0b0ff1c7", borderColor: "#0b0ff1c7", width: "6vw", fontSize: "0.7vw" }} raised />
                :
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-info" onClick={() => handleModalOpen()} style={{ marginRight: "15px", color: "#0b0ff1c7" }} text />
            }
            <Dialog header={editCustomerId ? "Edit Customer" : "Add Customer"} visible={visible} onHide={() => { setVisible(!visible); setFormData(initialFormData); handleClose() }} style={{ width: '800px' }}>
                <Toast ref={toast} />
                <Box sx={{ padding: 2, paddingTop: 1 }}>
                    <FormControl error={errors.prefix} component="fieldset" fullWidth sx={{ width: "100%" }}>
                        <FormLabel component="legend" sx={{ fontSize: '0.6vw', color: 'rgba(0, 0, 0, 0.6)' }}>
                            Prefix
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-label="prefix"
                            name="prefix"
                            value={formData.prefix}
                            onChange={(e) => handleInputChange(e, 'prefix')}
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '0.6vw' // Adjust font size of radio labels
                                }
                            }}
                        >
                            <FormControlLabel value="M/s" control={<Radio sx={{ padding: '0 8px' }} />} label="M/s" />
                            <FormControlLabel value="Mr" control={<Radio sx={{ padding: '0 8px' }} />} label="Mr" />
                            <FormControlLabel value="Mrs" control={<Radio sx={{ padding: '0 8px', ml: 3 }} />} label="Mrs" />
                            <FormControlLabel value="Ms" control={<Radio sx={{ padding: '0 8px', ml: 3 }} />} label="Ms" />
                            <FormControlLabel value="Other" control={<Radio sx={{ padding: '0 8px', ml: 3 }} />} label="Other" />
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ display: "flex", width: "100%", flexWrap: "wrap", mt: 3, justifyContent: "space-around" }}>

                        <TextField
                            fullWidth
                            id="cname"
                            name="cname"
                            label="Customer Name"
                            value={formData.cname}
                            sx={{ width: "31%" }}
                            onChange={(e) => handleInputChange(e, 'cname')}
                            error={!!errors.cname}
                            helperText={errors.cname}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '0.6vw',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            id="city"
                            name="city"
                            label="City"
                            value={formData.city}
                            sx={{ ml: 1, width: "22%" }}
                            onChange={(e) => handleInputChange(e, 'city')}
                            error={!!errors.city}
                            helperText={errors.city}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '0.6vw',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            id="address"
                            name="address"
                            label="Address"
                            value={formData.address}
                            sx={{ width: "44%", ml: 1 }}
                            onChange={(e) => handleInputChange(e, 'address')}
                            error={!!errors.address}
                            helperText={errors.address}
                            InputProps={{
                                sx: {
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '0.6vw',
                                    zIndex: 1
                                }
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '12px', // Adjust the helper text font size as needed
                                }
                            }}
                        />
                        <FormControl
                            sx={{ width: "100%", mt: 2 }}
                            error={!!errors.status}
                        >
                            <InputLabel sx={{ fontSize: '0.6vw', zIndex: 1 }} id="status-label">
                                Status
                            </InputLabel>
                            <Select
                                labelId="status-label"
                                id="status"
                                name="status"
                                value={formData.status}
                                onChange={(e) => handleInputChange(e, 'status')}
                                label="Status"
                                sx={{
                                    height: '45px', // Adjust the height as needed
                                    fontSize: '0.6vw', // Adjust the font size as needed
                                    borderRadius: 3,
                                    // zIndex:1001
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            fontSize: '0.6vw',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {statusOptions.map(data => (
                                    <MenuItem value={data}>
                                        <em>{data}</em>
                                    </MenuItem>
                                ))
                                }
                            </Select>
                            <FormHelperText sx={{ fontSize: '12px' }}>
                                {errors.status}
                            </FormHelperText>
                        </FormControl>
                        {/* {
                            contactPersons?.map((contactPerson, index) => (
                                <div style={{ width: "100%" }} className='d-flex justify-content-start align-items-center'>
                                    <TextField
                                        fullWidth
                                        id="contactPerson"
                                        name="contactPerson"
                                        label="Contact Person Name"
                                        sx={{ width: "30%", mt: 2 }}
                                        value={contactPerson.contactPerson}
                                        onChange={(e) => handleContactPersoninputChange(e, 'contactPerson', index)}
                                        error={!!errors.contactPerson}
                                        helperText={errors.contactPerson}
                                        InputProps={{
                                            sx: {
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '0.6vw', // Adjust the font size as needed
                                                borderRadius: 3,
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '0.6vw',
                                                zIndex: 1
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '12px', // Adjust the helper text font size as needed
                                            }
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Email Address"
                                        sx={{ ml: 2, width: "39%", mt: 2 }}
                                        value={contactPerson.email}
                                        onChange={(e) => handleContactPersoninputChange(e, 'email', index)}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                        InputProps={{
                                            sx: {
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '0.6vw', // Adjust the font size as needed
                                                borderRadius: 3,
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '0.6vw',
                                                zIndex: 1
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '12px', // Adjust the helper text font size as needed
                                            }
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        id="phone"
                                        name="phone"
                                        label="Mobile Number"
                                        value={contactPerson.phone}
                                        sx={{ mt: 2, width: "23%", ml: 1 }}
                                        onChange={(e) => handleContactPersoninputChange(e, 'phone', index)}
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                        InputProps={{
                                            sx: {
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '0.6vw', // Adjust the font size as needed
                                                borderRadius: 3,
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '0.6vw',
                                                zIndex: 1
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '12px', // Adjust the helper text font size as needed
                                            }
                                        }}
                                    />
                                    {
                                        index > 0 &&
                                        <div
                                            style={{
                                                border: "2px solid red",
                                                backgroundColor: "red",
                                                width: "21px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "21px",
                                                borderRadius: "50%",
                                                color: "#fff",
                                                fontSize: "15px",
                                                fontWeight: "bold",
                                                marginLeft: "1%",
                                                marginTop: "2%",
                                                cursor: "pointer"
                                            }} >
                                            X
                                        </div>
                                    }
                                </div>
                            ))
                        } */}
                    </Box>
                    <Button onClick={addMoreContactPerson()} icon="pi pi-plus" label="Add More" className='mt-3' style={{ fontSize: "0.6vw" }} raised />
                </Box>
                <DialogActions>
                    <Button severity='danger' style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} color='error' onClick={handleClose}>Cancel</Button>
                    <Button severity='primary' onClick={handleSubmit} style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} type="submit">{editCustomerId ? "Update" : "Create"}</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

import React, { Fragment, useState, useEffect } from 'react';

const Loader = (props) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Fragment>
      {show && (
        <div style={styles.loaderWrapper}>
          <div style={styles.loaderTop}>
            <div style={styles.loaderBar}></div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
// Inline styles
const styles = {
  loaderWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '5px', // Height of the top loader bar
    backgroundColor: 'transparent',
    zIndex: 9999, // Ensure the loader is on top of other content
  },
  loaderTop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderBar: {
    width: 0,
    height: '100%',
    backgroundColor: '#3498db', // Color of the loader bar
    animation: 'loading 3s linear infinite',
    // Add keyframes for animation
    animationName: `
      @keyframes loading {
        0% { width: 0; }
        50% { width: 100%; }
        100% { width: 0; }
      }
    `,
  },
};
export default Loader;

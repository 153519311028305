import { TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormContext } from '../../Provider/FormProvider';

export default function AdvancePaymentCheque({ type }) {
    const { formData, updateFormData } = useContext(FormContext);
    const [formValues, setFormValues] = useState({
        name: '',
        chequeNumber: '',
        amount: '',
        date: new Date().toISOString().split('T')[0], // Setting default to current date
        chequeImage: '',
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        chequeNumber: '',
        amount: '',
        date: '',
        chequeImage: '',
    });

    // Validation logic
    const validateField = (name, value) => {
        let error = '';
        if (name === 'name') {
            if (!value) error = 'Name is required';
        }
        if (name === 'chequeNumber') {
            if (!value || isNaN(value)) error = 'Enter a valid cheque number';
        }
        if (name === 'amount') {
            if (!value || isNaN(value) || parseFloat(value) <= 0)
                error = 'Enter a valid amount';
        }
        if (name === 'date') {
            if (!value) error = 'Date is required';
        }
        if (name === 'chequeImage') {
            if (!value) error = 'Cheque image is required';
        }
        return error;
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        let newValue = value;
        if (name === 'chequeImage') {
            newValue = files[0]; // Handle file input
            console.log(newValue);
        }
        setFormValues(prev=>{
            const values = {...prev, [name]: newValue,}
            let newFormValues = { AdvancePayment: { cheque: { ...values }, advancePaymentType: "Cheque" }, paymentType: type };
            updateFormData("paymentInfo", newFormValues,)
            return values;
        });

        // Validate field
        setFormErrors({
            ...formErrors,
            [name]: validateField(name, newValue),
        });
    };

    // Form submission logic (if required)
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate all fields
        const errors = {};
        for (let field in formValues) {
            const error = validateField(field, formValues[field]);
            if (error) errors[field] = error;
        }
        setFormErrors(errors);

        // If no errors, submit the form
        if (Object.keys(errors).length === 0) {
            console.log('Form submitted:', formValues);
            // Add submission logic here (e.g., API call)
        }
    };

    return (
        <div>
            <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                sx={{ width: '20%', backgroundColor: '#F7F8F9' }}
                value={formValues.name}
                onChange={handleChange}
                error={!!formErrors.name}
                helperText={formErrors.name}
                InputProps={{
                    sx: {
                        height: '45px',
                        fontSize: '13px',
                        borderRadius: 3,
                    },
                }}
                InputLabelProps={{
                    sx: {
                        fontSize: '13px',
                        zIndex: 1,
                    },
                }}
                FormHelperTextProps={{
                    sx: {
                        fontSize: '12px',
                    },
                }}
            />

            <TextField
                fullWidth
                id="chequeNumber"
                name="chequeNumber"
                label="Cheque Number"
                sx={{ width: '17%', ml: 2, backgroundColor: '#F7F8F9' }}
                value={formValues.chequeNumber}
                onChange={handleChange}
                error={!!formErrors.chequeNumber}
                helperText={formErrors.chequeNumber}
                InputProps={{
                    sx: {
                        height: '45px',
                        fontSize: '13px',
                        borderRadius: 3,
                    },
                }}
                InputLabelProps={{
                    sx: {
                        fontSize: '13px',
                        zIndex: 1,
                    },
                }}
                FormHelperTextProps={{
                    sx: {
                        fontSize: '12px',
                    },
                }}
            />

            <TextField
                fullWidth
                id="amount"
                name="amount"
                label="Amount"
                sx={{ width: '15%', ml: 2, backgroundColor: '#F7F8F9' }}
                value={formValues.amount}
                onChange={handleChange}
                error={!!formErrors.amount}
                helperText={formErrors.amount}
                InputProps={{
                    sx: {
                        height: '45px',
                        fontSize: '13px',
                        borderRadius: 3,
                    },
                }}
                InputLabelProps={{
                    sx: {
                        fontSize: '13px',
                        zIndex: 1,
                    },
                }}
                FormHelperTextProps={{
                    sx: {
                        fontSize: '12px',
                    },
                }}
            />

            <TextField
                fullWidth
                id="date"
                name="date"
                label="Date"
                sx={{ width: '12%', ml: 2, backgroundColor: '#F7F8F9' }}
                value={formValues.date}
                type="date"
                onChange={handleChange}
                error={!!formErrors.date}
                helperText={formErrors.date}
                InputProps={{
                    sx: {
                        height: '45px',
                        fontSize: '13px',
                        borderRadius: 3,
                    },
                }}
                InputLabelProps={{
                    shrink: true,
                    sx: {
                        fontSize: '13px',
                        zIndex: 1,
                    },
                }}
                FormHelperTextProps={{
                    sx: {
                        fontSize: '12px',
                    },
                }}
            />

            <TextField
                fullWidth
                id="chequeImage"
                name="chequeImage"
                label="Upload File"
                sx={{ width: '25%', ml: 2, backgroundColor: '#F7F8F9' }}
                type="file"
                onChange={handleChange}
                error={!!formErrors.chequeImage}
                helperText={formErrors.chequeImage}
                InputProps={{
                    sx: {
                        height: '45px',
                        fontSize: '13px',
                        borderRadius: 3,
                    },
                }}
                InputLabelProps={{
                    shrink: true,
                    sx: {
                        fontSize: '13px',
                        zIndex: 1,
                    },
                }}
                FormHelperTextProps={{
                    sx: {
                        fontSize: '12px',
                    },
                }}
            />
        </div>
    );
}

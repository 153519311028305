// // import React from 'react';
// import React, { Fragment } from "react";
// import { Btn, H4 } from "../AbstractElements";
// import { useForm } from "react-hook-form";
// import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap'
// import { EditProfile, Company, UsersCountryMenu, UpdateProfile, Address, EmailAddress,  CompanyDetail, Country, City, CompanyName, CompanyPrefix, GSTIN, PANNumber, CompanyTelephone, CompanyAddress, Companyincorporation, CompanyState, District, NextStep, PrevStep
//  } from '../Constant';

// const CompanyDetails = () => {
//     const { register, handleSubmit, formState: { errors } } = useForm();
//     const onEditSubmit = (data) => {
//         alert(data)
//     }
//     return (
//         <Fragment>
//             <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
//                 <CardHeader>
//                     <H4 attrH4={{ className: "card-title mb-0" }}>{CompanyDetail}</H4>
//                     <div className="card-options">
//                         <a className="card-options-collapse" href="#javascript">
//                             <i className="fe fe-chevron-up"></i>
//                         </a>
//                         <a className="card-options-remove" href="#javascript">
//                             <i className="fe fe-x"></i>
//                         </a>
//                     </div>
//                 </CardHeader>
//                 <CardBody>
//                     <Row>
//                         <Col md="6">
//                             <FormGroup className="mb-3"> <Label className="form-label">{CompanyName}</Label>
//                                 <Input className="form-control" type="text" placeholder="Company Name" {...register("company", { required: true })} /><span style={{ color: "red" }}>{errors.CompanyName && 'Company Name is required'} </span>
//                             </FormGroup>
//                         </Col>
//                         <Col md="6">
//                             <FormGroup> <Label className="form-label">{CompanyPrefix}</Label>
//                                 <Input className="form-control" type="text" placeholder="Company Prefix" {...register("Username", { required: true })} /><span style={{ color: "red" }}>{errors.ComplexPrefix && 'Complex Prefix is required'} </span>
//                             </FormGroup>
//                         </Col>
//                         <Col md="6">
//                             <FormGroup> <Label className="form-label">{EmailAddress}</Label>
//                                 <Input className="form-control" type="email" placeholder="Email" {...register("EmailAddress", { required: true })} /><span style={{ color: "red" }}>{errors.EmailAddress && 'EmailAddress is required'} </span>
//                             </FormGroup>
//                         </Col>
//                         <Col sm="6" md="6">
//                             <FormGroup><Label className="form-label">{CompanyTelephone}</Label>
//                                 <Input className="form-control" type="number" placeholder="Company Telephone" {...register("FirstName", { required: true })} /><span style={{ color: "red" }}>{errors.CompanyTelephone && 'Company Telephone is required'} </span>
//                             </FormGroup>
//                         </Col>
//                         <Col sm="6" md="6">
//                             <FormGroup><Label className="form-label">{GSTIN}</Label>
//                                 <Input className="form-control" type="text" placeholder="GSTIN" {...register("FirstName", { required: true })} /><span style={{ color: "red" }}>{errors.GSTIN && 'GSTIN is required'} </span>
//                             </FormGroup>
//                         </Col>
//                         <Col sm="6" md="6">
//                             <FormGroup><Label className="form-label">{PANNumber}</Label>
//                                 <Input className="form-control" type="text" placeholder="PAN Number" {...register("PAN Number", { required: true })} /><span style={{ color: "red" }}>{errors.PANNumber && 'PAN Number is required'} </span>
//                             </FormGroup>
//                         </Col>
//                         <Col md="12">
//                             <FormGroup><Label className="form-label">{CompanyAddress}</Label>
//                                 <Input className="form-control" type="text" placeholder="Company Full Address" {...register("Address", { required: true })} /><span style={{ color: "red" }}>{errors.CompanyAddress && ' Company Address is required'} </span>
//                             </FormGroup>
//                         </Col>

//                         <Col md="6">
//                             <FormGroup><Label className="form-label">{District}</Label>
//                                 <Input type="select" name="select" className="form-control btn-square">
//                                     {District.map((items, i) =>
//                                         <option key={i}>{items}</option>
//                                     )}
//                                 </Input>
//                             </FormGroup>
//                         </Col>
//                         <Col md="6">
//                             <FormGroup><Label className="form-label">{CompanyState}</Label>
//                                 <Input type="select" name="select" className="form-control btn-square">
//                                     {UsersCountryMenu.map((items, i) =>
//                                         <option key={i}>{items}</option>
//                                     )}
//                                 </Input>
//                             </FormGroup>
//                         </Col>

//                     </Row>
//                 </CardBody>
//                 {/* <CardFooter className="text-"> */}
//                 <div style={{display:'flex' ,justifyContent:"space-between",margin:"2rem"}}>

//                     <Btn className="text-start"  attrBtn={{ color: "primary", type: "submit" }} >{PrevStep}</Btn>
//                     {/* </CardFooter> */}
//                     {/* <CardFooter className="text-end"> */}
//                         <Btn attrBtn={{ color: "primary", type: "submit" }} >{NextStep}</Btn>
//                     {/* </CardFooter> */}
//                 </div>

//             </Form>
//         </Fragment>
//     )
// }
// export default CompanyDetails

import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Autocomplete, Box, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import { url } from '../Constant';
import { useFormData } from '../Components/FormDataProvider';
import { green } from '@mui/material/colors';

export default function CompanyDetails({ handleBack, handleNext, setValue, isUpdate, onValidate }) {
    const { updateFormData, combinedFormData } = useFormData();
    const [formValues, setFormValues] = useState((combinedFormData && combinedFormData.CompanyDetails) ? combinedFormData.CompanyDetails : {
        companyName: '',
        companyPrefix: '',
        email: '',
        otp: '',
        gstNumber: '',
        panNumber: '',
        incorporationDate: null,
        companyTelephone: '',
        companyAddress: '',
        district: '',
        state: '',
    });
    const [errors, setErrors] = useState({});
    const [otpSent, setOtpSent] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState((combinedFormData && combinedFormData.CompanyDetails) ? true : false);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
    const [otpTimer, setOtpTimer] = useState(null);
    const [resendOtp, setResendOtp] = useState(false);
    const [remainingTime, setRemainingTime] = useState(45);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        let { id, value } = e.target;
        if (id === 'companyPrefix' && value.toString().length > 3) {
            value = value.toString().slice(0, 3); // Truncate to 11 characters
        }
        if (id === 'otp' && value.toString().length > 4) {
            value = value.toString().slice(0, 4); // Truncate to 11 characters
        }
        if (id === 'gstNumber' && value.toString().length > 15) {
            value = value.toString().slice(0, 15); // Truncate to 11 characters
        }
        if (id === 'panNumber' && value.toString().length > 10) {
            value = value.toString().slice(0, 10); // Truncate to 11 characters
        }
        const updatedValues = { ...formValues, [id]: value };
        setFormValues(updatedValues);

        // Validation for specific fields
        let tempErrors = { ...errors };

        switch (id) {
            case 'companyName':
                tempErrors.companyName = value.trim() ? '' : 'Company Name is required';
                break;
            case 'companyPrefix':
                tempErrors.companyPrefix = value.trim()
                    ? (value.length !== 3 ? "3 Character Company Prefix is required" : '')
                    : true;
                break;
            case 'email':
                if (!value.trim()) {
                    tempErrors.email = true;
                } else if (!emailRegex.test(value)) {
                    tempErrors.email = 'Email is not valid';
                } else {
                    tempErrors.email = '';
                }
                break;
            case 'otp':
                tempErrors.otp = value.trim() ? true:false;
                break;
            case 'gstNumber':
                if (!gstinRegex.test(value) && value.length > 0) {
                    tempErrors.gstNumber = 'Invalid GST Number';
                } else {
                    if (value.length >= 12) {
                        const panNumber = value.substring(2, 12);
                        if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNumber)) {
                            tempErrors.panNumber = 'Invalid PAN Number';
                            tempErrors.gstNumber = 'Invalid GST Number';
                        } else {
                            tempErrors.panNumber = ''; // Clear PAN number error if valid
                            tempErrors.gstNumber = '';
                            setFormValues((prevState) => ({
                                ...prevState,
                                panNumber: panNumber // Set PAN number state
                            }));
                        }
                    } else if (value.length < 12) {
                        setFormValues((prevState) => ({
                            ...prevState,
                            panNumber: '' // Set PAN number state
                        }));
                    }
                }
                break;

            case 'panNumber':
                tempErrors.panNumber = value.trim() ? true:false;
                if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)) {
                    tempErrors.panNumber = 'Invalid PAN Number';
                }else{
                    tempErrors.panNumber = ''
                }
                break;
            case 'companyTelephone':
                if (value.trim()) {
                    if (!/^\d{10}$/.test(value)) {
                        tempErrors.companyTelephone = 'Telephone must be a 10-digit number';
                    } else {
                        tempErrors.companyTelephone = '';
                    }
                } else {
                    tempErrors.companyTelephone = true;
                }
                break;
            case 'companyAddress':
                tempErrors.companyAddress = value.trim() ? false:true;
                break;
            case 'district':
                tempErrors.district = value.trim() ? true:false;
                break;
            case 'state':
                tempErrors.state = value.trim() ? true:false;
                break;
            default:
                break;
        }

        setErrors(tempErrors);
        let isFormValid = true;
        Object.values(tempErrors).forEach(error => {
            if (error) {
                isFormValid = false;
            }
        });
        //console.log(tempErrors)
        onValidate(isFormValid && isOtpVerified ? true : false);
        if (isFormValid && isOtpVerified) {
            updateFormData('CompanyDetails', formValues);
            // handleNext();
            // setValue(1);
        }
    };

    const validate = () => {
        const tempErrors = {};
        if (!formValues.companyName) tempErrors.companyName = "Company Name is required";
        if (!formValues.companyPrefix || formValues.companyPrefix.length !== 3) tempErrors.companyPrefix = "3 Character Company Prefix is required";
        if (!formValues.email) tempErrors.email = "Email is required";
        if (!formValues.panNumber) tempErrors.panNumber = "PAN Number is required";
        if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formValues.panNumber)) tempErrors.panNumber = 'Invalid PAN Number';
        if (!formValues.companyTelephone) tempErrors.companyTelephone = "Company Telephone is required";
        if (!formValues.companyAddress) tempErrors.companyAddress = "Company Address is required";
        if (!formValues.district) tempErrors.district = "District is required";
        if (!formValues.state) tempErrors.state = "State is required";
        // if (!gstinRegex.test(formValues.gstNumber) && formValues.gstNumber.length > 0) {
        //     tempErrors.gstNumber = 'Invalid GST Number';
        // }else{
        //     if (formValues.gstNumber.length >= 12) {
        //         const panNumber = formValues.gstNumber.substring(2, 12);
        //         if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNumber)) {
        //             tempErrors.panNumber = 'Invalid PAN Number';
        //             tempErrors.gstNumber = 'Invalid GST Number';
        //         } else {
        //             tempErrors.panNumber = ''; // Clear PAN number error if valid
        //             tempErrors.gstNumber = '';
        //             setFormValues((prevState) => ({
        //                 ...prevState,
        //                 panNumber: panNumber // Set PAN number state
        //             }));
        //         }
        //     }else if (formValues.gstNumber.length < 12){
        //         setFormValues((prevState) => ({
        //             ...prevState,
        //             panNumber: '' // Set PAN number state
        //         }));
        //     }
        // }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const sendOtp = () => {
        const tempErrors = {};
        setLoading(true);

        if (!formValues.email) {
            tempErrors.email = "Email is required";
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formValues.email)) {
            tempErrors.email = "Please enter a valid email address";
        }

        setErrors(tempErrors);

        if (Object.keys(tempErrors).length > 0) {
            return false; // If there are validation errors, don't proceed
        }

        // Implement API call to send OTP
        //console.log("object")
        fetch(url + '/auth/register/send_email_otp', {
            method: 'POST',
            body: JSON.stringify({ email: formValues.email, mobile: combinedFormData.PersonalDetails.mobile }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async (response) => {
                setLoading(false);
                if (response.ok) {
                    setOtpSent(true);
                    startOtpTimer();
                    setResendOtp(true);
                    response.json().then((result)=>{
                        console.log(result.message);
                    })
                } else {
                    const data = await response.json();
                    setErrors({ ...tempErrors, email: data.message || 'Failed to send OTP' });
                }
            })
            .catch(error => {
                setErrors({ ...tempErrors, email: 'Error sending OTP' });
            });

        return true;
    };


    const startOtpTimer = () => {
        setOtpSent(true);
        setRemainingTime(45);
        const timer = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    setOtpSent(false);
                    return 45;
                }
                return prevTime - 1;
            });
        }, 1000);
        setOtpTimer(timer);
    };

    const verifyOtp = () => {
        // Implement API call to verify OTP
        setLoading(true);
        fetch(url + '/auth/register/verify_email_otp', {
            method: 'POST',
            body: JSON.stringify({ email: formValues.email, otp: formValues.otp }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                //console.log(response)
                setLoading(false);
                if (response.ok) {
                    setErrors({ ...errors, otp: '' });
                    setIsOtpVerified(true);
                    let isFormValid = true;
                    Object.values(errors).forEach(error => {
                        if (error) {
                            isFormValid = false;
                        }
                    });
                    //console.log(errors)
                    onValidate(isFormValid ? true : false);
                    if (isFormValid) {
                        updateFormData('CompanyDetails', formValues);
                        // handleNext();
                        // setValue(1);
                    }
                } else {
                    return response.json().then(data => {
                        setErrors({ ...errors, otp: data.message || "Invalid OTP" });
                    });
                }
            })
            .catch(error => {
                setErrors({ ...errors, otp: "Error verifying OTP" });
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // handleNext();
        if (validate()) {
            if (isOtpVerified) {
                updateFormData('CompanyDetails', formValues);
                // handleNext();
                setValue(2)
            } else {
                setErrors({ ...errors, otp: "Please Verify Otp" });
            }
        }
    };

    return (
        <div style={{ opacity: 1 }}>
            <Card  sx={{ boxShadow: "0px 0px 0px 0px lightGray", borderRadius:"25px", borderTopRightRadius:"0px", borderTopLeftRadius:"0px", marginTop:"1vh", background:"transparent"}}>
                <CardContent>
                    {/* <div style={{ display: "flex", alignItems:"center" }}>
                        <div style={{
                            width: "1.8em", height: "1.8em", justifyContent: "center", display: "flex",marginTop:-10,
                            alignItems: "center", borderRadius: "50%", border: "2px solid #a0a0a0", marginRight: "0.9vw"
                        }}>
                            <span style={{ fontSize: "1em", color:"#a0a0a0" }}>2</span>
                        </div>
                        <Typography gutterBottom variant="h5" component="div" fontStyle={"Roboto,sans-serif"} color='#a0a0a0' display={"flex"}>
                            Company Details
                        </Typography>
                    </div> */}
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                        style={{ marginTop: "0vh", padding: "", paddingTop: "0vh" }}
                    >
                        <div style={{ display: "flex" }}>
                            <TextField
                                id="companyName"
                                label="Company Name"
                                value={formValues.companyName}
                                onChange={handleChange}
                                error={!!errors.companyName}
                                // helperText={errors.companyName}
                                required
                                fullWidth
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", marginTop: "1vh" }}>
                            <TextField
                                id="companyPrefix"
                                label="Company Prefix"
                                value={formValues.companyPrefix}
                                onChange={handleChange}
                                error={!!errors.companyPrefix || errors.companyPrefix == true}
                                helperText={errors.companyPrefix}
                                required
                                style={{ marginLeft: "" }}
                                fullWidth
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                            <TextField
                                error={!!errors.incorporationDate || errors.incorporationDate == true}
                                fullWidth
                                type="date"
                                id="incorporationDate"
                                label="Company Incorporation Date"
                                value={formValues.incorporationDate}
                                onChange={handleChange}
                                // helperText={errors.incorporationDate}
                                required
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 3,
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    },
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    },
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", marginTop: "1vh" }}>
                            <div style={{ width: "97%", display: "flex", flexDirection: "column" }}>
                                <TextField
                                    id="email"
                                    label="Email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    error={!!errors.email || errors.email == true}
                                    helperText={errors.email}
                                    required
                                    disabled={isOtpVerified}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '0.6vw', // Adjust the label font size as needed
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            fontSize: '0.5vw', // Adjust the helper text font size as needed
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {!loading && <Button
                                                    variant={"success"}
                                                    style={{ cursor: "pointer", borderColor: "white", fontSize: "0.5vw", borderRadius: 15,padding:3 }}
                                                    onClick={sendOtp}
                                                    disabled={otpSent || !emailRegex.test(formValues.email) || isOtpVerified}
                                                >
                                                    Send OTP
                                                </Button>}
                                                {loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            color: green[500],
                                                        }}
                                                    />
                                                )}
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            height: '45px', // Adjust the height as needed
                                            fontSize: '0.6vw', // Adjust the font size as needed
                                            borderRadius: 30,
                                            opacity:1,
                                            backgroundColor:"#fff",
                                            width: "99%"
                                        }
                                    }}
                                    fullWidth
                                />
                                {otpSent && !isOtpVerified && <h6 style={{ alignSelf: "flex-end", color: "gray", fontSize: 13 }}>
                                    {otpSent ? `Resend OTP in: ${remainingTime}s` : 'Resend OTP'}
                                </h6>}
                            </div>
                            {(otpSent || resendOtp || isOtpVerified) && <TextField
                                id="otp"
                                label="OTP"
                                value={formValues.otp}
                                onChange={handleChange}
                                error={!!errors.otp || errors.otp == true}
                                helperText={errors.otp}
                                disabled={isOtpVerified}
                                required
                                type='number'
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                                style={{ marginLeft: "1vw" }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {!loading && <Button
                                                variant={"success"}
                                                disabled={formValues.otp.length < 4 || isOtpVerified}
                                                style={{ cursor: "pointer", borderColor: "white", fontSize: "0.5vw", borderRadius: 15,padding:3 }}
                                                onClick={verifyOtp}
                                            >
                                                Verify OTP
                                            </Button>}
                                            {loading && otpSent && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: green[500],
                                                    }}
                                                />
                                            )}
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff",
                                        width: "99.5%"
                                    }
                                }}
                                fullWidth
                            />}
                        </div>
                        <div style={{ display: "flex", marginTop: "1vh", width: "100%" }}>
                            <TextField
                                id="gstNumber"
                                label="GSTIN"
                                value={formValues.gstNumber}
                                onChange={handleChange}
                                error={!!errors.gstNumber || errors.gstNumber == true}
                                helperText={errors.gstNumber}
                                fullWidth
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                            <TextField
                                id="panNumber"
                                label="PAN Number"
                                value={formValues.panNumber}
                                onChange={handleChange}
                                error={!!errors.panNumber || errors.panNumber == true}
                                helperText={errors.panNumber}
                                required
                                fullWidth
                                style={{ marginLeft: "1vw" }}
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />


                        </div>
                        <div style={{ display: "flex", marginTop: "1vh" }}>
                            <TextField
                                id="companyTelephone"
                                label="Company Telephone"
                                value={formValues.companyTelephone}
                                onChange={handleChange}
                                error={!!errors.companyTelephone || errors.companyTelephone == true}
                                helperText={errors.companyTelephone}
                                required
                                fullWidth
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                            <TextField
                                id="companyAddress"
                                label="Company Full Address"
                                value={formValues.companyAddress}
                                onChange={handleChange}
                                error={!!errors.companyAddress || errors.companyAddress == true}
                                helperText={errors.companyAddress}
                                required
                                fullWidth
                                style={{ marginLeft: "1vw" }}
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", marginTop: "1vh" }}>
                            <Autocomplete
                                id="district"
                                options={["Raipur", "Ambikapur", "Raigarh", "Jagdalpur", "Durg"]}
                                value={formValues.district}
                                fullWidth
                                onChange={(event, newValue) => setFormValues({ ...formValues, district: newValue })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="District"
                                        error={!!errors.district || errors.district == true}
                                        helperText={errors.district}
                                        fullWidth
                                        InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '0.6vw', // Adjust the font size as needed
                                                borderRadius: 30,
                                                opacity:1,
                                                backgroundColor:"#fff"
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '0.6vw', // Adjust the label font size as needed
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '0.5vw', // Adjust the helper text font size as needed
                                            }
                                        }}
                                    />
                                )}
                                required
                            />
                            <Autocomplete
                                id="state"
                                options={["Chhatisgarh", "Madhya Pradesh"]}
                                value={formValues.state}
                                fullWidth
                                sx={{width:"97%"}}
                                onChange={(event, newValue) => setFormValues({ ...formValues, state: newValue })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{width:"95%"}}
                                        label="State"
                                        error={!!errors.state || errors.state == true}
                                        helperText={errors.state}
                                        fullWidth
                                        InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                                height: '45px', // Adjust the height as needed
                                                fontSize: '0.6vw', // Adjust the font size as needed
                                                borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                            }
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '0.6vw', // Adjust the label font size as needed
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                fontSize: '0.5vw', // Adjust the helper text font size as needed
                                            }
                                        }}
                                    />
                                )}
                                style={{ marginLeft: "0.9vw", marginRight: 8 }}
                                required
                            />
                        </div>
                    </Box>
                </CardContent>
                {/* {!isUpdate && */}
                    {/* <div style={{ display: "flex", justifyContent: "center", marginBottom: "2vh" }}> */}
                        {/* <Button onClick={() => { setValue(0) }} type="submit" style={{ marginTop: "2vh", backgroundColor: "blue", borderColor: "green", color: "white", alignSelf: "end" }}>
                        Previous Step
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        type="submit"
                        // disabled={!isOtpVerified}
                        style={{ marginTop: "2vh", backgroundColor: "green", borderColor: "green", color: "white", marginLeft: 50 }}
                    >
                        Next Step
                    </Button> */}
                    {/* </div> */}
                    {/* } */}
            </Card>
        </div>
    );
}

import React from 'react'
import FormProvider from './Provider/FormProvider'
import Bookings from './Bookings'

export default function BookingIndex() {
  return (
    <FormProvider>
        <Bookings />
    </FormProvider>
  )
}

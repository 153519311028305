import React, { useState, useEffect } from 'react';
import { url } from '../../../Constant';
import { Breadcrumbs } from '../../../AbstractElements';
import logo from "../../../assets/images/logo/logo-icon.png";
import UserDefault from "../../../assets/images/user/4.jpg";
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export default function CustomerProfile() {
  const [formData, setFormData] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get('id');
  const navigate = useNavigate()

  useEffect(() => {
    fetchUserData()
  }, [])

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${url}/api/admin/getCustomerById?cid=${userId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch user data');

      const userData = await response.json();
      console.log(userData)
      setFormData(userData);
    } catch (error) {
      // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch user data', life: 3000 });
    }
  };
  return (
    <div>
      <Breadcrumbs mainTitle="Customer Profile" parent="Setup" title="Profile" subParent="Customer" />
      <div className="col-md-10 offset-md-1" style={{ backgroundColor: "#fff", borderRadius: 25, paddingTop: "1%", paddingBottom: "1%" }}>
        <div style={{ marginLeft: "5%", marginRight: "5%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <div className="d-flex align-items-center">
              <img src={logo} />
              <div>
                <h5 style={{ color: "#1778b0", marginTop: "8px", marginLeft: "8px" }}>{formData?.contactPerson}</h5>
                <h6 style={{ color: "#A0A0A0", marginTop: "5px", marginLeft: "8px" }}><span style={{ color: "#A0A0A0" }}>{formData?.prefix + ". " + "    " + formData?.cname}</span></h6>
              </div>
            </div>

          </div>
          {/* <div className="d-flex align-items-center">
            <img src={UserDefault} />
            <h5 style={{ color: "#1778b0", marginTop:"8px", marginLeft:"8px" }}>{formData?.prefix + ". " + "    " + formData?.firstName + " " + formData?.middleName + " " + formData?.lastName}</h5>
          </div> */}
          <div className="d-flex align-items-center">
            {formData?.status == "Active" ?
              <div style={{ width: "10px", height: "10px", backgroundColor: "green", borderRadius: "5px" }}></div>
              :
              <div style={{ color: "red", fontWeight: "bold" }}>X</div>
            }
            <h5 style={{ color: formData?.status == "Active" ? "green" : "red", marginTop: "8px", marginLeft: "8px" }}>{formData?.status}</h5>
          </div>
        </div>
      </div>
      <div className="col-md-10 offset-md-1" style={{ backgroundColor: "#fff", borderRadius: 25, paddingTop: "2%", paddingBottom: "2%", marginTop: "2%" }}>
        <div className="profile_row">
          <div className='col-md-4'>
            <h6 className='profile_field_heading' >Contact Person :</h6>
            <h5 className='profile_field_value' >{formData?.contactPerson}</h5>
          </div>
          <div className='col-md-4'>
            <h6 className='profile_field_heading' >Mobile :</h6>
            <h5 className='profile_field_value' >{formData?.phone}</h5>
          </div>
          <div className='col-md-4'>
            <h6 className='profile_field_heading' >Email :</h6>
            <h5 className='profile_field_value' >{formData?.email} SDHF DFBGD</h5>
          </div>
          {/* <div>
            <h5 style={{ color: "#1778b0", marginTop: "5px", marginLeft: "5px" }}>Role :</h5>
            <h5 style={{ color: "#A0A0A0", marginTop: "15px", marginLeft: "2rem" }}>{formData?.role || "ADMIN"}</h5>
          </div> */}
        </div>
        <div className="profile_row" style={{ marginTop: "50px" }}>
          <div className='col-md-4'>
            <h6 className='profile_field_heading' >City :</h6>
            <h5 className='profile_field_value' >{formData?.city}</h5>
          </div>
          <div className='col-md-4'>
            <h6 className='profile_field_heading' >Address : </h6>
            <h5 className='profile_field_value' >{formData?.address}</h5>
          </div>
          <div className='col-md-4'>
            <h6 className='profile_field_heading' ></h6>
            <h5 className='profile_field_value' ></h5>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end" style={{ marginRight: "8.2%", marginTop: "1%" }}>
        <Button
          severity="primary"
          style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }}
          color="primary"
          onClick={() =>
            navigate("/panel/customer/edit?id=" + userId)}
        >
          Update Profile
        </Button>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { H5, UL, LI } from '../../../AbstractElements';
import { PaidCompanies, TotalCompanies, OverallBalanceTitle, url } from '../../../Constant';
import ReactApexChart from 'react-apexcharts';
import { CurrencyChartData } from '../../../Data/DefaultDashboard/Chart';

const OverallBalance = () => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const [chartData, setChartData] = useState({
    series: [], 
    options: {}
  });

  async function fetchMonthlyCompanyCount() {
    try {
      const response = await fetch(url + '/api/sup/dashboard/monthly-company-count');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      let data = await response.json();
      data = data?.result;

      let totalCompanies = data?.map((item) => item.totalCompanies);
      let paidCompanies = data?.map((item) => item.paidCompanies);

      let xaxisEles = data?.map((item) => {
        let year = item?._id?.year;
        let month = item?._id?.month;
        return months[month - 1] + " " + year;
      });

      const updatedChartData = {
        series: [
          { name: 'Total Companies', data: paidCompanies || [] },
          { name: 'Paid Companies', data: totalCompanies || [] }
        ],
        options: {
          ...CurrencyChartData.options,
          xaxis: {
            categories: xaxisEles || []
          }
        }
      };

      setChartData(updatedChartData);
    } catch (error) {
      console.error('Error fetching monthly company count:', error);
    }
  }

  useEffect(() => {
    fetchMonthlyCompanyCount();
  }, []);

  return (
    <Col xxl='8' lg='12' className='box-col-12'>
      <Card>
        <CardHeader className='card-no-border'>
          <H5>{OverallBalanceTitle}</H5>
        </CardHeader>
        <CardBody className='pt-0'>
          <Row className='m-0 overall-card'>
            <Col xl='12' md='12' sm='8' className='p-0'>
              <div className='chart-right'>
                <Row>
                  <Col xl='12' className='col-xl-12'>
                    <CardBody className='p-0'>
                      <UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
                        <LI>
                          <span className='circle bg-warning'> </span>
                          <span className='f-light ms-1'>{TotalCompanies}</span>
                        </LI>
                        <LI>
                          <span className='circle bg-primary'> </span>
                          <span className='f-light ms-1'>{PaidCompanies}</span>
                        </LI>
                      </UL>
                      <div className='current-sale-container'>
                        {chartData.series.length > 0 && chartData.options.xaxis ? (
                          <ReactApexChart 
                            type='bar' 
                            height={300} 
                            options={chartData.options} 
                            series={chartData.series} 
                          />
                        ) : (
                          <p>Loading chart...</p>
                        )}
                      </div>
                    </CardBody>
                  </Col>
                </Row>
              </div>
            </Col>
            {/* <LightCard LightCardData={LightCardData} /> */}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default OverallBalance;

import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function CustomPagination({
  currentPage,
  totalPages,
  onPageChange,
  onPageSizeChange,
  pageSize,
  setLoader
}) {
  // Handle page change
  const handlePageChange = (event, page) => {
    // if(!event) return;

    //console.log(page ,"   ******   ", currentPage)
    setLoader(true);
    // Avoid unnecessary updates if the page is already selected
    if (page !== currentPage) {
      setLoader(false);
      onPageChange(page); // Notify parent component of the page change
    }
    else{
      setLoader(false);
      //console.log("Page same as current page")
      // Do nothing if page is same as current page
    }
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setLoader(true);
    onPageSizeChange(event); // Notify parent component of the page size change
  };

  return (
    <div style={{ marginTop: '1rem', marginBottom: '1rem', textAlign: 'center' }}>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
        <Pagination
          count={totalPages}
          page={currentPage}
          variant="outlined"
          color="primary"
          onChange={handlePageChange}
        />
        {/* <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
          <InputLabel>Items per Page</InputLabel>
          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            label="Items per Page"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl> */}
            {/* <MenuItem value={1000}>All</MenuItem> */}
      </Stack>
    </div>
  );
}

import React, { Fragment,useRef, useState, useEffect, useContext } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, H6, P } from '../../../AbstractElements';
import { EmailAddress, CompanyCode, ForgotPassword, LoginWithJWT, LoginWithOTP, Password, RememberPassword, SignIn, url, Username } from '../../../Constant';
import { useNavigate } from 'react-router-dom';
import CustomizerContext from '../../../_helper/Customizer';
import OtherWay from './OtherWay';
import TooltipForm from '../../../Components/Forms/FormControl/TooltipForm';
import { InputGroup, InputGroupText } from 'reactstrap';
import { Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Toast } from 'primereact/toast';


const LoginTab = ({ selected }) => {
  const toast = useRef(null);
  const [formValues, setFormValues] = useState({
    companyCode: '',
    userName: '',
    password: '',
    otp: ''
  });

  const [errors, setErrors] = useState({
    companyCode: '',
    userName: '',
    password: '',
    otp: ''
  });

  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerificationError, setOtpVerificationError] = useState('');

  const [isCompanyCodeVerified, setIsCompanyCodeVerified] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);

  const [canResendOTP, setCanResendOTP] = useState(true);
  const [resendCountdown, setResendCountdown] = useState(0);

  const navigate = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  useEffect(() => {
    let timer;
    if (!canResendOTP && resendCountdown > 0) {
      timer = setInterval(() => {
        setResendCountdown(prev => prev - 1);
      }, 1000);
    } else if (resendCountdown === 0) {
      setCanResendOTP(true); // Enable the resend button
    }
    return () => clearInterval(timer);
  }, [canResendOTP, resendCountdown]);

  const formatCountdown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileRegex = /^\d{10}$/;
    return emailRegex.test(email) || mobileRegex.test(email);
  };

  const validate = () => {
    const isOtpRequired = selected === 'loginWithOTP' && otpSent;
    const isPasswordRequired = selected === 'simpleLogin' || (selected === 'loginWithOTP' && !otpSent);
  
    const newErrors = {
      companyCode: formValues.companyCode === '' ? 'Company Code is required' : '',
      userName: formValues.userName === '' ? 'User Name is required' : (!validateEmail(formValues.userName) ? 'Invalid User Name' : ''),
      password: isPasswordRequired && formValues.password === '' ? 'Password is required' : '',
      otp: isOtpRequired && formValues.otp === '' ? 'OTP is required' : '' // OTP required if OTP has been sent
    };
  
    setErrors(newErrors);
  
    return !Object.values(newErrors).some((error) => error !== '');
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'companyCode' && value.length > 9) {
      return;
    }

    if (id === 'otp' && value.length > 4) {
      return;
    }

    setFormValues({
      ...formValues,
      [id]: value
    });

    // Clear error message for the changed field
    setErrors({
      ...errors,
      [id]: value.trim() === '' ? `${id=="otp"?id.toUpperCase():id} is required` : ''
    });

    // Special case for company code and userName
    if (id === 'userName') {
      setErrors(prevErrors => ({
        ...prevErrors,
        userName: !validateEmail(value) ? 'Invalid User Name' : ''
      }));
    }

    if (id === 'companyCode') {
      setErrors(prevErrors => ({
        ...prevErrors,
        companyCode: value.trim() === '' ? 'Company Code is required' : ''
      }));
      handleCompanyCodeChange(value);
    }

    if (id === 'otp') {
      setOtpVerificationError('');
    }
  };

  const handleCompanyCodeChange = (val) => {
    setCompanyName("Invalid Company Code");
    setIsCompanyCodeVerified(false);
    if (val.length === 9) {
      fetch(`${url}/auth/login/verify_company_code?company_code=${val}`)
        .then((response) => {
          if (response.status === 200) {
            response.json()
              .then((data) => {
                setIsCompanyCodeVerified(true);
                setCompanyName(data || "Company verified");
              })
              .catch((error) => {
                setCompanyName("Invalid Company Code");
                setIsCompanyCodeVerified(false);
                console.error('Error:', error);
              });
          } else {
            setCompanyName("Invalid Company Code");
            setIsCompanyCodeVerified(false);
          }
        }).catch((error) => {
          console.error('Error:', error);
          setCompanyName("Invalid Company Code");
          setIsCompanyCodeVerified(false);
        });
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${url}/auth/login/userLogin`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          companyCode: formValues.companyCode,
          userName: formValues.userName,
          password: formValues.password
        })
      });
      const result = await res.json();
      //console.log(result)
      if (res.ok) {
        //console.log("Aman--------------------")
        localStorage.setItem('login', JSON.stringify(true));
        localStorage.setItem('token', result.token);
        localStorage.setItem('role', result.role);
        localStorage.setItem('name', result.name);
        navigate(`/panel/dashboard`);
      }
      else if(res.status === 401){
        if(result.error==="Company Inactive"){
        toast.current.show({ severity: 'error', summary: 'Error', detail: result.error, life: 3000 });
        }
        else if(result.error==="User Inactive"){
        toast.current.show({ severity: 'error', summary: 'Error', detail: result.error, life: 3000 });
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          userName: result.error === "Invalid username" ? "Invalid User Name" : prevErrors.userName,
          password: result.error === "Invalid password" ? "Invalid Password" : prevErrors.password
        }));
      }
      // else {
      // }
    } catch (e) {
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const validateForOTP = () => {
    const newErrors = {
      companyCode: formValues.companyCode === '' ? 'Company Code is required' : '',
      userName: formValues.userName === '' ? 'User Name is required' : (!validateEmail(formValues.userName) ? 'Invalid User Name' : ''),
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error !== '');
  };

  const handleSendOTP = async () => {
    if (!validateForOTP() || !isCompanyCodeVerified) {
      return;
    }
    setLoading(true);
    try {
      const res = await fetch(`${url}/auth/login/sendLoginOTP`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ companyCode: formValues.companyCode, userName: formValues.userName })
      });
      const result = await res.json();
      if (res.ok) {
        setErrors(prevErrors => ({
          ...prevErrors,
          otp: ''
        }));
        setOtpSent(true);
        setOtpVerificationError("")
        setCanResendOTP(false); // Disable the resend button
        setResendCountdown(45); // Start countdown
      } 
      else if(res.status === 401){
        if(result.error==="User Inactive"){
        toast.current.show({ severity: 'error', summary: 'Error', detail: result.error, life: 3000 });
        }
        else if(result.error==="Company Inactive"){
        toast.current.show({ severity: 'error', summary: 'Error', detail: result.error, life: 3000 });
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          userName: result.error === "Invalid username" ? "Invalid User Name" : prevErrors.userName,
        }));
        setOtpVerificationError(result.error || 'Failed to send OTP');
      }
      }
      catch (e) {
      console.error('Error sending OTP:', e.message);
      setOtpVerificationError('Failed to send OTP');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async () => {
    setOtpVerificationError('');
    setLoading(true);
    try {
      const res = await fetch(`${url}/auth/login/verifyOTP`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          companyCode: formValues.companyCode,
          userName: formValues.userName,
          otp: formValues.otp
        })
      });
      const result = await res.json();
      if (res.ok) {
        localStorage.setItem('login', JSON.stringify(true));
        localStorage.setItem('token', result.token);
        localStorage.setItem('role', result.role);
        localStorage.setItem('name', result.name);
        navigate(`/panel/dashboard`);
      }
      else if(res.status === 401){
        if(result.error==="Company Inactive"){
        toast.current.show({ severity: 'error', summary: 'Error', detail: result.error, life: 3000 });
        }
        else if(result.error==="User Inactive"){
        toast.current.show({ severity: 'error', summary: 'Error', detail: result.error, life: 3000 });
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          userName: result.error === "Invalid username" ? "Invalid User Name" : prevErrors.userName,
          otp: result.error === "Invalid OTP" ? "Invalid OTP" : prevErrors.otp,
        }));

      }
      else {
        setOtpVerificationError(result.error || 'Invalid OTP');
      }
    } catch (e) {
      setOtpVerificationError('Failed to verify OTP');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (selected === 'simpleLogin') {
        handleLogin();
      } else if (selected === 'loginWithOTP' && otpSent) {
        handleVerifyOTP();
      } else if (selected === 'loginWithOTP' && !otpSent) {
        handleSendOTP();
      }
    }
  };

  return (
    <Fragment>
      <Form className='form-bookmark needs-validation'>
      <Toast ref={toast} />
        <H4>{selected === 'simpleLogin' ? 'Sign in to account' : 'Sign In With OTP'}</H4>
        <P>{selected === 'simpleLogin' ? 'Enter your email & password to login' : 'Enter your details to login using OTP'}</P>
        {/* Company Code Field */}
        <FormGroup>
          <Label className='col-form-label'>{CompanyCode}</Label>
          <Input
            className={(errors.companyCode !== "" || companyName === "Invalid Company Code") ? 'form-control is-invalid' : 'form-control'}
            type='text'
            id='companyCode'
            value={formValues.companyCode}
            onChange={handleChange}
          />
          {errors.companyCode ? <span style={{ fontSize: "15px" }} className="text-danger">{errors.companyCode}</span> : <span style={{ fontSize: "15px" }} className={isCompanyCodeVerified ? "text-success" : "text-danger"}>{companyName}</span>}
        </FormGroup>
        {/* User Name Field */}
        <FormGroup>
          <Label className='col-form-label'>{Username}</Label>
          <Input
            className={errors.userName === "" ? 'form-control' : 'form-control is-invalid'}
            type='email'
            id='userName'
            value={formValues.userName}
            onChange={handleChange}
          />
          <span style={{ fontSize: "15px" }} className="text-danger">{errors.userName}</span>
        </FormGroup>
        {/* OTP Field (conditional rendering) */}
        {selected === 'loginWithOTP' && otpSent && (
          <FormGroup>
            <Label className='col-form-label'>OTP</Label>
            <Input
              className={(errors.otp !== "" || otpVerificationError) ? 'form-control is-invalid' : 'form-control'}
              type='text'
              id='otp'
              value={formValues.otp}
              onChange={handleChange}
            />
            <span className="text-danger">{errors.otp || otpVerificationError}</span>
          </FormGroup>
        )}
        {/* Password Field (conditional rendering) */}
        {selected === 'simpleLogin' && (
          <>
            <Label>{Password}</Label>
            <InputGroup>
            <Input className={errors.password === "" ? 'form-control' : 'form-control is-invalid'} id='password' value={formValues.password} onChange={handleChange} type={togglePassword?"text":"password"} placeholder=""  aria-label="Recipient's username" />
            <InputGroupText className='p-0 m-0'>
            <Button
              className='my-auto'
              variant="contained"
              disableElevation
              onClick={() => setTogglePassword(!togglePassword)}
              startIcon={togglePassword ? <VisibilityOff /> : <Visibility />}
              style={{
                backgroundColor: 'transparent',
                color: 'inherit', // Inherits the color from the parent or default text color
                paddingRight: 0,
                minWidth: 'auto',
                height:"auto",
                boxShadow: 'none',
                border: "none",
                '&:hover': {
                  backgroundColor: 'transparent',
                }
              }}
            />
          
          </InputGroupText>
        </InputGroup>
        <span style={{ fontSize: "15px" }} className="text-danger">{errors.password}</span>
          {/* // <FormGroup className='position-relative'>
          //   <Label className='col-form-label'>{Password}</Label>
          //   <div className='position-relative'>
          //     <Input
          //       className={errors.password === "" ? 'form-control' : 'form-control is-invalid'}
          //       type={togglePassword ? 'text' : 'password'}
          //       id='password'
          //       value={formValues.password}
          //       onChange={handleChange}
          //     />
          //     <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
          //       <span className={togglePassword ? '' : 'show'}></span>
          //     </div>
          //   </div>
          //   <span style={{ fontSize: "15px" }} className="text-danger">{errors.password}</span>
          // </FormGroup> */}
          </>
        )}
        {/* Buttons */}
        <div className='form-group mb-3'>
          {selected === "simpleLogin" && (
            <Label style={{ marginBottom: "4%",marginTop:"4%" }}>
              <a className='link' href='#javascript'>{ForgotPassword}</a>
            </Label>
          )}
          {selected === 'loginWithOTP' && (
            <div className="mt-2 mb-2 text-center">
              {!otpSent && (
                <Btn
                  attrBtn={{
                    color: 'primary',
                    onClick: handleSendOTP,
                    disabled: loading
                  }}
                >
                  Send OTP
                </Btn>
              )}
              {otpSent && canResendOTP && (
                <Btn
                  attrBtn={{
                    color: 'primary',
                    onClick: handleSendOTP,
                    disabled: loading
                  }}
                >
                  Resend OTP
                </Btn>
              )}
              {otpSent && !canResendOTP && (
                <p className="text-muted text-center">
                  {`You can resend OTP in ${formatCountdown(resendCountdown)}`}
                </p>
              )}
            </div>
          )}
          {(selected!=="loginWithOTP"||otpSent)&&(
          <Btn
            
            attrBtn={{
              disabled: (selected === "loginWithOTP" && !otpSent),
              color: 'primary',
              className: 'd-block w-100 mt-2',
              type:"submit",
              onClick: handleSubmit
            }}
          >
            {selected === 'simpleLogin' ? SignIn : 'Verify and Continue'}
          </Btn>)}
        </div>
        <OtherWay />
      </Form>
    </Fragment>
  );
};

export default LoginTab;

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { url } from '../../../_helper/Route/Url';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './style.css'
import AddMenu from './AddMenu';
import SubMenu from './SubMenu';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import IonIcon from '@reacticons/ionicons';
import useFetchMenus from '../../Layout/Sidebar/Menu';

export default function MenuTable() {
    const [products, setProducts] = useState([]);
    const toast = useRef(null);
    //console.log("2635545")
    const fetchMenus = useFetchMenus();
    //console.log("abcdefghi")
    const [rows, setRows] = useState([])
    const [render, setRender] = useState(false);
    const [open, setopen] = useState(false);
    const [openId, setOpenId] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        menuName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        menuRouteName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        sequence: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        menuType: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        id: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        //console.log(e.target.value)
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const refreshData = () => {
        setRender(!render)
    }

    useEffect(() => {
        fetchAllMenus()
        fetchMenus()
    }, [render, setRender])

    const fetchAllMenus = () => {
        fetch(url + '/api/menus/getAll').then((response) => {
            if (response.ok) {
                response.json().then((result) => {
                    setRows(result)
                    setProducts(result)
                    //console.log(result, "****************************************************************")
                })
            }
        })
    }
    const confirmDelete = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDelete(id),
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptClassName: "custom-accept-button",
            rejectClassName: "custom-reject-button"
        });
    }

    const handleDelete = async (id) => {

        try {
            fetch(`${url}/api/sup/menu/delete?id=${id}`, {
                method: 'DELETE',
            }).then((response) => {

                setRender(!render)
                refreshData()
                toast.current.show({ severity: 'success', summary: 'Menu Deleted Successfully!!', life: 3000 });
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Failed to delete Menu', life: 3000 });
            })
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Failed to delete Menu', life: 3000 });
        }
    };

    const actionsBodyTemplate = (rowData) => {
        return (
            <div className="actions-buttons">
                <AddMenu refreshData={refreshData} isUpdate={rowData.id} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-text p-button-danger"
                    onClick={() => confirmDelete(rowData.id)}
                    style={{ width: "20px", height: "20px", fontSize: "5px" }}
                    aria-label="Delete"
                />
            </div>
        );
    };

    const confirmStatusChange = (id, status) => {
        confirmDialog({
            message: `Are you sure you want to make it ${status ? "Active" : "Inactive"}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => changeStatus(id, status),
        });
    }

    const changeStatus = async (id, status) => {
        try {
            const response = await fetch(url + "/api/sup/menu/status/update?id=" + id, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({ status: status }),
            });
            if (!response.ok) {
                toast.current.show({ severity: 'error', summary: 'Failed to change status', life: 3000 })
            } else {

                refreshData();
                toast.current.show({ severity: 'success', summary: 'Menu Status Changed Successfully!!', life: 3000 })
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Failed to change status', life: 3000 })
        }
    }


    const statusBodyTemplate = (rowData) => {
        return <Tag onClick={() => confirmStatusChange(rowData.id, !rowData.status)} value={rowData.status || rowData.subMenuStatus ? "Active" : "Inactive"} severity={getMenuStatus(rowData)} style={{ fontSize: "12px", padding: "2px", cursor: "pointer", backgroundColor: rowData.status || rowData.subMenuStatus ? "green" : "red" }}></Tag>;
    };

    const getMenuStatus = (product) => {
        switch (product.status || product.subMenuStatus) {
            case true:
                return 'Active';

            case false:
                return 'Inactive';

            default:
                return null;
        }
    };

    const modelOpenerBodyTemplate = (rowData) => {
        const isOpen = openId === rowData.id; // Check if this row's modal should be open
    
        return (
            rowData?.subMenus?.length > 0 ? (
                <>
                    <Button 
                        icon="pi pi-eye" 
                        className="p-button-rounded p-button-text p-button-info"
                        onClick={() => setOpenId(isOpen ? null : rowData.id)} // Toggle modal for this row
                        style={{ fontSize: "10px", marginRight: "10px", padding: "0px", width: "20px", height: "20px", color: "#0b0ff1c7" }}
                        text 
                    />
    
                    <Dialog
                        visible={isOpen}
                        onHide={() => setOpenId(null)} // Close modal
                        style={{ minWidth: "58vw" }}
                        header="Sub-Menus"
                    >
                        <SubMenu subMenus={rowData.subMenus} id={rowData.id} />
                    </Dialog>
                </>
            ) : <IonIcon name='remove-outline' />
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2" style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
            <AddMenu refreshData={refreshData} />
            {/* <Button icon="pi pi-refresh" rounded raised /> */}
            <>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </IconField>
            </>
        </div>
    );

    return (
        <div className="card">
            <Toast ref={toast} />
            <DataTable
                value={rows}
                dataKey="id"
                header={header}
                filters={filters}
                globalFilterFields={["id", "menuName", "menuRoutename", "sequence", 'status', "menuType"]}
                removableSort
                tableStyle={{ minWidth: '60rem' }} 
                paginator 
                rows={5} 
                rowsPerPageOptions={[5, 10, 25, 50]}
                style={{paddingLeft:"20px", paddingRight:"20px"}}
                rowClassName={() => 'datatable-row'}>
                <Column field="id" header="Id" sortable style={{ fontSize: "14px", color: "black" }} />
                <Column field="menuType" header="Menu Type" style={{ fontSize: "14px", color: "black" }} />
                <Column field="menuName" header="Menu Name" style={{ fontSize: "14px", color: "black" }} />
                <Column field="menuRoutename" header="Menu Route" style={{ fontSize: "14px", color: "black" }} />
                <Column field="status" header="Status" body={statusBodyTemplate} style={{ fontSize: "14px", color: "black" }} />
                <Column field="sequence" header="Sequence" sortable style={{ fontSize: "14px", color: "black" }} />
                <Column header="Sub-Menus" body={modelOpenerBodyTemplate} style={{ fontSize: "14px", color: "black" }} />
                <Column field="inventoryStatus" header="Actions" body={actionsBodyTemplate} style={{ fontSize: "14px", color: "black" }} />
            </DataTable>
            <ConfirmDialog />
        </div>
    );
}

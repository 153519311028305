import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React, { useState } from 'react'
import SecurityCheque from './Components/SecurityCheque/SecurityCheque'
import AdvancePayment from './Components/AdvancePayment'

export default function PaymentSection() {
  const [paymentType, setPaymentType] = useState()
  return (
    <div style={{ marginTop: 30, marginBottom: 30 }}>
      <div style={{ width: "100%", height: "auto", paddingLeft: "2%", paddingRight: "2%", paddingTop: "2%", paddingBottom: "2%", backgroundColor: "#fff", borderRadius: 30 }}>
        <h5>Payment Info</h5>
        <div className="row" style={{ marginTop: 20 }}>
          <FormControl required component="fieldset" sx={{ ml: 2 }}>
            <RadioGroup
              name="status"
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)} // Handle change
              sx={{
                flexDirection: 'row', // To display radio buttons horizontally
                mt: 1,
              }}
            >
              <FormControlLabel
                value="SecurityCheque"
                control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                label="Security-Cheque"
                sx={{ fontSize: '13px' }}
              />
              <FormControlLabel
                value="AdvancePayment"
                control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                label="Advance-Payment"
                sx={{ fontSize: '13px' }}
              />
              <FormControlLabel
                value="SecurityChequeAndAdvancePayment"
                control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                label="Security-Cheque/Advance-Payment"
                sx={{ fontSize: '13px' }}
              />
            </RadioGroup>
          </FormControl>
        </div>
        {paymentType === "SecurityCheque" && <div className="row" style={{ marginTop: 20 }}>
          <SecurityCheque type={"SecurityCheque"} />
        </div>}
        {
          paymentType === "AdvancePayment" && (
            <AdvancePayment type={"AdvancePayment"} />
          )
        }
        {
          paymentType === "SecurityChequeAndAdvancePayment" && (
            <>
            <p className='mt-2'>Security-Cheque</p>
              <SecurityCheque type={"SecurityChequeAndAdvancePayment"} />
              <AdvancePayment type={"SecurityChequeAndAdvancePayment"} />
            </>
          )
        }
      </div>
    </div>
  )
}

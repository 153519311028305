import React, { createContext, useState } from 'react';

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [MENUITEMS, setMenuData] = useState([]);
  //console.log("I am updated")
  return (
    <MenuContext.Provider value={{ MENUITEMS, setMenuData }}>
      {children}
    </MenuContext.Provider>
  );
};

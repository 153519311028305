import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FlatStyle, url } from '../../../Constant';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import FullPageLoader from '../../../Components/Loaders/FullPageLoader';
import { Skeleton } from 'primereact/skeleton';
import { padStart } from '@fullcalendar/core/internal';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { SplitButton } from 'primereact/splitbutton';
import * as XLSX from 'xlsx';
import Pagination from '../../../_helper/Pagination/Pagination';
import TableSkeleton from '../../../Components/Loaders/TableSkeleton';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddCustomer from './AddCustomer';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';




// import { Box, Typography } from '@mui/material';
// import SadminSidebar from '../../components/Sidebar';


export default function Company() {
    let emptyCompany = {
        company_code: null,
        companyLogo: '',
        companyName: '',
        prefix: '',
        contactPerson: '',
        contact_No: '',
        revenue: '',
        status: ''
    };

    const [customerDialog, setCustomerDialog] = useState(false);
    const [deleteCustomerDialog, setDeleteCustomerDialog] = useState(false);
    const [deleteCompaniesDialog, setDeleteCompaniesDialog] = useState(false);
    const [customer, setCustomer] = useState(emptyCompany);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loader, setLoader] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [drawerWidth, setDrawerWidth] = React.useState(260);
    const [metadata, setMetadata] = useState({});
    const [customers, setCustomers] = useState(null);
    const [currentPage, setCurrentPage] = useState(() => { return 1 });
    const [pageSize, setPageSize] = useState(() => { return 10 });
    const [statusLoader, setStatusloader] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [visible, setVisible] = useState(false);
    const [editCustomerId, setEditCustomerId] = useState();


    useEffect(() => {
        console.log(selectedCustomers)
    }, [selectedCustomers])

    const fetchCustomers = async () => {
        setLoader(true);

        const filtersParams = ['cname', 'email', 'city', 'phone', 'contactPerson', 'cid']; // Example array
        const params = new URLSearchParams({
            companyCode: localStorage.getItem('companyCode'),
            page: currentPage,
            limit: pageSize,
            filter: globalFilter || ''
        });

        // Append each filter as a separate query parameter
        filtersParams.forEach(filter => params.append('filterParams[]', filter));

        try {
            const response = await fetch(`${url}/api/admin/allcustomer?${params.toString()}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) {
                if (response.status === 403) {
                    toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
                    throw new Error('Unauthorized');
                } else {
                    throw new Error('Network response was not ok.');
                }
            }
            const result = await response.json();
            console.log(result.data, "/*/*-/*/-/*-/-*/*-/*-/");
            setCustomers(pageSize === 1000 ? prevCustomers => [...prevCustomers, ...result.data] : result.data);
            setMetadata(result.metadata);
        } catch (error) {
            console.error('Fetch error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while fetching data', life: 3000 });
        } finally {
            setLoader(false); // Ensure loader is hidden after the fetch completes
        }
    };

    useEffect(() => {
        // Reset customers data when filter, pageSize, or currentPage changes
        setLoader(true);
        setCustomers([]);
        fetchCustomers();
    }, [currentPage, pageSize, globalFilter]);

    const addCustomer = async () => {
        setLoader(true);

        const customerData = {
            cname: formValues.cname,
            email: formValues.email,
            city: formValues.city,
            phone: formValues.phone,
            contactPerson: formValues.contactPerson,
            address: formValues.address,
        };

        try {
            const response = await fetch(`${url}/api/admin/addCustomer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(customerData),
            });

            if (!response.ok) {
                if (response.status === 403) {
                    toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
                    throw new Error('Unauthorized');
                } else {
                    throw new Error('Network response was not ok.');
                }
            }

            const result = await response.json();
            console.log(result, "Customer added successfully");

            // Optionally, you can update the customers list
            setCustomers((prevCustomers) => [...prevCustomers, result.data]);
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Customer added successfully', life: 3000 });

        } catch (error) {
            console.error('Fetch error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while adding customer', life: 3000 });
        } finally {
            setLoader(false); // Ensure loader is hidden after the operation completes
        }
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // createCustomer();
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= metadata.totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handlePageSizeChange = (event) => {
        // console.log(event.target.value);
        setPageSize(Number(event.target.value));
        console.log("page size set to ", event.target.value)
        setCurrentPage(1); // Reset to first page on page size change
    };



    const openNew = () => {
        setCustomer(emptyCompany);
        setSubmitted(false);
        setCustomerDialog(true);

    };

    const hideDialog = () => {
        setSubmitted(false);
        setCustomerDialog(false);
    };

    const hideDeleteCompanyDialog = () => {
        setDeleteCustomerDialog(false);
    };

    const hideDeleteCompaniesDialog = () => {
        setDeleteCompaniesDialog(false);
    };

    const saveCustomer = () => {
        setSubmitted(true);

        if (customer.customerName.trim()) {
            let _customers = [...customers];
            let _customer = { ...customer };

            if (customer.customer_code) {
                const index = findIndexById(customer.customer_code);

                _customer[index] = _customer;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Updated', life: 3000 });
            } else {
                _customer.customer_code = createId();
                _customers.push(_customer);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Created', life: 3000 });
            }
            addCustomer();
            setCustomers(_customers);
            setCustomerDialog(false);
            setCustomer(emptyCompany);
        }
    };

    // const editCustomer = (customer) => {
    //     setCustomer({ ...customer });
    //     setCustomerDialog(true);
    // };

    // const confirmDeleteCompany = (customer) => {

    //     setCustomer(customer);
    //     setDeleteCustomerDialog(true);
    // };

    const confirmDelete = (customer) => {
        confirmDialog({
            message: 'Are you sure you want to delete?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDelete(customer),
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptClassName: "custom-accept-button",
            rejectClassName: "custom-reject-button"
        });
    }

    const handleDelete = (customer) => {
        //console.log(machine)
        const collection = "customer"
        const field = "cid"
        const id = customer.cid;
        fetch(`${url}/api/admin/field/delete?collection=${collection}&field=${field}&id=${id}`, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (!response.ok) {
                setLoader(false);
                throw new Error('Network response was not ok');
            }
            setLoader(false);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Machine deleted', life: 3000 });
            fetchCustomers();
        }).catch((err) => {
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Failed to delete', life: 3000 });
            //console.log(err)
        })
    }

    const deleteCompany = async () => {
        setLoader(true);
        //console.log(customer.company_code)
        // //console.log(rowData.status, "++++++++++")
        try {
            // Perform the POST request to update the status
            const response = await fetch(`${url}/api/sup/delete_admin`, {
                method: 'delete',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    company_code: customer.company_code,
                })
            });
            if (!response.ok) {
                setLoader(false);
                throw new Error('Network response was not ok');

            }
            const result = await response.json();
            if (response.status == 200) {
                setLoader(false);
                //console.log("OK")
                // Update local state if the status change was successful
                fetchCustomers();
                // const index = findIndexById(rowData.company_code);
                // let _companies = [...customers];
                // _companies[index] = { ...rowData, status: newStatus };
                // setCustomers(_companies);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Deleted', life: 3000 });
            } else {
                throw new Error(result.message || 'Failed to Delete status');
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setLoader(false);
            setDeleteCustomerDialog(false);
            // //console.log("", newStatus);
        }
        // Delete API Call

        // let _companies = customers.filter((val) => val.company_code !== customer.company_code);
        // setCustomers(_companies);
        // setDeleteCustomerDialog(false);
        // setCustomer(emptyCompany);
        // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Deleted', life: 3000 });
    };





    const findIndexById = (company_code) => {
        let index = -1;

        for (let i = 0; i < customers.length; i++) {
            if (customers[i].customer_code === company_code) {
                index = i;
                break;
            }
        }
        return index;
    };

    const createId = () => {
        let customer_code = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            customer_code += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return customer_code;
    };



    const exportOptions = [
        {
            label: 'Export as PDF',
            icon: 'pi pi-file-pdf',
            command: () => {
                exportToPDF();
                toast.current.show({ severity: 'success', summary: 'Exported', detail: 'Data Exported' });
            }
        },
        {
            label: 'Export CSV',
            icon: 'pi-file',
            command: () => {
                exportCSV();
                toast.current.show({ severity: 'success', summary: 'Exported', detail: 'Data Exported' });
            }
        },
    ];


    const exportCSV = () => {
        // Use the same column headers as in your PDF export
        // const columns = [
        //     { title: "Company Code", dataKey: "companyCode" },
        //     { title: "Company Name", dataKey: "companyName" },
        //     { title: "Prefix", dataKey: "prefix" },
        //     { title: "Contact Person", dataKey: "contactPerson" },
        //     { title: "Contact Number", dataKey: "contactNumber" },
        //     { title: "Registration Date/Time", dataKey: "registrationDate" },
        //     { title: "Status", dataKey: "status" }
        // ];

        // Extract the data
        const customerData = selectedCustomers || customers;
        const rows = customerData.map(customer => ({
            customerCode: `FR${customer.CompanyDetails?.companyPrefix || "---"}${customer.customer_code ? padStart(customer.company_code, 4, '0') : "----"}`,
            customerName: customer.CompanyDetails?.companyName || "N/A",
            prefix: customer.CompanyDetails?.customerPrefix || "N/A",
            contactPerson: `${customer.PersonalDetails?.firstName || "N/A"} ${customer.PersonalDetails?.middleName || ""} ${customer.PersonalDetails?.lastName || ""}`,
            contactNumber: customer.mobile || "N/A",
            registrationDate: customer.register_date || "N/A",
            status: customer.status ? "Active" : "Inactive"
        }));

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(rows, { header: columns.map(col => col.title) });

        // Create a new workbook and add the worksheet
        const wb = XLSX.utils.book_new();


        XLSX.utils.book_append_sheet(wb, ws, 'Selected Companies');

        // Write the file
        XLSX.writeFile(wb, 'Companies.xlsx');
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.text("Companies List", 14, 16);

        const companyData = selectedCustomers || customers;


        const columns = [
            { title: "Company Code", dataKey: "companyCode" },
            { title: "Company Name", dataKey: "companyName" },
            { title: "Prefix", dataKey: "prefix" },
            { title: "Contact Person", dataKey: "contactPerson" },
            { title: "Contact Number", dataKey: "contactNumber" },
            { title: "Registration Date/Time", dataKey: "registrationDate" },
            { title: "Status", dataKey: "status" }
        ];
        const rows = companyData.map(customer => ({
            companyCode: `FR${customer.CompanyDetails?.companyPrefix || "---"}${customer.company_code ? padStart(customer.company_code, 4, '0') : "----"}`,
            companyName: customer.CompanyDetails?.companyName || "N/A",
            prefix: customer.CompanyDetails?.companyPrefix || "N/A",
            contactPerson: (customer.PersonalDetails?.firstName || "N/A") + " " + (customer.PersonalDetails?.middleName || "") + " " + (customer.PersonalDetails?.lastName || ""),
            contactNumber: customer.mobile || "N/A",
            registrationDate: customer.register_date || "N/A",
            status: customer.status ? "Active" : "Inactive"
        }));
        doc.autoTable(columns, rows, { startY: 22 });
        doc.save('customers.pdf');
    };

    const confirmDeleteSelected = (customer) => {
        setCustomer(customer);
        setDeleteCompaniesDialog(true);
    };

    const deleteSelectedCompanies = async () => {
        setLoader(true);
        const companyCodes = selectedCustomers.map(customer => customer.company_code);
        //console.log(companyCodes)

        // //console.log(filteredCompanies)
        // //console.log(rowData.status, "++++++++++")
        try {
            // Perform the POST request to update the status
            const response = await fetch(`${url}/api/sup/delete_admins`, {
                method: 'delete',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    companyCodes: companyCodes,
                })
            });
            if (!response.ok) {
                setLoader(false);
                throw new Error('Network response was not ok');

            }
            const result = await response.json();
            if (response.status === 200) {
                setLoader(false);
                //console.log("OK")
                // Update local state if the status change was successful
                fetchCustomers();
                // const index = findIndexById(rowData.company_code);
                // let _companies = [...customers];
                // _companies[index] = { ...rowData, status: newStatus };
                // setCustomers(_companies);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Deleted', life: 3000 });
                // deleteCustomerDialog(false);
            } else {
                throw new Error(result.message || 'Failed to Delete status');
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setLoader(false);
            // //console.log("", newStatus);
        }

        setDeleteCompaniesDialog(false);
        setSelectedCustomers(null);
        //console.log(selectedCustomers)
    };
    const CustomerDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveCustomer} />
        </React.Fragment>
    );

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _customer = { ...customer };
        _customer[`${name}`] = val;
        setCustomer(_customer);
        addCustomer();
    };

    // const handleCreate = async () => {
    //     setSubmitted(true);
    //     if (customer.name && customer.email) {
    //         await addCustomer(customer);
    //         fetchCustomers();
    //         setCustomerDialog(false);
    //         setCustomer({});
    //     }
    // };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _customer = { ...customer };
        _customer[`${name}`] = val;
        setCustomer(_customer);
    };

    // const onCategoryChange = (e) => {
    //     let _product = { ...customer };

    //     _customer['category'] = e.value;
    //     setCategory(_product);
    // };

    const leftToolbarTemplate = () => {
        return (<>
            {/* <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} /> */}

            <SplitButton label="EXPORT" className='m-2' onClick={exportCSV} icon="pi pi-file-xls" severity='danger' model={exportOptions} />
            <FormControl variant="outlined" size="small" style={{ minWidth: 75, }}>
                <InputLabel>Items</InputLabel>
                <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    label="Items"
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </Select>
            </FormControl>
        </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <IconField iconPosition="left" style={{ marginRight: "2%" }}>
                    <InputIcon className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </IconField>
                <AddCustomer visible={visible}
                    selectedCustomer={selectedCustomer}
                    setSelectedCustomer={setSelectedCustomer}
                    setVisible={setVisible}
                    editCustomerId={editCustomerId}
                    setEditCustomerId={setEditCustomerId}
                    customerDialog={customerDialog}
                    setCustomerDialog={setCustomerDialog} />
            </>

        )
        // <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />;
    };
    const onstatusChange = async (rowData) => {
        setStatusloader(true)
        const newStatus = rowData.status === true ? false : true;

        try {
            const response = await fetch(`${url}/api/admin/change_status`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    collectionName: 'customer',
                    status: newStatus,
                    uniqueRow: 'cid',
                    id: rowData.cid
                })
            });
            if (!response.ok) {
                // setLoader(false);
                setStatusloader(false)
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            if (response.status == 200) {
                setStatusloader(false)
                //console.log("OK")
                fetchCustomers();
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Status Updated', life: 3000 });
            } else {
                setStatusloader(false)
                throw new Error(result.message || 'Failed to update status');
            }
        } catch (error) {
            setStatusloader(false)
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setStatusloader(false)
            //console.log("Status changed: ", newStatus);
        }
    };


    const statusBodyTemplate = (rowData) => {
        return (
            <Button style={{ fontSize: "12px", padding: "2px", cursor: "pointer", backgroundColor: rowData.status ? "green" : "red" }} onClick={() => onstatusChange(rowData)} value={rowData.status} severity={getSeverity(rowData)}>{rowData.status ? "Active" : "Inactive"}</Button>
        );
    };


    function padZero(value) {
        const strValue = String(value);

        return strValue.padStart(4, '0');
    }

    const customerCodeBody = (rowData) => {
        return (
            <div>{`FR${rowData.CompanyDetails ? rowData.CompanyDetails.machinePrefix : "---"}${rowData.customer_code ? padZero(rowData.customer_code) : "----"}`}</div>
        )
    }
    const nameBodyTemplate = (rowData) => {
        return <div>{(rowData.PersonalDetails && rowData.PersonalDetails.firstName) ? rowData.PersonalDetails.firstName + " " + ((rowData.PersonalDetails.middleName !== undefined && rowData.PersonalDetails.middleName !== "") ? rowData.PersonalDetails.middleName : "") + " " + ((rowData.PersonalDetails.lastName !== undefined && rowData.PersonalDetails.lastName !== "") ? rowData.PersonalDetails.lastName : "") : "N/A"}</div>

    }
    function formatTimestampDDMMYYYY(timestamp) {
        // Create a new Date object from the timestamp
        const date = new Date(timestamp);

        // Extract day, month, and year
        const day = String(date.getUTCDate()).padStart(2, '0'); // Pad single-digit days with leading zero
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
        const year = date.getUTCFullYear();

        // Format the date as DD-MM-YYYY
        return `${day}-${month}-${year}`;
    }

    const formattedDate = (rowData) => {
        return <div>{formatTimestampDDMMYYYY(rowData.register_date)}</div>
    }
    const lastActiveBodyTemplate = (rowData) => {
        return <div>{(rowData.status) ? <div className='text-success'>Currently Active</div> : <div className='text-danger'>{rowData.deactivated_at}</div>}</div>

    }

    const linkBodyTemplate = (rowData) => {
        return (<Link to={`${process.env.PUBLIC_URL}/sup/customer/profile/${rowData.company_code}`}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                {/* <img src={rowData.companyLogo} alt="Company Logo" className="shadow-2" style={{ width: '35px',height:'35px',borderRadius:"50%" }} />                         */}
                <div className="my-auto" style={{ marginLeft: "7px" }}>{rowData.CompanyDetails && rowData.Customeremail}</div>
                {rowData.subscription && <i className='pi pi-crown my-auto mx-2' style={{ color: "#fc9403", fontSize: "1rem" }} />}
            </div>
        </Link>);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <Button
                    icon="pi pi-pencil"
                    rounded
                    outlined
                    severity="primary"
                    onClick={() => {setEditCustomerId(rowData.cid);setCustomerDialog(true)}}
                    style={{
                        outline: 'none',
                        border: 'none', // Remove border if needed
                        boxShadow: 'none' // Remove box-shadow if needed
                    }}
                /> */}
                {/* <AddCustomer setEditCustomerId={setEditCustomerId} editCustomerId={rowData.cid} setCustomerDialog={setCustomerDialog} hideDialog={hideDialog} /> */}
                <Link to={`/panel/customer/profile?id=${rowData.cid}`}>
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-text p-button-info" style={{ marginRight: "15px", color: "#0b0ff1c7" }} text />
                </Link>
                <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                    onClick={() => confirmDelete(rowData)}
                    style={{
                        outline: 'none',
                        border: 'none', // Remove border if needed
                        boxShadow: 'none' // Remove box-shadow if needed
                    }}
                />


            </React.Fragment>
        );
    };

    const getSeverity = (customer) => {
        switch (customer.status) {
            case true:
                return 'success';
            case false:
                return 'danger';
            default:
                return 'danger';
        }
    };

    const deleteCustomerDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteCompanyDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteCompany} />
        </React.Fragment>
    );

    const deleteCompaniesDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteCompaniesDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedCompanies} />
        </React.Fragment>
    );

    const columns = [
        { selectionMode: 'multiple', exportable: false, style: { width: '3rem', fontSize: "0.7vw" } },
        // { field: 'companyLogo', header: 'Company Logo', body: logoBodyTemplate, style: { minWidth: '10rem', fontSize: "0.7vw" } },
        {
            field: 'cid',
            header: 'ID',
            // body: companyCodeBody,
            sortable: true,
            style: { minWidth: '3rem', fontSize: "0.7vw" }
        },
        {
            field: 'cname',
            header: 'Customer Name',
            // body: companyCodeBody,
            sortable: true,
            style: { minWidth: '10rem', fontSize: "0.7vw" }
        },

        {
            field: 'email',
            header: 'Company Email',
            // body: linkBodyTemplate,
            sortable: true,
            style: { minWidth: '16rem', fontSize: "0.7vw" }
        },
        // {
        //     field: 'serial',
        //     header: 'Serial',
        //     sortable: true,
        //     style: { minWidth: '5rem', fontSize: "0.7vw" }
        // },
        {
            field: 'city',
            header: 'City',
            style: { minWidth: '8rem', fontSize: "0.7vw" }
        },
        {
            field: 'phone',
            header: 'Phone',
            // body: nameBodyTemplate,
            style: { minWidth: '10rem', fontSize: "0.7vw" }
        },
        {
            field: 'contactPerson',
            header: 'Contact Person Name',
            // body:formattedDate,
            style: { minWidth: '12rem', fontSize: "0.7vw" }
        },

        // {
        //     field: 'address',
        //     header: 'Address ',
        //     style: { minWidth: '8rem', fontSize: "0.7vw" }
        // },

        // {
        //     field: 'deactivated_at',
        //     header: 'Last Active',
        //     body: lastActiveBodyTemplate,
        //     style: { minWidth: '12rem', fontSize: "0.7vw" }
        // },
        {
            field: 'status',
            header: 'Status',
            body: statusBodyTemplate,
            style: { minWidth: '5rem', fontSize: "0.7vw" }
        },
        {
            header: 'Action',
            body: actionBodyTemplate,
            exportable: false,
            style: { minWidth: '8rem' }
        }
    ];

    const [distanceFromBottom, setDistanceFromBottom] = useState(0);
    const [noOfscrolls, setnoOfscrolls] = useState(0)
    const divRef = useRef(null); // Ref to access the div element

    // Function to be called when reaching the bottom
    const onReachBottom = () => {
        console.log(pageSize)
        if (!loader && pageSize === 1000) {
            setnoOfscrolls(noOfscrolls + 1);
            setCurrentPage(noOfscrolls)
            console.log('ififif');

        }
        console.log('Reached the bottom of the page');
        // Add your logic here;
        // Add your logic here
    };

    useEffect(() => {
        const calculateDistanceFromBottom = () => {
            if (divRef.current) {
                const rect = divRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const distance = viewportHeight - rect.bottom;

                // Call function when the div is at or near the bottom
                if (distance >= 0) {
                    onReachBottom();
                }
            }
        };

        // Initial calculation
        calculateDistanceFromBottom();

        // Recalculate on window resize and scroll
        window.addEventListener('resize', calculateDistanceFromBottom);
        window.addEventListener('scroll', calculateDistanceFromBottom);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('resize', calculateDistanceFromBottom);
            window.removeEventListener('scroll', calculateDistanceFromBottom);
        };
    }, []); // Empty dependency array to run only once on mount


    return (
        <>
            <div className="datatable-crud-demo">
                <Breadcrumbs mainTitle="Customer" parent="General" title="Customer" />
                <FullPageLoader open={statusLoader} />
                <Toast ref={toast} />
                <div className="card">
                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
                    <DataTable
                        ref={dt}
                        value={customers}
                        selection={selectedCustomers}
                        onSelectionChange={(e) => setSelectedCustomers(e.value)}
                        dataKey="cid"
                        paginator={false}

                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        // globalFilter={globalFilter}
                        // header={header}
                        emptyMessage={" "}
                        className="datatables-demo"
                    >
                        {columns.map((col, i) => (
                            <Column key={i} field={col.field} header={col.header} body={col.body} sortable={col.sortable} style={col.style} exportable={col.exportable} selectionMode={col.selectionMode} />
                        ))}
                    </DataTable>
                    {loader && (
                        <TableSkeleton rowCount={pageSize} columns={columns} />
                    )}
                    <Pagination
                        currentPage={currentPage}
                        totalPages={metadata?.totalPages}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        pageSize={pageSize}
                        setLoader={setLoader}
                    />
                </div>
                {/* < AddCustomer /> */}
                <ConfirmDialog />
            </div>
        </>
    );
}
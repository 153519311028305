import React, { Fragment, useEffect, useState } from 'react'
import CompanyProfile from './CompanyProfile'
import CompanyDetails from './CompanyDetails'
import { Breadcrumbs } from '../../../AbstractElements'
import { Col, Container, Row } from 'react-bootstrap'
import {url} from '../../../Constant/index'
import { useParams } from 'react-router-dom'

export default function Profile() {
    const [companyProfile, setCompanyProfile] = useState();
    const [companyDetails, setCompanyDetails] = useState();
    const [render, setRender] = useState(true);
    const {id} = useParams();
    useEffect(()=>{
        fetchAdmin()
    },[render, setRender])
    const refreshData = ()=>{
      setRender(!render)
    }
    const fetchAdmin = ()=>{
        fetch(url + "/api/admin/profile/get-by-id?company_code=" + Number(id)).then((response)=>{
          //console.log(response)
            if(response.ok){
                response.json().then((result)=>{
                    setCompanyDetails(result);
                    let details = result.CompanyDetails;
                    //console.log(result)
                    details = {...details, company_code:result.company_code, status:result.status, mobile:result.mobile}
                    setCompanyProfile(details);
                })
            }
        })
    }
  return (
    <Fragment>
    <Breadcrumbs mainTitle='Company Profile' parent='General' subParent="Company" title='Profile' />
    <Container fluid={true}>
      <div className='edit-profile'>
        <Row>
          <Col xl='4'>
          <CompanyProfile profile={companyProfile} refreshData={refreshData} />
          </Col>
          <Col xl='8'>
            <CompanyDetails companyDetails={companyDetails} refreshData={refreshData} />
          </Col>
        </Row>
      </div>
    </Container>
  </Fragment>
  )
}

import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid, Divider } from '@mui/material';
import { Button } from 'react-bootstrap';
import { Person, Business, AccountBalance, CloudUpload } from '@mui/icons-material';


import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


import PersonalDetails from '../../Registration-global/PersonalDetails';
import CompanyDetails from '../../Registration-global/CompanyDetails';
import BankDetails from '../../Registration-global/BankDetails';
import PicturesUpload from '../../Registration-global/PicturesUpload';
import { Breadcrumbs } from '../../AbstractElements';


const steps = ['Personal Details', 'Company Details', 'Bank Details', 'Pictures Upload'];


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // const theme = useTheme();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <p>{children}</p>
        </div>

      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function RegistrationStepperComp() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});



  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if (!isLastStep()) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  // const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [activeTabs, setActiveTabs] = useState(0);
  useEffect(() => {
    //console.log(value, "  ", activeTabs);
    setActiveTabs(value >= activeTabs ? value : activeTabs);

  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <>
      <Breadcrumbs mainTitle="Company Profile" parent="Section" title="Profile" style={{ marginTop: "20px" }} subParent='Company' />
      {/* <Grid container spacing={2} style={{ width: "100vw", height: "auto", maxWidth: '1200px', margin: "20% auto", backgroundColor: "white", marginBottom: "2%", marginTop: "8%", boxShadow: "0px 0px 10px 5px lightGray" }} backgroundColor> */}
      <Box
        sx={{
          width: "100%",
          height: "auto", // Adjust height automatically
          padding: "2%",
          backgroundColor: "white",
          boxShadow: "0px 0px 10px 5px lightGray",
          borderRadius: "8px",
        }}
      >
        <Box sx={{ width: "95%", marginLeft: "2.5%" }}>
          {/* <AppBar position="static"> */}

          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="transparent" // Set to transparent to hide default indicator
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{
              backgroundColor: 'white',
              borderBottom: '0.5px solid #e0e0e0',
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: 'orange', // Set the desired indicator color
                height: '3px', // Adjust the height if needed
                borderRadius: '4px', // Optional: Adjust border radius for rounded edges
              },
            }}
          >
            <Tab
              disabled={activeTabs < 0}
              label={
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '0.8rem', // Further reduce font size
                  whiteSpace: 'nowrap', // Prevent text from wrapping
                }}>
                  <Person style={{ fontSize: '18px', marginRight: '4px' }} /> {/* Reduce icon size */}
                  Personal Details
                </div>
              }
              {...a11yProps(0)}
              sx={{
                color: value === 0 ? 'purple' : 'black',
                borderRadius: value === 0 ? '8px 8px 0 0' : '0',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                position: 'relative',
                zIndex: 1,
                minWidth: '0',
                padding: '4px 8px', // Adjust padding
                overflow: 'hidden',
              }}
            />
            <Tab
              disabled={activeTabs < 1}
              label={
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '0.8rem', // Further reduce font size
                  whiteSpace: 'nowrap', // Prevent text from wrapping
                }}>
                  <Business style={{ fontSize: '18px', marginRight: '4px' }} /> {/* Reduce icon size */}
                  Company Details
                </div>
              }
              {...a11yProps(1)}
              sx={{
                color: value === 1 ? 'purple' : 'black',
                borderRadius: value === 1 ? '8px 8px 0 0' : '0',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                position: 'relative',
                zIndex: 1,
                minWidth: '0',
                padding: '4px 8px', // Adjust padding
                overflow: 'hidden',
              }}
            />
            <Tab
              disabled={activeTabs < 2}
              label={
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '0.8rem', // Further reduce font size
                  whiteSpace: 'nowrap', // Prevent text from wrapping
                }}>
                  <AccountBalance style={{ fontSize: '18px', marginRight: '4px' }} /> {/* Reduce icon size */}
                  Bank Details
                </div>
              }
              {...a11yProps(2)}
              sx={{
                color: value === 2 ? 'purple' : 'black',
                borderRadius: value === 2 ? '8px 8px 0 0' : '0',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                position: 'relative',
                zIndex: 1,
                minWidth: '0',
                padding: '4px 8px', // Adjust padding
                overflow: 'hidden',
              }}
            />
            <Tab
              disabled={activeTabs < 3}
              label={
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '0.8rem', // Further reduce font size
                  whiteSpace: 'nowrap', // Prevent text from wrapping
                }}>
                  <CloudUpload style={{ fontSize: '18px', marginRight: '4px' }} /> {/* Reduce icon size */}
                  Image Upload
                </div>
              }
              {...a11yProps(3)}
              sx={{
                color: value === 3 ? 'purple' : 'black',
                borderRadius: value === 3 ? '8px 8px 0 0' : '0',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                position: 'relative',
                zIndex: 1,
                minWidth: '0',
                padding: '4px 8px', // Adjust padding
                overflow: 'hidden',
              }}
            />

          </Tabs>
          {/* </AppBar> */}
          <SwipeableViews
            axis={'ltr' === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={'ltr'}>
              <PersonalDetails setValue={setValue} handleBack={handleBack} handleNext={handleNext} setActiveTabs={setActiveTabs} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={'ltr'}>
              <CompanyDetails setValue={setValue} handleBack={handleBack} handleNext={handleNext} setActiveTabs={setActiveTabs} />
            </TabPanel>
            <TabPanel value={value} index={2} dir={'ltr'}>
              <BankDetails setValue={setValue} handleBack={handleBack} handleNext={handleNext} setActiveTabs={setActiveTabs} />
            </TabPanel>
            <TabPanel value={value} index={3} dir={'ltr'}>
              <PicturesUpload setValue={setValue} handleBack={handleBack} handleNext={handleNext} setActiveTabs={setActiveTabs} />
            </TabPanel>
          </SwipeableViews>
        </Box>

        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you're finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                {/* Step {activeStep + 1} */}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : null)}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
      {/* </Grid> */}
      

    </>
  );
}

import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { url } from '../../../_helper/Route/Url';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import AddRoleDialog from './AddRole';
import Permissions from './Permissions';
import './style.css'
import { useLocation } from 'react-router';
import { PermissionContext } from '../../../_helper/Permissions/PermissionContext';

export default function RoleTable() {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [render, setRender] = useState(false);
    const toast = useRef(null);
    const { setPermissions, permissions } = useContext(PermissionContext);

    const location = useLocation();
    let currentPath = location.pathname;
    currentPath = currentPath.slice(11);
    if (currentPath?.endsWith("/")) currentPath = currentPath?.slice(0, -1)
    const data = permissions?.filter((item) => {
        return item.routeName.includes(currentPath);
    });
    const [isWrite, setIsWrite] = useState(false)
    const refreshData = () => {
        setRender(!render)
    }

    useEffect(() => {
        fetchAllRoles()
        setIsWrite(data?.length > 0 ?? true)
    }, [render])

    const getSeverity = (status) => {
        switch (status) {
            case true:
                return 'Active';

            case false:
                return 'Inactive';
        }
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 justify-content-between align-items-center" style={{ display: "flex", justifyContent: "center" }}>
                {/* {isWrite && <AddRoleDialog refreshData={refreshData} />} */}
                {/* <h4 className="m-0">Customers</h4> */}
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.status ? "Active" : "Inactive"} onClick={() => confirmStatusChange(rowData.id, rowData.status)} style={{ fontSize: "12px", padding: "2px", backgroundColor: rowData.status ? "darkgreen" : "red" }} severity={getSeverity(rowData.status)} />;
    };

    const confirmStatusChange = (id, status) => {
        // if (isWrite) {
            // confirmDialog({
            //     message: `Are you sure you want to make it ${status ? "Active" : "Inactive"}?`,
            //     header: 'Confirmation',
            //     icon: 'pi pi-exclamation-triangle',
            //     accept: () => changeStatus(id, status),
            // });
        // }
    }

    const changeStatus = async (id, status) => {
        try {
            const response = await fetch(url + "/api/sup/role/status/update?id=" + id, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({ status: !status }),
            });
            if (!response.ok) {
                toast.current.show({ severity: 'error', summary: 'Failed to change status', life: 3000 })
            } else {
                toast.current.show({ severity: 'success', summary: 'Status changed successfully!!', life: 3000 })
                refreshData();

            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Failed to change status', life: 3000 })
        }
    }

    const confirmDelete = (id) => {
        confirmDialog({
            message: `Are you sure you want to delete it?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDelete(id),
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptClassName: "custom-accept-button",
            rejectClassName: "custom-reject-button"
        });
    }
    const handleDelete = async (id) => {

        try {
            fetch(`${url}/api/sup/role/delete?id=${id}`, {
                method: 'DELETE',
            }).then((response) => {
                if (response.ok) {
                    toast.current.show({ severity: 'success', summary: 'Role Deleted Successfully!!', life: 3000 });
                    setRender(!render)
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed to delete Role', life: 3000 });
                }
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Failed to delete Role', life: 3000 });
            })
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Failed to delete Role', life: 3000 });
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions-buttons" style={{ display: "flex" }}>
                {/* <Permissions roleId={rowData.id} /> */}
                <AddRoleDialog refreshData={refreshData} isUpdate={rowData.id} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-text p-button-danger"
                    onClick={() => confirmDelete(rowData.id)}
                    style={{ width: "20px", height: "20px", marginLeft: "15px", fontSize: "5px" }}
                    aria-label="Delete"
                />
            </div>
        );
    };

    const permissionBodyTemplate = (rowData) => {
        return <Permissions roleId={rowData.id} />
    };

    const header = renderHeader();

    const fetchAllRoles = () => {
        fetch(url + '/api/sup/role/getAll').then((response) => {
            if (response.ok) {
                response.json().then((result) => {
                    setCustomers(result)
                    //console.log(result)
                })
            }
        })
    }

    return (
        <div className="card">
            <Toast ref={toast} />
            <DataTable
                value={customers}
                header={header}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
                rowClassName={() => 'datatable-row'}
                removableSort
                dataKey="id"
                emptyMessage="No customers found."
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
                <Column field="id" header="Role Id" sortable style={{ fontSize: "14px", minWidth: '5rem' }} />
                <Column field="roleName" header="Role" style={{ fontSize: "14px", minWidth: '5rem' }} />
                <Column field="permission" header="Permissions" style={{ fontSize: "14px", minWidth: '3rem' }} align={"center"} body={permissionBodyTemplate} />
                <Column field="status" header="Status" style={{ fontSize: "14px", minWidth: '6rem' }} body={statusBodyTemplate} />
                {/* {isWrite && <Column header={"Actions"} headerStyle={{ fontSize: "14px", minWidth: '3rem' }} body={actionBodyTemplate} />} */}
            </DataTable>
            <ConfirmDialog />
        </div>
    )
}

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { url } from '../../../Constant';
import { Breadcrumbs } from '../../../AbstractElements';
import FullPageLoader from '../../../Components/Loaders/FullPageLoader';
import 'jspdf-autotable';
import Pagination from '../../../_helper/Pagination/Pagination';
import TableSkeleton from '../../../Components/Loaders/TableSkeleton';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddMachine from './AddMachine';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


export default function Machine() {
    let emptyMachineStatus = {
        id: Number,
        machineStatusName: '',
        status: '',
    };

    const [deleteMachineStatusDialog, setDeleteMachineStatusDialog] = useState(false);
    const [deleteMachineStatusesDialog, setDeleteMachineStatusesDialog] = useState(false);
    const [machineStatus, setMachineStatus] = useState(emptyMachineStatus);
    const [selectedMachineStatuses, setSelectedMachineStatuses] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loader, setLoader] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [metadata, setMetadata] = useState({});
    const [machineStatuses, setMachineStatuses] = useState(null);
    const [currentPage, setCurrentPage] = useState(() => { return 1 });
    const [pageSize, setPageSize] = useState(() => { return 10 });
    const [statusLoader, setStatusloader] = useState(false);
    const [render, setRender] = useState(false);

    const fetchMachineStatuses = () => {
        setLoader(true);

        const filtersParams = []; // Example array
        const params = new URLSearchParams({
            page: currentPage,
            limit: pageSize,
            filter: globalFilter || ''
        });

        // Append each filter as a separate query parameter
        filtersParams.forEach(filter => params.append('filterParams[]', filter));

        fetch(url + `/api/sup/machineStatus/getAll?${params.toString()}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 403) {
                    setLoader(false);
                    toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
                    throw new Error('Unauthorized');
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then(result => {
                if (pageSize === 1000) {
                    setMachineStatuses(prevMachineStatuses => [...prevMachineStatuses, ...result]);
                } else {
                    console.log(result)
                    setMachineStatuses(result);
                }
                // setMetadata(result.metadata);
                setLoader(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setLoader(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while fetching data', life: 3000 });
            });
    }

    useEffect(() => {
        setLoader(true);
        setMachineStatuses([]);
        fetchMachineStatuses();
    }, [currentPage, pageSize, globalFilter, render, setRender]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= metadata.totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleRender = ()=>{
        setRender(!render);
    }

    const handlePageSizeChange = (event) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(1); // Reset to first page on page size change
    };

    const hideDeleteMachineStatusDialog = () => {
        setDeleteMachineStatusDialog(false);
    };

    const hideDeleteMachineStatusesDialog = () => {
        setDeleteMachineStatusesDialog(false);
    };

    const confirmDeleteMachineStatus = (machineStatus) => {

        // setMachineStatus(machineStatus);
        confirmDialog({
            message: `Are you sure you want to delete it?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteMachineStatus(machineStatus.id),
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptClassName: "custom-accept-button",
            rejectClassName: "custom-reject-button"
        });
    };

    const deleteMachineStatus = async (machineStatus) => {
        console.log(machineStatus)
        setLoader(true);
        try {
            // Perform the POST request to update the status
            const response = await fetch(`${url}/api/sup/machineStatus/delete?id=${machineStatus}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            });
            if (!response.ok) {
                setLoader(false);
                throw new Error('Network response was not ok');

            }
            const result = await response.json();
            if (response.status == 200) {
                setLoader(false);
                setRender(!render)
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Machine Status Deleted', life: 3000 });
            } else {
                throw new Error(result.message || 'Failed to Delete status');
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setLoader(false);
            setDeleteMachineStatusDialog(false);
            setRender(!render)
        }
    };

    const deleteSelectedMachineStatuses = async () => {
        setLoader(true);
        const machineStatusCodes = selectedMachineStatuses.map(machineStatus => machineStatus.machineStatus_code);
        try {
            // Perform the POST request to update the status
            const response = await fetch(`${url}/api/sup/delete_admins`, {
                method: 'delete',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: machineStatusCodes,
                })
            });
            if (!response.ok) {
                setLoader(false);
                throw new Error('Network response was not ok');

            }
            const result = await response.json();
            if (response.status === 200) {
                setLoader(false);
                //console.log("OK")
                // Update local state if the status change was successful
                fetchMachineStatuses();
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'MachineStatus Deleted', life: 3000 });
                // deleteMachineStatusDialog(false);
            } else {
                throw new Error(result.message || 'Failed to Delete status');
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setLoader(false);
            // //console.log("", newStatus);
        }

        setDeleteMachineStatusesDialog(false);
        setSelectedMachineStatuses(null);
        //console.log(selectedMachineStatuses)
    };

    const leftToolbarTemplate = () => {
        return (<>
            {/* <SplitButton label="EXPORT" className='m-2' onClick={exportCSV} icon="pi pi-file-xls" severity='danger' model={exportOptions} /> */}
            <AddMachine refreshData={handleRender} />
            <FormControl variant="outlined" size="small" style={{ minWidth: 75, marginLeft:20}}>
                <InputLabel>Items</InputLabel>
                <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    label="Items"
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </Select>
            </FormControl>
        </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </IconField>
        )
    };

    const confirmStatusChange = (id, status) => {
        confirmDialog({
            message: `Are you sure you want to make it ${status ? "Active" : "Inactive"}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => onstatusChange(id, status),
        });
    
}
    const onstatusChange = async (id, status) => {
        // setLoader(true);
        setStatusloader(true)

        try {
            const response = await fetch(`${url}/api/sup/machineStatus/status?id=${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify({status})
            });
            if (!response.ok) {
                // setLoader(false);
                setStatusloader(false)

                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            if (response.status == 200) {

                setStatusloader(false)
                //console.log("OK")
                setRender(!render)
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'MachineStatus Status Updated', life: 3000 });
            } else {
                setStatusloader(false)
                throw new Error(result.message || 'Failed to update status');
            }
        } catch (error) {
            setStatusloader(false)
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setStatusloader(false)
            //console.log("Status changed: ", newStatus);
        }
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <Button style={{ fontSize: "12px", padding: "2px", cursor: "pointer", backgroundColor: rowData.status ? "green" : "red" }} onClick={() => confirmStatusChange(rowData.id, !rowData.status)} value={rowData.status} severity={getSeverity(rowData)}>{rowData.status ? "Active" : "Inactive"}</Button>
        );
    };

    const machineStatusCodeBody = (rowData) => {
        return (
            <div>{rowData.id}</div>
        )
    }
    const statusNameTemplate = (rowData) => {
        return (<div>{rowData.statusName}</div>);
    };
    const sectionTemplate = (rowData) => {
        return (<div>{rowData.section}</div>);
    };
    const backgroundColorTemplate = (rowData) => {
        return (<div>{rowData.backgroundColor}</div>);
    };
    const textColorTemplate = (rowData) => {
        return (<div>{rowData.textColor}</div>);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className='d-flex justify-content-start align-items-center'>
                <AddMachine isUpdate={rowData.id}  refreshData={handleRender} />
                <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                    onClick={() => confirmDeleteMachineStatus(rowData)}
                    style={{
                        outline: 'none',
                        border: 'none', // Remove border if needed
                        boxShadow: 'none' // Remove box-shadow if needed
                    }}
                />
            </div>
        );
    };

    const getSeverity = (machineStatus) => {
        switch (machineStatus.status) {
            case true:
                return 'success';
            case false:
                return 'danger';
            default:
                return 'danger';
        }
    };

    const deleteMachineStatusDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMachineStatusDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteMachineStatus} />
        </React.Fragment>
    );

    const deleteMachineStatusesDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMachineStatusesDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedMachineStatuses} />
        </React.Fragment>
    );

    const columns = [
        {
            field: 'id',
            header: 'ID',
            body: machineStatusCodeBody,
            sortable: true,
            style: { minWidth: '10rem', fontSize: "13px" }
        },
        {
            field: 'section',
            header: 'Section',
            body: sectionTemplate,
            sortable: true,
            style: { minWidth: '12rem', fontSize: "13px" }
        },{
            field: 'machineStatusName',
            header: 'Machine Status Name',
            body: statusNameTemplate,
            sortable: true,
            style: { minWidth: '8rem', fontSize: "13px" }
        },{
            field: 'backgroundColor',
            header: 'Background Color',
            body: backgroundColorTemplate,
            sortable: true,
            style: { minWidth: '5rem', fontSize: "13px" }
        },
        {
            field: 'textColor',
            header: 'Text Color',
            body: textColorTemplate,
            sortable: true,
            style: { minWidth: '5rem', fontSize: "13px" }
        },
        {
            field: 'status',
            header: 'Status',
            body: statusBodyTemplate,
            style: { minWidth: '5rem', fontSize: "13px" }
        },
        {
            header: 'Action',
            body: actionBodyTemplate,
            exportable: false,
            style: { minWidth: '8rem' }
        }
    ];
    const [noOfscrolls, setnoOfscrolls] = useState(0)
    const divRef = useRef(null); // Ref to access the div element

    // Function to be called when reaching the bottom
    const onReachBottom = () => {
        //console.log(pageSize)
        if (!loader && pageSize === 1000) {
            setnoOfscrolls(noOfscrolls + 1);
            setCurrentPage(noOfscrolls)
            //console.log('ififif');

        }
    };

    useEffect(() => {
        const calculateDistanceFromBottom = () => {
            if (divRef.current) {
                const rect = divRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const distance = viewportHeight - rect.bottom;

                // Call function when the div is at or near the bottom
                if (distance >= 0) {
                    onReachBottom();
                }
            }
        };

        // Initial calculation
        calculateDistanceFromBottom();

        // Recalculate on window resize and scroll
        window.addEventListener('resize', calculateDistanceFromBottom);
        window.addEventListener('scroll', calculateDistanceFromBottom);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('resize', calculateDistanceFromBottom);
            window.removeEventListener('scroll', calculateDistanceFromBottom);
        };
    }, []); // Empty dependency array to run only once on mount


    return (
        <>
            <div className="datatable-crud-demo">
                <ConfirmDialog />
                <Breadcrumbs mainTitle="Machine Status" parent="General" title="Machine Status" />
                <FullPageLoader open={statusLoader} />
                <Toast ref={toast} />
                <div className="card">
                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
                    <DataTable
                        ref={dt}
                        value={machineStatuses}
                        selection={selectedMachineStatuses}
                        onSelectionChange={(e) => setSelectedMachineStatuses(e.value)}
                        dataKey="machineStatus_code"
                        paginator={false}
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        // globalFilter={globalFilter}
                        // header={header}
                        emptyMessage={" "}
                        className="datatables-demo"
                    >
                        {columns.map((col, i) => (
                            <Column key={i} field={col.field} header={col.header} body={col.body} sortable={col.sortable} style={col.style} exportable={col.exportable} selectionMode={col.selectionMode} />
                        ))}
                    </DataTable>
                    {loader && (
                        <TableSkeleton rowCount={pageSize} columns={columns} />
                    )}

                    {/* <Pagination
                        currentPage={currentPage}
                        totalPages={metadata?.totalPages}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        pageSize={pageSize}
                        setLoader={setLoader}
                    /> */}
                    {/* <p>Distance from bottom of viewport: {distanceFromBottom}px</p> */}
                </div>
            </div>
        </>
    );
}
import React, { createContext, useContext, useState } from 'react';

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
    const [combinedFormData, setCombinedFormData] = useState({});

    const updateFormData = (key, data) => {
        setCombinedFormData(prevData => ({ ...prevData, [key]: data }));
    };

    const clearFormData = () => {
        setCombinedFormData({
          PersonalDetails: {},
          CompanyDetails: {},
          BankDetails: {},
          PicturesUpload:{}
        });
      };

    return (
        <FormDataContext.Provider value={{ combinedFormData, updateFormData, clearFormData }}>
            {children}
        </FormDataContext.Provider>
    );
};

export const useFormData = () => useContext(FormDataContext);

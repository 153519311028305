// // import React from 'react';
// import React, { Fragment } from "react";
// import { Btn, H4 } from "../AbstractElements";
// import { useForm } from "react-hook-form";
// import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap'
// import {  UsersCountryMenu, Address,  CBankName, PANNumber, CompanyTelephone, CompanyAddress, BankACH,BankACN, BankBranch,BankDetail,BankName,IFSC, Companyincorporation, CompanyState, District, NextStep, PrevStep
//  } from '../Constant';

// const BankDetails = () => {
//     const { register, handleSubmit, formState: { errors } } = useForm();
//     const onEditSubmit = (data) => {
//         alert(data)
//     }
//     return (
//         <Fragment>
//             <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
//                 <CardHeader>
//                     <H4 attrH4={{ className: "card-title mb-0" }}>{BankDetail}</H4>
//                     <div className="card-options">
//                         <a className="card-options-collapse" href="#javascript">
//                             <i className="fe fe-chevron-up"></i>
//                         </a>
//                         <a className="card-options-remove" href="#javascript">
//                             <i className="fe fe-x"></i>
//                         </a>
//                     </div>
//                 </CardHeader>
//                 <CardBody>
//                     <Row>
//                         <Col md="6">
//                             <FormGroup className="mb-3"> <Label className="form-label">{IFSC}</Label>
//                                 <Input className="form-control" type="text" placeholder="IFSC Number" {...register("company", { required: true })} /><span style={{ color: "red" }}>{errors.company && 'IFSC is required'} </span>
//                             </FormGroup>
//                         </Col>
//                         <Col md="6">
//                             <FormGroup> <Label className="form-label">{CBankName}</Label>
//                                 <Input className="form-control" type="text" placeholder="Bank Name" {...register("Username", { required: true })} /><span style={{ color: "red" }}>{errors.Username && 'Bank Name is required'} </span>
//                             </FormGroup>
//                         </Col>
//                         <Col md="6">
//                             <FormGroup> <Label className="form-label">{BankBranch}</Label>
//                                 <Input className="form-control" type="text" placeholder="Bank Branch" {...register("EmailAddress", { required: true })} /><span style={{ color: "red" }}>{errors.EmailAddress && 'Bank Branch is required'} </span>
//                             </FormGroup>
//                         </Col>
//                         <Col sm="6" md="6">
//                             <FormGroup><Label className="form-label">{BankACH}</Label>
//                                 <Input className="form-control" type="text" placeholder="Account Holder Name" {...register("FirstName", { required: true })} /><span style={{ color: "red" }}>{errors.FirstName && 'A/C Holder Name is required'} </span>
//                             </FormGroup>
//                         </Col>
//                         <Col  md="12">
//                             <FormGroup><Label className="form-label">{BankACN}</Label>
//                                 <Input className="form-control" type="number" placeholder="Account Number" {...register("FirstName", { required: true })} /><span style={{ color: "red" }}>{errors.FirstName && 'A/C Number is required'} </span>
//                             </FormGroup>
//                         </Col>





//                     </Row>
//                 </CardBody>
//                 {/* <CardFooter className="text-"> */}
//                 <div style={{display:'flex' ,justifyContent:"space-between",margin:"2rem"}}>

//                     <Btn className="text-start"  attrBtn={{ color: "primary", type: "submit" }} >{PrevStep}</Btn>
//                     {/* </CardFooter> */}
//                     {/* <CardFooter className="text-end"> */}
//                         <Btn attrBtn={{ color: "primary", type: "submit" }} >{NextStep}</Btn>
//                     {/* </CardFooter> */}
//                 </div>

//             </Form>
//         </Fragment>
//     )
// }
// export default BankDetails
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useFormData } from '../Components/FormDataProvider';

export default function BankDetails({ handleBack, handleNext, setValue, isUpdate, onValidate }) {
    const { updateFormData, combinedFormData } = useFormData();
    const [formValues, setFormValues] = useState((combinedFormData && combinedFormData.BankDetails) ? combinedFormData.BankDetails : {
        bankName: '',
        ifscCode: '',
        holderName: '',
        accountNumber: '',
        bankBranch: '',
    });


    const [errors, setErrors] = useState({
        bankName: false,
        ifscCode: false,
        holderName: false,
        accountNumber: false,
        bankBranch: false,
    });
    const [fetchedDetails, setFetchedDetails] = useState(false);

    const handleChange = (e) => {
        let { id, value } = e.target;
        if (id === 'ifscCode' && value.length > 11) {
            value = value.slice(0, 11); // Truncate to 11 characters
        }
        setFormValues({
            ...formValues,
            [id]: value
        });

        // Validate the current field
        let errorMessage = '';
        switch (id) {
            case 'bankName':
                errorMessage = value.trim() === '' ? true : false;
                break;
            case 'ifscCode':
                //console.log(value.length)
                errorMessage = (value.trim() === '' || value.length !== 11) ? true : false;
                if (value.length === 11) fetchBankBranchByIFSCCode(value);
                else {
                    setFormValues({
                        ...formValues, ifscCode: value, bankName: "", bankBranch: ""
                    })
                    setFetchedDetails(false);
                }
                break;
            case 'holderName':
                errorMessage = value.trim() === '' ? true : false;
                break;
            case 'accountNumber':
                errorMessage = value.trim() === '' ? true : false;
                if (!/^\d{10,16}$/.test(value)) {
                    errorMessage = 'Account Number must be between 10 and 16 digits';
                } else {
                    errorMessage = '';
                }
                break;
            case 'bankBranch':
                errorMessage = value.trim() === '' ? true : false;
                break;
            default:
                break;
        }

        setErrors({
            ...errors,
            [id]: errorMessage
        });
        let isFormValid = true;
        Object.values(errorMessage).forEach(error => {
            if (error) {
                isFormValid = false;
            }
        });
        //console.log(errorMessage)
        onValidate(isFormValid ? true : false);
        if (isFormValid) {
            updateFormData("BankDetails", formValues)
            // handleNext();
            // setValue(1);
        }
    };

    const validate = () => {
        const newErrors = {
            bankName: formValues.bankName === '',
            ifscCode: formValues.ifscCode === '',
            holderName: formValues.holderName === '',
            accountNumber: formValues.accountNumber === '',
            bankBranch: formValues.bankBranch === ''
        };

        setErrors(newErrors);

        // Check if there are any errors
        return !Object.values(newErrors).some((error) => error);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // handleNext();
        if (validate()) {
            updateFormData("BankDetails", formValues)
            // handleNext();
            setValue(3);
        }
    };

    const fetchBankBranchByIFSCCode = (IFSC) => {
        fetch("https://bank-apis.justinclicks.com/API/V1/IFSC/" + IFSC).then((response) => {
            if (response.ok) {
                response.json().then((result) => {
                    setFormValues({ ...formValues, ifscCode: IFSC, bankName: result.BANK, bankBranch: result.BRANCH })
                    setFetchedDetails(true);
                    setErrors({ ...errors, ifscCode: "", bankName: "", bankBranch: "" })
                })
            } else {
                setErrors({ ...errors, ifscCode: "Please enter valid IFSC code" })
                setFetchedDetails(false);
            }

        })
    }

    return (
        <div style={{ opacity: 1 }}>
            <Card  sx={{ boxShadow: "0px 0px 0px 0px lightGray", borderRadius:"25px", borderTopRightRadius:"0px", borderTopLeftRadius:"0px", marginTop:"1vh", background:"transparent"}}>
                <CardContent>
                    {/* <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "1.8em", marginTop: -10, height: "1.8em", justifyContent: "center", display: "flex", alignItems: "center", borderRadius: "50%", border: "2px solid #a0a0a0", marginRight: "0.9vw" }}>
                            <span style={{ fontSize: "1em", color: '#a0a0a0' }}>3</span>
                        </div>
                        <Typography gutterBottom variant="h5" component="div" fontStyle={"Roboto,sans-serif"} color='#a0a0a0' display={"flex"}>
                            Bank Details
                        </Typography>
                    </div> */}
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                        style={{ marginTop: "1vh", padding: "1vh" }}
                        onSubmit={handleSubmit}
                    >
                        <div style={{ display: "flex" }}>
                            <TextField
                                error={errors.ifscCode || errors.ifscCode == true}
                                fullWidth
                                id="ifscCode"
                                label="IFSC"
                                required
                                value={formValues.ifscCode}
                                onChange={handleChange}
                                helperText={errors.ifscCode}
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", marginTop: "2vh" }}>
                            <TextField
                                error={errors.bankName || errors.bankName == true}
                                fullWidth
                                id="bankName"
                                label="Bank Name"
                                required
                                // style={{ marginLeft: "1vw" }}
                                value={formValues.bankName}
                                disabled={fetchedDetails}
                                onChange={handleChange}
                                helperText={errors.bankName}
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />

                        </div>
                        <div style={{ display: "flex", marginTop: "2vh" }}>
                            <TextField
                                error={errors.bankBranch || errors.bankBranch == true}
                                fullWidth
                                id="bankBranch"
                                label="Bank Branch"
                                required
                                value={formValues.bankBranch}
                                disabled={fetchedDetails}
                                onChange={handleChange}
                                helperText={errors.bankBranch}
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", marginTop: "2vh" }}>
                            <TextField
                                error={errors.holderName || errors.holderName == true}
                                fullWidth
                                id="holderName"
                                label="Account Holder Name"
                                required
                                value={formValues.holderName}
                                onChange={handleChange}
                                helperText={errors.holderName}
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", marginTop: "2vh" }}>
                            <TextField
                                error={errors.accountNumber || errors.accountNumber == true}
                                fullWidth
                                id="accountNumber"
                                label="Account Number"
                                required
                                type="number"
                                // style={{ marginLeft: "1vw" }}
                                value={formValues.accountNumber}
                                onChange={handleChange}
                                helperText={errors.accountNumber}
                                InputProps={{
                                    sx: {
                                        height: '45px', // Adjust the height as needed
                                        fontSize: '0.6vw', // Adjust the font size as needed
                                        borderRadius: 30,
                                        opacity:1,
                                        backgroundColor:"#fff"
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: '0.6vw', // Adjust the label font size as needed
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.5vw', // Adjust the helper text font size as needed
                                    }
                                }}
                            />
                        </div>
                    </Box>
                </CardContent>
                {/* {!isUpdate && <div style={{ display: "flex", justifyContent: "center", marginBottom: "2vh" }}>
                    <Button onClick={() => { setValue(1) }} type="submit" style={{ marginTop: "2vh", backgroundColor: "blue", borderColor: "green", color: "white", alignSelf: "end" }}>
                        Previous Step
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        type="submit"
                        // disabled={!isOtpVerified}
                        style={{ marginTop: "2vh", backgroundColor: "green", borderColor: "green", color: "white", marginLeft: 50 }}
                    >
                        Next Step
                    </Button>
                </div>} */}
            </Card>
        </div>
    );
}

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useState } from 'react'
import SecurityCheque from './SecurityCheque/SecurityCheque'
import AdvancePaymentCash from './AdvancePayment/AdvancePaymentCash'
import AdvancePaymentBank from './AdvancePayment/AdvancePaymentBank'
import AdvancePaymentCheque from './AdvancePayment/AdvancePaymentCheque'

export default function AdvancePayment({type}) {
    const [advancePaymentType, setAdvancePaymentType] = useState()
    return (
        <div>
            <div className="row" style={{ marginTop: 20 }}>
                <FormControl component="fieldset" sx={{ ml: 2 }}>
                    <FormLabel component="legend" sx={{ fontSize: '13px' }}>Advance-Payment-Type</FormLabel>
                    <RadioGroup
                        name="status"
                        value={advancePaymentType}
                        onChange={(e) => setAdvancePaymentType(e.target.value)} // Handle change
                        sx={{
                            flexDirection: 'row',
                        }}
                    >
                        <FormControlLabel
                            value="Cash"
                            control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                            label="Cash"
                            sx={{ fontSize: '13px' }}
                        />
                        <FormControlLabel
                            value="Cheque"
                            control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                            label="Cheque"
                            sx={{ fontSize: '13px' }}
                        />
                        <FormControlLabel
                            value="Bank"
                            control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                            label="Bank"
                            sx={{ fontSize: '13px' }}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="row" style={{ marginTop: 20 }}>
                {
                    advancePaymentType === "Cash" && (
                        <AdvancePaymentCash type={type} />
                    )
                }
                {
                    advancePaymentType === "Cheque" && (
                        <AdvancePaymentCheque type={type} />
                    )
                }
                {
                    advancePaymentType === "Bank" && (
                        <AdvancePaymentBank type={type} />
                    )
                }
            </div>
        </div>
    )
}

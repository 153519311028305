import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { url } from '../../../../Constant';
import { Toast } from 'primereact/toast';
import { FormContext } from './Provider/FormProvider';
import { useLocation } from 'react-router';
import { Calendar } from 'primereact/calendar';
import { parseISO } from 'date-fns';

export default function MachineInfo({ handleStatus }) {
    const { formData, updateFormData } = useContext(FormContext)
    const location = useLocation();
    const [formValues, setFormValues] = useState({
        prodOwner: "",
        prodType: "",
        prodId: "",
        machineModel: "",
        machineModelYear: new Date().getFullYear(),
        machineModelYear: undefined,
        machineSerialNo: undefined,
        rtoNumber: undefined,
        insuranceValidThru: new Date().getFullYear(),
        status: undefined
    })
    const [machineStatuses, setMachineStatuses] = useState([]);
    const toast = useRef(null);

    useEffect(() => {
        setFormValues(location.state);
        fetchMachineStatuses();
    }, [])

    useEffect(() => {
        updateFormData("machineInfo", formValues)
    }, [])


    const fetchMachineStatuses = () => {

        fetch(url + `/api/sup/machineStatus/getAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 403) {
                    toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
                    throw new Error('Unauthorized');
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then(result => {
                result = result.filter((item) => item.status === true)
                setMachineStatuses(result);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while fetching data', life: 3000 });
            });
    }

    function parseISODate(isoDateString) {
        return isoDateString ? new Date(isoDateString) : null; // Return null for empty input
    }

    function formatDateToMMDDYYYY(dateString) {
        if (!dateString) return ''; // Return empty string for empty input
        const date = new Date(dateString);

        // Extract month, day, and year from the Date object
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        // Format the date as mm/dd/yyyy
        return `${month}/${day}/${year}`;
    }

    const handleStatusChange = (e) => {
        const newStatus = Number(e.target.value);
    
        // Update the formValues state with the new status value
        setFormValues(prevState => {
            const updatedFormValues = { ...prevState, status: newStatus };
    
            // Call updateFormData with the updated form values
            updateFormData("machineInfo", updatedFormValues);
    
            return updatedFormValues; // Ensure the state is updated with the latest value
        });

        handleStatus(newStatus);
        // Log the current input value (optional)
        console.log("New status:", newStatus);
    };
    

    return (
        <div>
            <Toast ref={toast} />
            <div style={{ width: "100%", height: "auto", paddingLeft: "1%", paddingRight: "1%", paddingTop: "2%", paddingBottom: "2%", backgroundColor: "#fff", borderRadius: 30 }}>
                <h5>Motor Grader Info</h5>
                <div className="row" style={{ marginTop: 25 }}>
                    <TextField
                        fullWidth
                        id="prodOwner"
                        name="prodOwner"
                        label="Machine Owner"
                        sx={{ width: "24%", ml: "2%", backgroundColor: "#F7F8F9" }}
                        value={formValues.prodOwner}
                        disabled
                        // onChange={(e) => handleChange('prodOwner', e.target.value)}
                        // error={!!formErrors.menuName}
                        // helperText={formErrors.menuName}
                        InputProps={{
                            sx: {
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: '12px', // Adjust the helper text font size as needed
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        id="prodType"
                        name="prodType"
                        label="Product Type"
                        sx={{ width: "24%", ml: "1%", backgroundColor: "#F7F8F9" }}
                        disabled
                        value={formValues.prodType}
                        // onChange={(e) => handleChange('prodOwner', e.target.value)}
                        // error={!!formErrors.menuName}
                        // helperText={formErrors.menuName}
                        InputProps={{
                            sx: {
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: '12px', // Adjust the helper text font size as needed
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        id="prodId"
                        name="prodId"
                        label="Asset Id"
                        sx={{ width: "22%", ml: "1%", backgroundColor: "#F7F8F9" }}
                        disabled
                        value={formValues.prodId}
                        // onChange={(e) => handleChange('prodOwner', e.target.value)}
                        // error={!!formErrors.menuName}
                        // helperText={formErrors.menuName}
                        InputProps={{
                            sx: {
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: '12px', // Adjust the helper text font size as needed
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        id="machineModel"
                        name="machineModel"
                        label="Machine Model"
                        sx={{ width: "23%", ml: "1%", backgroundColor: "#F7F8F9" }}
                        disabled
                        value={formValues.machineModel}
                        // onChange={(e) => handleChange('prodOwner', e.target.value)}
                        // error={!!formErrors.menuName}
                        // helperText={formErrors.menuName}
                        InputProps={{
                            sx: {
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: '12px', // Adjust the helper text font size as needed
                            }
                        }}
                    />

                    <TextField
                        fullWidth
                        id="machineModelYear"
                        name="machineModelYear"
                        label="Model Year"
                        sx={{ width: "18%", ml: "2%", mt: 3, backgroundColor: "#F7F8F9" }}
                        disabled
                        value={formValues.machineModelYear}
                        // onChange={(e) => handleChange('prodOwner', e.target.value)}
                        // error={!!formErrors.menuName}
                        // helperText={formErrors.menuName}
                        InputProps={{
                            sx: {
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: '12px', // Adjust the helper text font size as needed
                            }
                        }}
                    />

                    <TextField
                        fullWidth
                        id="machineSerialNo"
                        name="machineSerialNo"
                        label="Machine Serial No."
                        sx={{ width: "19%", ml: "1%", mt: 3, backgroundColor: "#F7F8F9" }}
                        disabled
                        value={formValues.machineSerialNo}
                        // onChange={(e) => handleChange('prodOwner', e.target.value)}
                        // error={!!formErrors.menuName}
                        // helperText={formErrors.menuName}
                        InputProps={{
                            sx: {
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: '12px', // Adjust the helper text font size as needed
                            }
                        }}
                    />

                    <TextField
                        fullWidth
                        id="rtoNumber"
                        name="rtoNumber"
                        label="Machine RTO No."
                        sx={{ width: "19%", ml: "1%", mt: 3, backgroundColor: "#F7F8F9" }}
                        disabled
                        value={formValues.rtoNumber}
                        // onChange={(e) => handleChange('prodOwner', e.target.value)}
                        // error={!!formErrors.menuName}
                        // helperText={formErrors.menuName}
                        InputProps={{
                            sx: {
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: '12px', // Adjust the helper text font size as needed
                            }
                        }}
                    />

                    <TextField
                        fullWidth
                        id="insuranceValidThru"
                        name="insuranceValidThru"
                        label="Insurance Validity"
                        sx={{ width: "17%", ml: "1%", mt: 3, backgroundColor: "#F7F8F9" }}
                        disabled
                        type='text'  // Change to 'text' because 'date' will override the format
                        value={formatDateToMMDDYYYY(parseISODate(formValues.insuranceValidThru))}
                        InputProps={{
                            sx: {
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: '12px', // Adjust the helper text font size as needed
                            }
                        }}
                    />
                    {/* <Calendar style={{ width: "17%", ml: "1%", mt: 3, backgroundColor: "#F7F8F9" }} id="insuranceValidThru" placeholder='MM-DD-YYYY' value={parseISODate(formValues.insuranceValidThru)} /> */}

                    <FormControl
                        sx={{
                            width: "19%",
                            mt: 3,
                            ml: "1%",
                            backgroundColor: "#F7F8F9",
                            borderRadius: 3
                        }}>
                        <InputLabel id="status-label" sx={{ fontSize: '13px' }}>Machine-Status</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status"
                            name="status"
                            value={formValues.status}
                            onChange={(e) => handleStatusChange(e)} // Uncomment to handle change
                            label="Machine-Status"
                            sx={{
                                height: '45px', // Adjust the height as needed
                                fontSize: '13px', // Adjust the font size as needed
                                borderRadius: 3
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        fontSize: '13px',
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {machineStatuses?.map((item) => (<MenuItem value={item.id}>
                                {item.statusName}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </div>
            </div>
        </div>
    )
}

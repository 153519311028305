import React, { useEffect, useState } from 'react';
import { Box, TableContainer, Typography, Checkbox, FormControlLabel, DialogActions, Divider } from '@mui/material';
import Swal from 'sweetalert2';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { url } from '../../../_helper/Route/Url';
import { Shield } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldHalved, faUserGear } from '@fortawesome/free-solid-svg-icons';

export default function Permissions({ roleId }) {
    console.log(roleId)
    const [menus, setMenus] = useState([]);
    const [open, setOpen] = useState(false);
    const [createObject, setCreateObject] = useState({});
    const [all, setAll] = useState(false)

    const fetchAllMenus = async () => {
        fetch(url + "/api/permission/get_permission?roleId=" + roleId.toString(), {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then((result) => {
                        console.log(result)
                        setMenus(result.mappedData);
                        setCreateObject(result.permission)
                    }).catch((error) => {
                        console.error("Error:", error);
                    });
                }
            }).catch((error) => {
                console.error("Error:", error);
            })
    }

    useEffect(() => {

    }, []);

    const handleMenuForSubMenu = (data) => {
        if (data && data.permission_subMenu) {
            const keys = Object.keys(data.permission_subMenu);
            keys.forEach((key) => {
                let subMenuNew;
                if (menus && menus.length > 0 && (data.permission_subMenu[key].read == true || data.permission_subMenu[key].write == true)) {
                    menus.forEach((menu) => {
                        if (menu.subMenus && menu.subMenus.length > 0) {
                            subMenuNew = menu.subMenus.find((sub) => sub.id == key)
                            subMenuNew = menu.id
                            if (data.permission_subMenu[key].read == true || data.permission_subMenu[key].write == true)
                                data.permission_menu = { ...data.permission_menu, [subMenuNew]: { read: true } }
                            else if (data.permission_subMenu[key].read == false || data.permission_subMenu[key].write == false)
                                data.permission_menu = { ...data.permission_menu, [subMenuNew]: { read: false } }
                        }
                    })
                }
            })
        }
        return data;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = createObject;
        //console.log(createObject)
        data = { ...data, role_id: roleId };
        data = handleMenuForSubMenu(data)
        //console.log(data)
        // //console.log(menus)

        fetch(url + "/api/permission/add_permission", {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(response => {
            if (response.ok) {
                Swal.fire({
                    title: "Updated!",
                    html: `
                        <div class="custom-content">
                            Permission has been Updated
                        </div>
                        <style>
                            .swal2-icon.swal2-success {
                                font-size: 11px !important; /* Adjust the size as needed */
                            }
                            .swal2-title {
                                font-size: 18px !important; /* Adjust the size as needed */
                            }
                            .custom-content {
                                font-size: 14px !important; /* Adjust the size as needed */
                            }
                        </style>
                    `,
                    icon: "success",
                    customClass: {
                        icon: 'swal2-icon',
                        title: 'swal2-title',
                        content: 'custom-content'
                    }
                });
                handleClose();
            }
        }).catch(error => console.error('Error:', error));
    }

    const handleOpen = () => {
        setOpen(true);
        fetchAllMenus();
    }
    const handleClose = () => setOpen(false);

    const handleForSubMenu = (subMenuId, value, key, menuId) => {
        //console.log(createObject)
        setCreateObject((prev) => {
            //console.log(prev)
            return {
                ...prev,
                permission_subMenu: {
                    ...prev.permission_subMenu,
                    [subMenuId]: {
                        ...prev.permission_subMenu?.[subMenuId], // Merge existing values
                        [key]: value
                    }
                }
            };
        });
        //console.log(createObject)
        setMenus((prevMenus) => {
            let updatedMenus = prevMenus.map((menu) => {
                if (menu.id == menuId) {
                    let updatedSubMenus = menu.subMenus.map((subMenu) => {
                        if (subMenu.id == subMenuId) {
                            return { ...subMenu, [key]: value };
                        }
                        return subMenu;
                    });
                    return { ...menu, subMenus: updatedSubMenus };
                }
                return menu;
            });
            return updatedMenus;
        });
    };


    const handleForMenu = (menuId, value, key) => {
        menuId = Number(menuId);
        let foundMenu = menus.find((menu) => menu.id === menuId);
        if (foundMenu.subMenus && foundMenu.subMenus.length > 0) return;
        setCreateObject((prev) => {
            return {
                ...prev,
                permission_menu: {
                    ...prev.permission_menu,
                    [menuId]: {
                        ...prev.permission_menu?.[menuId],
                        [key]: value
                    }
                }
            }
        })
        setMenus((prevMenus) => {
            let updatedMenus = prevMenus.map((menu) => {
                if (menu.id === menuId) {
                    return { ...menu, [key]: value };
                }
                return menu;
            });
            return updatedMenus;
        });
    }

    const handleAll = () => {
        //console.log("Selecting all permissions");

        if (menus && menus.length > 0) {
            menus.forEach((menu) => {
                // Set permissions for each menu
                handleForMenu(menu.id, !all, "read");
                handleForMenu(menu.id, !all, "write");

                // Set permissions for each submenu within the menu
                if (menu.subMenus && menu.subMenus.length > 0) {
                    menu.subMenus.forEach((subMenu) => {
                        handleForSubMenu(subMenu.id, !all, "read", menu.id);
                        handleForSubMenu(subMenu.id, !all, "write", menu.id);
                    });
                }
            });
        }

        // Toggle the "all" state
        setAll(!all);
        //console.log(menus, "Updated menus:");
    };


    useEffect(() => {
    }, [all, menus, setMenus, setAll]); // Depend on "all" and "menus"




    return (
        <>
            {/* <Shield onClick={handleOpen} size={"20px"} style={{cursor:"pointer", color:"darkblue"}} /> */}
            <Button onClick={handleOpen} style={{ width: "30px", height: "25px", backgroundColor: "#fff", borderColor: "#fff", paddingLeft:"5px"}}>
                <FontAwesomeIcon color="#0b0ff1c7" icon={faUserGear} fontSize='18px' />
            </Button>
            <Dialog
                visible={open}
                onHide={handleClose}
                style={{ minWidth: "33vw" }}
                header={"Permissions"}

            >
                <TableContainer>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 5, paddingRight: 5, paddingTop: 2 }}>
                        <Typography variant='h6' fontSize={20} fontWeight="Normal">All</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={all}
                                    onChange={handleAll}
                                    color="primary"
                                />
                            }
                            sx={{ margin: 0 }}
                        />
                    </Box>
                    <hr />
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 5, paddingRight: 5, paddingTop: 2 }}>
                        <Typography variant='h6' fontSize={16} fontWeight="bold">Menus</Typography>
                        <Typography variant='h6' fontSize={16} fontWeight="bold">Permissions</Typography>
                    </Box>
                    {menus && menus.length > 0 && menus.map((menu, index) => (
                        <>
                            <Box sx={{ paddingLeft: 5, paddingRight: 5, paddingTop: 3 }}>
                                <Typography fontWeight="450">{menu.menuName}</Typography>
                                {
                                    (menu.subMenus && menu.subMenus.length > 0) ?
                                        menu.subMenus.map((subMenu, i) => {
                                            return (
                                                <Box key={i} sx={{ paddingLeft: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography fontSize={15}>{subMenu.subMenuName}</Typography>
                                                    <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <Typography fontSize={12}>Read</Typography>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={Boolean(subMenu && subMenu.read)}
                                                                        onChange={() => handleForSubMenu(subMenu.id, !subMenu.read, "read", menu.id)}
                                                                        color="primary"
                                                                        sx={{ transform: 'scale(0.8)' }}
                                                                    />
                                                                }
                                                                sx={{ margin: 0 }}
                                                            />
                                                        </Box>
                                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", ml: 2 }}>
                                                            <Typography fontSize={12}>Write</Typography>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={Boolean(subMenu && subMenu.write)}
                                                                        onChange={() => handleForSubMenu(subMenu.id, !subMenu.write, "write", menu.id)}
                                                                        color="primary"
                                                                        sx={{ transform: 'scale(0.8)' }}
                                                                    />
                                                                }
                                                                sx={{ margin: 0 }}
                                                            />
                                                        </Box>

                                                    </Box>
                                                </Box>
                                            );
                                        }) :
                                        <Box key={index} sx={{ paddingLeft: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography fontSize={15}>{menus[index].menuName}</Typography>
                                            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography fontSize={12}>Read</Typography>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={Boolean(menu && menu.read)}
                                                                onChange={() => handleForMenu(menu.id, !menu.read, "read")}
                                                                color="primary"
                                                                sx={{ transform: 'scale(0.8)' }}
                                                            />
                                                        }
                                                        sx={{ margin: 0 }}
                                                    />
                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", ml: 2 }}>
                                                    <Typography fontSize={12}>Write</Typography>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={Boolean(menu && menu.write)}
                                                                onChange={() => handleForMenu(menu.id, !menu.write, "write")}
                                                                color="primary"
                                                                sx={{ transform: 'scale(0.8)' }}
                                                            />
                                                        }
                                                        sx={{ margin: 0 }}
                                                    />
                                                </Box>

                                            </Box>
                                        </Box>
                                }
                                <Divider sx={{ borderWidth: 2 }} />
                            </Box>
                        </>
                    ))}
                </TableContainer>
                {/* <DialogActions sx={{ marginTop: 3 }}>
                    <Button color='error' onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Update Permissions</Button>
                </DialogActions> */}
                <DialogActions>
                    <Button severity='danger' style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} color='error' onClick={handleClose}>Cancel</Button>
                    <Button severity='primary' onClick={handleSubmit} style={{ borderRadius: "10px", fontSize: "15px", padding: "8px", fontWeight: "bold" }} type="submit">{"Update"}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
import React from 'react'
import MenuTable from './MenuTable';
import { Breadcrumbs } from '../../../AbstractElements';

export default function Menu() {
    
    return (
        <div>
            <Breadcrumbs mainTitle="Menu" parent="General" title="Menu" />
            <MenuTable />
        </div>
    )
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Table } from 'reactstrap';
import { Image, H5, P } from '../../../AbstractElements';
import { DailyDropdown, MonthlyData, RecentSalesTitle } from '../../../Constant';
import DropdownCommon from '../../Common/Dropdown';
import {url} from '../../../Constant';


const RecentSales = () => {
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      // Replace with your API endpoint
      fetch(`${url}/api/sup/dashboard/monthly-companies`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setCompanies(data);
          setLoading(false);
          //console.log(data)
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }, []);
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  return (
    <Col xxl='4' md='6' className='appointment-sec box-col-6'>
      <div className='appointment'>
        <Card>
          <CardHeader className='card-no-border'>
            <div className='header-top'>
              <H5 attrH5={{ className: 'm-0' }}>{MonthlyData}</H5>
              {/* <div className='card-header-right-icon'>
                <DropdownCommon icon={false} options={DailyDropdown} btn={{ caret: true }} />
              </div> */}
            </div>
          </CardHeader>
          <CardBody className='pt-0'>
            <div className='appointment-table'>
              <Table borderless responsive>
                <tbody>
                  {companies?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {/* <Image attrImage={{ className: 'img-fluid img-40 rounded-circle', src: require(`../../../assets/images/dashboard/user/${item.image}`), alt: 'user' }} /> */}
                      </td>
                      <td className='img-content-box'>
                        <Link className='d-block f-w-500' to={`${process.env.PUBLIC_URL}/sup/company/profile/${item.company_code}`}>
                          {item?.CompanyDetails?.companyName}
                        </Link>
                        <span className='f-light'>{item.register_date}</span>
                      </td>   
                      <td className='text-end'>
                        <P attrPara={{ className: 'm-0 font-success' }}>{item.status}</P>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
};

export default RecentSales;

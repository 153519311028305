import React, { useContext, useEffect, useState,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FileText, LogIn, Mail, User } from "react-feather";
import man from "../../../../assets/images/dashboard/profile.png";
import user from "../../../../assets/images/user/4.jpg";
import { Toast } from "reactstrap";
import { LI, UL, Image, P } from "../../../../AbstractElements";
import CustomizerContext from "../../../../_helper/Customizer";
import { Account, Admin, Inbox, LogOut, Taskboard,url } from "../../../../Constant";

const UserHeader = () => {
  const toast = useRef(null);
  const history = useNavigate();
  const navigate = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("Emay Walter");
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = localStorage.getItem("name");
  const auth0_role = localStorage.getItem("role");

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
    setName(localStorage.getItem("Name") ? localStorage.getItem("Name") : name);
  }, []);

  const Logout = async () => {
    // //console.log(localStorage.getItem("token"))
    //console.log("Logout")
    try {
      const response = await fetch(`${url}/auth/sadmin_logout`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
      });
  
      // Parse the response as JSON
      const data = await response.json();
  
      // Check for a successful response
      if (response.ok) { // response.ok is true for status codes in the range 200-299
        localStorage.clear();
        navigate(`${process.env.PUBLIC_URL}/sadmin_login`);
      } else {
        console.error("Logout failed:", data.message || response.statusText);
      }
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };
  

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <>
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
        <img src={user} style={{width:"35px", height:"35px"}} alt="" />
        <div className="media-body">
          <span>{auth0_profile ?? name}</span>
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            {auth0_role??Admin} <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/profile/${layoutURL}`),
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        {/* <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app/${layoutURL}`),
          }}>
          <Mail />
          <span>{Inbox}</span>
        </LI> */}
        {/* <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo/${layoutURL}`),
          }}>
          <FileText />
          <span>{Taskboard}</span>
        </LI> */}
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
    </>

  );
};

export default UserHeader;

import React from 'react';
import { Col, Row } from 'reactstrap';
import { Widgets2Data, Widgets2Data2, WidgetsData, WidgetsData2, WidgetsData3, WidgetsData4 } from '../../../Data/DefaultDashboard';
import Widgets1 from '../../Common/CommonWidgets/Widgets1';
import Widgets2 from '../../Common/CommonWidgets/Widgets2';
import { BarChart2, DollarSign, Info, Lock, User } from 'react-feather';
import { useEffect, useState } from 'react';
import { url } from '../../../Constant';



const WidgetsWrapper = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data1, setData1] = useState({
    title: 'Total Companies',
    total: 0,
    color: 'secondary',
    icon: <BarChart2 />
  })

  const [data2, setData2] = useState({
    title: 'Free Companies',
    total: 0,
    color: 'warning',
    icon: <Lock/>,
  })

  const [data3, setData3] = useState({
    title: 'Paid Companies',
  total: 0,
  color: 'primary',
  icon: <DollarSign/>,
  })

  const [data4, setData4] = useState({
    title: 'Inactive Companies',
    total: 0,
    color: 'success',
    icon: <Info/>,
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(`${url}/api/sup/dashboard/company-counts`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setData1({...data1, total: data.totalCompanies });
        setData2({...data2, total: data.freeCompanies });
        setData3({...data3, total: data.paidCompanies });
        setData4({...data4, total: data.inactiveCompanies });
        //console.log(data)
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <Col className='col-xxl-8 col-sm-6 box-col-6'>
        <Row className='d-flex align-items-stretch g-3' style={{ alignItems: "stretch", flexWrap: "wrap" }}>
          <Col xl='3' className="d-flex">
            <Widgets1 data={data1} />

          </Col>
          <Col xl='3' className="d-flex">
            <Widgets1 data={data2} />
          </Col>
          <Col xl='3' className="d-flex">
            <Widgets1 data={data3} />
          </Col>
          <Col xl='3' className="d-flex">
            <Widgets1 data={data4} />
          </Col>
        </Row>
      </Col>
      {/* <Col xxl='auto' xl='2' sm='3' className='box-col-5' >
        <Row>
          <Col xl='12'>
            <Widgets1 data={WidgetsData2} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={WidgetsData4} />
          </Col>
        </Row>
      </Col>
      <Col xxl='auto' xl='2' sm='3' className='box-col-5' >
        <Row>
          <Col xl='12'>
            <Widgets1 data={WidgetsData3} />
          </Col>
          <Col xxl='12' xl='6' className='box-col-12'>
            <Widgets2 chartClass='profit-chart ' data={Widgets2Data2} />
          </Col>
        </Row>
      </Col> */}

      {/* <Col xxl='auto' xl='2' sm='3' className='box-col-5'>
        <Row>
          <Col xl='12'>
            <Widgets1 data={WidgetsData4} />
          </Col>
        </Row>
      </Col> */}

    </>
  );
};


export default WidgetsWrapper;

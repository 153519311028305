import React from 'react'
import { Breadcrumbs } from '../../../AbstractElements'
import RoleTable from './RoleTable'

export default function Role() {
  return (
    <div>
       <Breadcrumbs mainTitle="Roles" parent="General" title="Roles" />
       <RoleTable />
    </div>
  )
}


import React from 'react'
const poweredBy = () => {
  return (
    <div classname="mt-1 justify-content-center">
    <span classname="mt-1 d-flex mx-auto">
      Powered By{" "}
      <b>
        <a href="https://fixingdots.com">
          <span style={{ fontFamily: "Baloo 2, cursive", color: "#313131" }}>
            FIXING
          </span>
          <span style={{ fontFamily: "Baloo 2, cursive", color: "#ffa514" }}>
            DOTS
          </span>
        </a>
      </b>{" "}
    </span>
  </div> 
  )
}
export default poweredBy
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Btn, H4, H5, Image } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import { Button } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { url } from "../../../Constant";
import { Toast } from 'primereact/toast';

const CompanyDetails = ({ companyDetails, refreshData }) => {
    const toast = useRef();
    const [isDisabledBankDetails, setisDisabledBankDetails] = useState(true);
    const [isDisabledPersonalDetails, setisDisabledPersonalDetails] = useState(true);
    const [BankDetails, setBankDetails] = useState({});
    const [PersonalDetails, setPersonalDetails] = useState({});
    const [errors, setErrors] = useState({});
    // const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if (companyDetails?.BankDetails) {
            setBankDetails(companyDetails.BankDetails);
        }
        if (companyDetails?.PersonalDetails) {
            setPersonalDetails(companyDetails.PersonalDetails);
        }
    }, [companyDetails]);

    const validateForm = () => {
        const newErrors = {};

        // Validate account number (10-16 digits)
        const accountNumberRegex = /^[0-9]{10,16}$/;
        if (!accountNumberRegex.test(BankDetails.accountNumber)) {
            newErrors.accountNumber = 'Account number must be 10 to 16 digits';
        }

        // Validate IFSC (example: 4 letters, 0, 6 alphanumeric)
        const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/i;
        if (!ifscCodeRegex.test(BankDetails.ifscCode)) {
            newErrors.ifscCode = 'Invalid IFSC code';
        }

        // Validate required fields
        if (!BankDetails.bankBranch) {
            newErrors.bankBranch = 'Bank branch is required';
        }
        if (!BankDetails.holderName) {
            newErrors.holderName = 'Account holder name is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBankDetails({ ...BankDetails, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);

        // Validate form
        if (!validateForm()) {
            return;
        }
        try {
            const response = await fetch(url + `/api/admin/profile/update?company_code=${companyDetails.company_code}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                },
                body: JSON.stringify({ BankDetails })
            });

            if (response.ok) {
                const updatedData = await response.json();
                setBankDetails(updatedData.BankDetails);
                setisDisabledBankDetails(true)
                refreshData()
                toast.current.show({ severity: 'success', summary: 'Bank Details updated Successfully!!', life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Failed to update bank details', life: 3000 });
            }
        } catch (error) {
            console.error('Error updating bank details:', error);
            toast.current.show({ severity: 'error', summary: 'An error occurred while updating bank details', life: 3000 });
            // alert('An error occurred while updating bank details');
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {

    }, [setisDisabledBankDetails, isDisabledBankDetails])

    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

    const handleInputChangePersonal = (e) => {
        const { name, value } = e.target;
        setPersonalDetails({ ...PersonalDetails, [name]: value });
    };

    const validatePersonalDetailsForm = () => {
        const newErrors = {};
        if (!PersonalDetails.firstName) {
            newErrors.firstName = 'First name is required';
        }
        if (!PersonalDetails.lastName) {
            newErrors.lastName = 'Last name is required';
        }
        if (!PersonalDetails.dateOfBirth) {
            newErrors.dateOfBirth = 'DOB is required';
        }
        if (!PersonalDetails.gender) {
            newErrors.gender = 'Gender is required';
        }
        if (!PersonalDetails.designation) {
            newErrors.designation = 'Designation is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmitPersonalDetails = async (e) => {
        e.preventDefault();
        //console.log(PersonalDetails);
        if (!validatePersonalDetailsForm()) {
            return;
        }
        let details = PersonalDetails;
        const dateOfBirth = formatDate(details?.dateOfBirth)
        details = { ...details, companyPrefix: companyDetails?.CompanyDetails?.companyPrefix, email: companyDetails?.CompanyDetails?.email, dateOfBirth };
        setPersonalDetails(details);
        // API call to update personal details
        try {
            const response = await fetch(url + `/api/admin/profile/update?company_code=${companyDetails.company_code}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                },
                body: JSON.stringify({ PersonalDetails: details })
            });

            if (response.ok) {
                const updatedData = await response.json();
                setPersonalDetails(updatedData.PersonalDetails);
                setisDisabledPersonalDetails(true);
                refreshData()
                toast.current.show({ severity: 'success', summary: 'Contact-Person Details updated Successfully!!', life: 3000 });
            } else {
                // alert('Failed to update personal details');
                toast.current.show({ severity: 'error', summary: 'Failed to update Contact-Person details', life: 3000 });
            }
        } catch (error) {
            console.error('Error updating personal details:', error);
            // alert('An error occurred while updating personal details');
            toast.current.show({ severity: 'error', summary: 'An error occurred while updating Contact-Person details', life: 3000 });
        }
    };

    const cancelBankDetailsEdit = ()=>{
        setisDisabledBankDetails(!isDisabledBankDetails);
        setBankDetails(companyDetails.BankDetails);
    }

    const cancelPersonalDetailsEdit = ()=>{
        setisDisabledPersonalDetails(!isDisabledPersonalDetails);
        setPersonalDetails(companyDetails.PersonalDetails);
    }
    return (
        <Fragment>
            <Toast ref={toast} />
            <Form className="card" onSubmit={handleSubmit}>
                <CardHeader>
                    <H5 attrH4={{ className: "card-title mb-0" }}>Company Bank-Details</H5>
                    <div className="card-options">
                        <a className="card-options-collapse" href="#javascript">
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                        </a>
                    </div>
                </CardHeader>
                <CardBody className="mb-1" style={{ marginBottom: "-15px" }}>
                    <Row>
                        <Col sm="3" md="3">
                            <FormGroup>
                                <Label className="form-label">Account-Number</Label>
                                <Input
                                    name="accountNumber"
                                    value={BankDetails?.accountNumber}
                                    onChange={handleInputChange}
                                    disabled={isDisabledBankDetails}
                                    style={{ backgroundColor: isDisabledBankDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                                    className="form-control"
                                    type="text"
                                />
                                {errors.accountNumber && <span className="text-danger">{errors.accountNumber}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm="3" md="3">
                            <FormGroup>
                                <Label className="form-label">IFSC</Label>
                                <Input
                                    name="ifscCode"
                                    value={BankDetails?.ifscCode}
                                    onChange={handleInputChange}
                                    disabled={isDisabledBankDetails}
                                    style={{ backgroundColor: isDisabledBankDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                                    className="form-control"
                                    type="text"
                                />
                                {errors.ifscCode && <span className="text-danger">{errors.ifscCode}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm="3" md="3">
                            <FormGroup>
                                <Label className="form-label">Bank Branch</Label>
                                <Input
                                    name="bankBranch"
                                    value={BankDetails?.bankBranch}
                                    onChange={handleInputChange}
                                    disabled={isDisabledBankDetails}
                                    style={{ backgroundColor: isDisabledBankDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                                    className="form-control"
                                    type="text"
                                />
                                {errors.bankBranch && <span className="text-danger">{errors.bankBranch}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm="3" md="3">
                            <FormGroup>
                                <Label className="form-label">Account Holder Name</Label>
                                <Input
                                    name="holderName"
                                    value={BankDetails?.holderName}
                                    onChange={handleInputChange}
                                    disabled={isDisabledBankDetails}
                                    style={{ backgroundColor: isDisabledBankDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                                    className="form-control"
                                    type="text"
                                />
                                {errors.holderName && <span className="text-danger">{errors.holderName}</span>}
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
                <div className='d-flex justify-content-end mb-3' style={{ marginRight: "35px", marginTop: "-20px" }}>
                    {isDisabledBankDetails ?
                        <Button type="button" onClick={() => setisDisabledBankDetails(!isDisabledBankDetails)}>Update</Button> :
                        <>
                            <Button variant="danger" type="button" onClick={() => cancelBankDetailsEdit()} >Cancel</Button>
                            {loader ?
                                <CircularProgress
                                    style={{ marginLeft: "10px", marginTop: "3px", width: "20px", height: "20px" }}
                                />
                                : <Button style={{ backgroundColor: "#0B0FF1CY", marginLeft: "15px" }} type="submit">Submit</Button>}
                        </>
                    }
                </div>
            </Form>
            <Form className="card" onSubmit={handleSubmitPersonalDetails}>
                <CardHeader>
                    <H5 attrH4={{ className: "card-title mb-0" }}>Contact-Person Details</H5>
                    <div className="card-options">
                        <a className="card-options-collapse" href="#javascript">
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                        </a>
                    </div>
                </CardHeader>
                <CardBody className="mb-1" style={{ marginBottom: "-15px" }}>
                    <Row>
                        <Col sm="4" md="4">
                            <FormGroup>
                                <Label className="form-label">First Name</Label>
                                <Input
                                    name="firstName"
                                    value={PersonalDetails?.firstName}
                                    onChange={handleInputChangePersonal}
                                    disabled={isDisabledPersonalDetails}
                                    style={{ backgroundColor: isDisabledPersonalDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                                    className="form-control"
                                    type="text"
                                />
                                {errors.firstName && <span className="text-danger">{errors.firstName}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm="4" md="4">
                            <FormGroup>
                                <Label className="form-label">Middle Name</Label>
                                <Input
                                    name="middleName"
                                    value={PersonalDetails?.middleName}
                                    onChange={handleInputChangePersonal}
                                    disabled={isDisabledPersonalDetails}
                                    style={{ backgroundColor: isDisabledPersonalDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                                    className="form-control"
                                    type="text"
                                />
                                {errors.middleName && <span className="text-danger">{errors.middleName}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm="4" md="4">
                            <FormGroup>
                                <Label className="form-label">Last Name</Label>
                                <Input
                                    name="lastName"
                                    value={PersonalDetails?.lastName}
                                    onChange={handleInputChangePersonal}
                                    disabled={isDisabledPersonalDetails}
                                    style={{ backgroundColor: isDisabledPersonalDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                                    className="form-control"
                                    type="text"
                                />
                                {errors.lastName && <span className="text-danger">{errors.lastName}</span>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4" md="4">
                            <FormGroup>
                                <Label className="form-label">DOB</Label>
                                <Input
                                    name="dateOfBirth"
                                    value={PersonalDetails?.dateOfBirth}
                                    onChange={handleInputChangePersonal}
                                    disabled={isDisabledPersonalDetails}
                                    style={{ backgroundColor: isDisabledPersonalDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                                    className="form-control"
                                    type="date"
                                />
                                {errors.dateOfBirth && <span className="text-danger">{errors.dateOfBirth}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm="4" md="4">
                            <FormGroup>
                                <Label className="form-label">Gender</Label>
                                <Input
                                    name="gender"
                                    value={PersonalDetails?.gender}
                                    onChange={handleInputChangePersonal}
                                    disabled={isDisabledPersonalDetails}
                                    style={{ backgroundColor: isDisabledPersonalDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                                    className="form-control"
                                    type="select"
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </Input>
                                {errors.gender && <span className="text-danger">{errors.gender}</span>}
                            </FormGroup>

                        </Col>
                        <Col sm="4" md="4">
                            <FormGroup>
                                <Label className="form-label">Designation</Label>
                                <Input
                                    name="designation"
                                    value={PersonalDetails?.designation}
                                    onChange={handleInputChangePersonal}
                                    disabled={isDisabledPersonalDetails}
                                    style={{ backgroundColor: isDisabledPersonalDetails ? "#e9efff" : "#fff", fontSize: "14px" }}
                                    className="form-control"
                                    type="text"
                                />
                                {errors.designation && <span className="text-danger">{errors.designation}</span>}
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
                <div className='d-flex justify-content-end mb-3' style={{ marginRight: "35px", marginTop: "-20px" }}>
                    {isDisabledPersonalDetails ?
                        <Button type="button" onClick={() => setisDisabledPersonalDetails(!isDisabledPersonalDetails)}>Update</Button> :
                        <>
                            <Button variant="danger" type="button" onClick={() => cancelPersonalDetailsEdit()}>Cancel</Button>
                            <Button style={{ backgroundColor: "#0B0FF1CY", marginLeft: "15px" }} type="submit">Submit</Button>
                        </>
                    }
                </div>

                {/* <CardHeader style={{ marginTop: "-15px" }}>
                    <H5 attrH4={{ className: "card-title mb-0" }}>Pictures Upload</H5>
                    <div className="card-options">
                        <a className="card-options-collapse" href="#javascript">
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                        </a>
                    </div>
                </CardHeader>
                <CardBody className="mb-1" style={{ marginBottom: "-15px", display: "flex", justifyContent: "space-between" }}>
                    <Row>
                        <Col sm="4" md="4">
                            <Image attrImage={{ className: 'img-90 ml-3', alt: '', src: require('../../../assets/images/user/2.png') }} />
                        </Col>
                        <Col sm="4" md="4">
                            <Image attrImage={{ className: 'img-90 ml-3', alt: '', src: require('../../../assets/images/user/2.png') }} />
                        </Col>
                        <Col sm="4" md="4">
                            <Image attrImage={{ className: 'img-90 ml-3', alt: '', src: require('../../../assets/images/user/2.png') }} />
                        </Col>
                    </Row>
                </CardBody> */}
                {/* <div className='d-flex justify-content-end mb-3' style={{ marginRight: "35px", marginTop: "-20px" }}>
                    <Btn attrBtn={{ className: 'btn-block', color: 'primary' }}>Update</Btn>
                </div> */}
            </Form>
        </Fragment>
    )
}
export default CompanyDetails
import React, { createContext, useState } from 'react';

// Create the PermissionContext
export const PermissionContext = createContext();

// Create the PermissionProvider component
export const PermissionProvider = ({ children }) => {
  const [permissions, setPermissions] = useState({});
  
  //console.log("Permissions updated");

  return (
    <PermissionContext.Provider value={{ permissions, setPermissions }}>
      {children}
    </PermissionContext.Provider>
  );
};

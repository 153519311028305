import React, { useRef, useState } from "react";
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import './test.css'; // Import the CSS file (adjust the path as needed)
import PersonalDetails from '../Registration-global/PersonalDetails';
import CompanyDetails from '../Registration-global/CompanyDetails';
import BankDetails from '../Registration-global/BankDetails';
import PicturesUpload from '../Registration-global/PicturesUpload';
import { TextField } from "@mui/material";
import { BorderRight, classname } from "../Constant";

export default function Test() {
    const stepperRef = useRef(null);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [isPersonalDetailsValid, setIsPersonalDetailsValid] = useState(false);
    const [isCompanyDetailsValid, setIsCompanyDetailsValid] = useState(false);
    const [isBankDetailsValid, setIsBankDetailsValid] = useState(false);
    const [isPicturesDetailsValid, setIsPicturesDetailsValid] = useState(false);
    const handleValidationForPictures = (isValid) => {
        setIsPicturesDetailsValid(isValid);
    };
    const handleValidationForBank = (isValid) => {
        setIsBankDetailsValid(isValid);
    };
    const handleValidationForCompany = (isValid) => {
        setIsCompanyDetailsValid(isValid);
    };
    const handleValidationForPersonal = (isValid) => {
        setIsPersonalDetailsValid(isValid);
    };
    const handleIndex = () => {
        setActiveIndex(activeIndex - 1)
    }
    return (
        <div className='reg-context1'>
            <div className="reg-area">
                <div className='reg-row'>
                    <div className="reg-card shadow">
                        <div className="reg-card-bod col-md-4" style={{ border: "1px solid #a0a0a0", borderRadius: 10, backgroundColor: "#fff" }}>
                            {/* <PersonalDetails /> */}
                            {/* <CompanyDetails /> */}
                            {/* <BankDetails /> */}
                            {/* <PicturesUpload /> */}


                            <div className="d-flex">
                                <button
                                    onClick={() => {
                                        if (isPersonalDetailsValid) {
                                            setActiveIndex(0);
                                        }
                                    }}
                                    style={{ width: "23%", marginLeft: "2%", color: "#a0a0a0", borderTopLeftRadius: "30px", height: "40px", borderWidth: 0, borderBottom: `${activeIndex == 0 ? "2px solid #a0a0a0" : "0px solid #a0a0a0"}`, background: "transparent", fontSize: "0.8vw", fontWeight: "500" }}>Personal</button>
                                <button
                                    onClick={() => {
                                        if (isCompanyDetailsValid) {
                                            setActiveIndex(1);
                                        }
                                    }}
                                    style={{ width: "25%", color: "#a0a0a0", height: "40px", borderWidth: 0, borderBottom: `${activeIndex == 1 ? "2px solid #a0a0a0" : "0px solid #a0a0a0"}`, background: "transparent", fontWeight: "500", fontSize: "0.8vw" }}>Company</button>
                                <button
                                    onClick={() => {
                                        if (isBankDetailsValid) {
                                            setActiveIndex(2);
                                        }
                                    }}
                                    style={{ width: "25%", color: "#a0a0a0", height: "40px", borderWidth: 0, borderBottom: `${activeIndex == 2 ? "2px solid #a0a0a0" : "0px solid #a0a0a0"}`, background: "transparent", fontWeight: "500", fontSize: "0.8vw" }}>Bank</button>
                                <button
                                    onClick={() => {
                                        if (isBankDetailsValid) {
                                            setActiveIndex(3);
                                        }
                                    }} style={{ width: "25%", color: "#a0a0a0", height: "40px", borderWidth: 0, borderBottom: `${activeIndex == 3 ? "2px solid #a0a0a0" : "0px solid #a0a0a0"}`, background: "transparent", fontWeight: "500", fontSize: "0.8vw" }}>Pictures</button>
                            </div>
                            {/* <Stepper ref={stepperRef} style={{ flexBasis: '50rem',backgroundColor: 'rgba(255, 255, 255, 0)', opacity:1, background:"transparent" }} orientation="horizontal"> */}
                            {/* <StepperPanel header="Personal" > */}
                            {/* <PersonalDetails /> */}
                            {/* <CompanyDetails /> */}
                            {/* <BankDetails /> */}
                            {activeIndex == 0 && <>
                                <PersonalDetails onValidate={handleValidationForPersonal} />
                                <Button
                                    label="Next"
                                    icon="pi pi-arrow-right"
                                    iconPos="right"
                                    onClick={() => {
                                        if (isPersonalDetailsValid) {
                                            setActiveIndex(activeIndex + 1);
                                        }
                                    }}
                                    disabled={!isPersonalDetailsValid} // Disable button if validation fails
                                    style={{ marginLeft: "25px", marginBottom: "25px", padding:"0.4vw" }}
                                />
                            </>}
                            {/* </StepperPanel> */}

                            {/* <StepperPanel header="Company"> */}
                            {activeIndex == 1 && <>
                                <CompanyDetails onValidate={handleValidationForCompany} />
                                <div className="flex py-4 gap-2" style={{ marginLeft: "25px" }}>
                                    <Button label="Back" style={{padding:"0.4vw"}} severity="secondary" icon="pi pi-arrow-left" onClick={() => setActiveIndex(activeIndex - 1)} />
                                    <Button
                                        label="Next"
                                        icon="pi pi-arrow-right"
                                        iconPos="right"
                                        onClick={() => {
                                            if (isCompanyDetailsValid) {
                                                setActiveIndex(activeIndex + 1);
                                            }
                                        }}
                                        disabled={!isCompanyDetailsValid} // Disable button if validation fails
                                        style={{ marginLeft: "25px", padding:"0.4vw" }}
                                    />
                                </div>
                            </>}
                            {/* </StepperPanel> */}
                            {/* <StepperPanel header="Bank"> */}
                            {activeIndex == 2 && <>
                                <BankDetails onValidate={handleValidationForBank} />
                                <div className="flex py-4" style={{ marginLeft: "25px" }}>
                                    <Button label="Back" style={{padding:"0.4vw"}} severity="secondary" icon="pi pi-arrow-left" onClick={() => setActiveIndex(activeIndex - 1)} />
                                    <Button
                                        label="Next"
                                        icon="pi pi-arrow-right"
                                        iconPos="right"
                                        onClick={() => {
                                            if (isBankDetailsValid) {
                                                setActiveIndex(activeIndex + 1);
                                            }
                                        }}
                                        disabled={!isBankDetailsValid} // Disable button if validation fails
                                        style={{ marginLeft: "25px", padding:"0.4vw" }}
                                    />
                                </div>
                            </>}
                            {/* </StepperPanel> */}
                            {/* <StepperPanel header="Pictures"> */}
                            {activeIndex == 3 && <>
                                <PicturesUpload onValidate={handleValidationForPictures} handleIndex={handleIndex} />
                                {/* <div className="flex py-4" style={{ marginLeft: "25px" }}> */}
                                {/* <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => setActiveIndex(activeIndex - 1)} /> */}
                                {/* <Button
                                        label="Next"
                                        icon="pi pi-arrow-right"
                                        iconPos="right"
                                        // onClick={() => {
                                        //     if (isBankDetailsValid) {
                                        //         setActiveIndex(activeIndex + 1);
                                        //     }
                                        // }}
                                        disabled={!isPicturesDetailsValid} // Disable button if validation fails
                                        style={{ marginLeft: "25px" }}
                                    /> */}
                                {/* </div> */}
                            </>}
                            {/* </StepperPanel> */}
                            {/* </Stepper> */}
                        </div>
                    </div>
                </div>
                <ul className="reg-circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>

        </div>
    );
}

import { TextField, Button, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { FormContext } from '../../Provider/FormProvider'

export default function AdvancePaymentCash({type}) {
    const {formData, updateFormData} = useContext(FormContext);
    const [formValues, setFormValues] = useState({
        amount: "",
        date: "",
        paidBy: "",
        receivedBy: "",
    })

    const [errors, setErrors] = useState({})

    // Function to validate a specific field during input change
    const validateField = (name, value) => {
        let tempErrors = { ...errors }

        switch (name) {
            case "paidBy":
                tempErrors.paidBy = value ? "" : "Paid by is required"
                break
            case "receivedBy":
                tempErrors.receivedBy = value ? "" : "Received by is required"
                break
            case "amount":
                if (!value) {
                    tempErrors.amount = "Amount is required"
                } else if (isNaN(value)) {
                    tempErrors.amount = "Amount must be a number"
                } else {
                    tempErrors.amount = ""
                }
                break
            case "date":
                tempErrors.date = value ? "" : "Date is required"
                break
            default:
                break
        }
        setErrors(tempErrors)
    }

    // Function to handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormValues(prev=>{ 
            const values = {...prev, [name]: value }
            let newFormValues = { AdvancePayment:{cash:{...values},advancePaymentType:"Cash"}, paymentType: type};
            updateFormData("paymentInfo", newFormValues,)
            return values
        })
        validateField(name, value) // Validate as the input changes
    }

    // Validation function for form submission
    const validate = () => {
        let tempErrors = {}
        tempErrors.paidBy = formValues.paidBy ? "" : "Paid by is required"
        tempErrors.receivedBy = formValues.receivedBy ? "" : "Received by is required"
        tempErrors.amount = formValues.amount ? (isNaN(formValues.amount) ? "Amount must be a number" : "") : "Amount is required"
        tempErrors.date = formValues.date ? "" : "Date is required"

        setErrors(tempErrors)
        return Object.values(tempErrors).every(x => x === "")
    }

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault()
        if (validate()) {
            // If valid, process the form values
            console.log("Form is valid and ready to submit: ", formValues)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <TextField
                    fullWidth
                    id="paidBy"
                    name="paidBy"
                    label="Paid By"
                    sx={{ width: "20%", backgroundColor: "#F7F8F9" }}
                    value={formValues.paidBy}
                    onChange={handleInputChange}
                    error={!!errors.paidBy} // Show error state if validation fails
                    helperText={errors.paidBy} // Display error message
                    InputProps={{
                        sx: {
                            height: '45px',
                            fontSize: '13px',
                            borderRadius: 3,
                        }
                    }}
                    InputLabelProps={{
                        sx: {
                            fontSize: '13px',
                            zIndex: 1
                        }
                    }}
                    FormHelperTextProps={{
                        sx: {
                            fontSize: '12px',
                        }
                    }}
                />

                <TextField
                    fullWidth
                    id="receivedBy"
                    name="receivedBy"
                    label="Received By"
                    sx={{ width: "17%", ml: 2, backgroundColor: "#F7F8F9" }}
                    value={formValues.receivedBy}
                    onChange={handleInputChange}
                    error={!!errors.receivedBy}
                    helperText={errors.receivedBy}
                    InputProps={{
                        sx: {
                            height: '45px',
                            fontSize: '13px',
                            borderRadius: 3,
                        }
                    }}
                    InputLabelProps={{
                        sx: {
                            fontSize: '13px',
                            zIndex: 1
                        }
                    }}
                    FormHelperTextProps={{
                        sx: {
                            fontSize: '12px',
                        }
                    }}
                />

                <TextField
                    fullWidth
                    id="amount"
                    name="amount"
                    label="Amount"
                    sx={{ width: "15%", ml: 2, backgroundColor: "#F7F8F9" }}
                    value={formValues.amount}
                    onChange={handleInputChange}
                    error={!!errors.amount}
                    helperText={errors.amount}
                    InputProps={{
                        sx: {
                            height: '45px',
                            fontSize: '13px',
                            borderRadius: 3,
                        }
                    }}
                    InputLabelProps={{
                        sx: {
                            fontSize: '13px',
                            zIndex: 1
                        }
                    }}
                    FormHelperTextProps={{
                        sx: {
                            fontSize: '12px',
                        }
                    }}
                />

                <TextField
                    fullWidth
                    id="date"
                    name="date"
                    label="Date"
                    sx={{ width: "14%", ml: 2, backgroundColor: "#F7F8F9" }}
                    type="date"
                    value={formValues.date}
                    onChange={handleInputChange}
                    error={!!errors.date}
                    helperText={errors.date}
                    InputProps={{
                        sx: {
                            height: '45px',
                            fontSize: '13px',
                            borderRadius: 3,
                        }
                    }}
                    InputLabelProps={{
                        sx: {
                            fontSize: '13px',
                            zIndex: 1
                        }
                    }}
                    FormHelperTextProps={{
                        sx: {
                            fontSize: '12px',
                        }
                    }}
                />
            </div>
        </form>
    )
}

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';
    import { PermissionContext } from '../_helper/Permissions/PermissionContext';
import { url } from "../Constant";

const AuthCheck = ({ children }) => {
    const navigate = useNavigate();
    const [isVerified, setIsVerified] = useState(null); // null means checking, true means verified, false means not verified
    const location = useLocation();
    const currentPath = location.pathname;
  const { setPermissions, permissions } = useContext(PermissionContext);

    useEffect(() => {
        const verifyUser = async () => {
            try {
                const token = localStorage.getItem("token")
                const role = localStorage.getItem("role")

                if (!(token && role)) {
                    navigate(`/sadmin_login`,);
                    return;
                }

                const response = await fetch(`${url}/auth/validate?${currentPath}`, {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ role, currentPath })
                });

                if (response.ok) {
                    const result = await response.json();
                    //console.log(result)
                    if (result.isVerified) {
                        setIsVerified(true);
                        setPermissions(result.permissions);
                        //console.log(result.permissions)
                    } else {
                        setIsVerified(false);
                        navigate(`/sadmin_login`,);
                    }
                } else {
                    setIsVerified(false);
                    navigate(`/sadmin_login`,);
                }
            } catch (error) {
                console.error("Error verifying user:", error);
                setIsVerified(false);
                navigate(`/sadmin_login`,);
            }
        };

        verifyUser();
    }, [navigate, currentPath]);

    if (isVerified === null) {
        // Show loading spinner while checking
        return (
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
                open={true}
            >
                <Box sx={{ width: '100%', position: 'absolute', top: 0 }}>
                    <LinearProgress sx={{ height: 10 }} />
                </Box>
            </Backdrop>
        );
    }
    if (isVerified === false) {
        return null; // Render nothing if verification fails
    }

    // Render children if verified
    return children;
};
export default AuthCheck;
import React, { useEffect, useState, useRef } from 'react';
import { url } from '../../../Constant';
import { Toast } from 'primereact/toast';
import { Chip, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const AssetsCatNavbar = ({ selectedCategory, setSelectedCategory }) => {
  const [categories, setCategories] = useState([]);
  const toast = useRef(null);
  const [loader, setLoader] = useState(true);
  const scrollRef = useRef(null);
  const scrollAmount = 300;

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${url}/api/admin/get_all_categories_for_machines`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        if (response.status === 403) {
          toast.current.show({ severity: 'error', summary: 'Unauthorized', detail: 'You don\'t have rights', life: 3000 });
          throw new Error('Unauthorized');
        }
        throw new Error('Network response was not ok.');
      }
      const result = await response.json();
      setCategories(result);

      // Set the first category as selected by default if categories exist
      if (result.length > 0) {
        setSelectedCategory(result[0].catId);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    } finally {
      setLoader(false); // Set loader to false after fetching
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleScroll = (direction) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: direction * scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleWheelScroll = (event) => {
    event.preventDefault();
    const delta = event.deltaY || event.detail || event.wheelDelta;
    handleScroll(delta > 0 ? 1 : -1);
  };

  useEffect(() => {
    const currentScrollRef = scrollRef.current;
    if (currentScrollRef) {
      currentScrollRef.addEventListener('wheel', handleWheelScroll);
    }
    return () => {
      if (currentScrollRef) {
        currentScrollRef.removeEventListener('wheel', handleWheelScroll);
      }
    };
  }, []);

  useEffect(() => {
    const initialScroll = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollBy({
          top: 0,
          left: scrollAmount,
          behavior: 'smooth',
        });

        setTimeout(() => {
          if (scrollRef.current) {
            scrollRef.current.scrollBy({
              top: 0,
              left: -scrollAmount,
              behavior: 'smooth',
            });
          }
        }, 800);
      }
    };

    const timer = setTimeout(initialScroll, 100);
    return () => clearTimeout(timer);
  }, [categories]);

  return (
    <div>
      <Toast ref={toast} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <h4>CATEGORY</h4> */}
        <IconButton onClick={() => handleScroll(-1)} aria-label="scroll left">
          <ArrowBackIosIcon />
        </IconButton>
        <Box
          ref={scrollRef}
          sx={{
            display: 'flex',
            overflow: 'hidden',
            padding: '10px',
            whiteSpace: 'nowrap',
            flexGrow: 1,
          }}
        >
          {loader ? (
            Array.from(new Array(20)).map((_, index) => (
              <Skeleton key={index} variant="rectangular" width={100} height={30} sx={{ marginRight: '8px',borderRadius:'50px' }} />
            ))
          ) : (
            categories.map((category) => (
              <Chip
                key={category.catId}
                label={category.catName}
                onClick={() => setSelectedCategory(category.catId)}
                sx={{
                    marginRight: '8px',
                    fontSize: '14px',
                    padding: '5px',
                    backgroundColor: selectedCategory === category.catId ? '#1778B0' : 'default', // Custom color when selected
                    color: selectedCategory === category.catId ? 'white' : 'inherit', // Text color when selected
                    '&:hover': {
                        backgroundColor: selectedCategory === category.catId ? '#1778B0' : 'default', // Darker shade on hover when selected
                    }
                }}
            />

            ))
          )}
        </Box>
        <IconButton onClick={() => handleScroll(1)} aria-label="scroll right">
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default AssetsCatNavbar;

import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormContext } from './Provider/FormProvider';

export default function TransportationInfo() {
    const {formData, updateFormData} = useContext(FormContext);
    const [formValues, setFormValues] = useState({
        transporterName: "",
        truckNumber: "",
        fromLocation: "",
        toLocation: "",
        startingDate: "",
        transportationFare: "",
    });

    const [formErrors, setFormErrors] = useState({
        transporterName: "",
        truckNumber: "",
        fromLocation: "",
        toLocation: "",
        startingDate: "",
        transportationFare: "",
    });

    // Validation logic
    const validateField = (name, value) => {
        let error = "";
        if (name === "transporterName" || name === "truckNumber" || name === "fromLocation" || name === "toLocation") {
            if (!value) {
                error = "This field is required";
            }
        }
        if (name === "startingDate") {
            if (!value) {
                error = "Please select a starting date";
            }
        }
        if (name === "transportationFare") {
            if (!value || isNaN(value) || parseFloat(value) <= 0) {
                error = "Please enter a valid transportation fare";
            }
        }
        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update form data
        setFormValues(prev=>{
            const values = {...prev,[name]: value}
            updateFormData("transportationInfo", values)
            return values;
        });

        // Validate the field and set error if invalid
        setFormErrors({
            ...formErrors,
            [name]: validateField(name, value)
        });
    };

    return (
        <div>
            <div style={{ width: "100%", height: "auto", padding: "2%", backgroundColor: "#fff", borderRadius: 30 }}>
                <h5>Transportation Details</h5>
                <div className="row" style={{ marginTop: 25 }}>
                    
                    {/* Transporter Name */}
                    <TextField
                        fullWidth
                        id="transporterName"
                        name="transporterName"
                        label="Transporter Name"
                        sx={{ width: "46.5%", ml: "3%", backgroundColor:"#F7F8F9" }}
                        value={formValues.transporterName}
                        onChange={handleChange}
                        error={!!formErrors.transporterName}
                        helperText={formErrors.transporterName}
                        InputProps={{
                            sx: {
                                height: '45px',
                                fontSize: '13px',
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                    />

                    {/* Truck Number */}
                    <TextField
                        fullWidth
                        id="truckNumber"
                        name="truckNumber"
                        label="Truck Number"
                        sx={{ width: "46.5%", ml: "2%", backgroundColor:"#F7F8F9" }}
                        value={formValues.truckNumber}
                        onChange={handleChange}
                        error={!!formErrors.truckNumber}
                        helperText={formErrors.truckNumber}
                        InputProps={{
                            sx: {
                                height: '45px',
                                fontSize: '13px',
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                    />

                    {/* From Location */}
                    <TextField
                        fullWidth
                        id="fromLocation"
                        name="fromLocation"
                        label="From Location"
                        sx={{ width: "46.5%", ml: "3%", mt:3, backgroundColor:"#F7F8F9" }}
                        value={formValues.fromLocation}
                        onChange={handleChange}
                        error={!!formErrors.fromLocation}
                        helperText={formErrors.fromLocation}
                        InputProps={{
                            sx: {
                                height: '45px',
                                fontSize: '13px',
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                    />

                    {/* To Location */}
                    <TextField
                        fullWidth
                        id="toLocation"
                        name="toLocation"
                        label="To Location"
                        sx={{ width: "46.5%", ml: "2%", mt:3, backgroundColor:"#F7F8F9" }}
                        value={formValues.toLocation}
                        onChange={handleChange}
                        error={!!formErrors.toLocation}
                        helperText={formErrors.toLocation}
                        InputProps={{
                            sx: {
                                height: '45px',
                                fontSize: '13px',
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1
                            }
                        }}
                    />

                    {/* Starting Date */}
                    <TextField
                        fullWidth
                        id="startingDate"
                        name="startingDate"
                        label="Starting Date"
                        type="date"
                        sx={{ width: "46.5%", ml: "3%", mt:3, backgroundColor:"#F7F8F9" }}
                        value={formValues.startingDate}
                        onChange={handleChange}
                        error={!!formErrors.startingDate}
                        helperText={formErrors.startingDate}
                        InputProps={{
                            sx: {
                                height: '45px',
                                fontSize: '13px',
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1,
                            },
                            shrink:true
                        }}
                    />

                    {/* Transportation Fare */}
                    <TextField
                        fullWidth
                        id="transportationFare"
                        name="transportationFare"
                        label="Transportation Fare"
                        type="number"
                        sx={{ width: "46.5%", ml: "2%", mt:3, backgroundColor:"#F7F8F9" }}
                        value={formValues.transportationFare}
                        onChange={handleChange}
                        error={!!formErrors.transportationFare}
                        helperText={formErrors.transportationFare}
                        InputProps={{
                            sx: {
                                height: '45px',
                                fontSize: '13px',
                                borderRadius: 3,
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '13px',
                                zIndex: 1,
                            }
                        }}
                    />
                </div>

                {/* Submit Button (if needed) */}
                {/* <button onClick={handleSubmit} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#0a82fd', color: '#fff', border: 'none', borderRadius: '5px' }}>
                    Submit
                </button> */}
            </div>
        </div>
    );
}

import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';
import { url } from '../../../Constant';
import { Toast } from 'primereact/toast';

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center; /* Center items horizontally */
`;

const Field = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; /* Make fields full width */
    max-width: 300px; /* Limit maximum width for better appearance */
`;

const ErrorMessage = styled.span`
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem; /* Space between input and error message */
`;

const initialCategoryData = {
    catName: '',
    catDescr: '',
};

export default function AddCategoryModal({ editCategoryId,setEditCategoryId, categoryDialog, setCategoryDialog,fetchCategories}) {
    const toast = useRef(null);
    const [categoryData, setCategoryData] = useState(initialCategoryData);
    const [errors, setErrors] = useState({});
    const [visible, setVisible] = useState(false);
    // const [formData, setFormData] = useState(initialFormData);
    // const [errors, setErrors] = useState({});
    // const toast = useRef(null);
    // const [loader, setLoader] = useState(false);
    // const [catIdOptions, setCatIdOptions] = useState()

    useEffect(() => {
        if (editCategoryId) {
            const fetchCategoryData = async () => {
                try {
                    const response = await fetch(`${url}/api/admin/get_category_by_id?catId=${editCategoryId}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setCategoryData({
                            catName: data.catName,
                            catDescr: data.catDescr || '',
                        });
                    } else {
                        toast.current.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Failed to fetch category data',
                            life: 3000,
                        });
                    }
                } catch (error) {
                    console.error('Error fetching category data:', error);
                }
            };
            fetchCategoryData();
        } else {
            setCategoryData(initialCategoryData);
        }
    }, [editCategoryId]);

    function closeForm(){
        fetchCategories();
        setVisible(!visible); setCategoryData(initialCategoryData); setErrors({});setCategoryDialog(false);setEditCategoryId(null);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCategoryData({ ...categoryData, [name]: value });

        // Validate form on input change
        const formErrors = validateForm({ ...categoryData, [name]: value });
        setErrors(formErrors);
    };

    const validateForm = (data) => {
        const newErrors = {};
        if (!data.catName) newErrors.catName = "Category Name is required";
        if (!data.catDescr) newErrors.catDescr = "Category Description is required";
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm(categoryData);
        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) return;

        try {
            const body = editCategoryId 
                ? { ...categoryData, catId: editCategoryId } 
                : categoryData;

            const response = await fetch(`${url}/api/admin/add_category`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (response.ok) {
                const data = await response.json();
                setCategoryData(initialCategoryData);
                setErrors({});
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: editCategoryId ? 'Category updated successfully' : 'Category added successfully',
                    life: 3000,
                });
                // hideDialog(); // Close the dialog after successful submission
                closeForm();
            } else {
                const errorData = await response.json();
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: errorData.message || 'An unexpected error occurred',
                    life: 3000,
                });
            }
        } catch (error) {
            console.error('Error during submission:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Network Error',
                detail: `An error occurred: ${error.message}`,
                life: 3000,
            });
        }
    };
    
    return (
        <>
        <Button label="Add Machine" icon="pi pi-plus" className="p-button-help mx-1" onClick={() => setVisible(!visible)} />
        <Dialog header={editCategoryId ? "Update Category" : "Add Category"} visible={categoryDialog} onHide={() => { closeForm()}} style={{ width: '400px' }}>
            <Toast ref={toast} />
            <FormContainer onSubmit={handleSubmit}>
                <Field>
                    <label htmlFor="catName">Category Name</label>
                    <InputText
                        id="catName"
                        name="catName"
                        value={categoryData.catName}
                        onChange={handleInputChange}
                        style={{ width: '100%' }} // Ensure input takes full width of Field
                    />
                    {errors.catName && <ErrorMessage>{errors.catName}</ErrorMessage>}
                </Field>

                <Field>
                    <label htmlFor="catDescr">Category Description</label>
                    <InputText
                        id="catDescr"
                        name="catDescr"
                        value={categoryData.catDescr}
                        onChange={handleInputChange}
                        style={{ width: '100%' }} // Ensure input takes full width of Field
                    />
                    {errors.catDescr && <ErrorMessage>{errors.catDescr}</ErrorMessage>}
                </Field>

                <Button label="Submit" icon="pi pi-check" type="submit" />
            </FormContainer>
        </Dialog>
        </>
    );
}

import React, { useState } from 'react'
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeatherIcon from 'feather-icons-react';

export default function FullScreen() {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showFullScreenText, setShowFullScreenText] = useState(false);

    const handleMouseEnter = () => {
        setShowFullScreenText(true);
    };

    const handleMouseLeave = () => {
        setShowFullScreenText(false);
    };
    const handleFullScreenToggle = () => {
        if (!isFullScreen) {
            // Enter full screen
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
                document.documentElement.msRequestFullscreen();
            }
        } else {
            // Exit full screen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
        }
        setIsFullScreen(!isFullScreen); // Toggle full screen state
    };
    return (
        <div>
            <FeatherIcon
                id='full_screen_icon'
                color="gray"
                size={"20px"}
                onClick={handleFullScreenToggle}
                style={{marginLeft: "20px", marginTop: "5px", cursor: "pointer", marginRight: "15px" }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                icon={"maximize"} />
        </div>
    )
}

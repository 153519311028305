import React, { createContext, useState } from 'react'

export const FormContext = createContext();
export default function FormProvider({children}) {

    const [formData, setFormData] = useState({
        transportationInfo: {
            transporterName: "",
            truckNumber: "",
            fromLocation: "",
            toLocation: "",
            startingDate: "",
            transportationFare: "",
        },
        bookingInfo: {
            bookingId: "",
            customer: "",
            siteLocation: "",
            operator: "",
            currentHMR: "",
            startHMR: "",
            hrsCommittedPerMonth: "",
            bookingDuration: "",
            bookingType: "Months",
            bookingPrice: "",
        },
        machineInfo: {
            prodOwner: "",
            prodType: "",
            prodId: "",
            machineModel: "",
            machineModelYear: new Date().getFullYear(),
            machineModelYear: undefined,
            machineSerialNo: undefined,
            rtoNumber: undefined,
            insuranceValidThru: new Date().getFullYear(),
            status: ""
        },
        paymentInfo: {
            paymentType: "",
            SecurityCheque: {
                name: "",
                chequeNumber: undefined,
                amount: undefined,
                date: new Date(),
                chequeImage: "",
            },
            AdvancePayment: {
                advancePaymentType:"",
                cash: {
                    amount: undefined,
                    date: new Date(),
                    paidBy: "",
                    receivedBy: "",
                },
                cheque: {
                    name: "",
                    chequeNumber: undefined,
                    amount: undefined,
                    date: new Date(),
                    chequeImage: "",
                },
                bank: {
                    amount: undefined,
                    date: new Date(),
                    bankName: "",
                    UTRNumber: "",
                }
            },
        }
    })

    const updateFormData = (section, newData) => {
        setFormData(prev=>({
            ...prev, 
            [section]:{
                ...prev[section],
                ...newData
            }
        }))
    }
    return (
        <FormContext.Provider value={{formData, updateFormData}}>
            {children}
        </FormContext.Provider>
    )
}
